(function () {
  'use strict';

  angular
    .module('module.marketing')
    .controller('controller.marketing.video.index', ['$rootScope', controller]);

  function controller($rootScope) {
    // TODO: This should not be needed, remove after body class refactor
    $rootScope.bodyClass = '';
  }
})();
