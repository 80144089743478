(function () {
  'use strict';

  angular.module('module.sales').filter('quoteSliceTitle', filter);

  /**
   * Format a quote title for a given quote
   *
   * @returns {function}
   */
  function filter() {
    return function (quote) {
      var title = quote.metadata.reference;

      if (!quote.metadata.description) {
        return title;
      }

      return title + ' &mdash; ' + quote.metadata.description;
    };
  }
})();
