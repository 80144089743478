(function () {
  'use strict';

  angular
    .module('module.visualiser')
    .factory('CompletedImageService', ['$timeout', 'ApiService', service]);

  function service($timeout, ApiService) {
    var pollHandle,
      pollInterval = 500;

    return {
      getList: getList,
      pollList: pollList,
      cancelPollList: cancelPollList,
      getImage: getImage,
      deleteImage: deleteImage,
      createImage: createImage,
      updateImage: updateImage,
    };

    /**
     * Get a list of all completed images in a quote
     * GET /visualiser/{quoteId}/completedimages
     *
     * @param {string} quoteId
     *
     * @returns {$q}
     */
    function getList(quoteId) {
      return ApiService.get('/visualiser/' + quoteId + '/completedimages').then(
        function (response) {
          // return only completed image collection
          return response.data.completedImages;
        },
      );
    }

    /**
     * Get full details of a completed image
     * GET /visualiser/{quoteId}/completedimages/{imageId}
     *
     * @param {string} quoteId
     * @param {string} imageId
     *
     * @returns {$q}
     */
    function getImage(quoteId, imageId) {
      return ApiService.get('/visualiser/' + quoteId + '/completedimages/' + imageId).then(
        function (response) {
          // return only completed image object
          return response.data.completedImage;
        },
      );
    }

    /**
     * Delete a completed image
     * DELETE /visualiser/{quoteId}/completedimages/{imageId}
     *
     * @param {string} quoteId
     * @param {string} imageId
     *
     * @returns {$q}
     */
    function deleteImage(quoteId, imageId) {
      return ApiService.delete('/visualiser/' + quoteId + '/completedimages/' + imageId).then(
        function () {
          // no return other than success state
          return null;
        },
      );
    }

    /**
     * Save a new completed image
     * POST /visualiser/{quoteId}/completedimages
     *
     * @param {string} quoteId
     * @param {object} image
     *
     * @returns {$q}
     */
    function createImage(quoteId, image) {
      return ApiService.post('/visualiser/' + quoteId + '/completedimages', image).then(
        function (response) {
          // return only the completed image data
          return response.data.completedImage;
        },
      );
    }

    /**
     * Update an existing completed image
     * PUT /visualiser/{quoteId}/completedimages/{imageId}
     *
     * @param {string} quoteId
     * @param {string} imageId
     * @param {object} image
     *
     * @returns {$q}
     */
    function updateImage(quoteId, imageId, image) {
      return ApiService.put('/visualiser/' + quoteId + '/completedimages/' + imageId, image).then(
        function (response) {
          // return only completed image data
          return response.data.completedImage;
        },
      );
    }

    /**
     * Start polling for updated completed images, if any not valid
     *
     * @param {string} quoteId
     * @param {function} cb
     */
    function pollList(quoteId, cb) {
      // ensure no running poll
      cancelPollList();

      getList(quoteId).then(function (completedImages) {
        cb(completedImages);

        // stop polling if all completed images are available
        if (!allImagesValid(completedImages)) {
          pollHandle = $timeout(pollList, pollInterval, true, quoteId, cb);
        }
      });
    }

    /**
     * Cancels the running poll
     */
    function cancelPollList() {
      $timeout.cancel(pollHandle);
    }

    /**
     * Check completed images array to see if all are valid
     *
     * @param {[object]} completedImages
     * @returns {boolean}
     */
    function allImagesValid(completedImages) {
      var allValid = true;

      angular.forEach(completedImages, function (image) {
        if (image.thumbUrl === null) {
          allValid = false;
        }
      });

      return allValid;
    }
  }
})();
