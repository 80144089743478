(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .directive('ttPricingMatrixAddModal', ['ModalService', directive]);

  function directive(ModalService) {
    return {
      restrict: 'A',
      scope: {
        matrixType: '<',
        optionalMatrices: '<',
        requiredMatrices: '<',
        onAdd: '<?',
        styleLabels: '<',
      },
      link: link,
    };

    function link(scope, element) {
      element.on('click', function (event) {
        event.preventDefault();
        showModal(
          scope.matrixType,
          scope.optionalMatrices.concat(scope.requiredMatrices),
          scope.onAdd,
          scope.styleLabels,
        );
      });
    }

    function showModal(matrixType, availableMatrices, onAdd, styleLabels) {
      ModalService.showModal({
        controller: 'controller.retailerProducts.pricingMatrices.add.modal',
        templateUrl: 'templates/settings.retailerProducts.pricingMatrices.add.modal.template.html',
        controllerAs: '$ctrl',
        inputs: {
          matrixType: matrixType,
          availableMatrices: availableMatrices,
          styleLabels: styleLabels,
        },
      }).then(function (modal) {
        modal.element.modal();

        modal.close.then(function (addedMatrix) {
          modal.element.modal('hide');

          if (addedMatrix && onAdd) {
            onAdd(addedMatrix);
          }
        });
      });
    }
  }
})();
