(function () {
  'use strict';

  angular.module('tommyApp').factory('BrowserCheckService', ['$window', 'Bowser', service]);

  function service($window, Bowser) {
    return {
      isMobile: Bowser.mobile || Bowser.tablet || false,
      isBrowserSupported: browserSupported(),
      isResolutionSupported: resolutionSupported(),
      isSupported: browserSupported() && resolutionSupported(),
    };

    function browserSupported() {
      // Chrome on iOS uses the Safari rendering engine so to look into issues
      // on iPads, load the site in Safari using this line:
      // return Bowser.chrome || Bowser.safari || false;

      return Bowser.chrome || Bowser.msedge || false;
    }

    function resolutionSupported() {
      if ($window.screen.height * Math.max($window.devicePixelRatio, 1) < 768) {
        return false;
      }

      if ($window.screen.width * Math.max($window.devicePixelRatio, 1) < 768) {
        return false;
      }

      return true;
    }
  }
})();
