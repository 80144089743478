(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .directive('ttCollapsingContainer', ['ModalService', directive]);

  function directive(ModalService) {
    return {
      controllerAs: '$ctrl',
      bindToController: true,
      restrict: 'A',
      scope: {
        collapsed: '<',
      },
      link: link,
      controller: function () {},
    };

    function link(scope, elements, attrs, $ctrl) {
      var firstTransition = true;

      scope.$watch('$ctrl.collapsed', function () {
        if (typeof $ctrl.collapsed === 'undefined') {
          return;
        } else if ($ctrl.collapsed) {
          collapse();
        } else {
          expand();
        }
      });

      function expand() {
        if (firstTransition) {
          elements[0].style.height = null;
          firstTransition = false;
        } else {
          var sectionHeight = elements[0].scrollHeight;
          elements[0].style.height = sectionHeight + 'px';
          window.setTimeout(function () {
            if (!$ctrl.collapsed) {
              elements[0].style.height = null;
            }
          }, 200);
        }
      }

      function collapse() {
        if (firstTransition) {
          elements[0].style.height = '0px';
          firstTransition = false;
        } else {
          var sectionHeight = elements[0].scrollHeight;
          var elementTransition = elements[0].style.transition;
          elements[0].style.transition = '';
          requestAnimationFrame(function () {
            elements[0].style.height = sectionHeight + 'px';
            elements[0].style.transition = elementTransition;
            requestAnimationFrame(function () {
              elements[0].style.height = 0 + 'px';
            });
          });
        }
      }
    }
  }
})();
