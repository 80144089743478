(function () {
  'use strict';

  angular.module('module.retailers').directive('ttAddLibraryProducts', [
    function () {
      return {
        restrict: 'A',
        scope: {
          retailer: '<',
          onAdd: '<',
        },
        controller: ['ModalService', controller],
        bindToController: true,
        link: link,
      };
    },
  ]);

  function controller(ModalService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.onClick = onClick;

    function onClick() {
      ModalService.showModal({
        controller: 'controller.retailers.ownerView.libraryProduct.modal',
        templateUrl: 'templates/retailers.ownerView.libraryProduct.modal.template.html',
        controllerAs: '$ctrl',
        inputs: {
          retailer: $ctrl.retailer,
        },
      }).then(function (modal) {
        modal.element.modal();

        modal.close.then(function (response) {
          angular.element('.modal-backdrop').remove();
          if (response.added) {
            $ctrl.onAdd();
          }
        });
      });
    }
  }

  function link(scope, element, attrs, controller) {
    element.on('click', function (event) {
      event.preventDefault();
      controller.onClick();
    });
  }
})();
