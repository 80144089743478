(function () {
  'use strict';

  const module = angular.module('module.customItem');

  const PRICING_UNIT = {
    EACH: 'each',
    PER_METRE: 'per_m',
    PER_SQUARE_METRE: 'per_m2',
    PER_LITRE: 'per_l',
    PER_KILO: 'per_kg',
  };

  module.constant('PRICING_UNIT', PRICING_UNIT);

  module.constant('PRICING_UNIT_SUFFIX', {
    [PRICING_UNIT.EACH]: '',
    [PRICING_UNIT.PER_METRE]: 'm',
    [PRICING_UNIT.PER_SQUARE_METRE]: 'm²',
    [PRICING_UNIT.PER_LITRE]: 'L',
    [PRICING_UNIT.PER_KILO]: 'kg',
  });

  const CUSTOM_ITEM_CATEGORY = {
    ACCESS_EQUIPMENT: 'Access Equipment',
    BUILDING_WORKS_MATERIALS: 'Building Works & Materials',
    COMPLIANCE: 'Compliance',
    DELIVERY: 'Delivery',
    GLASS_GLAZING: 'Glass & Glazing',
    MASTICS_FIXINGS_ANCILLARIES: 'Mastics, Fixings, Ancillaries',
    PRODUCTS: 'Products',
    SERVICES: 'Services',
    TRIMS_WINDOW_BOARDS: 'Trims & Window Boards',
    WASTE_DISPOSAL: 'Waste Disposal',
    MISC: 'Misc',
  };
  module.constant('CUSTOM_ITEM_CATEGORY', CUSTOM_ITEM_CATEGORY);

  module.constant('CUSTOM_ITEM_CATEGORY_ORDER', [
    CUSTOM_ITEM_CATEGORY.ACCESS_EQUIPMENT,
    CUSTOM_ITEM_CATEGORY.BUILDING_WORKS_MATERIALS,
    CUSTOM_ITEM_CATEGORY.COMPLIANCE,
    CUSTOM_ITEM_CATEGORY.DELIVERY,
    CUSTOM_ITEM_CATEGORY.GLASS_GLAZING,
    CUSTOM_ITEM_CATEGORY.MASTICS_FIXINGS_ANCILLARIES,
    CUSTOM_ITEM_CATEGORY.PRODUCTS,
    CUSTOM_ITEM_CATEGORY.SERVICES,
    CUSTOM_ITEM_CATEGORY.TRIMS_WINDOW_BOARDS,
    CUSTOM_ITEM_CATEGORY.WASTE_DISPOSAL,
    CUSTOM_ITEM_CATEGORY.MISC,
  ]);
})();
