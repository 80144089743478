(function () {
  'use strict';

  angular
    .module('module.settings.retailer')
    .controller('controller.settings.retailer.paymentTerms.list', [
      '$scope',
      'dataPaymentTerms',
      function ($scope, paymentTerms) {
        $scope.paymentTerms = paymentTerms;
      },
    ]);
})();
