(function () {
  'use strict';

  angular.module('module.sales').directive('ttCreateContractReport', [
    function () {
      return {
        restrict: 'A',
        scope: {
          quote: '<',
        },
        controller: ['$state', 'ModalService', 'UpsellModalService', 'SettingsService', controller],
        bindToController: true,
        link: link,
      };
    },
  ]);

  function controller($state, ModalService, UpsellModalService, SettingsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.onClick = onClick;

    function onClick() {
      SettingsService.getSettings().then(function (companySettings) {
        if (companySettings.settings.canGenerateContracts) {
          showModal();
        } else {
          UpsellModalService.showModal('contractReport');
        }
      });
    }

    function showModal() {
      ModalService.showModal({
        controller: 'controller.sales.quotes.createContractReport.modal',
        templateUrl: 'templates/sales.quotes.createContractReport.modal.template.html',
        controllerAs: '$ctrl',
        inputs: {
          quote: vm.quote,
        },
      }).then(function (modal) {
        // open modal
        modal.element.parent().find('#createContractReportModal').modal();

        modal.close.then(function (response) {
          if (response.created) {
            $state.reload();
          }
        });
      });
    }
  }

  function link(scope, element, attrs, controller) {
    element.on('click', function (event) {
      event.preventDefault();
      controller.onClick();
    });
  }
})();
