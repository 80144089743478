(function () {
  'use strict';

  angular.module('module.sales').component('ttOrderPartDocument', {
    templateUrl: 'templates/sales.orders.orderPart.document.component.template.html',
    controller: ['Notification', controller],
    bindings: {
      orderPart: '<',
    },
  });

  function controller(Notification) {
    /* jshint validthis: true */
    var $ctrl = this;

    var orderPartStatus = '';
    $ctrl.status = '';

    $ctrl.$doCheck = function () {
      if (orderPartStatus !== $ctrl.orderPart.status) {
        updatePdfStatus($ctrl.orderPart.status);
      }
    };

    $ctrl.copyLinkAction = copyLinkAction;

    function updatePdfStatus(status) {
      orderPartStatus = status;

      switch (status) {
        case 'queued':
          $ctrl.status = 'Generating';

          return;
        case 'new':
          $ctrl.status = 'Awaiting delivery information';

          return;
        default:
          $ctrl.status = 'Generated';
      }
    }

    function copyLinkAction() {
      Notification.success('Link copied to clipboard');
    }
  }
})();
