(function () {
  'use strict';

  angular.module('module.help').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('help', {
      abstract: true,
      url: '/help',
      views: {
        'navigation@': {
          controller: 'controller.navigation',
          templateUrl: 'templates/navigation.template.html',
          resolve: {
            dataUser: [
              'AuthenticationService',
              function (AuthenticationService) {
                return AuthenticationService.getUser();
              },
            ],
          },
        },
      },
      ncyBreadcrumb: {
        label: 'Help',
      },
    });

    $stateProvider.state('help.faq', {
      url: '/faq',
      views: {
        'content@': {
          templateUrl: 'templates/help.faq.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'FAQ',
      },
      data: {
        meta: {
          titleSuffix: '| Help | FAQ',
        },
      },
    });

    $stateProvider.state('help.howToVideos', {
      url: '/how-to-videos',
      views: {
        'content@': {
          controller: 'controller.help.howTo',
          controllerAs: 'helpVm',
          templateUrl: 'templates/help.howTo.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'How To Videos',
      },
      data: {
        meta: {
          titleSuffix: '| Help | How To Videos',
        },
      },
    });
  }
})();
