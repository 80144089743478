(function () {
  'use strict';

  angular.module('module.profile.team').factory('CustomerSegmentationService', [
    '$q',
    'AuthenticationService',
    'UsersService',
    'SettingsService',
    function ($q, AuthenticationService, UsersService, SettingsService) {
      var managerUserTypes = ['Admin', 'Manager', 'Surveyor'];

      return {
        getVisibleUsers: getVisibleUsers,
        isManagerUser: isManagerUser,
        getSegmenting: getSegmenting,
      };

      /**
       * Gets a promise of a list of users visible to the current user including self
       *
       * @returns {Promise}
       */
      function getVisibleUsers() {
        return $q
          .all({
            segment: getSegmenting(),
            isManagerUser: isManagerUser(),
          })
          .then(function (promises) {
            // if segmenting and not a manager return only own user as single element in array
            if (!promises.isManagerUser) {
              return getOwnUser().then(function (user) {
                return [user];
              });
            }

            // get full user list and format
            var users = UsersService.listUsers().then(function (response) {
              return response.users.map(formatUser);
            });

            // sort
            return users.then(sortUsers);
          });
      }

      /**
       * Format a user object
       * TODO: remove split when name is supplied in component parts
       *
       * @param user
       *
       * @returns {{id, name}}
       */
      function formatUser(user) {
        return {
          id: user.id,
          name: user.metadata.name.split(' ', 1)[0],
        };
      }

      /**
       * Move own user to start of array
       *
       * @param users
       */
      function sortUsers(users) {
        return getOwnUser().then(function (user) {
          return users.sort(function (a) {
            if (a.id === user.id) {
              return -1;
            }

            return 1;
          });
        });
      }

      /**
       * Get userdata for own user
       *
       * @returns {Promise}
       */
      function getOwnUser() {
        return AuthenticationService.getUser().then(formatUser);
      }

      /**
       * Check whether customer segmentation is enabled
       *
       * @returns {$q}
       */
      function getSegmenting() {
        return SettingsService.getSettings().then(function (data) {
          return data.settings.segmentCustomers;
        });
      }

      /**
       * Check if user is of type able to see other users
       *
       * Note: This function sounds like it should be in the AuthenticationService but in this context Surveyor is a
       *       Manager as will need to see all users customers.
       *
       * @returns {Promise}
       */
      function isManagerUser() {
        return AuthenticationService.getUser().then(function (user) {
          return managerUserTypes.indexOf(user.metadata.userType.name) !== -1;
        });
      }
    },
  ]);
})();
