(function () {
  'use strict';

  angular.module('module.products').directive('ttProductDetails', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/products.retrieve.details.directive.template.html',
        controllerAs: 'detailsVm',
        scope: {
          product: '=',
        },
        controller: function () {},
        bindToController: true,
      };
    },
  ]);
})();
