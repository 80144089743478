(function () {
  'use strict';

  angular.module('module.settings.advanced').service('SettingsProductTypesService', [
    'SettingsService',
    'TypesService',
    function (SettingsService, TypesService) {
      var service = {};

      service.ProductTypesRetrieve = function () {
        return TypesService.List('products').then(function (response) {
          var productTypes = response;

          SettingsService.getSettings().then(function (settings) {
            var settingsProductTypes = settings.settings.productTypes;

            for (var i = 0, l = productTypes.length; i < l; i++) {
              for (var j = 0, k = settingsProductTypes.length; j < k; j++) {
                if (productTypes[i].id === settingsProductTypes[j].id) {
                  productTypes[i].selected = true;
                }
              }
            }
          });

          return productTypes;
        });
      };

      service.ProductTypesUpdate = function (productTypes) {
        var processedProductTypes = [];

        for (var i = 0, l = productTypes.length; i < l; i++) {
          if (productTypes[i].selected === true) {
            processedProductTypes.push({id: productTypes[i].id});
          }
        }

        return SettingsService.getSettings(true).then(function (settings) {
          settings.settings.productTypes = processedProductTypes;
          SettingsService.updateSettings(settings);
        });
      };

      return service;
    },
  ]);
})();
