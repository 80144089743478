(function () {
  'use strict';

  angular.module('module.products').controller('controller.components.update', [
    '$scope',
    '$state',
    'ComponentsService',
    'dataComponent',
    'dataProducts',
    'dataProfileSuites',
    function ($scope, $state, ComponentsService, dataComponent, dataProducts, dataProfileSuites) {
      delete dataComponent.attributes;
      delete dataComponent.constraints;
      delete dataComponent.attributes;
      delete dataComponent.prices;
      delete dataComponent.manufacturerAttributes;
      delete dataComponent.manufacturerConstraints;

      $scope.component = dataComponent;

      $scope.update = function () {
        $scope.formErrors = [];

        if ($scope.component.metadata.componentType.length > 1) {
          $scope.component.metadata.componentType = {
            id: $scope.component.metadata.componentType[
              $scope.component.metadata.componentType.length - 1
            ].id,
          };
        }

        ComponentsService.updateComponent($scope.component).then(
          function () {
            $scope.formComponentDetails.$setPristine();
          },
          function (response) {
            /* Handle request errors manually until a more robust solution is put in place */
            if (response.data.error !== undefined) {
              if (response.data.error['metadata.supplierCode'] !== undefined) {
                $scope.formErrors.supplierCode = response.data.error['metadata.supplierCode'][0];
              }
            }
          },
        );
      };

      /* Products */
      $scope.componentProducts = dataProducts;

      $scope.updateProducts = function () {
        var component = angular.copy($scope.component);

        component.products = [];
        angular.forEach($scope.componentProducts, function (product) {
          if (product.selected === true) {
            component.products.push({id: product.id});
          }
        });

        ComponentsService.updateComponent(component);
      };

      $scope.processProducts = function () {
        for (var i = 0, l = $scope.componentProducts.length; i < l; i++) {
          var product = $scope.componentProducts[i];
          product.selected =
            $scope.component.products
              .map(function (e) {
                return e.id;
              })
              .indexOf(product.id) !== -1;
        }
      };

      $scope.processProducts();

      /* Profile Suites */
      $scope.componentProfileSuites = dataProfileSuites.profilesuites;

      $scope.updateProfileSuites = function () {
        var component = angular.copy($scope.component);

        component.profileSuites = [];
        angular.forEach($scope.componentProfileSuites, function (profileSuite) {
          if (profileSuite.selected === true) {
            component.profileSuites.push({id: profileSuite.id});
          }
        });

        ComponentsService.updateComponent(component);
      };

      $scope.processProfileSuites = function () {
        if ($scope.component.profileSuites !== undefined) {
          for (var i = 0, l = $scope.componentProfileSuites.length; i < l; i++) {
            var profilesuite = $scope.componentProfileSuites[i];
            profilesuite.selected =
              $scope.component.profileSuites
                .map(function (e) {
                  return e.id;
                })
                .indexOf(profilesuite.id) !== -1;
          }
        }
      };

      $scope.processProfileSuites();

      $scope.removeUpdate = function () {
        $scope.updated = false;
      };
    },
  ]);
})();
