(function () {
  'use strict';

  angular.module('module.sales').filter('finaliseModalTitle', filter);

  var modeTitles = {
    customerInactive: 'Customer details incomplete',
    completedImages: 'Select visualisations to include in quote',
    processing: 'Preparing quote',
    emailSent: 'Quote emailed',
    selectDocuments: 'Include documents with quote',
    pdfFailed: 'Quote PDF generation failed',
    quoteSettings: 'Customise Your Quote',
  };

  /**
   * Get style text for a quote item
   *
   * @returns {function}
   */
  function filter() {
    return function (mode) {
      if (!angular.isDefined(modeTitles[mode])) {
        throw 'Unknown finalise modal mode [' + mode + ']';
      }

      return modeTitles[mode];
    };
  }
})();
