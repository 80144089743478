(function () {
  'use strict';

  angular
    .module('module.settings.advanced.documentStore')
    .controller('controller.settings.advanced.documentStore.upload.modal', [
      '$q',
      '$element',
      'DocumentStoreUploadService',
      'maxUploads',
      'close',
      controller,
    ]);

  function controller($q, $element, DocumentStoreUploadService, maxUploads, close) {
    /* jshint validthis: true */
    var vm = this;

    vm.upload = upload;
    vm.closeModal = closeModal;
    vm.deleteDocument = deleteDocument;

    vm.documents = [];
    vm.maxUploads = maxUploads;

    /**
     * Handle upload
     */
    function upload() {
      var promises = [];

      vm.uploading = true;

      angular.forEach(vm.documents, function (document) {
        promises.push(DocumentStoreUploadService.upload(document));
      });

      $q.all(promises).then(function (newDocuments) {
        vm.uploading = false;
        closeModal(newDocuments);
      });
    }

    /**
     * @param {Object} document
     */
    function deleteDocument(document) {
      vm.documents.splice(vm.documents.indexOf(document), 1);
    }

    /**
     * Trigger modal cleanup and pass any new documents
     */
    function closeModal(newDocuments) {
      if (!vm.uploading) {
        $element.modal('hide');
        close(newDocuments);
      }
    }
  }
})();
