(function () {
  'use strict';

  angular.module('module.settings.retailerGlass').directive('ttSettingsGlassTabFeature', directive);

  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/settings.retailerGlass.tab.feature.directive.template.html',
      controllerAs: 'featureVm',
      scope: {
        featureSettings: '=',
        hideOwnCosts: '<?',
        hideManufacturerCosts: '<?',
      },
      controller: ['CurrencyService', controller],
      bindToController: true,
    };
  }

  function controller(CurrencyService) {
    /* jshint validthis: true */
    var vm = this;

    vm.currencySymbol = CurrencyService.getSettings().currencySymbol;
  }
})();
