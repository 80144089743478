(function () {
  'use strict';

  angular.module('module.navigation').directive('ttBreadcrumbs', function () {
    return {
      restrict: 'A',
      scope: {},
      controller: ['BreadcrumbsService', controller],
      controllerAs: 'breadcrumbVm',
      templateUrl: 'templates/navigation.breadcrumbs.directive.template.html',
    };
  });

  /**
   * @param BreadcrumbsService
   */
  function controller(BreadcrumbsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.breadcrumbsHidden = false;
    BreadcrumbsService.onChange(function (hidden) {
      vm.breadcrumbsHidden = hidden;
    });
  }
})();
