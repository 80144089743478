(function () {
  'use strict';
  angular
    .module('module.settings.retailer')
    .controller('controller.settings.retailer.discount', [
      'RetailerDiscountApiService',
      'dataSettings',
      controller,
    ]);

  function controller(RetailerDiscountApiService, dataSettings) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.isSubmitting = false;
    $ctrl.defaultDiscount = dataSettings.settings.defaultRetailerDiscount;

    $ctrl.save = function () {
      if ($ctrl.isSubmitting) {
        return;
      }
      $ctrl.isSubmitting = true;
      update(false);
    };

    $ctrl.applyToAllRetailers = function () {
      if ($ctrl.isSubmitting) {
        return;
      }
      $ctrl.isSubmitting = true;
      update(true);
    };

    function update(applyToAllRetailers) {
      RetailerDiscountApiService.update($ctrl.defaultDiscount, applyToAllRetailers)

        .finally(function () {
          $ctrl.isSubmitting = false;
        });
    }
  }
})();
