(function () {
  'use strict';

  angular.module('module.sales').directive('ttNotesDetails', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/sales.quotes.notes.directive.template.html',
        controllerAs: 'quoteVm',
        scope: {
          customer: '=',
          quote: '=',
        },
        controller: ['$scope', '$state', 'QuotesService', 'QuoteFinalisationService', controller],
        bindToController: true,
      };
    },
  ]);

  /**
   * @param ModalService
   * @param PhotoBankService
   */
  function controller($scope, $state, QuotesService, QuoteFinalisationService) {
    /* jshint validthis: true */
    var vm = this;
    vm.update = update;

    vm.readOnly = QuoteFinalisationService.isFinalised(vm.quote);

    function update() {
      QuotesService.updateQuote(vm.quote);
    }

    $scope.$watch('detailsVm.quote.metadata.status', function () {
      vm.finalised = QuoteFinalisationService.isFinalised(vm.quote);
    });
  }
})();
