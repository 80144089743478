(function () {
  'use strict';

  angular.module('module.sales').component('ttPendingRetailerSlice', {
    templateUrl: 'templates/sales.retailers.pending.slice.component.template.html',
    bindings: {
      pendingRetailer: '<',
    },
  });
})();
