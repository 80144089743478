(function () {
  'use strict';

  angular.module('module.sales').directive('ttCreateQuote', [
    '$state',
    'QuotesService',
    function ($state, QuotesService) {
      return {
        restrict: 'A',
        scope: {
          customer: '=',
          creatingNewQuote: '=',
        },
        link: link,
      };

      /**
       * Create a new quote on click
       *
       * @param scope
       * @param element
       */
      function link(scope, element) {
        element.on('click', function (event) {
          event.preventDefault();
          scope.creatingNewQuote = true;
          QuotesService.createQuote(scope.customer)
            .then(function (quote) {
              $state.go('sales.quotes.retrieve', {quoteId: quote.id});
            })
            .catch(function (error) {
              scope.creatingNewQuote = false;
            });
        });
      }
    },
  ]);
})();
