(function () {
  'use strict';

  angular.module('module.visualiser').component('ttVisualiserButton', {
    templateUrl: 'templates/visualiser.enterButton.component.template.html',
    bindings: {
      quote: '<',
    },
    controller: ['$state', 'SettingsService', 'UpsellModalService', controller],
  });

  function controller($state, SettingsService, UpsellModalService) {
    /* jshint validthis: true */
    var $ctrl = this,
      companyActive = false,
      makeoversEnabled = false;

    $ctrl.showButton = false;

    $ctrl.$onChanges = onChanges;
    $ctrl.onClick = onClick;

    SettingsService.getSettings().then(function (settings) {
      companyActive = settings.metadata.active;
      makeoversEnabled = settings.settings.makeoversEnabled;
      updateStatus();
    });

    function onChanges(changes) {
      if (typeof changes.quote !== 'undefined' && !changes.quote.isFirstChange()) {
        updateStatus();
      }
    }

    function updateStatus() {
      $ctrl.hasCompletedImages = $ctrl.quote.metadata.completedImagesCount > 0;
      $ctrl.showButton = companyActive && !isFinalisedWithNoVisualisations();
    }

    function isFinalisedWithNoVisualisations() {
      return $ctrl.quote.metadata.status !== 'draft' && !$ctrl.hasCompletedImages;
    }

    function onClick() {
      if (makeoversEnabled) {
        $state.go('visualiser.dash', {quoteId: $ctrl.quote.id});
      } else {
        UpsellModalService.showModal('makeover');
      }
    }
  }
})();
