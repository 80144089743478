(function () {
  'use strict';

  angular
    .module('module.marketing')
    .controller('controller.marketing.video.package', [
      '$scope',
      '$stateParams',
      '$state',
      'VideoApiService',
      controller,
    ]);

  function controller($scope, $stateParams, $state, VideoApiService) {
    /* jshint validthis: true */
    var vm = this;

    // this needs to be on scope to support the breadcrumb when in other states
    $scope.packageName = $stateParams.packageName;

    vm.accceptTerms = false;
    vm.order = order;

    /**
     * Order video if terms are accepted
     */
    function order() {
      VideoApiService.create($scope.packageName).then(function () {
        $state.go('marketing.video.package.complete');
      });
    }
  }
})();
