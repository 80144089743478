(function () {
  'use strict';
  angular.module('module.profile').controller('controller.profile.myprofile', [
    '$scope',
    'UsersService',
    'dataProfile',
    function ($scope, UsersService, dataProfile) {
      $scope.user = dataProfile;
      $scope.isSubmitting = false;

      $scope.save = function () {
        if ($scope.isSubmitting) {
          return;
        }

        $scope.isSubmitting = true;
        UsersService.updateUser($scope.user).finally(function () {
          $scope.isSubmitting = false;
        });
      };
    },
  ]);
})();
