(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .factory('QuoteSettingsApiService', [
      '$q',
      'Notification',
      'ApiService',
      'SettingsService',
      service,
    ]);

  function service($q, Notification, ApiService, SettingsService) {
    return {
      getSettings: getSettings,
      updateSettings: updateSettings,
    };

    function getSettings() {
      return SettingsService.getSettings(true).then(function (response) {
        var settings = {
          quotePdfHideDimensions: response.settings.quotePdfHideDimensions,
          defaultQuoteExpiryDays: response.settings.defaultQuoteExpiryDays,
          showCompanyDetailsOnQuote: response.settings.showCompanyDetailsOnQuote,
          guarantee: response.settings.guarantee,
          leadTime: response.settings.leadTime,
          showCheckaTradeBranding: response.settings.showCheckaTradeBranding,
          checkaTradeId: response.settings.checkaTradeId,
        };
        return settings;
      });
    }

    function updateSettings(settings) {
      return ApiService.put('/settings/quotes', settings)
        .then(function () {
          Notification.success('Updated quote settings successfully');
        })
        .catch(function () {
          Notification.error('Failed to update quote settings');
          return $q.reject('Failed to update quote settings');
        });
    }
  }
})();
