(function () {
  'use strict';

  angular.module('module.settings.advanced').directive('ttSettingsAdvancedProductStyles', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/settings.advanced.productStyles.directive.template.html',
        controllerAs: 'stylesVm',
        scope: {
          productType: '<',
        },
        controller: ['$scope', 'ProductStylesService', controller],
        bindToController: true,
      };
    },
  ]);

  function controller($scope, ProductStylesService) {
    /* jshint validthis: true */
    var vm = this;
    vm.save = save;

    $scope.$watch('stylesVm.productType', getProductStyles);

    /**
     * Get product styles for a given product type.
     *
     * @param {object} productType
     */
    function getProductStyles(productType) {
      ProductStylesService.getProductStylesForProductType(productType)
        .then(processProductStyles)
        .then(function (productStyles) {
          vm.productStyles = productStyles;
        });
    }

    /**
     * Save product styles to API.
     */
    function save() {
      ProductStylesService.updateCollectionOfStyles(vm.productStyles, vm.productType)
        .then(processProductStyles)
        .then(function (productStyles) {
          vm.productStyles = productStyles;
        });
    }

    /**
     * Clear manufacturer name if matches internal name to avoid confusion.
     *
     * @param {Array} productStyles
     * @returns {Array}
     */
    function processProductStyles(productStyles) {
      angular.forEach(productStyles, function (productStyle) {
        if (productStyle.manufacturerName === productStyle.name) {
          productStyle.manufacturerName = '';
        }
      });

      return productStyles;
    }
  }
})();
