(function () {
  'use strict';

  angular
    .module('module.settings.partsShop')
    .controller('controller.settings.partsShop.list', [
      '$scope',
      'PartsShopApiService',
      'CUSTOM_ITEM_CATEGORY_ORDER',
      controller,
    ]);

  function controller($scope, PartsShopApiService, CATEGORY_ORDER) {
    /* jshint validthis: true */
    const $ctrl = this;

    $ctrl.onDelete = onDelete;
    $ctrl.onCopy = onCopy;
    $ctrl.items = null;
    $ctrl.categories = [];

    const listPoller = PartsShopApiService.pollList();
    $scope.$on('$destroy', () => listPoller.cancel());
    listPoller.promise.then(updateItems, null, updateItems);

    function updateItems(items) {
      $ctrl.items = items;
      updateCategoryList();
    }

    function onDelete(item) {
      $ctrl.items.splice($ctrl.items.indexOf(item), 1);
      updateCategoryList();
    }

    function onCopy() {
      PartsShopApiService.list().then((items) => ($ctrl.items = items));
    }

    function updateCategoryList() {
      $ctrl.categories = [];
      $ctrl.items.forEach((item) => {
        if (!$ctrl.categories.includes(item.category)) {
          $ctrl.categories.push(item.category);
        }
      });
      $ctrl.categories = $ctrl.categories.sort(
        (a, b) => CATEGORY_ORDER.indexOf(a) - CATEGORY_ORDER.indexOf(b),
      );
    }
  }
})();
