(function () {
  'use strict';

  angular
    .module('module.navigation')
    .controller('controller.navigation', [
      '$scope',
      'NavigationService',
      'AuthenticationService',
      controller,
    ]);

  /**
   * @param $scope
   * @param NavigationService
   * @param AuthenticationService
   */
  function controller($scope, NavigationService, AuthenticationService) {
    $scope.navigation = NavigationService.getMainNavItems();
    $scope.dropdownSettings = NavigationService.getSettingsDropdownItems();
    $scope.dropdownProfile = NavigationService.getProfileDropdownItems();
    $scope.dropdownMarketing = NavigationService.getMarketingDropdownItems();
    $scope.showHelp = AuthenticationService.getPermissions().companyType !== 'owner';

    $scope.signOut = function () {
      AuthenticationService.signOut();
    };
  }
})();
