(function () {
  'use strict';

  angular.module('module.sales').filter('quoteFinalisationStatus', filter);

  /**
   * @returns {function}
   */
  function filter() {
    return function (quote, retailerActive) {
      if (typeof quote === 'undefined') {
        return '';
      }

      if (!retailerActive) {
        return 'Onboarding has not been completed';
      }

      if (quote.metadata.status === 'expired') {
        return 'Quote is expired';
      }

      if (['needsReprice', 'repricing'].includes(quote.metadata.status)) {
        return 'Quote is being repriced';
      }

      if (!quote.metadata.customer.active) {
        return 'Customer details are incomplete';
      }

      if (
        quote.metadata.status === 'finalising' &&
        quote.metadata.documents.quote.pdfGenerationFailed
      ) {
        return 'PDF generation failed';
      }

      if (quote.metadata.totalItems > 0 && !quote.metadata.allowFinalise) {
        return 'Quote has no valid items';
      }

      return '';
    };
  }
})();
