(function () {
  'use strict';

  angular.module('tommyApp').directive('intCurrency', directive);

  /**
   * Directive to format currency values from and to pence format used in API
   */
  function directive() {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: link,
      priority: 100, // run at top of stack
    };

    /**
     * @param scope
     * @param element
     * @param attr
     * @param ngModel
     */
    function link(scope, element, attr, ngModel) {
      ngModel.$formatters.push(toDisplayTransformer);
      ngModel.$parsers.push(toApiTransformer);
    }

    /**
     * Multiply by 100 and remove DP for API (stored as integer)
     *
     * @param {string|Number} displayValue
     * @returns {Number|void}
     */
    function toApiTransformer(displayValue) {
      if (!angular.isNumber(displayValue)) {
        displayValue = parseFloat(displayValue);
      }

      if (isNaN(displayValue)) {
        return;
      }

      return Math.round(displayValue * 100);
    }

    /**
     * Divide by 100 for display (displayed as number)
     *
     * @param {int|null} apiValue
     * @returns {Number|void}
     */
    function toDisplayTransformer(apiValue) {
      if (!angular.isNumber(apiValue)) {
        return;
      }

      return apiValue / 100;
    }
  }
})();
