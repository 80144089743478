(function () {
  'use strict';

  angular.module('module.settings').controller('controller.settings.productranges.retrieve', [
    '$scope',
    '$state',
    'productRangesService',
    'dataProductRange',
    function ($scope, $state, productRangesService, dataProductRange) {
      $scope.productrange = dataProductRange.productrange;
      $scope.productrangeProducts = dataProductRange.reference.products;

      $scope.update = function () {
        productRangesService
          .updateProductRange(
            $scope.productrange.id,
            $scope.productrange,
            $scope.productrangeProducts,
          )
          .success(function () {
            $state.reload();
          });
      };
    },
  ]);
})();
