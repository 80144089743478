(function () {
  'use strict';

  angular.module('module.users').factory('UsersApiService', ['ApiService', service]);

  function service(ApiService) {
    return {
      getPendingUsers: getPendingUsers,
      getAllUsers: getAllUsers,
    };

    function getPendingUsers() {
      return ApiService.get('/users/pending').then(function (response) {
        return response.data;
      });
    }

    function getAllUsers() {
      return ApiService.get('/users/all').then(function (response) {
        return response.data;
      });
    }
  }
})();
