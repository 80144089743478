(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.manufacturers.update', [
      '$state',
      'CompaniesService',
      'dataManufacturer',
      controller,
    ]);

  function controller($state, CompaniesService, dataManufacturer) {
    /* jshint validthis: true */
    var vm = this;

    vm.update = update;

    vm.manufacturer = dataManufacturer;

    function update() {
      CompaniesService.updateCompany('/manufacturers/' + vm.manufacturer.id, vm.manufacturer).then(
        function () {
          $state.go('sales.manufacturers.list');
        },
      );
    }
  }
})();
