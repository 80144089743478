(function () {
  'use strict';

  angular.module('tommyApp').config([
    '$provide',
    function ($provide) {
      $provide.decorator('currencyFilter', ['$delegate', 'CurrencyService', currencyDecorator]);
    },
  ]);

  function currencyDecorator($delegate, CurrencyService) {
    var originalFilter = $delegate;

    function localisedCurrency(amount, symbol, fractionSize) {
      symbol = symbol || CurrencyService.getSettings().currencySymbol;

      return originalFilter(amount, symbol, fractionSize);
    }

    localisedCurrency.$stateful = true;

    return localisedCurrency;
  }
})();
