(function () {
  'use strict';

  angular.module('module.retailers').component('ttActiveCampaignAccount', {
    templateUrl: 'templates/retailers.activeCampaignAccount.component.template.html',
    bindings: {
      retailer: '<',
    },
    controller: ['ActiveCampaignApiService', '$animate', controller],
  });

  function controller(ActiveCampaignApiService, $animate) {
    /* jshint validthis: true */
    var $ctrl = this;

    $animate.enabled(false);

    $ctrl.update = update;
    $ctrl.getAccounts = getAccounts;

    $ctrl.account = $ctrl.retailer.activeCampaignAccount;
    $ctrl.accounts = [];

    function getAccounts(search) {
      return ActiveCampaignApiService.searchAccounts(search).then(function (accounts) {
        $ctrl.accounts = accounts;
      });
    }

    function update() {
      ActiveCampaignApiService.updateAccount($ctrl.retailer, $ctrl.account);
    }
  }
})();
