(function () {
  'use strict';

  angular.module('module.sales').filter('orderSliceStatusText', ['AuthenticationService', filter]);

  var statusText = {
      new: 'New',
      ready: 'Ready to send',
      queued: 'Processing',
      sent: 'Sent',
      accepted: 'Accepted',
      dispatched: 'Dispatched',
      delivered: 'Delivered',
    },
    statusTextManufacturer = angular.copy(statusText);

  statusTextManufacturer.sent = 'Received';

  /**
   * Generate a status text for a given order
   *
   * @returns {function}
   */
  function filter(AuthenticationService) {
    return function (status) {
      if (AuthenticationService.getPermissions().companyType === 'manufacturer') {
        return statusTextManufacturer[status];
      }

      return statusText[status];
    };
  }
})();
