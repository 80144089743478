(function () {
  'use strict';

  angular.module('module.api').factory('UploadService', ['Upload', 'ApiUrlService', service]);

  function service(Upload, ApiUrlService) {
    return {
      uploadImage: uploadImage,
      uploadDocument: uploadDocument,
    };

    /**
     * Upload a generic image
     *
     * @param {object} image Model created by ng-file-upload
     * @returns {$q}
     */
    function uploadImage(image) {
      return upload(image, '/upload/image', 'image');
    }

    /**
     * Upload a generic document
     *
     * @param {object} document Model created by ng-file-upload
     * @returns {$q}
     */
    function uploadDocument(document) {
      return upload(document, '/upload/document', 'file');
    }

    /**
     * @param {object} file
     * @param {string} url
     * @param {string} apiParam
     * @returns {$q}
     */
    function upload(file, url, apiParam) {
      var data = {};
      data[apiParam] = file;

      return Upload.upload({
        url: ApiUrlService.getApiUrl(url),
        data: data,
      }).then(
        function (response) {
          file.resourceId = response.data.resourceId;

          return file;
        },
        null,
        function (event) {
          // notify progress as a percentage
          return (100 * event.loaded) / event.total;
        },
      );
    }
  }
})();
