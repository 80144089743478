(function () {
  'use strict';

  angular.module('module.visualiser').directive('ttSkewImage', [
    '$window',
    'numeric',
    'CompositorScalingService',
    function ($window, numeric, CompositorScalingService) {
      return {
        restrict: 'E',
        templateUrl: 'templates/visualiser.compositor.skewImage.directive.template.html',
        scope: {
          image: '=',
          deleteFunc: '&',
        },
        controllerAs: 'imageVm',
        bindToController: true,
        controller: controller,
        require: ['^^ttCompositor', 'ttSkewImage'],
        link: getLink($window, numeric, CompositorScalingService),
      };
    },
  ]);

  function controller() {
    /* jshint validthis: true */
    var vm = this;

    vm.deleteImage = deleteImage;

    function deleteImage() {
      vm.deleteFunc()(vm.image);
    }
  }

  /**
   * Build link function
   * @param $window
   * @param numeric
   * @param CompositorScalingService
   * @returns {function}
   */
  function getLink($window, numeric, CompositorScalingService) {
    return function (scope, element, attrs, controller) {
      var img = element.find('.skew-image'),
        imgElement = img[0],
        skewControls = element.find('.skew-image__control-point'),
        deleteControl = element.find('.skew-image__control-delete'),
        transformationOrigin,
        compositorVm = controller[0],
        imageVm = controller[1],
        touchId = null;

      init();

      /**
       * Init the directive
       */
      function init() {
        img.on('load', function () {
          // use natural image dimensions as original position to ensure full quality transformation
          transformationOrigin = [
            {x: 0, y: 0}, // top left
            {x: 0, y: imgElement.naturalHeight}, // bottom left
            {x: imgElement.naturalWidth, y: 0}, // top right
            {x: imgElement.naturalWidth, y: imgElement.naturalHeight}, // bottom right
          ];

          initScaling();
          img.css('display', 'block'); // hidden before initial transform to prevent FOUC
        });

        img.on('mousedown touchstart', moveStartImage);
        skewControls.on('mousedown touchstart', moveStartSkewControl);
      }

      /**
       * Add scaling event listeners and do initial render
       */
      function initScaling() {
        CompositorScalingService.addScaleChangeEventListener(onScaleChange);

        scope.$on('$destroy', function () {
          CompositorScalingService.removeScaleChangeEventListener(onScaleChange);
        });

        renderImage();
      }

      /**
       * Re-render image on scale change
       * Reverse existing scale then scale to new factor
       */
      function onScaleChange(scalingFactor, oldScalingFactor) {
        angular.forEach(imageVm.image.position, function (corner) {
          corner.x = CompositorScalingService.scale(
            CompositorScalingService.reverseScale(corner.x, oldScalingFactor),
            scalingFactor,
          );
          corner.y = CompositorScalingService.scale(
            CompositorScalingService.reverseScale(corner.y, oldScalingFactor),
            scalingFactor,
          );
        });

        renderImage();
      }

      /**
       * Hook up item image movement to drag of image
       *
       * @param event
       */
      function moveStartImage(event) {
        // if id is set we are already handling a touch session
        if (touchId !== null) {
          return;
        }

        // set as selected image
        scope.$apply(function () {
          compositorVm.selectImage(imageVm.image);
        });

        captureMove(event, function (offset) {
          angular.forEach(imageVm.image.position, function (corner) {
            corner.x += offset.x;
            corner.y += offset.y;
          });

          renderImage();
        });
      }

      /**
       * Hook up skewing to drag of skew points
       *
       * TODO: fix mouse drift on moving in forbidden area
       * @param startEvent
       */
      function moveStartSkewControl(startEvent) {
        // if id is set we are already handling a touch session
        if (touchId !== null) {
          return;
        }

        var point = parseInt(startEvent.target.dataset.point);

        captureMove(startEvent, function (offset) {
          var position = imageVm.image.position[point],
            tempPosition;

          tempPosition = {x: position.x + offset.x, y: position.y + offset.y};
          if (isValidSkewPoint(point, tempPosition, imageVm.image.position)) {
            position.x = tempPosition.x;
            position.y = tempPosition.y;

            renderImage();
          }
        });
      }

      /**
       * Render the transformed image and update control points
       */
      function renderImage() {
        img.css(getTransformCss(transformationOrigin, imageVm.image.position));
        updateControlPosition(imageVm.image.position);
      }

      /**
       * Provide mouse move / touch offsets to a callback function
       * TODO: create directive
       *
       * @param startEvent
       * @param cb
       */
      function captureMove(startEvent, cb) {
        startEvent.preventDefault();

        // get touch id and copy client co-ords if a touch event
        if (isTouchEvent(startEvent)) {
          var touch = getTouchFromEvent(startEvent.originalEvent);
          touchId = touch.identifier;
          startEvent.clientX = touch.clientX;
          startEvent.clientY = touch.clientY;
        }

        var lastPosition = getCurrentPosition(startEvent);
        $window.addEventListener('mousemove', onMove);
        $window.addEventListener('touchmove', onMove);
        $window.addEventListener('mouseup', cleanup);
        $window.addEventListener('touchend', cleanup);

        /**
         * Run callback on move event
         *
         * @param moveEvent
         */
        function onMove(moveEvent) {
          // touch shim
          if (touchId !== null) {
            // get touch and stop processing if tracked id hasn't moved
            var touch = getTouchFromEvent(moveEvent, touchId);
            if (!touch) {
              return;
            }

            moveEvent.clientX = touch.clientX;
            moveEvent.clientY = touch.clientY;
          }

          var currentPosition = getCurrentPosition(moveEvent);

          cb(getOffset(currentPosition, lastPosition));
          lastPosition = currentPosition;
        }

        /**
         * Calculate offset from last position
         *
         * @param currentPosition
         * @param lastPosition
         *
         * @returns {{x: number, y: number}}
         */
        function getOffset(currentPosition, lastPosition) {
          return {
            x: currentPosition.x - lastPosition.x,
            y: currentPosition.y - lastPosition.y,
          };
        }

        /**
         * Get changed touch by identifier, first if no id provided
         * Returns false if id not changed
         *
         * @param event
         * @param {int?} id
         *
         * @returns {*|false}
         */
        function getTouchFromEvent(event, id) {
          if (typeof id === 'undefined') {
            return event.changedTouches[0];
          }

          for (var i = 0; i < event.changedTouches.length; ++i) {
            if (event.changedTouches[i].identifier === id) {
              return event.changedTouches[i];
            }
          }

          return false;
        }

        /**
         * Determine if event is a touch event
         *
         * @param event
         *
         * @returns {boolean}
         */
        function isTouchEvent(event) {
          return typeof event.originalEvent.changedTouches !== 'undefined';
        }

        /**
         * Get current position from event
         * @param event
         * @returns {{x: (number|Number|*), y: (Number|number|*)}}
         */
        function getCurrentPosition(event) {
          return {x: event.clientX, y: event.clientY};
        }

        /**
         * Clean on move end event
         */
        function cleanup(endEvent) {
          endEvent.preventDefault();

          // check tracked touch event is the one ending
          if (touchId !== null && !getTouchFromEvent(endEvent, touchId)) {
            return;
          }

          $window.removeEventListener('mousemove', onMove);
          $window.removeEventListener('touchmove', onMove);
          $window.removeEventListener('mouseup', cleanup);
          $window.removeEventListener('touchend', cleanup);

          touchId = null;
          scope.$apply();
        }
      }

      /**
       * Update the control positions based on image position
       *
       * @param imagePosition
       */
      function updateControlPosition(imagePosition) {
        angular.element(skewControls[0]).css({
          left: imagePosition[0].x - 30,
          top: imagePosition[0].y - 30,
        });

        angular.element(skewControls[1]).css({
          left: imagePosition[1].x - 30,
          top: imagePosition[1].y,
        });

        angular.element(skewControls[2]).css({
          left: imagePosition[2].x,
          top: imagePosition[2].y - 30,
        });

        angular.element(skewControls[3]).css({
          left: imagePosition[3].x,
          top: imagePosition[3].y,
        });

        deleteControl.css({
          left: imagePosition[0].x - 80,
          top: imagePosition[0].y - 35,
        });
      }

      /**
       * Check new skew point won't distort image
       *
       * For point 0 the bounding lines are as follows:
       *      |/
       *   0  2
       *     /|
       * --1--3--
       *  /   |
       *
       * Check against value of 100 to prevent skew point getting too close to the line for rendering
       *
       * @param point
       * @param position
       * @param existingPositions
       *
       * @returns {boolean}
       */
      function isValidSkewPoint(point, position, existingPositions) {
        var linePositionsPerPoint = {
            0: [
              {A: 1, B: 2},
              {A: 1, B: 3},
              {A: 3, B: 2},
            ],
            1: [
              {A: 3, B: 0},
              {A: 3, B: 2},
              {A: 2, B: 0},
            ],
            2: [
              {A: 0, B: 3},
              {A: 0, B: 1},
              {A: 1, B: 3},
            ],
            3: [
              {A: 2, B: 1},
              {A: 2, B: 0},
              {A: 0, B: 1},
            ],
          },
          linePositions = linePositionsPerPoint[point];

        return linePositions.reduce(function (pass, linePosition) {
          return (
            pass &&
            sideOfLine(
              existingPositions[linePosition.A],
              existingPositions[linePosition.B],
              position,
            ) > 100
          );
        }, true);
      }

      /**
       * Compute cross product of vectors
       * @param A
       * @param B
       * @param P
       *
       * @returns {number}
       */
      function sideOfLine(A, B, P) {
        return (P.x - A.x) * (B.y - A.y) - (P.y - A.y) * (B.x - A.x);
      }

      /**
       * Perform the calculation of the transform
       *
       * @param {[{x: int, y: int}, {x: int, y: int}, {x: int, y: int}, {x: int, y: int}]} from
       * @param {[{x: int, y: int}, {x: int, y: int}, {x: int, y: int}, {x: int, y: int}]} to
       * @returns {[[[], [], [], []], [[], [], [], []], [[], [], [], []], [[], [], [], []]]}
       */
      function getTransform(from, to) {
        var A, b, h, i;

        A = []; // 8x8
        for (i = 0; i < 4; ++i) {
          A.push([from[i].x, from[i].y, 1, 0, 0, 0, -from[i].x * to[i].x, -from[i].y * to[i].x]);
          A.push([0, 0, 0, from[i].x, from[i].y, 1, -from[i].x * to[i].y, -from[i].y * to[i].y]);
        }

        b = []; // 8x1
        for (i = 0; i < 4; ++i) {
          b.push(to[i].x);
          b.push(to[i].y);
        }

        // Solve A * h = b for h
        h = numeric.solve(A, b);

        return [
          [h[0], h[1], 0, h[2]],
          [h[3], h[4], 0, h[5]],
          [0, 0, 1, 0],
          [h[6], h[7], 0, 1],
        ];
      }

      /**
       * Get the CSS transformation from originalPos to targetPos
       *
       * @param {[{x: int, y:int}, {x: int, y:int}, {x: int, y:int}, {x: int, y:int}]} originalPos
       * @param {[{x: int, y:int}, {x: int, y:int}, {x: int, y:int}, {x: int, y:int}]} targetPos
       * @returns {{transform: string, transform-origin: string}}
       */
      function getTransformCss(originalPos, targetPos) {
        var H,
          from = [],
          to = [],
          i,
          j,
          params = [];

        // All offsets were calculated relative to the document
        // Make them relative to (0, 0) of the element instead
        for (i = 0; i < 4; ++i) {
          from.push({
            x: originalPos[i].x - originalPos[0].x,
            y: originalPos[i].y - originalPos[0].y,
          });
        }

        for (i = 0; i < 4; ++i) {
          to.push({
            x: targetPos[i].x - originalPos[0].x,
            y: targetPos[i].y - originalPos[0].y,
          });
        }

        H = getTransform(from, to);

        // Apply the matrix3d as H transposed because matrix3d is column major order
        // Also need use toFixed because css doesn't allow scientific notation
        for (i = 0; i < 4; ++i) {
          for (j = 0; j < 4; ++j) {
            params.push(H[j][i].toFixed(20));
          }
        }

        return {
          transform: 'matrix3d(' + params.join(',') + ')',
          'transform-origin': '0 0',
        };
      }
    };
  }
})();
