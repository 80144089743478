(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.orderCharges', [
      'Notification',
      'SettingsService',
      'RepriceModalService',
      'CurrencyService',
      'dataSettings',
      controller,
    ]);

  function controller(
    Notification,
    SettingsService,
    RepriceModalService,
    CurrencyService,
    dataSettings,
  ) {
    /* jshint validthis: true */
    var vm = this;

    vm.update = update;

    vm.manufacturers = dataSettings.settings.companyManufacturers;
    vm.currencySymbol = CurrencyService.getSettings().currencySymbol;

    SettingsService.getOrderCharges().then(function (settings) {
      vm.settings = settings;
    });

    /**
     * @param {Object} manufacturer
     */
    function update(manufacturer) {
      var failed = false;

      if (!vm.settings.deliveryCharge) {
        vm.settings.chargeDelivery = false;
        vm.settings.deliveryCharge = null;
      }

      if (vm.settings.chargeDelivery) {
        if (!vm.settings.minPrice && !vm.settings.minItems) {
          Notification.error(
            'You must specify either a minimum number of items or a minimum order value for free delivery',
          );
          failed = true;
        }
      }

      if (failed) {
        return;
      }

      RepriceModalService.showModal(
        'Changing pricing settings results in the recalculation of draft quotes',
        function () {
          SettingsService.updateOrderCharges(vm.settings);
        },
      );
    }
  }
})();
