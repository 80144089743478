(function () {
  'use strict';

  angular.module('module.sales').component('ttOrderPartSlice', {
    templateUrl: 'templates/sales.orders.slice.component.template.html',
    bindings: {
      orderPart: '<',
      showDate: '<?',
      showActions: '<?',
    },
    controller: ['AuthenticationService', 'Notification', controller],
  });

  function controller(AuthenticationService, Notification) {
    /* jshint validthis: true */
    var $ctrl = this;

    var isManufacturer = AuthenticationService.isManufacturer();

    $ctrl.showActions = typeof $ctrl.showActions === 'undefined' ? true : $ctrl.showActions;
    $ctrl.showDate = typeof $ctrl.showDate === 'undefined' ? true : $ctrl.showDate;

    $ctrl.copyLinkAction = copyLinkAction;

    $ctrl.$onChanges = function (changes) {
      if (changes.orderPart && changes.orderPart.currentValue) {
        updateData(changes.orderPart.currentValue);
      }
    };

    function updateData(orderPart) {
      $ctrl.id = isManufacturer ? orderPart.id : orderPart.orderId;
      $ctrl.reference = orderPart.reference;
      $ctrl.status = orderPart.status;
      $ctrl.items = orderPart.items;
      $ctrl.companyName = orderPart.manufacturerName || orderPart.retailerName;
      $ctrl.lastUpdatedTime = orderPart.lastUpdatedTime;
      $ctrl.downloadUrl = orderPart.downloadUrl;
      $ctrl.externalLinkUrl = orderPart.externalLinkUrl;
    }

    function copyLinkAction() {
      Notification.success('Link copied to clipboard');
    }
  }
})();
