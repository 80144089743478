(function () {
  'use strict';

  angular.module('module.sales').controller('controller.sales.manufacturers.create', [
    '$scope',
    '$state',
    'CompaniesService',
    'NavigationService',
    function ($scope, $state, CompaniesService, NavigationService) {
      $scope.manufacturer = {
        company: {
          name: '',
          commissionRate: 0,
        },
        user: {
          name: '',
          email: '',
        },
      };

      $scope.formErrors = [];

      NavigationService.GetSubNavigation().then(function (navigation) {
        $scope.navigation = navigation;
      });

      $scope.create = function () {
        CompaniesService.createCompany('/manufacturers', $scope.manufacturer)
          .success(function (response) {
            $state.go('/manufacturers');
          })
          .error(function (responseData) {
            /* Handle request errors manually until a more robust solution is put in place */
            if (responseData.error !== undefined) {
              if (responseData.error['company.name'] !== undefined) {
                $scope.formErrors.manufacturerName = responseData.error['company.name'][0];
              }

              if (responseData.error['user.email'] !== undefined) {
                $scope.formErrors.userEmail = responseData.error['user.email'][0];
              }
            }
          });
      };
    },
  ]);
})();
