(function () {
  'use strict';

  angular
    .module('module.freshchat')
    .factory('Freshchat', [
      '$window',
      '$rootScope',
      'FreshchatApiService',
      'AuthenticationService',
      'VersionService',
      'APP_ENV',
      'FRESHCHAT_TOKEN',
      service,
    ]);

  function service(
    $window,
    $rootScope,
    FreshchatApiService,
    AuthenticationService,
    VersionService,
    APP_ENV,
    FRESHCHAT_TOKEN,
  ) {
    if (typeof $window.fcWidget === 'undefined') {
      throw Error('Freshchat library not found');
    }

    var widget = $window.fcWidget;

    $rootScope.$on('authentication.login', onLogin);
    $rootScope.$on('authentication.logout', onLogout);

    // if already logged in init, handles init on refresh
    AuthenticationService.checkAuthentication().then(initWidget);

    return {
      getWidget: getWidget,
      updateUser: setUserProperties,
    };

    function getWidget() {
      return widget;
    }

    /**
     * @param {object} user
     */
    function setUserProperties(user) {
      return widget.user.setProperties({
        firstName: user.metadata.name.split(' ')[0],
        lastName: user.metadata.name.split(' ')[1],
        email: user.metadata.email,
        phone: user.metadata.contactNumber,
        company: user.metadata.company.name,
        role: user.metadata.jobTitle,
      });
    }

    function onLogout() {
      widget.destroy();
    }

    /**
     * @param {object} event
     * @param {object} user
     */
    function onLogin(event, user) {
      initWidget(user);
    }

    /**
     * @param {object} user
     */
    function initWidget(user) {
      if (user.metadata.isMasquerading === false) {
        widget.init({
          token: FRESHCHAT_TOKEN,
          host: 'https://wchat.freshchat.com',
          siteId: APP_ENV,
          externalId: user.metadata.isMasquerading ? user.metadata.originalUser.id : user.id,
          restoreId: user.metadata.freshchatRestoreId,
        });
      } else if (user.metadata.isMasquerading === true) {
        widget.destroy();
        return;
      }

      widget.user.get(function (response) {
        if (200 === response.status) {
          // existing user restored
          return;
        }

        // new user
        widget.on('user:created', onUserCreated);
        setUserProperties(user);
      });
    }

    /**
     * Freshchat user is created on the first message sent
     *
     * @param {object} response
     */
    function onUserCreated(response) {
      // this is called during startup with a failing response in some situations, so status check required
      if (200 === response.status) {
        FreshchatApiService.setRestoreId(response.data.restoreId);
      }
    }
  }
})();
