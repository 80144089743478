(function () {
  'use strict';

  angular.module('module.settings', [
    'module.settings.company',
    'module.settings.advanced',
    'module.settings.retailer',
    'module.settings.billingAndPayments',
    'module.settings.retailerProducts',
    'module.settings.retailerGlass',
    'module.settings.partsShop',
    'module.api',
    'module.components',
    'module.types',
    'ui.router',
  ]);
})();
