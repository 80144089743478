(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuoteDetails', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/sales.quotes.details.directive.template.html',
        controllerAs: 'detailsVm',
        scope: {
          customer: '=',
          quote: '=',
        },
        controller: [
          '$scope',
          '$state',
          'QuotesService',
          'AddressService',
          'QuoteFinalisationService',
          controller,
        ],
        bindToController: true,
      };
    },
  ]);

  function controller($scope, $state, QuotesService, AddressService, QuoteFinalisationService) {
    /* jshint validthis: true */
    var vm = this;

    vm.onCustomerUpdate = onCustomerUpdate;
    vm.copyAddress = copyAddress;
    vm.readOnly = QuoteFinalisationService.isFinalised(vm.quote);

    vm.updateSiteDetails = updateSiteDetails;

    function updateSiteDetails() {
      QuotesService.updateQuote(vm.quote);
    }

    $scope.$watch('detailsVm.quote.metadata.status', function () {
      vm.finalised = QuoteFinalisationService.isFinalised(vm.quote);
    });

    /**
     * Copies the customers address to the site address of the quote
     */
    function copyAddress() {
      vm.quote.metadata.siteAddress = AddressService.getSiteAddress(
        vm.quote.metadata.customer.address,
      );
      vm.quote.metadata.siteAddressPostcode = vm.quote.metadata.customer.address.postcode;
    }

    function onCustomerUpdate() {
      $state.reload();
    }
  }
})();
