(function () {
  'use strict';

  angular.module('module.profile.team').controller('controller.profile.team.list', [
    '$state',
    'UsersService',
    'dataTeam',
    function ($state, UsersService, dataTeam) {
      var vm = this;

      vm.team = dataTeam.users;
      vm.canAddTeamMember = dataTeam.canAddTeamMember;
      vm.isSubmitting = false;

      vm.init = function () {
        vm.newFormShow = false;
        UsersService.initUser().then(function (user) {
          vm.user = user;
        });
      };

      vm.onFormEdit = function () {
        vm.isSubmitting = false;
      };

      vm.create = function () {
        if (vm.isSubmitting) {
          return;
        }
        vm.isSubmitting = true;
        UsersService.createUser('/users', vm.user)
          .then(function () {
            vm.init();
            $state.reload();
          })
          .finally(function () {
            vm.isSubmitting = false;
          });
      };

      vm.resend = function (user) {
        UsersService.resendInvite(user);
      };

      vm.init();
    },
  ]);
})();
