(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .filter('matrixTypeName', ['matrixTypes', filter]);

  function filter(matrixTypes) {
    return function (matrixType) {
      if (angular.isDefined(matrixTypes[matrixType])) {
        return matrixTypes[matrixType];
      }

      return '';
    };
  }
})();
