(function () {
  'use strict';

  angular.module('tommyApp', [
    'module.bugsnag',

    'module.errors',

    'module.api',
    'module.authentication',

    'module.dashboard',
    'module.components',
    'module.messages',
    'module.navigation',
    'module.products',
    'module.profile',
    'module.types',
    'module.sales',
    'module.settings',
    'module.visualiser',
    'module.freshchat',
    'module.marketing',
    'module.legal',
    'module.help',
    'module.users',
    'module.retailers',
    'module.stripe',
    'module.customItem',

    'angular.filter',
    'angularMoment',
    'ngSanitize',
    'focus-if',
    'ncy-angular-breadcrumb',
    'ngMeta',
    'ngAnimate',
    'angularBowser',
    'ngclipboard',
    'ngMessages',
    'ng-sortable',
    'ui.select',
  ]);
})();
