(function () {
  'use strict';

  angular.module('module.settings').controller('controller.settings.productranges.create', [
    '$location',
    '$scope',
    'productRangesService',
    function ($location, $scope, productRangesService) {
      $scope.productrange = {};

      $scope.create = function () {
        productRangesService.createProductRange($scope.productrange).success(function (response) {
          $location.path('/productranges/' + response.productrange.id);
        });
      };
    },
  ]);
})();
