(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.retailers.list', [
      '$rootScope',
      '$scope',
      'RetailersService',
      'PendingRetailerService',
      'dataRetailers',
      controller,
    ]);

  function controller($rootScope, $scope, RetailersService, PendingRetailerService, dataRetailers) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.changePage = changePage;
    $ctrl.changeSearchTerm = changeSearchTerm;

    $ctrl.searchTerm = '';
    $ctrl.noRetailers = !dataRetailers.retailers.length;
    $ctrl.retailers = dataRetailers.retailers;

    $ctrl.pagination = {
      currentPage: 1,
      totalPages: dataRetailers.totalPages,
    };

    $rootScope.bodyClass = 'customer__list';
    $scope.$on('$destroy', function () {
      $rootScope.bodyClass = '';
    });

    PendingRetailerService.list().then(function (pendingRetailers) {
      $ctrl.pendingRetailers = pendingRetailers;
    });

    function changeSearchTerm() {
      getRetailers(1);
    }

    function changePage(page) {
      getRetailers(page);
    }

    function getRetailers(page) {
      RetailersService.listRetailers(page, null, $ctrl.searchTerm).then(function (list) {
        $ctrl.noRetailers = !list.retailers.length;
        $ctrl.retailers = list.retailers;
        $ctrl.pagination.totalPages = list.totalPages;
        $ctrl.pagination.currentPage = page;
      });
    }
  }
})();
