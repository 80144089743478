(function () {
  'use strict';

  angular.module('module.visualiser').controller('controller.visualiser.compositor', [
    '$rootScope',
    '$scope',
    '$stateParams',
    '$state',
    '$element',
    'ItemImagesService',
    'CompletedImageService',
    'CompositorSizeService',
    'completedImage',
    'photoBankImage',
    'quote',
    function (
      $rootScope,
      $scope,
      $stateParams,
      $state,
      $element,
      ItemImagesService,
      CompletedImageService,
      CompositorSizeService,
      completedImage,
      photoBankImage,
      quote,
    ) {
      /* jshint validthis: true */
      var vm = this;

      $rootScope.bodyClass = 'page__compositor';

      // for breadcrumbs TODO: improve
      $scope.quote = quote;
      $scope.customer = quote.metadata.customer;
      $scope.customer.metadata = {
        name: $scope.customer.name,
      };

      $scope.customersampleId = quote.metadata.customer.id;
      $scope.hashLocation = 'quotes';
      $scope.itemActiveName = 'Visualiser';
      $scope.customerfullname = quote.metadata.customer.metadata.name.fullName;

      vm.photoBankImage = photoBankImage;
      vm.quoteId = $stateParams.quoteId;
      vm.allowSave = false;

      vm.save = save;
      vm.validateCompletedImage = validateCompletedImage;

      init();

      /**
       * Init compositor
       */
      function init() {
        initCompletedImage();

        CompositorSizeService.addResizeEventListener(resizeCompositor);
        resizeCompositor(CompositorSizeService.getContentHeight());

        ItemImagesService.pollList($stateParams.quoteId, function (itemImages) {
          vm.itemImages = itemImages;
        });

        $scope.$on('$destroy', function () {
          ItemImagesService.cancelPollList();
          CompositorSizeService.removeResizeEventListener(resizeCompositor);
        });
      }

      /**
       * Apply height to compositor
       *
       * TODO: this is really dirty, should be a directive when altering $element, refactor
       * @param height
       */
      function resizeCompositor(height) {
        $element.find('.compositor__container').height(height);
        $element.find('.tt-compositor').height(height - 131); // -61px controls -40px padding
        $element.find('.compositor__items').height(height - 151); // -61px controls -50px padding -40px extra spacing
      }

      /**
       * Build completed image if new
       */
      function initCompletedImage() {
        if (completedImage === null) {
          completedImage = {
            name: photoBankImage.name,
            photoBankImage: photoBankImage.id,
            items: [],
          };
        }

        vm.completedImage = completedImage;
        validateCompletedImage();
      }

      /**
       * Save the completed image
       * TODO: handle failure
       */
      function save() {
        if (vm.completedImage.id) {
          CompletedImageService.updateImage(
            vm.quoteId,
            vm.completedImage.id,
            vm.completedImage,
          ).then(success);
        } else {
          CompletedImageService.createImage(vm.quoteId, vm.completedImage).then(success);
        }

        /**
         * Go to the visualiser dash
         */
        function success() {
          $state.go('visualiser.dash', {quoteId: vm.quoteId});
        }
      }

      /**
       * Validate the completed image
       */
      function validateCompletedImage() {
        vm.allowSave = vm.completedImage.name && vm.completedImage.name.length >= 3;
      }
    },
  ]);
})();
