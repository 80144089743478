(function () {
  'use strict';

  angular.module('module.types').service('TypesService', ['$q', 'ApiService', TypesService]);

  function TypesService($q, ApiService) {
    var vm = this;

    vm.buildComponentFlatTree = buildComponentFlatTree;
    vm.filterLastOnly = filterLastOnly;
    vm.getComponentFlatTree = getComponentFlatTree;
    vm.List = List;

    function buildComponentFlatTree(tree, existingBranch) {
      if (existingBranch === undefined) {
        existingBranch = {
          pathDescription: '',
          pathId: '',
        };
      }

      var flatTreeArray = [];

      for (var i = tree.length - 1; i >= 0; i--) {
        var branch = tree[i];

        var branchObject = {
          id: branch.id,
          children: !!branch.componentTypes.length,
          name: branch.name,
          pathDescription:
            existingBranch.pathDescription === ''
              ? branch.name
              : existingBranch.pathDescription + ' > ' + branch.name,
          pathId:
            existingBranch.pathId === '' ? branch.id : existingBranch.pathId + '/' + branch.id,
        };

        branchObject.pathDescription = branchObject.pathDescription.replace(
          /\w\S*/g,
          function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          },
        );

        flatTreeArray.push(branchObject);

        if (branch.componentTypes.length) {
          flatTreeArray = flatTreeArray.concat(
            buildComponentFlatTree(branch.componentTypes, branchObject),
          );
        }
      }

      return flatTreeArray;
    }

    function filterLastOnly(items) {
      var responseData = [];

      for (var i = 0, l = items.length; i < l; i++) {
        if (items[i].children === false) {
          responseData.push({id: items[i].id, title: items[i].pathDescription});
        }
      }

      return responseData;
    }

    function getComponentFlatTree(tree) {
      var items = buildComponentFlatTree(tree);
      items.sort(compareSecondColumn);

      function compareSecondColumn(a, b) {
        if (a.pathDescription === b.pathDescription) {
          return 0;
        } else {
          return a.pathDescription < b.pathDescription ? -1 : 1;
        }
      }

      return items;
    }

    function List(type) {
      if (type !== undefined) {
        var glazingMethodsTypes = [
          {
            id: 1,
            name: 'Internal',
          },
          {
            id: 2,
            name: 'External',
          },
          {
            id: 3,
            name: 'Captive',
          },
        ];

        var typeObject = type.substr(0, type.length - 1) + 'Types';

        if (typeObject === 'glazingMethodTypes') {
          return $q(function (resolve, reject) {
            resolve(glazingMethodsTypes);
          });
        } else {
          return ApiService.get('/types/' + type).then(function (responseData) {
            if (type === 'components') {
              var data = {
                tree: responseData.data.componentTypes,
              };

              data.flat = getComponentFlatTree(data.tree);
              data.flatLastOnly = filterLastOnly(data.flat);

              return data;
            } else {
              return responseData.data[typeObject];
            }
          });
        }
      }
    }
  }
})();
