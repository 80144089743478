(function () {
  'use strict';

  angular
    .module('module.retailers')
    .controller('controller.retailers.ownerView.users', ['dataRetailer', controller]);

  function controller(dataRetailer) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.retailer = dataRetailer;
    $ctrl.users = dataRetailer.users;
  }
})();
