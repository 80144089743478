(function () {
  'use strict';

  angular
    .module('module.bugsnag')
    .factory('$exceptionHandler', [
      '$log',
      'bugsnagClient',
      'APP_ENV',
      'BUGSNAG_RELEASE_STAGES',
      handler,
    ]);

  /**
   * @param $log
   * @param bugsnagClient
   * @param {string} APP_ENV
   * @param {Array} BUGSNAG_RELEASE_STAGES
   * @returns {Function}
   */
  function handler($log, bugsnagClient, APP_ENV, BUGSNAG_RELEASE_STAGES) {
    return function (exception, cause) {
      bugsnagClient.notify(exception, {
        beforeSend: function (report) {
          report.updateMetaData('angular', {cause: cause});
        },
      });

      // if not notifying with BugSnag rethrow exception
      if (BUGSNAG_RELEASE_STAGES.indexOf(APP_ENV) === -1) {
        throw exception;
      }
    };
  }
})();
