(function () {
  'use strict';

  angular.module('module.products').directive('ttProductProductStyles', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/products.retrieve.productStyles.directive.template.html',
        controllerAs: 'stylesVm',
        scope: {
          product: '<',
        },
        controller: ['$scope', 'ProductStylesService', 'ProductsService', controller],
        bindToController: true,
      };
    },
  ]);

  function controller($scope, ProductStylesService, ProductsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.save = save;

    $scope.$watch('stylesVm.product.metadata.active', function (active) {
      vm.productActive = active;
    });

    initProductStyles();

    /**
     * Get list of selected productStyles and apply to full list of product styles
     */
    function initProductStyles() {
      var selectedProductStyles = [];
      angular.forEach(vm.product.metadata.productStyles, function (productStyle) {
        selectedProductStyles.push(productStyle.name);
      });

      ProductStylesService.getProductStylesForProductType(vm.product.metadata.productType).then(
        function (styles) {
          angular.forEach(styles, function (style) {
            style.selected = -1 !== selectedProductStyles.indexOf(style.name);
          });

          vm.productStyles = styles;
        },
      );
    }

    /**
     * Apply pre-processing to product styles and save product
     */
    function save() {
      var selectedProductStyles = [];
      angular.forEach(vm.productStyles, function (productStyle) {
        // remove unselected productStyles
        if (productStyle.selected) {
          selectedProductStyles.push(productStyle);
        }
      });

      vm.product.metadata.productStyles = selectedProductStyles;
      ProductsService.updateProduct(vm.product);
    }
  }
})();
