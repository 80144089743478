(function () {
  'use strict';

  angular.module('module.stripe').factory('Stripe', ['$window', 'STRIPE_PUBLIC_KEY', service]);

  function service($window, key) {
    if (typeof $window.Stripe === 'undefined') {
      throw Error('Stripe library not loaded');
    }

    return $window.Stripe(key);
  }
})();
