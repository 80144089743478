(function () {
  'use strict';

  angular.module('module.sales').component('ttCreatePricingRequestItemSelector', {
    templateUrl: 'templates/sales.quotes.createPricingRequest.itemSelector.component.template.html',
    controller: ['QuoteItemsService', controller],
    bindings: {
      quoteItems: '<',
      onChange: '<',
    },
  });

  function controller(QuoteItemsService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.$onInit = onInit;
    $ctrl.$onChanges = onChanges;

    function onInit() {
      $ctrl.items = processItems($ctrl.quoteItems);
    }

    function onChanges(changes) {
      if (typeof changes.quoteItems !== 'undefined' && !changes.quoteItems.isFirstChange()) {
        $ctrl.items = processItems(changes.quoteItems);
      }
    }

    function processItems(quoteItems) {
      var items = [];

      angular.forEach(quoteItems, function (quoteItem) {
        if (quoteItem.metadata.itemValid && QuoteItemsService.isGenericItem(quoteItem)) {
          items.push({
            id: quoteItem.id,
            name: quoteItem.metadata.name,
            location: quoteItem.metadata.location,
            thumbImageUrl: quoteItem.images.vfoThumb,
          });
        }
      });

      return items;
    }
  }
})();
