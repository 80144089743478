(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.salesperson', [
      'SalespersonSettingsApiService',
      'dataSettings',
      controller,
    ]);

  function controller(SalespersonSettingsApiService, dataSettings) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.save = save;
    $ctrl.saving = false;

    $ctrl.settings = dataSettings;

    function save() {
      $ctrl.saving = true;
      SalespersonSettingsApiService.updateSettings($ctrl.settings).finally(function () {
        $ctrl.saving = false;
      });
    }
  }
})();
