(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('QuotePaymentService', ['$q', 'ApiService', 'Notification', service]);

  function service($q, ApiService, Notification) {
    return {
      updateCustomerPaymentStages: updateCustomerPaymentStages,
    };

    /**
     * Update an existing quote's stage payments
     *
     * @param {Object} quote
     * @param {Object} data
     * @param {Boolean} notifyOnSuccess
     *
     * @return {$q}
     */
    function updateCustomerPaymentStages(quote, data, notifyOnSuccess) {
      return ApiService.put('/quotes/' + quote.id + '/payment-stages', data).then(
        function () {
          if (notifyOnSuccess !== false) {
            Notification.success('Updated quote payments');
          }
        },
        function () {
          Notification.error('Failed to update quote payments');
          return $q.reject(null);
        },
      );
    }
  }
})();
