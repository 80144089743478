(function () {
  'use strict';

  angular.module('module.visualiser').directive('ttCompletedImage', [
    function () {
      return {
        restrict: 'E',
        templateUrl: 'templates/visualiser.completedImage.directive.template.html',
        controllerAs: 'imageVm',
        scope: {
          image: '=',
          deleteFunc: '&',
          readOnly: '=',
        },
        controller: ['$state', '$stateParams', '$window', imageController],
        bindToController: true,
      };
    },
  ]);

  function imageController($state, $stateParams, $window) {
    /* jshint validthis: true */
    var vm = this;

    vm.deleteDisabled = false;
    vm.deleteImage = deleteImage;
    vm.editImage = editImage;

    /**
     * Delete using passed function, debouncing
     */
    function deleteImage() {
      if (vm.deleteDisabled) {
        return;
      }
      if ($window.confirm('Are you sure you want to delete this image?')) {
        vm.deleteDisabled = true;
        vm.deleteFunc()(vm.image);
      }
    }

    /**
     * Edit image if not in read-only mode
     */
    function editImage() {
      if (!vm.readOnly) {
        $state.go('visualiser.compositor', {
          mode: 'edit',
          imageId: vm.image.id,
          quoteId: $stateParams.quoteId,
        });
      }
    }
  }
})();
