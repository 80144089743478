(function () {
  'use strict';

  angular
    .module('module.settings.retailer')
    .factory('RetailerDiscountApiService', ['$q', 'ApiService', 'Notification', service]);

  function service($q, ApiService, Notification) {
    return {
      update: update,
    };

    function update(defaultDiscount, applyToAllRetailers) {
      var data = {
        defaultDiscount: defaultDiscount,
        applyToAllRetailers: applyToAllRetailers,
      };

      return ApiService.put('/companies/retailers/discount', data).then(
        function () {
          Notification.success('Updated retailer discount settings');
        },
        function () {
          Notification.error('Failed to update retailer discount settings');

          return $q.reject();
        },
      );
    }
  }
})();
