(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.pricing.reprice.modal', [
      'message',
      'close',
      controller,
    ]);

  function controller(message, close) {
    /* jshint validthis: true */
    var vm = this;

    vm.closeModal = closeModal;

    vm.message = message;

    function closeModal(result) {
      close(result, 200);
    }
  }
})();
