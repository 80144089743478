(function () {
  'use strict';

  angular.module('module.settings.company').controller('controller.settings.company.logo', [
    '$scope',
    '$state',
    '$timeout',
    'dataSettings',
    'SettingsService',
    function ($scope, $state, $timeout, dataSettings, SettingsService) {
      $scope.company = dataSettings;
      $scope.logoActionLabel =
        $scope.company.metadata.logoStatus === 'none' ? 'Upload Logo' : 'Update Logo';

      $scope.checkLogo = function () {
        $timeout(function () {
          SettingsService.getSettings(true).then(function (settingsData) {
            $scope.company.metadata.logo = settingsData.metadata.logo;

            if (settingsData.metadata.logoStatus === 'processing') {
              $scope.logoActionLoading = true;
              $scope.checkLogo();
            } else {
              $scope.logoActionLoading = false;
              $scope.logoActionLabel = 'Update Logo';
            }
          });
        }, 5000);
      };

      $scope.uploadLogo = function () {
        if (document.getElementById('file').files[0] !== undefined) {
          $scope.logoActionLabel = 'Uploading...';
          var file = document.getElementById('file').files[0];

          SettingsService.uploadLogo(file).then(function () {
            $scope.company.metadata.logoStatus = 'processing';
            $scope.company.metadata.logo = null;
            $scope.logoActionLoading = true;
            $scope.checkLogo();
          });
        }
      };

      if ($scope.company.metadata.logoStatus === 'processing') {
        $scope.logoActionLabel = 'Uploading...';
        $scope.logoActionLoading = true;
        $scope.checkLogo();
      }
    },
  ]);
})();
