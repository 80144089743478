(function () {
  'use strict';

  angular.module('module.users').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('users', {
      abstract: true,
      url: '/users',
      views: {
        'navigation@': {
          controller: 'controller.navigation',
          templateUrl: 'templates/navigation.template.html',
          resolve: {
            dataUser: [
              'AuthenticationService',
              function (AuthenticationService) {
                return AuthenticationService.getUser();
              },
            ],
          },
        },
        'content@': {
          templateUrl: 'templates/navigation.subnavTabs.template.html',
          controllerAs: '$ctrl',
          controller: [
            'NavigationService',
            function (NavigationService) {
              var $ctrl = this;
              $ctrl.tabs = NavigationService.getOwnerUsersNavItems();
            },
          ],
        },
      },
      ncyBreadcrumb: {
        label: 'Users',
      },
    });

    $stateProvider.state('users.pending', {
      url: '/pending',
      controller: 'controller.users.pending',
      controllerAs: '$ctrl',
      templateUrl: 'templates/users.pending.template.html',
      resolve: {
        pendingUsers: [
          'UsersApiService',
          function (UsersApiService) {
            return UsersApiService.getPendingUsers();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Pending',
      },
      data: {
        meta: {
          titleSuffix: '| Users | Pending',
        },
      },
    });

    $stateProvider.state('users.all', {
      url: '/all',
      controller: 'controller.users.all',
      controllerAs: '$ctrl',
      templateUrl: 'templates/users.all.template.html',
      ncyBreadcrumb: {
        label: 'Users',
      },
      data: {
        meta: {
          titleSuffix: '| Users',
        },
      },
    });
  }
})();
