(function () {
  'use strict';

  angular.module('module.settings.billingAndPayments').filter('cardStatusLabel', filter);

  function filter() {
    var statusText = {
      auth_required: 'Card re-authorisation required',
      pending: 'Card processing...',
      success: 'Card valid',
      not_sent: 'Awaiting card details',
      replace_card: 'Card invalid, please replace with an alternative',
    };

    /**
     * @param {string} status
     * @returns {string}
     */
    return function (status) {
      if (typeof statusText[status] === 'undefined') {
        throw new Error('Invalid card status');
      }

      return statusText[status];
    };
  }
})();
