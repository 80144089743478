(function () {
  'use strict';

  angular.module('module.retailers').component('ttRetailerUserSlice', {
    templateUrl: 'templates/retailers.userSlice.component.template.html',
    bindings: {
      user: '<',
    },
  });
})();
