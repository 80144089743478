(function () {
  'use strict';

  angular
    .module('module.dashboard')
    .controller('controller.dashboard.manufacturer', [
      '$rootScope',
      '$scope',
      'ComponentsService',
      'OrderPartApiService',
      'ProductsService',
      'BreadcrumbsService',
      'RetailersService',
      'ReportsApiService',
      controller,
    ]);

  function controller(
    $rootScope,
    $scope,
    ComponentsService,
    OrderPartApiService,
    ProductsService,
    BreadcrumbsService,
    RetailersService,
    ReportsApiService,
  ) {
    /* jshint validthis: true */
    var vm = this;

    $rootScope.bodyClass = 'customer__list';

    vm.downloadWeeklyQuotes = downloadWeeklyQuotes;
    vm.downloadAllTimeQuotes = downloadAllTimeQuotes;
    vm.downloadAllTimeOrders = downloadAllTimeOrders;
    vm.downloadingWeeklyQuotes = false;
    vm.downloadingAllTimeQuotes = false;
    vm.downloadingAllTimeOrders = false;

    BreadcrumbsService.hideBreadcrumbs();

    vm.loadingOrders = true;
    vm.loadingRetailers = true;

    RetailersService.listRetailers(1, 5).then(function (list) {
      vm.loadingRetailers = false;
      vm.retailers = list.retailers;
    });

    // TODO: re-enable when manufacturers given access to products and components
    // ComponentsService.listComponents().then(function (response) {
    //   vm.components = response.components;
    // });

    OrderPartApiService.list().then(function (orderParts) {
      vm.loadingOrders = false;
      vm.orderParts = orderParts.orders;
    });

    // TODO: re-enable when manufacturers given access to products and components
    // ProductsService.listProducts().then(function (products) {
    //   vm.products = products;
    // });

    function downloadWeeklyQuotes() {
      vm.downloadingWeeklyQuotes = true;
      ReportsApiService.downloadWeeklyQuotes().finally(function () {
        vm.downloadingWeeklyQuotes = false;
      });
    }

    function downloadAllTimeQuotes() {
      vm.downloadingAllTimeQuotes = true;
      ReportsApiService.downloadAllTimeQuotes().finally(function () {
        vm.downloadingAllTimeQuotes = false;
      });
    }

    function downloadAllTimeOrders() {
      vm.downloadingAllTimeOrders = true;
      ReportsApiService.downloadAllTimeOrders().finally(function () {
        vm.downloadingAllTimeOrders = false;
      });
    }
  }
})();
