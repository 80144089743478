(function () {
  'use strict';

  angular.module('module.authentication').directive('matchPassword', directive);

  function directive() {
    return {
      restrict: 'A',
      require: ['^ngModel', '^form'],
      link: link,
    };

    function link(scope, element, attrs, ctrls) {
      var ngModel = ctrls[0];
      var formController = ctrls[1];
      var otherPasswordModel = formController[attrs.matchPassword];

      ngModel.$validators.passwordMatch = function (value) {
        return value === otherPasswordModel.$viewValue;
      };

      otherPasswordModel.$parsers.push(function (value) {
        ngModel.$setValidity('passwordMatch', value === ngModel.$viewValue);
        return value;
      });
    }
  }
})();
