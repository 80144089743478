(function () {
  'use strict';

  angular.module('module.sales').filter('quotePdfStatus', ['moment', filter]);

  /**
   * @param moment
   * @returns {function}
   */
  function filter(moment) {
    return function (quote) {
      if (typeof quote === 'undefined') {
        return '';
      }

      if (quote.metadata.status === 'expired') {
        return 'Expired';
      }

      var quoteDocument = quote.metadata.documents.quote;
      if (quoteDocument.externalLinkUrl !== null) {
        return 'Generated ' + moment(quote.metadata.quoteFinalised - 1000).fromNow();
      }

      if (quoteDocument.pdfGenerationFailed) {
        return 'Generation failed';
      }

      return 'Awaiting finalisation';
    };
  }
})();
