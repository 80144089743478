(function () {
  'use strict';

  angular
    .module('module.dashboard')
    .controller('controller.dashboard.owner', [
      '$rootScope',
      '$scope',
      'CompaniesService',
      'BreadcrumbsService',
      'PendingRetailerService',
      controller,
    ]);

  function controller(
    $rootScope,
    $scope,
    CompaniesService,
    BreadcrumbsService,
    PendingRetailerService,
  ) {
    /* jshint validthis: true */
    var vm = this;

    $rootScope.bodyClass = 'customer__list';
    $scope.$on('$destroy', function () {
      $rootScope.bodyClass = '';
    });
    BreadcrumbsService.hideBreadcrumbs();

    CompaniesService.listManufacturers().then(function (manufacturers) {
      vm.manufacturers = manufacturers;
    });

    PendingRetailerService.list().then(function (pendingRetailers) {
      vm.pendingRetailers = pendingRetailers;
    });
  }
})();
