(function () {
  'use strict';

  angular.module('module.navigation').factory('BreadcrumbsService', ['$rootScope', service]);

  /**
   * @param $rootScope
   * @returns {{hideBreadcrumbs: hideBreadcrumbs, showBreadcrumbs: showBreadcrumbs, onChange: onChange}}
   */
  function service($rootScope) {
    var changeCallback, hidden;

    // reset status when a state change starts
    $rootScope.$on('$stateChangeStart', function () {
      hidden = false;
    });

    // status is only updated on a successful state change
    $rootScope.$on('$stateChangeSuccess', change);

    return {
      hideBreadcrumbs: hideBreadcrumbs,
      showBreadcrumbs: showBreadcrumbs,
      onChange: onChange,
    };

    /**
     * Check breadcrumbs status
     *
     * @returns {boolean}
     */
    function onChange(callback) {
      changeCallback = callback;
      change();
    }

    /**
     * Call callback with current hidden state
     */
    function change() {
      if (typeof changeCallback === 'function') {
        changeCallback(hidden);
      }
    }

    /**
     * Hide breadcrumbs till next state change
     */
    function hideBreadcrumbs() {
      hidden = true;
      change();
    }

    /**
     * Show breadcrumbs
     */
    function showBreadcrumbs() {
      hidden = false;
      change();
    }
  }
})();
