(function () {
  'use strict';

  angular
    .module('module.navigation')
    .factory('OnboardingService', [
      '$rootScope',
      '$q',
      '$state',
      'AuthenticationService',
      'SettingsService',
      service,
    ]);

  /**
   * @param $rootScope
   * @param $q
   * @param $state
   * @param AuthenticationService
   * @param SettingsService
   * @returns {{updateOnboarding: updateOnboarding, setOnboardingListener: setOnboardingListener}}
   */
  function service($rootScope, $q, $state, AuthenticationService, SettingsService) {
    var onboardingListener;

    // update onboarding on settings update
    $rootScope.$on('settings.update', function (event, settings) {
      updateOnboarding($q.when(settings));
    });

    return {
      updateOnboarding: updateOnboarding,
      setOnboardingListener: setOnboardingListener,
    };

    /**
     * Update onboarding
     *
     * @param {Promise} [settingsPromise]
     */
    function updateOnboarding(settingsPromise) {
      // skip update if no listener set
      if (typeof onboardingListener !== 'function') {
        return;
      }

      // use either passed settings or get from settings service
      settingsPromise = settingsPromise || SettingsService.getSettings();
      settingsPromise.then(processOnboarding).then(onboardingListener);
    }

    /**
     * Set the onboarding listener and update onboarding
     * Only supports a single listener
     *
     * @param {function} listener
     */
    function setOnboardingListener(listener) {
      onboardingListener = listener;
      updateOnboarding();
    }

    /**
     * Get the current company type
     *
     * @returns {Promise}
     */
    function getCompanyType() {
      return AuthenticationService.getUser().then(function (user) {
        return user.permissions.companyType;
      });
    }

    /**
     * Process the company settings to get list of items for onboarding, return empty array if all complete or no access
     *
     * @param {object} companySettings
     * @returns {Promise}
     */
    function processOnboarding(companySettings) {
      // user does not have access to onboarding settings
      if (companySettings === null || typeof companySettings.onboarding === 'undefined') {
        return $q.when([]);
      }

      return getOnboardingItems(companySettings.onboarding).then(function (items) {
        if (allItemsComplete(items)) {
          return [];
        }

        return items;
      });
    }

    /**
     * Check if all onboarding items completed
     *
     * @param {Array} onboardingItems
     * @returns {boolean}
     */
    function allItemsComplete(onboardingItems) {
      for (var i = 0; i < onboardingItems.length; i++) {
        if (!onboardingItems[i].completed) {
          return false;
        }
      }

      return true;
    }

    /**
     * Get onboarding items (reduced from full list)
     *
     * @param {object} onboardingStatus
     * @returns {Promise}
     */
    function getOnboardingItems(onboardingStatus) {
      return getCompanyType().then(function (companyType) {
        return getOnboardingItemData(companyType).reduce(function (onboardingItems, item) {
          // skip item if not in settings items
          if (typeof onboardingStatus[item.id] === 'undefined') {
            return onboardingItems;
          }

          // get completion status from settingsItems
          item.completed = onboardingStatus[item.id];
          onboardingItems.push(item);

          return onboardingItems;
        }, []);
      });
    }

    /**
     * Get full list of onboarding items
     *
     * @param {string} companyType
     * @returns {Array}
     */
    function getOnboardingItemData(companyType) {
      switch (companyType) {
        case 'manufacturer':
          return [];
        case 'retailer':
          return [
            {
              id: 'companyDetails',
              message: 'Update and confirm your <u>company details</u>.',
              name: 'Company Details',
              href: $state.href('settings.company.details'),
            },
            {
              id: 'billing',
              message: 'Provide us with your <u>payment details</u>.',
              name: 'Payment Details',
              href: $state.href('settings.billingAndPayments.billing'),
            },
            {
              id: 'termsAndConditions',
              message: "Accept the manufacturer's <u>Terms and Conditions</u>.",
              name: 'Terms & Conditions',
              href: $state.href('settings.advanced.manufacturers'),
            },
            {
              id: 'priceSettings',
              message: 'Set your <u>price settings</u>.',
              name: 'Price Settings',
              href: $state.href('settings.advanced.pricing'),
            },
          ];

        default:
          throw Error('Company type ' + companyType + ' not valid for onboarding');
      }
    }
  }
})();
