(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('CreateOrderApiService', ['$q', '$timeout', 'Notification', 'ApiService', service]);

  function service($q, $timeout, Notification, ApiService) {
    return {
      getDetails: getDetails,
      createOrder: createOrder,
    };

    function getDetails(quote) {
      return ApiService.get('/quotes/' + quote.id + '/createOrder').then(function (response) {
        return response.data;
      });
    }

    function createOrder(quote, manufacturers) {
      var processedManufacturers = manufacturers.map(function (manufacturer) {
        var itemIds = manufacturer.items.map(function (item) {
          return item.id;
        });

        return {
          manufacturerName: manufacturer.manufacturerName,
          items: itemIds,
        };
      });

      var data = processedManufacturers.length > 0 ? {manufacturers: processedManufacturers} : null;

      return ApiService.put('/quotes/' + quote.id + '/createOrder', data).then(
        function (response) {
          Notification.success('Created order successfully');

          return response.data;
        },
        function () {
          Notification.error('Failed to create order');

          return $q.reject(null);
        },
      );
    }
  }
})();
