(function () {
  'use strict';

  angular.module('module.sales').component('ttContractReportSelectDocuments', {
    templateUrl:
      'templates/sales.quotes.createContractReport.selectDocuments.component.template.html',
    require: {
      ngModelCtrl: '^ngModel',
    },
    controller: ['DocumentStoreApiService', controller],
    controllerAs: '$ctrl',
    bindings: {
      quote: '<',
      manufacturerDocuments: '<',
      retailerDocuments: '<',
    },
  });

  function controller(DocumentStoreApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.$onInit = onInit;
    $ctrl.onChange = onChange;
    $ctrl.maxDocuments = 30;

    function onInit() {
      $ctrl.retailerDocuments = [];
      $ctrl.manufacturerDocuments = [];
      DocumentStoreApiService.list($ctrl.quote).then(function (response) {
        $ctrl.retailerDocuments = response.documents;
        $ctrl.manufacturerDocuments = response.manufacturerDocuments;
        $ctrl.coverPagePdf = response.coverPagePdf;
      });
    }

    function onChange(selectedDocuments) {
      var documentIds = selectedDocuments.map(function (document) {
        return document.id;
      });

      $ctrl.ngModelCtrl.$setViewValue(documentIds);
    }
  }
})();
