(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuoteActions', [
    function () {
      return {
        restrict: 'AE',
        templateUrl: 'templates/sales.quotes.actions.directive.template.html',
        controllerAs: 'actionsVm',
        scope: {
          quote: '=',
          customerId: '<',
          pdfActions: '=?',
        },
        controller: [
          '$state',
          '$window',
          'QuotesService',
          'BrowserCheckService',
          'Notification',
          controller,
        ],
        bindToController: true,
      };
    },
  ]);

  function controller($state, $window, QuotesService, BrowserCheckService, Notification) {
    /* jshint validthis: true */
    var vm = this;

    vm.actionAvailable = actionAvailable;
    vm.copyAction = copyAction;
    vm.archiveAction = archiveAction;
    vm.copyLinkAction = copyLinkAction;

    /**
     * Check if action available for current quote status
     *
     * @param action
     * @returns {boolean}
     */
    function actionAvailable(action) {
      return getActions(vm.quote).indexOf(action) !== -1;
    }

    /**
     * Get list of available actions for a given quote status
     *
     * @returns {[]}
     */
    function getActions(quote) {
      var actions = ['archive'];
      var canEmail = quote.metadata.canEmail;
      var canFinalise = quote.metadata.allowFinalise;
      var status = quote.metadata.status;
      var pdfFailure = quote.metadata.documents.quote.pdfGenerationFailed;

      switch (status) {
        case 'draft':
          if (canFinalise) {
            actions.push('download', canEmail ? 'email' : 'disabledEmail', 'print');
          }
          actions.push('copy');
          break;
        case 'reprice':
        case 'repricing':
        case 'needsReprice':
          actions.push('copy');
          break;
        case 'finalising':
        case 'confirmed':
          if (!pdfFailure) {
            actions.push('download', canEmail ? 'email' : 'disabledEmail', 'print');
          }
          actions.push('copy');

          break;
        case 'finalised':
        case 'sent':
        case 'viewed':
          actions.push(
            'download',
            canEmail ? 'email' : 'disabledEmail',
            'print',
            'copy',
            'confirm',
          );
          if (vm.pdfActions && quote.metadata.documents.externalLinkUrl !== null) {
            actions.push('copyLink');
          }

          break;
        case 'expired':
          actions.push('copy');
          break;
      }

      // don't allow print
      if (BrowserCheckService.isMobile) {
        actions = actions.filter(function (action) {
          return 'print' !== action;
        });
      }

      return actions;
    }

    /**
     * Copy the quote
     */
    function copyAction() {
      QuotesService.copyQuote(vm.quote).then(function () {
        $state.reload();
      });
    }

    /**
     * Archive the quote
     */
    function archiveAction() {
      if (
        $window.confirm(
          'Are you sure you want to archive quote with reference ' +
            vm.quote.metadata.reference +
            '?',
        )
      ) {
        var hashLocation = 'quotes';
        QuotesService.archiveQuote(vm.quote).then(function () {
          $state.go(
            'sales.customers.retrieve',
            {customerId: vm.customerId, '#': 'quotes', hashLocation: hashLocation},
            {reload: true},
          );
        });
      }
    }

    function copyLinkAction() {
      Notification.success('Link copied to clipboard');
    }
  }
})();
