(function () {
  'use strict';

  angular.module('module.visualiser').directive('ttCompositor', [
    'CompositorSizeService',
    'CompositorScalingService',
    function (CompositorSizeService, CompositorScalingService) {
      return {
        restrict: 'A',
        templateUrl: 'templates/visualiser.compositor.directive.template.html',
        controllerAs: 'compositorVm',
        scope: {
          photoBankImage: '=',
          completedImage: '=',
        },
        controller: ['$scope', 'CompositorScalingService', compositorController],
        bindToController: true,
        link: link(CompositorSizeService, CompositorScalingService),
      };
    },
  ]);

  /**
   * @param $scope
   * @param CompositorScalingService
   */
  function compositorController($scope, CompositorScalingService) {
    /* jshint validthis: true */
    var vm = this;
    vm.onDrop = onDrop;

    $scope.skewImages = [];
    vm.skewImages = $scope.skewImages;
    vm.deleteImage = deleteImage;
    vm.selectImage = selectImage;
    vm.deselectImage = deselectImage;
    vm.initSkewImages = initSkewImages;

    /**
     * Load skew images and attach update callback
     */
    function initSkewImages() {
      angular.forEach(vm.completedImage.items, createSkewImage);
      updateCompletedImage(vm.skewImages);

      // TODO: review if performance issue
      // must be after creating the skewImages to avoid loop
      $scope.$watch('skewImages', updateCompletedImage, true);
    }

    /**
     * Handle drop of image
     *
     * @param image
     * @param position
     */
    function onDrop(image, position) {
      var index =
        vm.skewImages.push({
          id: image.id,
          url: image.url,
          position: [
            {x: position.x, y: position.y},
            {x: position.x, y: position.y + image.height},
            {x: position.x + image.width, y: position.y},
            {x: position.x + image.width, y: position.y + image.height},
          ],
          selected: false,
        }) - 1;

      selectImage(vm.skewImages[index]);
    }

    /**
     * Select an image
     *
     * @param {image} selected
     */
    function selectImage(selected) {
      angular.forEach(vm.skewImages, function (image) {
        image.selected = image === selected;
      });

      // move selected item to end of array for control of z-index
      vm.skewImages.push(vm.skewImages.splice(vm.skewImages.indexOf(selected), 1)[0]);
    }

    /**
     * Deselect image to hide grab handles
     */
    function deselectImage() {
      angular.forEach(vm.skewImages, function (image) {
        image.selected = false;
      });
    }

    /**
     * Remove an image from the dom
     *
     * @param {object} image
     */
    function deleteImage(image) {
      vm.skewImages.splice(vm.skewImages.indexOf(image), 1);
    }

    /**
     *  Update completedImage to reflect changes in skewImages
     *
     * @param {[object]} skewImages
     */
    function updateCompletedImage(skewImages) {
      vm.completedImage.items = [];
      angular.forEach(skewImages, function (skewImage) {
        vm.completedImage.items.push({
          item: skewImage.id,
          topLeft: toCoordArray(skewImage.position[0]),
          bottomLeft: toCoordArray(skewImage.position[1]),
          topRight: toCoordArray(skewImage.position[2]),
          bottomRight: toCoordArray(skewImage.position[3]),
        });
      });
    }

    function toCoordArray(position) {
      return [
        Math.round(CompositorScalingService.reverseScale(position.x)),
        Math.round(CompositorScalingService.reverseScale(position.y)),
      ];
    }

    /**
     * Create skewImage object from completedImage item
     *
     * @param {object} item
     */
    function createSkewImage(item) {
      vm.skewImages.push({
        id: item.item.id,
        url: item.item.imageUrl,
        position: [
          {
            x: CompositorScalingService.scale(item.topLeft[0]),
            y: CompositorScalingService.scale(item.topLeft[1]),
          },
          {
            x: CompositorScalingService.scale(item.bottomLeft[0]),
            y: CompositorScalingService.scale(item.bottomLeft[1]),
          },
          {
            x: CompositorScalingService.scale(item.topRight[0]),
            y: CompositorScalingService.scale(item.topRight[1]),
          },
          {
            x: CompositorScalingService.scale(item.bottomRight[0]),
            y: CompositorScalingService.scale(item.bottomRight[1]),
          },
        ],
        selected: false,
      });
    }
  }

  /**
   * @param CompositorSizeService
   * @param CompositorScalingService
   * @returns {Function}
   */
  function link(CompositorSizeService, CompositorScalingService) {
    return function (scope, element, attrs, controller) {
      var imageElement = element.find('.compositor__bank-image');

      init();

      /**
       * Handle scaling
       */
      function init() {
        imageElement.on('load', function () {
          CompositorScalingService.setImageSize(
            imageElement[0].naturalWidth,
            imageElement[0].naturalHeight,
          );
          resizeCompositor();

          // init skew images here as needs to be after the bank image so the scaling service is ready
          controller.initSkewImages();
        });

        // watch for changes in size of viewport
        CompositorSizeService.addResizeEventListener(resizeCompositor);
        scope.$on('$destroy', function () {
          CompositorSizeService.removeResizeEventListener(resizeCompositor);
        });
      }

      /**
       * Resize the photo bank image using the scaling service
       */
      function resizeCompositor() {
        CompositorScalingService.setContainerSize(element.width(), element.height());
        imageElement.width(CompositorScalingService.scale(imageElement[0].naturalWidth));
        imageElement.height(CompositorScalingService.scale(imageElement[0].naturalHeight));
      }
    };
  }
})();
