(function () {
  'use strict';

  angular
    .module('module.settings.advanced.documentStore')
    .factory('DocumentStoreApiService', ['$q', 'Notification', 'ApiService', service]);

  /**
   * @param $q
   * @param Notification
   * @param ApiService
   */
  function service($q, Notification, ApiService) {
    return {
      list: list,
      deleteDocument: deleteDocument,
      update: update,
      updateQuotePdfCoverPage: updateQuotePdfCoverPage,
      create: create,
      get: get,
    };

    /**
     * List documents optionally filtered by quote.
     *
     * @param {?object} quote
     *
     * @returns {$q}
     */
    function list(quote) {
      var url = '/document-store';

      if ('undefined' !== typeof quote) {
        url = url + '?quote=' + quote.id;
      }
      return ApiService.get(url).then(function (response) {
        return response.data;
      });
    }

    /**
     * Delete a document.
     *
     * @param {Object} document
     *
     * @returns {$q}
     */
    function deleteDocument(document) {
      return ApiService.delete('/document-store/' + document.id).then(
        function () {
          Notification.success('Deleted document');
        },
        function () {
          Notification.error('Failed to delete document');

          return $q.reject();
        },
      );
    }

    /**
     * Updates a document.
     *
     * @param {Object} document
     *
     * @returns {$q}
     */
    function update(document) {
      var data = {
        name: document.name,
        requireAppend: document.requireAppend,
        tempFile: document.tempFile,
      };

      return ApiService.put('/document-store/' + document.id, data).then(
        function (response) {
          Notification.success('Updated document');

          return response.data;
        },
        function () {
          Notification.error('Failed to update document');

          return $q.reject();
        },
      );
    }

    /**
     * Updates a Cover Page.
     *
     * @param {string} documentId
     *
     * @returns {$q}
     */
    function updateQuotePdfCoverPage(documentId) {
      var data = {
        quotePdfCoverPage: documentId,
      };

      return ApiService.put('/settings/quotes/pdf-cover-page', data).then(
        function (response) {
          Notification.success('Updated document');

          return response.data;
        },
        function () {
          Notification.error('Failed to update document');

          return $q.reject();
        },
      );
    }

    /**
     * Creates a document.
     *
     * @param {Object} document
     *
     * @returns {$q}
     */
    function create(document) {
      return ApiService.post('/document-store', document).then(
        function (response) {
          return response.data;
        },
        function () {
          Notification.error('Failed to create document');

          return $q.reject();
        },
      );
    }

    /**
     * get a document.
     *
     *
     * @returns {$q}
     */
    function get() {
      return ApiService.get('/document-store').then(
        function (response) {
          return response.data;
        },
        function () {
          Notification.error('Failed to get document');

          return $q.reject();
        },
      );
    }
  }
})();
