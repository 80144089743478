(function () {
  'use strict';

  angular.module('module.products').controller('controller.products.retrieve', [
    '$scope',
    'NavigationService',
    'dataProduct',
    'dataProductType',
    function ($scope, NavigationService, dataProduct, dataProductType) {
      $scope.product = dataProduct;
      $scope.productType = dataProductType;
      $scope.activeTab = 'details';
    },
  ]);
})();
