(function () {
  'use strict';
  angular
    .module('module.settings.retailer')
    .controller('controller.settings.retailer.surcharge', [
      'RetailerSurchargeApiService',
      controller,
    ]);

  function controller(RetailerSurchargeApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.isSubmitting = false;
    $ctrl.settings = null;
    $ctrl.applyToAllRetailers = applyToAllRetailers;

    RetailerSurchargeApiService.get().then(function (settings) {
      $ctrl.settings = settings;
    });

    $ctrl.save = function () {
      if ($ctrl.isSubmitting) {
        return;
      }
      $ctrl.isSubmitting = true;
      update(false);
    };

    function applyToAllRetailers() {
      update(true);
    }

    function update(applyToAllRetailers) {
      RetailerSurchargeApiService.update($ctrl.settings, applyToAllRetailers).finally(function () {
        $ctrl.isSubmitting = false;
      });
    }
  }
})();
