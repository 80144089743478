(function () {
  'use strict';

  angular.module('module.sales').filter('intCurrency', ['$filter', filter]);

  /**
   * Convert an integer currency value to decimal and apply currency filter
   *
   * @returns {function}
   */
  function filter($filter) {
    return function (amount, symbol, fractionSize, base) {
      base = base || 100;
      amount = amount / base;

      return $filter('currency')(amount, symbol, fractionSize);
    };
  }
})();
