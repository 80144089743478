(function () {
  'use strict';

  angular.module('module.navigation').directive('ttLogo', [
    'SettingsService',
    function (SettingsService) {
      return {
        restrict: 'A',
        scope: {
          useRetailer: '@',
        },
        link: link(SettingsService),
      };
    },
  ]);

  /**
   * @param SettingsService
   */
  function link(SettingsService) {
    return function (scope, element) {
      element.attr('src', '/images/tt-logo-black.png');

      if (scope.useRetailer) {
        SettingsService.getSettings().then(function (data) {
          var logo = data.metadata.logo;

          if (!logo) {
            return;
          }

          element.attr('src', logo);
        });
      }
    };
  }
})();
