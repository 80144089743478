(function () {
  'use strict';

  angular.module('module.products').directive('ttComponentAttributes', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/components.retrieve.attributes.directive.template.html',
        controllerAs: 'attributesVm',
        scope: {
          component: '=',
        },
        controller: ['ComponentsService', controller],
        bindToController: true,
      };
    },
  ]);

  /**
   * @param ComponentsService
   */
  function controller(ComponentsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.save = save;

    vm.attributes = buildAttributes(vm.component);

    function save() {
      ComponentsService.updateComponent(vm.component);
    }

    /**
     * Create array of mapped manufacturer and supplier attributes
     *
     * @param {object} component
     * @returns {Array}
     */
    function buildAttributes(component) {
      var attributes = [];

      // supplier attributes
      angular.forEach(component.attributes, function (attribute) {
        attributes.push({
          name: attribute.name,
          supplier: attribute,
          manufacturer: null,
        });
      });

      // manufacturer attributes
      angular.forEach(component.manufacturerAttributes, function (attribute) {
        // check if attribute exists already
        for (var i = 0; i < attributes.length; i++) {
          if (attributes[i].name === attribute.name) {
            attributes[i].manufacturer = attribute;

            return;
          }
        }

        // supplier attribute did not exist, create new
        attributes.push({
          name: attribute.name,
          supplier: null,
          manufacturer: attribute,
        });
      });

      return attributes;
    }
  }
})();
