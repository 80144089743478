(function () {
  'use strict';

  angular
    .module('module.visualiser')
    .factory('ItemImagesService', ['$timeout', 'ApiService', service]);

  function service($timeout, ApiService) {
    var pollInterval = 1000,
      pollHandle;

    return {
      getList: getList,
      pollList: pollList,
      cancelPollList: cancelPollList,
    };

    /**
     * Get a list of item images in a quote
     * GET /visualiser/{quoteId}/item-images
     *
     * @param {string} quoteId
     *
     * @returns {$q}
     */
    function getList(quoteId) {
      return ApiService.get('/visualiser/' + quoteId + '/itemimages').then(function (response) {
        // return only item image collection
        return response.data.items;
      });
    }

    /**
     * Start polling for updated item images, if any not available
     *
     * @param {string} quoteId
     * @param {function} cb
     */
    function pollList(quoteId, cb) {
      // ensure no running poll
      cancelPollList();

      getList(quoteId).then(function (itemImages) {
        cb(itemImages);

        // stop polling if all item images are available
        if (!allImagesAvailable(itemImages)) {
          pollHandle = $timeout(pollList, pollInterval, true, quoteId, cb);
        }
      });
    }

    /**
     * Cancels the running poll
     */
    function cancelPollList() {
      $timeout.cancel(pollHandle);
    }

    /**
     * Check item images array to see if all images are available
     *
     * @param {[object]} itemImages
     * @returns {boolean}
     */
    function allImagesAvailable(itemImages) {
      var allAvailable = true;

      angular.forEach(itemImages, function (itemImage) {
        if (!itemImage.images.outsidePngUrl) {
          allAvailable = false;
        }
      });

      return allAvailable;
    }
  }
})();
