(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .factory('RetailerProductsApiService', [
      '$q',
      '$timeout',
      '$window',
      'ApiService',
      'Notification',
      'UploadService',
      service,
    ]);

  var pollRate = 1000;

  var genericProductsCachedResult = {};

  var splitDoorHandlesForProductTypes = ['BiFold Door', 'Double Door'];

  var extraFields = {
    beads: ['Bevel', 'Ovolo', 'Square'],
    mullions: ['Bevel', 'Ovolo', 'Square'],
    transoms: ['Bevel', 'Ovolo', 'Square'],
    frameSizes: ['Slim', 'Standard', 'Large'],
    sashSizes: ['Slim', 'Standard', 'Large'],
    transomSizes: ['Slim', 'Standard', 'Large'],
    mullionSizes: ['Slim', 'Standard', 'Large'],
    flushSashOption: false,
    dummyOpenerOption: false,
    sashJointOption: true,
    transomMullionJointOption: true,
    doorSectionJointOption: false,
    allowTogglingPaints: true,
    showPaintPresets: false,
    stormproofBottomRailOptions: false,
    defaultSashType: 'Stormproof',
    interlockSizes: null,
  };

  var extraFieldsByMaterial = {
    Aluminium: {
      allowTogglingPaints: false,
    },
    Timber: {
      beads: ['Bevel', 'Lambs Tongue', 'Ovolo', 'Square'],
      stormproofBottomRailOptions: true,
    },
  };

  var extraFieldsByType = {
    'Stormproof Casement Window': {
      flushSashOption: true,
      dummyOpenerOption: true,
      doubleFlushSashOption: true,
      doubleFlushFrameSets: ['Square'],
      flushSashSizes: ['Slim', 'Standard', 'Large'],
      bottomRailSizes: ['Standard', 'Large'],
    },
    'Tilt Turn Window': {
      dummyOpenerOption: true,
    },
    'Sash Window': {
      transomMullionJointOption: false,
      doorSectionJointOption: false,
      mullionSizes: null,
      transomSizes: null,
      midrailSizes: null,
      doorMullionSizes: null,
      mullions: null,
      transoms: null,
      meetingRailSizes: ['Slim', 'Standard', 'Large'],
      bottomRailSizes: ['Standard', 'Large'],
      stormproofBottomRailOptions: true,
    },
    'Entrance Door': {
      doorSectionJointOption: true,
      flushSashOption: true,
      doorMullions: ['Bevel', 'Ovolo', 'Square'],
      doorMidrails: ['Bevel', 'Ovolo', 'Square'],
      flushSashSizes: ['Slim', 'Standard', 'Large'],
      bottomRailSizes: ['Standard', 'Large'],
    },
    'Double Door': {
      doorSectionJointOption: true,
      flushSashOption: true,
      doorMullions: ['Bevel', 'Ovolo', 'Square'],
      doorMidrails: ['Bevel', 'Ovolo', 'Square'],
      flushSashSizes: ['Slim', 'Standard', 'Large'],
      bottomRailSizes: ['Standard', 'Large'],
    },
    'Composite Door': {
      sashJointOption: false,
      allowTogglingPaints: false,
      showPaintPresets: true,
      sashSizes: null,
    },
    'Patio Door': {
      transomMullionJointOption: false,
      doorSectionJointOption: true,
      mullionSizes: null,
      transomSizes: null,
      midrailSizes: null,
      doorMullionSizes: null,
      mullions: null,
      transoms: null,
      doorMidrails: ['Bevel', 'Ovolo', 'Square'],
      interlockSizes: ['Ultra Slim', 'Super Slim', 'Slim', 'Standard', 'Large'],
    },
    'BiFold Door': {
      transomMullionJointOption: false,
      doorSectionJointOption: true,
      mullionSizes: null,
      transomSizes: null,
      midrailSizes: null,
      doorMullionSizes: null,
      mullions: null,
      transoms: null,
      doorMidrails: ['Bevel', 'Ovolo', 'Square'],
    },
  };

  var extraFieldsByProduct = {
    'PVCu Casement': {
      sashSizes: ['Slim', 'Standard'],
    },
    'PVCu Entrance Door': {
      sashSizes: ['Standard', 'Large'],
      midrailSizes: ['Slim', 'Standard', 'Large', 'Extra Large'],
      doorMullionSizes: ['Slim', 'Standard', 'Large'],
    },
    'PVCu French Door': {
      sashSizes: ['Standard', 'Large'],
      midrailSizes: ['Slim', 'Standard', 'Large', 'Extra Large'],
      doorMullionSizes: ['Slim', 'Standard', 'Large'],
    },
    'PVCu Tilt Turn': {
      sashSizes: ['Standard', 'Large'],
    },
    'PVCu Patio Door': {
      sashSizes: ['Standard', 'Large'],
      midrailSizes: ['Slim', 'Standard'],
      interlockSizes: ['Standard', 'Large'],
    },
    'PVCu Bifold': {
      frameSizes: ['Standard', 'Large'],
      midrailSizes: ['Slim', 'Standard', 'Large'],
    },
    'PVCu Sash Window': {
      frameSizes: ['Standard'],
      sashSizes: ['Standard'],
      // For now, no size options for sash windows
    },
    'Composite Door': {
      midrailSizes: ['Slim', 'Standard', 'Large', 'Extra Large'],
    },
    'Composite Side Screen': {
      beads: null,
      transomSizes: null,
      mullionSizes: null,
      sashSizes: null,
      dummyOpenerOption: false,
      flushSashOption: false,
      transomMullionJointOption: false,
      sashJointOption: false,
      showPaintPresets: true,
      allowTogglingPaints: false,
    },
    'Aluminium Bifold': {
      frameSizes: ['Standard', 'Large'],
      midrailSizes: ['Standard', 'Large'],
    },
    'Aluminium Window': {
      transomSizes: ['Standard', 'Large'],
      mullionSizes: ['Standard', 'Large'],
    },
    'Aluminium Entrance Door': {
      frameSizes: ['Standard', 'Large'],
      sashSizes: ['Steel-look', 'Slim', 'Standard', 'Large'],
      transomSizes: ['Steel-look', 'Standard'],
      mullionSizes: ['Steel-look', 'Standard'],
      midrailSizes: ['Steel-look', 'Standard', 'Large'],
      doorMullionSizes: ['Steel-look', 'Standard'],
    },
    'Aluminium French Door': {
      frameSizes: ['Standard', 'Large'],
      sashSizes: ['Steel-look', 'Slim', 'Standard', 'Large'],
      transomSizes: ['Steel-look', 'Standard'],
      mullionSizes: ['Steel-look', 'Standard'],
      midrailSizes: ['Steel-look', 'Standard', 'Large'],
      doorMullionSizes: ['Steel-look', 'Standard'],
    },
    'Aluminium Patio Door': {
      frameSizes: ['Standard', 'Large'],
      sashSizes: ['Slim', 'Standard'],
      midrailSizes: ['Slim', 'Standard'],
    },
    'Aluminium Tilt Turn': {
      flushSashOption: true,
      insideFlushSashOption: true,
      doubleFlushSashOption: true,
      doubleFlushFrameSets: ['Square'],
      flushSashSizes: ['Slim', 'Standard', 'Large'],
    },
    'Timber Entrance Door': {
      midrailSizes: ['Slim', 'Standard', 'Large', 'Extra Large'],
      doorMullionSizes: ['Slim', 'Standard', 'Large'],
      defaultSashType: 'Flush',
    },
    'Timber French Door': {
      midrailSizes: ['Slim', 'Standard', 'Large', 'Extra Large'],
      doorMullionSizes: ['Slim', 'Standard', 'Large'],
    },
    'Timber Casement Window': {
      bottomRailSizes: ['Standard', 'Large'],
      doubleFlushFrameSets: null,
      defaultSashType: 'Flush',
    },
    'Timber Patio Door': {
      midrailSizes: ['Slim', 'Standard', 'Large', 'Extra Large'],
    },
    'Timber Bifold': {
      midrailSizes: ['Slim', 'Standard', 'Large'],
    },
    'Timber Front Door': {
      midrailSizes: ['Slim', 'Standard', 'Large', 'Extra Large'],
    },
  };

  function service($q, $timeout, $window, ApiService, Notification, UploadService) {
    return {
      list: list,
      listForRetailer: listForRetailer,
      listProductLibrary: listProductLibrary,
      pollList: pollList,
      delete: deleteProduct,
      create: create,
      listGenericProducts: listGenericProducts,
      getGenericProduct: getGenericProduct,
      get: get,
      update: update,
      copy: copy,
      addLibraryProducts: addLibraryProducts,
    };

    function list() {
      return ApiService.get('/retailer-products').then(function (response) {
        return response.data.retailerProducts;
      });
    }

    function listForRetailer(retailerId) {
      return ApiService.get('/companies/retailers/' + retailerId + '/retailer-products').then(
        function (response) {
          return response.data.retailerProducts;
        },
      );
    }

    function listProductLibrary() {
      return ApiService.get('/retailer-products/library').then(function (response) {
        return response.data.retailerProducts;
      });
    }

    function pollList(scope) {
      var deferred = $q.defer();
      var pollPromise;

      var pollFunction = function () {
        list().then(
          function (retailerProducts) {
            for (var i = 0; i < retailerProducts.length; i++) {
              if (retailerProducts[i].productImage === null) {
                deferred.notify(retailerProducts);
                pollPromise = $timeout(pollFunction, pollRate);

                return;
              }
            }

            deferred.resolve(retailerProducts);
          },
          function () {
            pollPromise = $timeout(pollFunction, pollRate);
          },
        );
      };

      pollFunction();

      scope.$on('$destroy', function () {
        if (typeof pollPromise !== 'undefined') {
          $timeout.cancel(pollPromise);
        }
      });

      return deferred.promise;
    }

    function deleteProduct(product) {
      return ApiService.delete('/retailer-products/' + product.id).then(
        function () {
          Notification.success('Deleted retailer product');
        },
        function () {
          Notification.error('Failed to delete retailer product');

          return $q.reject();
        },
      );
    }

    function copy(product) {
      return ApiService.post('/retailer-products/' + product.id + '/copy').then(
        function (response) {
          Notification.success('Copied retailer product');

          return response.data.id;
        },
        function () {
          Notification.error('Failed to copy retailer product');

          return $q.reject();
        },
      );
    }

    function create(
      name,
      productId,
      settings,
      productImageFile,
      manufacturerImageFile,
      pricing,
      componentPricing,
    ) {
      return uploadImages(productImageFile, manufacturerImageFile).then(function (uploads) {
        var retailerProduct = {
          name: name,
          product: productId,
          settings: settings,
          productImage: uploads.productImage,
          manufacturerImage: uploads.manufacturerImage,
          pricing: pricing,
          componentPricing: componentPricing,
        };

        // For now Flush/Flush items *must* have dummy openers, otherwise the
        // item doesn't draw right (it would have no bead on fixed apertures).
        // Also does this on update.
        if (retailerProduct.settings.sashType === 'Flush/Flush') {
          retailerProduct.settings.dummyOpeners = 'Required';
        }

        return ApiService.post('/retailer-products', retailerProduct).then(
          function (response) {
            Notification.success('Created retailer product');

            return response.data;
          },
          function () {
            Notification.error('Failed to create retailer product');

            return $q.reject();
          },
        );
      });
    }

    function uploadImages(productImageFile, manufacturerImageFile) {
      return $q.all({
        productImage: uploadImage(productImageFile),
        manufacturerImage: uploadImage(manufacturerImageFile),
      });
    }

    function uploadImage(imageFile) {
      if (!imageFile) {
        return $q.when(null);
      }

      return UploadService.uploadImage(imageFile).then(function (file) {
        return file.resourceId;
      });
    }

    function addLibraryProducts(retailer, products) {
      var data = {
        retailerProducts: products,
      };

      return ApiService.post(
        '/companies/retailers/' + retailer.id + '/retailer-products/add',
        data,
      ).then(
        function (response) {
          Notification.success('Added library products');

          return null;
        },
        function () {
          Notification.error('Failed to add library products');

          return $q.reject();
        },
      );
    }

    function listGenericProducts() {
      return ApiService.get('/generic-products').then(function (response) {
        return response.data;
      });
    }

    function getGenericProduct(productId) {
      if (genericProductsCachedResult[productId] !== undefined) {
        return $q.when(genericProductsCachedResult[productId]);
      }

      return ApiService.get('/generic-product/' + productId).then(function (response) {
        var product = response.data;

        Object.assign(product, extraFields);

        if (extraFieldsByMaterial[product.materialType]) {
          Object.assign(product, extraFieldsByMaterial[product.materialType]);
        }

        if (extraFieldsByType[product.productType]) {
          Object.assign(product, extraFieldsByType[product.productType]);
        }

        if (extraFieldsByProduct[product.name]) {
          Object.assign(product, extraFieldsByProduct[product.name]);
        }

        sortComponentsByName(product);

        if (splitDoorHandlesForProductTypes.includes(product.productType)) {
          splitDoorHandles(product);
        }

        genericProductsCachedResult[productId] = product;

        return product;
      });
    }

    function get(id) {
      return ApiService.get('/retailer-products/' + id).then(function (response) {
        return response.data;
      });
    }

    function update(
      id,
      name,
      settings,
      productImageFile,
      manufacturerImageFile,
      pricing,
      componentPricing,
    ) {
      return uploadImages(productImageFile, manufacturerImageFile).then(function (uploads) {
        var retailerProduct = {
          name: name,
          settings: settings,
          pricing: pricing,
          componentPricing: componentPricing,
        };

        // For now Flush/Flush items *must* have dummy openers, otherwise the
        // item doesn't draw right (it would have no bead on fixed apertures)
        if (retailerProduct.settings.sashType === 'Flush/Flush') {
          retailerProduct.settings.dummyOpeners = 'Required';
        }

        if (uploads.productImage) {
          retailerProduct.productImage = uploads.productImage;
        }

        if (uploads.manufacturerImage) {
          retailerProduct.manufacturerImage = uploads.manufacturerImage;
        }

        return ApiService.put('/retailer-products/' + id, retailerProduct).then(
          function () {
            Notification.success('Updated retailer product');
          },
          function () {
            Notification.error('Failed to update retailer product');

            return $q.reject();
          },
        );
      });
    }

    function sortComponentsByName(product) {
      Object.keys(product.components).forEach(function (key) {
        product.components[key] = product.components[key].sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      });
    }

    function splitDoorHandles(p) {
      var doorHandles = p.components['door handle'];
      if (doorHandles && doorHandles.length) {
        p.components['master handle'] = doorHandles.filter(filterHandleType('master', true));

        // This uses multiple filters to ensure master and slave handles are sorted before slave only handles
        p.components['slave handle'] = doorHandles
          .filter(filterHandleType('slave', true))
          .filter(filterHandleType('master', true))
          .concat(
            doorHandles
              .filter(filterHandleType('slave', true))
              .filter(filterHandleType('master', false)),
          );

        p.components['folding handle'] = doorHandles.filter(filterHandleType('folding', true));

        delete p.components['door handle'];
      }

      function filterHandleType(type, includes) {
        return function (handle) {
          return handle.allowedIn.split('|').includes(type) === includes;
        };
      }
    }
  }
})();
