(function () {
  'use strict';

  angular.module('module.errors').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
      $stateProvider
        .state('errors', {
          abstract: true,
          views: {
            'navigation@': {
              controller: 'controller.navigation',
              templateUrl: 'templates/navigation.template.html',
              resolve: {
                dataUser: [
                  'AuthenticationService',
                  function (AuthenticationService) {
                    return AuthenticationService.getUser();
                  },
                ],
              },
            },
          },
        })

        .state('errors.404', {
          views: {
            'content@': {
              templateUrl: 'templates/errors.404.template.html',
            },
          },
          ncyBreadcrumb: {
            label: 'Page not found',
          },
          data: {
            meta: {
              titleSuffix: '| Page not found',
            },
          },
        });

      // show 404 state without location change
      $urlRouterProvider.otherwise(function ($injector, $location) {
        $injector.get('$state').go('errors.404');
        return $location.path();
      });
    },
  ]);

  angular.module('module.errors').run([
    '$rootScope',
    '$state',
    function ($rootScope, $state) {
      // on state change failure (like rejected resolve) go to 404
      $rootScope.$on('$stateChangeError', function () {
        $state.go('errors.404');
      });
    },
  ]);
})();
