(function () {
  'use strict';

  angular
    .module('tommyApp')
    .run([
      '$location',
      '$rootScope',
      '$state',
      '$window',
      'AuthenticationService',
      'ngMeta',
      'BrowserCheckService',
      run,
    ]);

  function run(
    $location,
    $rootScope,
    $state,
    $window,
    AuthenticationService,
    ngMeta,
    BrowserCheckService,
  ) {
    ngMeta.init();

    if (!BrowserCheckService.isSupported) {
      $state.go('authentication.browserCheck');

      return;
    }

    $rootScope.$on('$stateChangeStart', function (event, toState) {
      if (toState.externalUrl) {
        event.preventDefault();
        $window.open(toState.externalUrl, '_blank');
      }
    });

    // TODO: remove during authentication service refactor
    if (
      $location.path() !== '/signin' &&
      $location.path() !== '/browser-check' &&
      $location.path().substr(0, 9) !== '/register' &&
      $location.path().substr(0, 15) !== '/password-reset'
    ) {
      AuthenticationService.checkAuthentication().catch(function (redirect) {
        if (!redirect) {
          $state.go('authentication.signin');
        }
      });
    }
  }
})();
