(function () {
  'use strict';

  angular.module('module.sales').component('ttRetailerManufacturerProductDiscount', {
    templateUrl: 'templates/sales.retailer.manufacturerProduct.discount.component.template.html',
    controller: ['pricingModes', controller],
    bindings: {
      pricingMode: '<',
      parentOnChange: '<onChange',
    },
  });

  function controller(pricingModes) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.enabled = $ctrl.pricingMode !== pricingModes.none;
    $ctrl.perProduct = $ctrl.pricingMode === pricingModes.perProduct;

    $ctrl.onChange = function () {
      if (!$ctrl.enabled) {
        $ctrl.perProduct = false;
        $ctrl.parentOnChange(pricingModes.none);

        return;
      }

      return $ctrl.parentOnChange($ctrl.perProduct ? pricingModes.perProduct : pricingModes.fixed);
    };
  }
})();
