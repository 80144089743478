(function () {
  'use strict';

  angular
    .module('module.settings.advanced.documentStore')
    .controller('controller.settings.advanced.documentStore.list', [
      '$scope',
      '$q',
      '$timeout',
      'dataDocuments',
      'DocumentStoreApiService',
      'Notification',
      controller,
    ]);

  function controller($scope, $q, $timeout, dataDocuments, DocumentStoreApiService, Notification) {
    /* jshint validthis: true */
    var vm = this;

    vm.deleteDocument = deleteDocument;
    vm.onUpload = onUpload;
    vm.coverPage = updateCoverPagePdf;
    vm.maxDocuments = 30;
    vm.documents = dataDocuments.documents;
    vm.coverPagePdf = dataDocuments.coverPagePdf;
    vm.showCoverPage = vm.coverPagePdf ? true : false;
    var pollRate = 2000;

    $scope.updatePreviewPDF = function () {
      var deferred = $q.defer();
      var pollPromise;

      var pollFunction = function () {
        DocumentStoreApiService.get()
          .then(function (response) {
            var allHaveThumbnail = response.documents.every(function (doc) {
              return doc.thumbnailUrl !== null;
            });

            if (allHaveThumbnail) {
              vm.documents = response.documents;
              deferred.resolve(response);
            } else {
              pollPromise = $timeout(pollFunction, pollRate);
            }
          })
          .catch(function () {
            deferred.reject('Failed to get documents');
          });
      };

      pollFunction();

      $scope.$on('$destroy', function () {
        if (pollPromise) {
          $timeout.cancel(pollPromise);
        }
      });

      return deferred.promise;
    };

    /**
     * @param {Object} document
     */
    function deleteDocument(document) {
      vm.documents.splice(vm.documents.indexOf(document), 1);
    }

    /**
     * @param {[{object}]} uploadedDocuments
     */
    function onUpload(uploadedDocuments) {
      angular.forEach(uploadedDocuments, function (document) {
        vm.documents.push(document);
        if (document.thumbnailUrl === null) {
          $scope.updatePreviewPDF(document);
        }
      });
    }

    angular.forEach(vm.documents, function (document) {
      if (!document.thumbnailUrl) {
        $scope.updatePreviewPDF(document);
      }
    });

    function updateCoverPagePdf() {
      if (vm.showCoverPage && vm.documents && vm.documents.length >= 0) {
        vm.coverPagePdf = vm.documents[0].id;
        vm.documents[0].requireAppend = true;
        DocumentStoreApiService.updateQuotePdfCoverPage(vm.coverPagePdf);
        DocumentStoreApiService.update(vm.documents[0]);
      } else {
        var prevCoverPageDoc = vm.documents.find((doc) => doc.id === vm.coverPagePdf);
        vm.coverPagePdf = null;
        prevCoverPageDoc.requireAppend = false;
        DocumentStoreApiService.update(prevCoverPageDoc);
        DocumentStoreApiService.updateQuotePdfCoverPage(vm.coverPagePdf);
      }
    }
  }
})();
