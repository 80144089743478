(function () {
  'use strict';

  angular
    .module('module.visualiser')
    .factory('CompositorSizeService', ['$window', '$document', '$timeout', service]);

  function service($window, $document, $timeout) {
    var view = $document.find('div.view'),
      resizeCallbacks = [],
      resizeDebounce;

    return {
      addResizeEventListener: addResizeEventListener,
      removeResizeEventListener: removeResizeEventListener,
      getContentHeight: getContentHeight,
    };

    /**
     * Get height of content area (window minus nav, minus breadcrumbs)
     *
     * @returns {number}
     */
    function getContentHeight() {
      return $window.innerHeight - view[0].offsetTop;
    }

    /**
     * Add resize event listener
     *
     * @param {function} cb
     */
    function addResizeEventListener(cb) {
      // add listener if first callback
      if (!resizeCallbacks.length) {
        $window.addEventListener('resize', onResize);
      }

      resizeCallbacks.push(cb);
    }

    /**
     * Remove resize event listener
     *
     * @param {function} cb
     */
    function removeResizeEventListener(cb) {
      var callbackIndex = resizeCallbacks.indexOf(cb);
      if (callbackIndex > -1) {
        resizeCallbacks.splice(callbackIndex, 1);
      }

      // remove listener if no callbacks
      if (!resizeCallbacks.length) {
        $window.removeEventListener('resize', onResize);
      }
    }

    /**
     * Handle browser resize, debouncing (limit to 5 updates/second)
     */
    function onResize() {
      if (resizeDebounce) {
        $timeout.cancel(resizeDebounce);
      }

      resizeDebounce = $timeout(function () {
        var height = getContentHeight();

        angular.forEach(resizeCallbacks, function (cb) {
          cb(height);
        });
      }, 200);
    }
  }
})();
