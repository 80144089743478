(function () {
  'use strict';

  angular.module('module.products').controller('controller.components.list', [
    '$location',
    '$scope',
    '$stateParams',
    'ComponentsService',
    function ($location, $scope, $stateParams, ComponentsService) {
      $scope.showFilter = false;
      $scope.searchStringHelper = '';

      ComponentsService.listComponents($stateParams).then(function (data) {
        $scope.components = data.components;
        $scope.pagination = data.pagination;
        $scope.search = data.search;
        $scope.searchResultsString =
          data.pagination.pageItemsTotal +
          (data.pagination.pageItemsTotal === 1 ? ' component found' : ' components found');

        if ($scope.search !== undefined) {
          $scope.showFilter = !(
            $scope.search.filter.supplier === '0' && $scope.search.filter.componentType === 0
          );
        }
      });

      ComponentsService.getFilterData().then(function (data) {
        $scope.reference = data;
      });

      $scope.clearSearch = function () {
        $location.path('/components');
      };

      $scope.searchComponents = function () {
        var pathItems = [];

        pathItems.push('', 'components');
        pathItems.push(
          $scope.search.string !== undefined && $scope.search.string !== ''
            ? $scope.search.string
            : 'all',
        );
        pathItems.push(
          $scope.search.filter.componentType !== undefined &&
            $scope.search.filter.componentType !== ''
            ? $scope.search.filter.componentType
            : '0',
        );
        pathItems.push(
          $scope.search.filter.supplier !== undefined && $scope.search.filter.supplier !== ''
            ? $scope.search.filter.supplier
            : '0',
        );
        pathItems.push('1');

        $location.path(pathItems.join('/'));
      };

      $scope.searchChange = function () {
        if ($scope.search.string === '') {
          $scope.searchComponents();
        }
      };
    },
  ]);
})();
