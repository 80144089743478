(function () {
  'use strict';

  angular
    .module('module.dashboard')
    .factory('ReportsApiService', ['$q', 'Notification', 'ApiService', service]);

  function service($q, Notification, ApiService) {
    return {
      downloadWeeklyQuotes: downloadWeeklyQuotes,
      downloadAllTimeQuotes: downloadAllTimeQuotes,
      downloadAllTimeOrders: downloadAllTimeOrders,
    };

    function downloadWeeklyQuotes() {
      return ApiService.download(ApiService.get('/reports/weekly-quotes/download')).catch(
        function () {
          Notification.error('Failed to download report');

          return $q.reject();
        },
      );
    }

    function downloadAllTimeQuotes() {
      return ApiService.download(ApiService.get('/reports/all-time-quotes/download')).catch(
        function () {
          Notification.error('Failed to download report');

          return $q.reject();
        },
      );
    }

    function downloadAllTimeOrders() {
      return ApiService.download(ApiService.get('/reports/all-time-orders/download')).catch(
        function () {
          Notification.error('Failed to download report');

          return $q.reject();
        },
      );
    }
  }
})();
