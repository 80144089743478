(function () {
  'use strict';

  angular.module('module.settings.advanced').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('settings.advanced', {
      url: '/advanced',
      abstract: true,
      views: {
        'content@': {
          controller: [
            '$scope',
            'NavigationService',
            function ($scope, NavigationService) {
              $scope.navigation = NavigationService.getAdvancedSettingsNavItems();
            },
          ],
          templateUrl: 'templates/settings.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Advanced',
      },
    });

    $stateProvider.state('settings.advanced.suppliers', {
      url: '/suppliers',
      controller: 'controller.settings.advanced.suppliers',
      templateUrl: 'templates/settings.advanced.suppliers.template.html',
      resolve: {
        dataSettingsSuppliers: [
          'SettingsSuppliersService',
          function (SettingsSuppliersService) {
            return SettingsSuppliersService.SuppliersRetrieve();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Suppliers',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Suppliers',
        },
      },
    });

    $stateProvider.state('settings.advanced.profileSuites', {
      url: '/profile-suites',
      controller: 'controller.settings.advanced.profileSuites.list',
      templateUrl: 'templates/settings.advanced.profileSuites.list.template.html',
      resolve: {
        dataProfileSuites: [
          '$stateParams',
          'ProfileSuitesService',
          function ($stateParams, ProfileSuitesService) {
            return ProfileSuitesService.ListProfileSuites();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Profile Suites',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Profile Suites',
        },
      },
    });

    $stateProvider.state('settings.advanced.materialTypes', {
      url: '/material-types',
      controller: 'controller.settings.advanced.materials',
      templateUrl: 'templates/settings.advanced.materialTypes.template.html',
      resolve: {
        dataSettingsMaterials: [
          'SettingsMaterialsService',
          function (SettingsMaterialsService) {
            return SettingsMaterialsService.MaterialsRetrieve();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Material Types',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Material Types',
        },
      },
    });

    $stateProvider.state('settings.advanced.productTypes', {
      url: '/product-types',
      controller: 'controller.settings.advanced.productTypes',
      templateUrl: 'templates/settings.advanced.productTypes.template.html',
      resolve: {
        dataSettingsMaterials: [
          'SettingsProductTypesService',
          function (SettingsProductTypesService) {
            return SettingsProductTypesService.ProductTypesRetrieve();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Product Types',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Product Types',
        },
      },
    });

    $stateProvider.state('settings.advanced.productStyles', {
      url: '/product-styles',
      controller: 'controller.settings.advanced.productStyles',
      controllerAs: 'stylesVm',
      templateUrl: 'templates/settings.advanced.productStyles.template.html',
      resolve: {
        dataProductTypes: [
          'TypesService',
          function (TypesService) {
            // TODO: this should probably be a method on the TypesService
            return TypesService.List('products').then(function (types) {
              return types.sort(function (a, b) {
                return a.name.localeCompare(b.name);
              });
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Product Styles',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Product Styles',
        },
      },
    });

    $stateProvider.state('settings.advanced.quote', {
      url: '/quote',
      controller: 'controller.settings.advanced.quote',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.advanced.quote.template.html',
      resolve: {
        dataSettings: [
          'QuoteSettingsApiService',
          function (QuoteSettingsApiService) {
            return QuoteSettingsApiService.getSettings();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Quote Settings',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Quote Settings',
        },
      },
    });

    $stateProvider.state('settings.advanced.salesperson', {
      url: '/salesperson',
      controller: 'controller.settings.advanced.salesperson',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.advanced.salesperson.template.html',
      resolve: {
        dataSettings: [
          'SalespersonSettingsApiService',
          function (SalespersonSettingsApiService) {
            return SalespersonSettingsApiService.getSettings();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Salesperson Settings',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Salesperson Settings',
        },
      },
    });

    $stateProvider.state('settings.advanced.customerPaymentTerms', {
      url: '/customer-payment-terms',
      controller: 'controller.settings.advanced.customerPaymentTerms',
      controllerAs: 'termsVm',
      templateUrl: 'templates/settings.advanced.customerPaymentTerms.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
        dataIsManager: [
          'AuthenticationService',
          function (AuthenticationService) {
            return AuthenticationService.isManagerUser();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Payment Terms',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Payment Terms',
        },
      },
    });

    $stateProvider.state('settings.advanced.manufacturers', {
      url: '/manufacturers',
      controller: 'controller.settings.advanced.manufacturers',
      controllerAs: 'manufacturersVm',
      templateUrl: 'templates/settings.advanced.manufacturers.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Manufacturers',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Manufacturers',
        },
      },
    });

    $stateProvider.state('settings.advanced.pricing', {
      url: '/pricing',
      controller: 'controller.settings.advanced.pricing',
      controllerAs: 'pricingVm',
      templateUrl: 'templates/settings.advanced.pricing.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
        currencySettings: [
          'CurrencyService',
          function (CurrencyService) {
            return CurrencyService.getSettings();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Pricing',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Pricing',
        },
      },
    });

    $stateProvider.state('settings.advanced.glass', {
      url: '/glass',
      controller: 'controller.settings.advanced.glass',
      controllerAs: 'glassVm',
      templateUrl: 'templates/settings.advanced.glass.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Glass',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Glass',
        },
      },
    });

    $stateProvider.state('settings.advanced.orderCharges', {
      url: '/order-charges',
      controller: 'controller.settings.advanced.orderCharges',
      controllerAs: 'orderChargesVm',
      templateUrl: 'templates/settings.advanced.orderCharges.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Order Charges',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Order Charges',
        },
      },
    });

    $stateProvider.state('settings.advanced.country', {
      url: '/country',
      controller: 'controller.settings.advanced.country',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.advanced.country.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Country Settings',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Country Settings',
        },
      },
    });

    $stateProvider.state('settings.advanced.contractSettings', {
      url: '/contract',
      controller: 'controller.settings.advanced.contractSettings',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.advanced.contractSettings.template.html',
      resolve: {
        dataContractSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true).then(function (response) {
              return response.settings.contract;
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Contract Settings',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Contract Settings',
        },
      },
    });

    $stateProvider.state('settings.advanced.externalApi', {
      url: '/external-api',
      controller: 'controller.settings.advanced.externalApi',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.advanced.externalApi.template.html',
      resolve: {
        dataWebhook: [
          'ExternalApiApiService',
          function (ExternalApiApiService) {
            return ExternalApiApiService.getWebhookDetails();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'External Api',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | External Api',
        },
      },
    });

    $stateProvider.state('settings.advanced.businessPilot', {
      url: '/business-pilot',
      controller: 'controller.settings.advanced.businessPilot',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.advanced.businessPilot.template.html',
      resolve: {
        dataWebhook: [
          'ExternalApiApiService',
          function (ExternalApiApiService) {
            return ExternalApiApiService.getWebhookDetails();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Business Pilot',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Business Pilot',
        },
      },
    });

    $stateProvider.state('settings.advanced.vipSupport', {
      url: '/vip-support',
      controller: 'controller.settings.advanced.accountMonitoring',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.advanced.accountMonitoring.template.html',
      resolve: {
        dataProfile: [
          'AuthenticationService',
          function (AuthenticationService) {
            return AuthenticationService.getUser();
          },
        ],
        activeStatus: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings().then(function (settings) {
              return settings.settings.accountMonitoringEnabled;
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'VIP Support',
      },
      data: {
        meta: {
          titleSuffix: '| VIP Support',
        },
      },
    });
  }
})();
