(function () {
  'use strict';

  angular
    .module('module.marketing')
    .factory('VideoApiService', ['$q', 'Notification', 'ApiService', service]);

  /**
   * @param $q
   * @param Notification
   * @param ApiService
   */
  function service($q, Notification, ApiService) {
    return {
      create: create,
    };

    /**
     * Order / create a new video request
     *
     * @param {string} videoType
     * @returns {$q}
     */
    function create(videoType) {
      var data = {
        videoType: videoType,
      };

      return ApiService.post('/videosales', data).catch(function () {
        Notification.error('Failed to order video');

        return $q.reject(null);
      });
    }
  }
})();
