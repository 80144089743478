(function () {
  'use strict';

  angular.module('module.sales').directive('ttCustomerDetails', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/sales.customers.details.directive.template.html',
        controllerAs: 'customerVm',
        scope: {
          customer: '=',
        },
        controller: function () {},
        bindToController: true,
      };
    },
  ]);
})();
