(function () {
  'use strict';

  angular
    .module('module.products')
    .controller('controller.products.create', [
      '$state',
      'ProductsService',
      'dataProductTypes',
      'dataMaterialTypes',
      'dataGlazingMethods',
      'dataJointTypes',
      'dataProfileSuites',
      controller,
    ]);

  /**
   * @param $state
   * @param ProductsService
   * @param dataProductTypes
   * @param dataMaterialTypes
   * @param dataGlazingMethods
   * @param dataJointTypes
   * @param dataProfileSuites
   */
  function controller(
    $state,
    ProductsService,
    dataProductTypes,
    dataMaterialTypes,
    dataGlazingMethods,
    dataJointTypes,
    dataProfileSuites,
  ) {
    /* jshint validthis: true */
    var vm = this;

    vm.create = create;
    vm.product = ProductsService.initProduct();
    vm.productTypes = dataProductTypes;
    vm.materialTypes = dataMaterialTypes;
    vm.glazingMethods = dataGlazingMethods;
    vm.jointTypes = dataJointTypes;
    vm.profileSuites = dataProfileSuites;

    function create() {
      ProductsService.createProduct(vm.product).then(function (product) {
        $state.go('products.products.retrieve', {productId: product.id});
      });
    }
  }
})();
