(function () {
  'use strict';

  angular.module('module.settings.company').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('settings.company', {
        url: '/company',
        abstract: true,
        views: {
          'content@': {
            controller: [
              '$scope',
              'NavigationService',
              function ($scope, NavigationService) {
                $scope.navigation = NavigationService.getCompanySettingsNavItems();
              },
            ],
            templateUrl: 'templates/settings.template.html',
          },
        },
        ncyBreadcrumb: {
          label: 'Company',
        },
      });

      $stateProvider.state('settings.company.details', {
        url: '/contact-details',
        controller: 'controller.settings.company.contactDetails',
        controllerAs: '$ctrl',
        templateUrl: 'templates/settings.company.contactDetails.template.html',
        resolve: {
          dataSettings: [
            'SettingsService',
            function (SettingsService) {
              return SettingsService.getSettings(true);
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'Contact Details',
        },
        data: {
          meta: {
            titleSuffix: '| Settings | Company | Contact Details',
          },
        },
      });

      $stateProvider.state('settings.company.logo', {
        url: '/logo',
        controller: 'controller.settings.company.logo',
        templateUrl: 'templates/settings.company.logo.template.html',
        resolve: {
          dataSettings: [
            'SettingsService',
            function (SettingsService) {
              return SettingsService.getSettings(true);
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'Logo',
        },
        data: {
          meta: {
            titleSuffix: '| Settings | Company | Logo',
          },
        },
      });
    },
  ]);
})();
