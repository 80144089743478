(function () {
  'use strict';

  angular.module('module.products').controller('controller.products', [
    '$scope',
    'NavigationService',
    function ($scope, NavigationService) {
      NavigationService.GetSubNavigation().then(function (navigation) {
        $scope.navigation = navigation;
      });
    },
  ]);
})();
