(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('QuotesService', [
      '$q',
      'Notification',
      'ApiService',
      'ApiUrlService',
      'QuotePricingService',
      service,
    ]);

  var pollStatus = ['finalising', 'needsReprice', 'repricing'];

  function service($q, Notification, ApiService, ApiUrlService, QuotePricingService) {
    return {
      createQuote: createQuote,
      retrieveQuote: retrieveQuote,
      sendQuote: sendQuote,
      copyQuote: copyQuote,
      archiveQuote: archiveQuote,
      restoreQuote: restoreQuote,
      getQuotePdfUrl: getQuotePdfUrl,
      getQuotePdfFetchUrl: getQuotePdfFetchUrl,
      updateQuote: updateQuote,
      updateQuotePricing: updateQuotePricing,
      pollQuote: pollQuote,
      quoteShouldBePolled: quoteShouldBePolled,
      updateQuoteTaxRate: updateQuoteTaxRate,
      updateQuoteDescription: updateQuoteDescription,
      updateReference: updateReference,
    };

    /**
     * Create a quote for a given customer
     *
     * @param {object} customer
     * @returns {$Q}
     */
    function createQuote(customer) {
      var quoteData = {
        metadata: {
          customer: {
            id: customer.id,
          },
        },
      };

      return ApiService.post('/quotes', quoteData).then(
        function (response) {
          return response.data.quote;
        },
        function () {
          Notification.error('Failed to create quote');

          return $q.reject(null);
        },
      );
    }

    /**
     * Retrieve quote without items
     *
     * @param {string} quoteId
     * @returns {$Q}
     */
    function retrieveQuote(quoteId) {
      return ApiService.get('/quotes/' + quoteId).then(function (response) {
        return response.data.quote;
      });
    }

    /**
     * Send a quote
     *
     * @param {object} quote
     * @returns {$Q}
     */
    function sendQuote(quote) {
      return ApiService.put('/quotes/' + quote.id + '/send');
    }

    /**
     * Copy a quote
     *
     * @param {object} quote
     * @returns {$Q}
     */
    function copyQuote(quote) {
      return ApiService.put('/quotes/' + quote.id + '/copy').then(
        function (response) {
          var quote = response.data.quote;
          Notification.success('Copied quote to ' + quote.metadata.reference);

          return quote;
        },
        function () {
          Notification.error('Failed to copy quote');

          return $q.reject(null);
        },
      );
    }

    /**
     * Archive a quote
     *
     * @param {object} quote
     * @returns {$q|Promise}
     */
    function archiveQuote(quote) {
      return ApiService.put('/quotes/' + quote.id + '/archive').then(
        function () {
          Notification.success('Archived quote');
        },
        function () {
          Notification.error('Failed to archive quote');

          return $q.reject(null);
        },
      );
    }

    /**
     * Restore archived quote
     *
     * @param {object} quote
     * @returns {$q|Promise}
     */
    function restoreQuote(quote) {
      return ApiService.put('/quotes/' + quote.id + '/restore').then(
        function () {
          Notification.success('Restore archived quote');
        },
        function () {
          Notification.error('Failed to restore archived quote');

          return $q.reject(null);
        },
      );
    }

    /**
     * Get download url for quote PDF
     *
     * @param {object} quote
     * @returns {string}
     */
    function getQuotePdfUrl(quote) {
      return ApiUrlService.getApiUrl('/quotes/' + quote.id + '/download');
    }

    /**
     * Get view url for quote PDF
     *
     * @param {object} quote
     * @returns {string}
     */
    function getQuotePdfFetchUrl(quote) {
      return ApiUrlService.getApiUrl('/quotes/' + quote.id + '/pdf');
    }

    /**
     * Save a quote
     *
     * @param {object} quote
     * @returns {$Q}
     */
    function updateQuote(quote) {
      return ApiService.put('/quotes/' + quote.id, quote).then(
        function (response) {
          var updatedQuote = response.data.quote;

          // if quote has items copy it back (update response doesn't contain items)
          if (typeof quote.items !== 'undefined') {
            updatedQuote.items = quote.items;
          }

          Notification.success('Updated quote details');

          return updatedQuote;
        },
        function () {
          Notification.error('Failed to update quote details');

          return $q.reject(null);
        },
      );
    }

    /**
     * Updates quote pricing, returns a promise
     *
     * @param {object} quote
     * @returns {$Q}
     */
    function updateQuotePricing(quote) {
      return QuotePricingService.updateQuotePricing(quote);
    }

    function pollQuote(quoteId) {
      var requestFn = function () {
        return ApiService.get('/quotes/' + quoteId);
      };

      var endPollFn = function (data) {
        return !quoteShouldBePolled(data.quote);
      };

      return ApiService.poll(requestFn, endPollFn);
    }

    function quoteShouldBePolled(quote) {
      if (pollStatus.includes(quote.metadata.status)) {
        // failed PDFs will be stuck in finalising but should not poll
        return !quote.metadata.documents.quote.pdfGenerationFailed;
      }

      return false;
    }

    function updateQuoteTaxRate(quote, newTaxRate) {
      return ApiService.put('/quotes/' + quote.id + '/update-tax-rate', {taxRate: newTaxRate}).then(
        function () {
          Notification.success('Updated quote tax rate');
        },
        function () {
          Notification.error('Failed to update quote tax rate');

          return $q.reject(null);
        },
      );
    }

    /**
     * update a quote name
     *
     * @param {object} quote
     * @param {object} description
     * @returns {$Q}
     */
    function updateQuoteDescription(quote, description) {
      return ApiService.put('/quotes/' + quote.id + '/description', {
        description: description,
      }).then(
        function () {
          Notification.success('Updated quote name');
        },
        function () {
          Notification.error('Failed to update quote name');

          return $q.reject(null);
        },
      );
    }

    /**
     * update a quote reference
     *
     * @param {object} quote
     * @param {string} reference
     * @returns {$Q}
     */
    function updateReference(quote, reference) {
      return ApiService.put('/quotes/' + quote.id + '/reference', {reference: reference}).then(
        function () {
          Notification.success('Updated quote reference');
        },
        function () {
          Notification.error('Failed to update quote reference');

          return $q.reject(null);
        },
      );
    }
  }
})();
