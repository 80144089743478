(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('QuoteItemsService', [
      '$q',
      '$timeout',
      'moment',
      'Notification',
      'ApiService',
      'QuotePricingService',
      'CustomItemService',
      'QuotesService',
      service,
    ]);

  function service(
    $q,
    $timeout,
    moment,
    Notification,
    ApiService,
    QuotePricingService,
    CustomItemService,
    QuotesService,
  ) {
    var pollTimeoutHandle = null;

    return {
      retrieveItems: retrieveItems,
      copyItem: copyItem,
      copyCustomItem: copyCustomItem,
      deleteItem: deleteItem,
      deleteCustomItem: deleteCustomItem,
      updateItemQuantity: updateItemQuantity,
      updateItemPricing: updateItemPricing,
      pollForItemImages: pollForItemImages,
      cancelPollForItemImages: cancelPollForItemImages,
      isMissingThumbImages: isMissingThumbImages,
      isGenericItem: isGenericItem,
      updateItemPosition: updateItemPosition,
      updateLocation: updateLocation,
    };

    /**
     * Get items for a given quote id
     *
     * @param {string} quoteId
     * @returns {$Q}
     */
    function retrieveItems(quoteId) {
      return ApiService.get('/quotes/' + quoteId + '/items').then(function (response) {
        var normalItems = response.data.items.map(function (item) {
          var updated = moment(item.metadata.lastUpdated - 1000).fromNow();
          item.metadata.lastUpdatedString = updated;
          item.metadata.lastUpdatedTimestamp = moment(item.metadata.lastUpdated).format(
            'MMMM Do YYYY, h:mm:ss a',
          );
          return item;
        });

        return {
          items: normalItems,
          customItems: response.data.customItems,
        };
      });
    }

    /**
     * Copy a quote item
     *
     * @param {object} quote
     * @param {object} item
     * @returns {$Q}
     */
    function copyItem(quote, item) {
      return ApiService.post('/quotes/' + quote.id + '/items/' + item.id + '/copy', null).then(
        function (response) {
          var newItem = response.data.item;

          quote.items.push(newItem);
          updateQuotePricing(quote);

          Notification.success('Copied item');

          return newItem;
        },
        function () {
          Notification.error('Failed to copy item');

          return $q.reject(null);
        },
      );
    }

    /**
     * Copy a custom item
     *
     * @param {object} quote
     * @param {object} item
     * @returns {$q}
     */
    function copyCustomItem(quote, item) {
      return CustomItemService.copy(quote, item).then(function (newItem) {
        quote.customItems.push(newItem);
        updateQuotePricing(quote);
      });
    }

    /**
     * Delete a quote item
     *
     * @param {object} quote
     * @param {object} item
     * @returns {$Q}
     */
    function deleteItem(quote, item) {
      return ApiService.delete('/quotes/' + quote.id + '/items/' + item.id).then(
        function () {
          var index = quote.items.indexOf(item);

          if (index > -1) {
            quote.items.splice(index, 1);
          }

          updateQuotePricing(quote);

          Notification.success('Deleted item');
        },
        function () {
          Notification.error('Failed to delete item');

          return $q.reject(null);
        },
      );
    }

    /**
     * Delete a custom item
     *
     * @param {object} quote
     * @param {object} item
     * @returns {$Q}
     */
    function deleteCustomItem(quote, item) {
      return ApiService.delete('/quotes/' + quote.id + '/customItems/' + item.id).then(
        function () {
          var index = quote.customItems.indexOf(item);

          if (index > -1) {
            quote.customItems.splice(index, 1);
          }

          updateQuotePricing(quote);

          Notification.success('Deleted custom item');
        },
        function () {
          Notification.error('Failed to delete custom item');

          return $q.reject(null);
        },
      );
    }

    /**
     * Update a quote item quantity
     *
     * @param {object} quote
     * @param {object} item
     * @param {int} quantity
     * @param {object} options
     * @returns {$q}
     */
    function updateItemQuantity(quote, item, quantity, options) {
      var itemType = options && options.customItem ? 'customItems' : 'items';
      var url = ['/quotes', quote.id, itemType, item.id, 'quantity'].join('/');

      return ApiService.put(url, {quantity: quantity}).then(
        function (response) {
          if (options.customItem) {
            quote.customItems[quote.customItems.indexOf(item)] = response.data;
          } else {
            quote.items[quote.items.indexOf(item)] = response.data.item;
          }

          updateQuotePricing(quote);

          Notification.success('Updated item quantity');

          return item;
        },
        function () {
          Notification.error('Failed to update item quantity');

          $q.reject();
        },
      );
    }

    /**
     * Update a quote item pricing
     *
     * @param {object} quote
     * @param {object} item
     * @param {object} pricing An object with costPrice and sellPrice keys
     * @returns {$q}
     */
    function updateItemPricing(quote, item, pricing) {
      var url = ['/quotes', quote.id, 'items', item.id, 'price'].join('/');

      return ApiService.put(url, pricing).then(
        function () {
          updateQuotePricing(quote);

          Notification.success('Updated item pricing');

          return item;
        },
        function () {
          Notification.error('Failed to update item pricing');

          return $q.reject();
        },
      );
    }

    /**
     * Start polling for item images
     *
     * @param {string} quoteId
     * @returns {Promise}
     */
    function pollForItemImages(quoteId) {
      var deferred = $q.defer();
      var lastItems = null;

      if (pollTimeoutHandle !== null) {
        cancelPollForItemImages();
      }

      var pollFunction = function () {
        retrieveItems(quoteId).then(
          function (response) {
            var items = response.items;
            if (isMissingThumbImages(items)) {
              if (isThumbImagesChanged(items, lastItems)) {
                deferred.notify(items);
                lastItems = items;
              }

              pollTimeoutHandle = $timeout(pollFunction, 1000);
              return;
            }

            pollTimeoutHandle = null;
            deferred.resolve(items);
          },
          function () {
            pollTimeoutHandle = null;
            deferred.reject();
          },
        );
      };

      pollFunction();

      return deferred.promise;
    }

    /**
     * Stop polling for item images
     */
    function cancelPollForItemImages() {
      $timeout.cancel(pollTimeoutHandle);
    }

    /**
     * Check a set of quote items for missing thumb images
     *
     * @param {Array} quoteItems
     * @returns {boolean}
     */
    function isMissingThumbImages(quoteItems) {
      for (var i = 0; i < quoteItems.length; i++) {
        if (!quoteItems[i].images.vfoThumb && quoteItems[i].metadata.itemValid) {
          return true;
        }
      }

      return false;
    }

    /**
     * Determine if item thumbs have changed between polls
     *
     * @param {object} items
     * @param {object} lastItems
     * @returns {boolean}
     */
    function isThumbImagesChanged(items, lastItems) {
      var itemThumbs = [];
      angular.forEach(items, function (item) {
        itemThumbs.push(item.images.vfoThumb);
      });

      var lastItemThumbs = [];
      angular.forEach(lastItems, function (item) {
        lastItemThumbs.push(item.images.vfoThumb);
      });

      return !angular.equals(itemThumbs, lastItemThumbs);
    }

    function isGenericItem(quoteItem) {
      return typeof quoteItem.genman.isGenericProduct === 'undefined'
        ? false
        : quoteItem.genman.isGenericProduct;
    }

    function updateQuotePricing(quote) {
      return QuotePricingService.updateQuotePricing(quote).then(function () {
        return QuotesService.retrieveQuote(quote.id).then(function (updatedQuote) {
          angular.merge(quote, updatedQuote);
        });
      });
    }

    function updateItemPosition(quote, item, position) {
      var url = ['/quotes', quote.id, 'items', item.id, 'position', position].join('/');

      return ApiService.put(url).then(
        function () {
          // Anything to do here?
          Notification.success('Updated item order');
        },
        function () {
          Notification.error('Failed to update item order');

          return $q.reject();
        },
      );
    }

    /**
     * Update an item location
     *
     * @param {object} quote
     * @param {object} item
     * @param {string} location
     * @param {string} type
     * @returns {$q}
     */
    function updateLocation(quote, item, location, type) {
      var itemType = type === 'custom' ? 'customItems' : 'items';
      var url = ['/quotes/' + quote.id + '/' + itemType + '/' + item.id + '/location'];
      return ApiService.put(url, {location: location}).then(
        function () {
          Notification.success('Updated item location');
        },
        function () {
          Notification.error('Failed to update item location');
          return $q.reject();
        },
      );
    }
  }
})();
