(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.profileSuites.create', [
      '$scope',
      '$location',
      'ProfileSuitesService',
      function ($scope, $location, ProfileSuitesService) {
        ProfileSuitesService.InitProfileSuite().then(function (profilesuite) {
          $scope.profilesuite = profilesuite;
        });

        $scope.create = function () {
          ProfileSuitesService.CreateProfileSuite($scope.profilesuite).success(function (response) {
            $location.path('/profilesuites/' + response.profileSuite.id);
          });
        };
      },
    ]);
})();
