(function () {
  'use strict';

  angular
    .module('module.products')
    .factory('ProductsService', ['$q', 'Notification', 'ApiService', service]);

  /**
   * @param $q
   * @param Notification
   * @param ApiService
   * @returns {{initProduct: initProduct, listProducts: listProducts, getProductType: getProductType, createProduct: createProduct, copyProduct: copyProduct, retrieveProduct: retrieveProduct, retrieveProductComponents: retrieveProductComponents, updateProduct: updateProduct, activateProduct: activateProduct, setDefaultComponent: setDefaultComponent, removeComponent: removeComponent}}
   */
  function service($q, Notification, ApiService) {
    var productTypes = {
      'Stormproof Casement Window': 'window',
      'Flush Casement Window': 'window',
      'Sash Window': 'window',
      'Tilt Turn Window': 'window',
      'Entrance Door': 'door',
      'Composite Door': 'door',
      'Double Door': 'door',
      'Composite Double Door': 'door',
      'BiFold Door': 'door',
      'Patio Door': 'door',
      'Combination Product': 'combi',
    };

    return {
      initProduct: initProduct,
      listProducts: listProducts,
      getProductType: getProductType,
      createProduct: createProduct,
      copyProduct: copyProduct,
      retrieveProduct: retrieveProduct,
      retrieveProductComponents: retrieveProductComponents,
      updateProduct: updateProduct,
      activateProduct: activateProduct,
      setDefaultComponent: setDefaultComponent,
      removeComponent: removeComponent,
    };

    /**
     * @returns {object}
     */
    function initProduct() {
      return {
        metadata: {
          useDefaultTransomDrop: 0,
          useDefaultMullionWidth: 0,
        },
      };
    }

    /**
     * Get a promise of products optionally constrained by product type
     *
     * @param {string=} productType
     * @returns {$Q}
     */
    function listProducts(productType) {
      return ApiService.get('/products').then(function (response) {
        if (typeof productType === 'undefined') {
          return response.data.products;
        }

        var products = [];
        angular.forEach(response.data.products, function (product) {
          if (getProductType(product) !== productType) {
            return;
          }

          products.push(product);
        });

        return products;
      });
    }

    /**
     * Get a high level product type for a product
     *
     * @param {object} product
     * @returns {string}
     */
    function getProductType(product) {
      if (angular.isUndefined(productTypes[product.metadata.productType.name])) {
        throw new Error('Product type ' + product.metadata.productType.name + ' not found');
      }

      return productTypes[product.metadata.productType.name];
    }

    /**
     * @param {object} product
     * @returns {$Q}
     */
    function createProduct(product) {
      return ApiService.post('/products', product).then(
        function () {
          Notification.success('Created product');
        },
        function () {
          Notification.error('Failed to create product');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {object} product
     * @returns {$q}
     */
    function copyProduct(product) {
      return ApiService.post('/products/' + product.id + '/copy').then(
        function (response) {
          Notification.success('Copied product');

          return response.data.product;
        },
        function () {
          Notification.error('Failed to copy product');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {string} productId
     * @returns {$Q}
     */
    function retrieveProduct(productId) {
      return ApiService.get('/products/' + productId).then(function (response) {
        return response.data.product;
      });
    }

    /**
     * @param {string} productId
     * @returns {$Q}
     */
    function retrieveProductComponents(productId) {
      return ApiService.get('/products/' + productId + '/components').then(function (response) {
        return response.data.components;
      });
    }

    /**
     * @param {object} product
     * @param {boolean=} blockNotification
     * @returns {$q}
     */
    function updateProduct(product, blockNotification) {
      blockNotification = blockNotification || false;

      return ApiService.put('/products/' + product.id, product).then(
        function (response) {
          if (!blockNotification) {
            Notification.success('Updated product');
          }

          return response.data.product;
        },
        function () {
          if (!blockNotification) {
            Notification.error('Failed to update product');
          }

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {object} product
     * @returns {$q}
     */
    function activateProduct(product) {
      product.metadata.active = true;

      return updateProduct(product, true).then(
        function (product) {
          Notification.success('Activated product');

          return product;
        },
        function () {
          Notification.error('Failed to activate product');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {string} productId
     * @param {string} componentId
     * @returns {$Q}
     */
    function setDefaultComponent(productId, componentId) {
      var component = {
        component: {
          id: componentId,
          default: true,
        },
      };

      return ApiService.put(
        '/products/' + productId + '/components/' + componentId,
        component,
      ).then(
        function () {
          Notification.success('Set default component');
        },
        function () {
          Notification.error('Failed to set default component');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {string} productId
     * @param {string} componentId
     * @returns {$Q}
     */
    function removeComponent(productId, componentId) {
      return ApiService.delete('/products/' + productId + '/components/' + componentId).then(
        function () {
          Notification.success('Deleted product component');
        },
        function () {
          Notification.error('Failed to delete product component');

          return $q.reject(null);
        },
      );
    }
  }
})();
