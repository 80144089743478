(function () {
  'use strict';

  angular.module('module.products').controller('controller.components.create', [
    '$location',
    '$scope',
    '$state',
    'ComponentsService',
    'dataComponentTypes',
    'dataSuppliers',
    function ($location, $scope, $state, ComponentsService, dataComponentTypes, dataSuppliers) {
      $scope.component = ComponentsService.initComponent();
      $scope.componentTypes = dataComponentTypes;
      $scope.suppliers = dataSuppliers;

      $scope.create = function () {
        $scope.formErrors = [];
        $scope.componentCopy = angular.copy($scope.component);

        ComponentsService.createComponent($scope.componentCopy).then(
          function (component) {
            $state.go('products.components.retrieve', {componentId: component.id});
          },
          function (errors) {
            if (typeof errors.metadata.supplierCode !== 'undefined') {
              $scope.formErrors.supplierCode = errors.metadata.supplierCode['0'];
            }

            $scope.formComponent.$invalid = true;
          },
        );
      };

      $scope.componentTypeRemove = function (index) {
        $scope.component.metadata.componentType.splice(
          index,
          $scope.component.metadata.componentType.length,
        );
      };
    },
  ]);
})();
