(function () {
  'use strict';

  angular
    .module('module.productStyles')
    .factory('ProductStylesService', ['$q', 'ApiService', 'Notification', service]);

  function service($q, ApiService, Notification) {
    return {
      getProductStylesForProductType: getProductStylesForProductType,
      updateCollectionOfStyles: updateCollectionOfStyles,
    };

    /**
     * Get set of product styles for a given product type.
     *
     * @param {object} productType
     *
     * @returns {$q}
     */
    function getProductStylesForProductType(productType) {
      return ApiService.get('/producttype/' + productType.id + '/productstyles').then(
        function (response) {
          return response.data.productStyles;
        },
      );
    }

    /**
     * Update a set of product styles for a given product type.
     *
     * @param {Array} productStyles
     * @param {object} productType
     *
     * @returns {$q}
     */
    function updateCollectionOfStyles(productStyles, productType) {
      var promises = [];
      angular.forEach(productStyles, function (productStyle) {
        promises.push(updateProductStyle(productStyle, productType));
      });

      return $q.all(promises).then(
        function (updatedProductStyles) {
          Notification.success('Updated product styles');

          return updatedProductStyles;
        },
        function () {
          Notification.error('Failed to update product styles');

          return $q.reject(null);
        },
      );
    }

    /**
     * Update a single product style.
     *
     * @param {object} productStyle
     * @param {object} productType
     *
     * @returns {$q}
     */
    function updateProductStyle(productStyle, productType) {
      var url = '/producttype/' + productType.id + '/productstyles/' + productStyle.name,
        data = {
          name: productStyle.manufacturerName,
        };

      return ApiService.put(url, data).then(function (response) {
        return response.data.productStyle;
      });
    }
  }
})();
