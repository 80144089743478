(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.quotes.createPricingRequest.modal', [
      '$element',
      'PricingRequestApiService',
      'quote',
      'close',
      controller,
    ]);

  function controller($element, PricingRequestApiService, quote, close) {
    /* jshint validthis: true */
    var vm = this;

    vm.closeModal = closeModal;
    vm.create = create;
    vm.setSelectedItems = setSelectedItems;

    vm.quote = quote;
    vm.selectedItems = [];
    vm.notes = null;
    vm.reference = null;

    function create() {
      var items = vm.selectedItems.map(function (item) {
        return item.id;
      });

      PricingRequestApiService.create(vm.quote, items, vm.reference, vm.notes).then(function () {
        closeModal(true);
      });
    }

    function setSelectedItems(selectedItems) {
      vm.selectedItems = selectedItems;
    }

    function closeModal(created) {
      // this has to happen here as following close the modal is removed from the DOM and doesn't clean up
      $element.parent().find('#createPricingRequestModal').modal('hide');
      close({created: !!created});
    }
  }
})();
