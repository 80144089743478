(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuoteItemActions', [
    function () {
      return {
        restrict: 'E',
        templateUrl: 'templates/sales.quoteItem.actions.directive.template.html',
        controllerAs: 'actionsVm',
        scope: {
          quote: '=',
          item: '=',
          customItem: '=',
        },
        controller: ['$window', 'QuoteItemsService', controller],
        bindToController: true,
      };
    },
  ]);

  /**
   * @param QuoteItemsService
   */
  function controller($window, QuoteItemsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.actionAvailable = actionAvailable;
    vm.copyAction = copyAction;
    vm.deleteAction = deleteAction;

    /**
     * Check if action available for current quote status
     *
     * @param {string} action
     * @returns {boolean}
     */
    function actionAvailable(action) {
      var availableActions = vm.quote.metadata.allowItemUpdates ? ['copy', 'delete'] : [];

      return availableActions.indexOf(action) !== -1;
    }

    /**
     * Copy the quote item
     */
    function copyAction() {
      if (vm.customItem) {
        QuoteItemsService.copyCustomItem(vm.quote, vm.item);
      } else {
        QuoteItemsService.copyItem(vm.quote, vm.item);
      }
    }

    /**
     * Delete the quote item
     */
    function deleteAction() {
      if (
        $window.confirm(
          'Are you sure you want to remove the ' +
            vm.item.metadata.name +
            ' at ' +
            vm.item.metadata.location +
            ' from this quote?',
        )
      ) {
        if (vm.customItem) {
          QuoteItemsService.deleteCustomItem(vm.quote, vm.item);
        } else {
          QuoteItemsService.deleteItem(vm.quote, vm.item);
        }
      }
    }
  }
})();
