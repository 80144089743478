(function () {
  'use strict';

  angular.module('module.visualiser', [
    'ngFileUpload',
    'angularModalService',
    'angularNumeric',
    'ang-drag-drop',
    'module.api',
  ]);
})();
