(function () {
  'use strict';

  angular.module('module.sales').component('ttRetailerSlice', {
    templateUrl: 'templates/sales.retailers.slice.component.template.html',
    bindings: {
      retailer: '<',
      showProductCount: '<',
    },
  });
})();
