(function () {
  'use strict';

  angular.module('module.settings.advanced').service('SettingsMaterialsService', [
    'SettingsService',
    'TypesService',
    function (SettingsService, TypesService) {
      var service = {};

      service.MaterialsRetrieve = function () {
        var materials = TypesService.List('materials').then(function (response) {
          var materials = response;

          SettingsService.getSettings().then(function (settings) {
            var settingsMaterialTypes = settings.settings.materialTypes;

            for (var i = 0, l = materials.length; i < l; i++) {
              for (var j = 0, k = settingsMaterialTypes.length; j < k; j++) {
                if (materials[i].id === settingsMaterialTypes[j].id) {
                  materials[i].selected = true;
                }
              }
            }
          });

          return materials;
        });

        return materials;
      };

      service.MaterialsUpdate = function (materialTypes) {
        var processedMaterialTypes = [];

        for (var i = 0, l = materialTypes.length; i < l; i++) {
          if (materialTypes[i].selected === true) {
            processedMaterialTypes.push({id: materialTypes[i].id});
          }
        }

        return SettingsService.getSettings(true).then(function (settings) {
          settings.settings.materialTypes = processedMaterialTypes;
          SettingsService.updateSettings(settings);
        });
      };

      return service;
    },
  ]);
})();
