(function () {
  'use strict';

  angular
    .module('module.retailers')
    .controller('controller.retailers.ownerView.libraryProduct.modal', [
      'RetailerProductsApiService',
      'retailer',
      'close',
      controller,
    ]);

  function controller(RetailerProductsApiService, retailer, close) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.closeModal = closeModal;
    $ctrl.add = add;
    $ctrl.setSelectedProducts = setSelectedProducts;
    $ctrl.adding = false;

    $ctrl.retailer = retailer;
    $ctrl.selectedProducts = [];

    function add() {
      $ctrl.adding = true;

      RetailerProductsApiService.addLibraryProducts($ctrl.retailer, $ctrl.selectedProducts)
        .then(function () {
          closeModal(true);
        })
        .finally(function () {
          $ctrl.adding = false;
        });
    }

    function setSelectedProducts(selectedProducts) {
      $ctrl.selectedProducts = selectedProducts;
    }

    function closeModal(productsAdded) {
      close({added: !!productsAdded});
    }
  }
})();
