(function () {
  'use strict';
  angular
    .module('module.settings.retailer')
    .controller('controller.settings.retailer.paymentTerms.create', [
      '$scope',
      '$state',
      'PaymentTermsService',
      function ($scope, $state, PaymentTermsService) {
        $scope.paymentTerm = {
          metadata: {
            title: '',
            description: '',
          },
        };
        $scope.buttonText = 'Create Payment Term';
        $scope.isSubmitting = false;

        $scope.submit = function () {
          if ($scope.isSubmitting) {
            return;
          }

          $scope.isSubmitting = true;
          PaymentTermsService.paymentTermsCreate($scope.paymentTerm)
            .then(function () {
              $state.go('settings.retailer.paymentTerms', {}, {reload: true});
            })
            .finally(function () {
              $scope.isSubmitting = false;
            });
        };
      },
    ]);
})();
