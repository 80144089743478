(function () {
  'use strict';

  angular.module('module.sales').filter('quoteStatusTitle', filter);

  var statusTitle = {
    draft: 'Draft',
    itemsLocked: 'Draft (Items Locked)',
    /** @deprecated */
    reprice: 'Recalculating Price',
    repricing: 'Recalculating Price',
    needsReprice: 'Recalculating Price',
    finalising: 'Preparing',
    finalised: 'Locked',
    sent: 'Sent',
    viewed: 'Viewed',
    confirmed: 'Confirmed',
    expired: 'Expired',
    pdfFailed: 'PDF Generation Failed',
  };

  /**
   * Get title for a quote status
   *
   * @returns {function}
   */
  function filter() {
    return function (quote) {
      if (quote.metadata.documents.quote.pdfGenerationFailed) {
        return statusTitle.pdfFailed;
      }

      if (!quote.metadata.allowItemUpdates && quote.metadata.status === 'draft') {
        return statusTitle.itemsLocked;
      }

      return statusTitle[quote.metadata.status];
    };
  }
})();
