(function () {
  'use strict';

  angular.module('module.products').config([
    '$stateProvider',
    function ($stateProvider) {
      var productTypeRoutes = {
        window: 'products.products.windows',
        door: 'products.products.doors',
        combi: 'products.products.combiProducts',
        roofLantern: 'products.products.roofLanterns',
      };

      $stateProvider

        .state('products.products', {
          abstract: true,
          views: {
            'content@': {
              templateUrl: 'templates/products.template.html',
            },
          },
          ncyBreadcrumb: {
            label: 'Products',
          },
        })

        .state('products.products.windows', {
          url: '/products/windows',
          controller: 'controller.products.list',
          templateUrl: 'templates/products.list.template.html',
          resolve: {
            dataProducts: [
              'ProductsService',
              function (ProductsService) {
                return ProductsService.listProducts('window');
              },
            ],
            dataProductType: function () {
              return 'Windows';
            },
          },
          ncyBreadcrumb: {
            label: 'Windows',
          },
          data: {
            meta: {
              titleSuffix: '| Windows',
            },
          },
        })

        .state('products.products.doors', {
          url: '/products/doors',
          controller: 'controller.products.list',
          templateUrl: 'templates/products.list.template.html',
          resolve: {
            dataProducts: [
              'ProductsService',
              function (ProductsService) {
                return ProductsService.listProducts('door');
              },
            ],
            dataProductType: function () {
              return 'Doors';
            },
          },
          ncyBreadcrumb: {
            label: 'Doors',
          },
          data: {
            meta: {
              titleSuffix: '| Doors',
            },
          },
        })

        .state('products.products.roofLanterns', {
          url: '/products/roof-lanterns',
          controller: 'controller.products.list',
          templateUrl: 'templates/products.list.template.html',
          resolve: {
            dataProducts: [
              'ProductsService',
              function (ProductsService) {
                return ProductsService.listProducts('roof-lantern');
              },
            ],
            dataProductType: function () {
              return 'Roof Lanterns';
            },
          },
          ncyBreadcrumb: {
            label: 'Roof Lanterns',
          },
          data: {
            meta: {
              titleSuffix: '| Roof Lanterns',
            },
          },
        })

        .state('products.products.combiProducts', {
          url: '/products/combi-products',
          controller: 'controller.products.list',
          templateUrl: 'templates/products.list.template.html',
          resolve: {
            dataProducts: [
              'ProductsService',
              function (ProductsService) {
                return ProductsService.listProducts('combi');
              },
            ],
            dataProductType: function () {
              return 'Combi Products';
            },
          },
          ncyBreadcrumb: {
            label: 'Combi Products',
          },
          data: {
            meta: {
              titleSuffix: '| Combi Products',
            },
          },
        })

        .state('products.products.create', {
          url: '/products/new',
          controller: 'controller.products.create',
          controllerAs: 'productVm',
          templateUrl: 'templates/products.create.template.html',
          resolve: {
            dataProductTypes: [
              'SettingsService',
              function (SettingsService) {
                return SettingsService.getSettings().then(function (settings) {
                  return settings.settings.productTypes;
                });
              },
            ],
            dataMaterialTypes: [
              'SettingsService',
              function (SettingsService) {
                return SettingsService.getSettings().then(function (settings) {
                  return settings.settings.materialTypes;
                });
              },
            ],
            dataGlazingMethods: [
              'TypesService',
              function (TypesService) {
                return TypesService.List('glazingMethods');
              },
            ],
            dataJointTypes: function () {
              // TODO: remove? API doesn't supply this in types
              return [];
            },
            dataProfileSuites: [
              'ProfileSuitesService',
              function (ProfileSuitesService) {
                return ProfileSuitesService.ListProfileSuites().then(function (response) {
                  return response.profilesuites;
                });
              },
            ],
          },
          ncyBreadcrumb: {
            label: 'Create Product',
          },
          data: {
            meta: {
              titleSuffix: '| Create Product',
            },
          },
        })

        .state('products.products.retrieve', {
          url: '/products/:productId',
          controller: 'controller.products.retrieve',
          templateUrl: 'templates/products.retrieve.template.html',
          resolve: {
            dataProduct: [
              '$stateParams',
              'ProductsService',
              function ($stateParams, ProductsService) {
                return ProductsService.retrieveProduct($stateParams.productId);
              },
            ],
            dataProductType: [
              'ProductsService',
              'dataProduct',
              function (ProductsService, dataProduct) {
                return ProductsService.getProductType(dataProduct);
              },
            ],
          },
          ncyBreadcrumb: {
            parent: function ($scope) {
              return productTypeRoutes[$scope.productType];
            },
            label: '{{product.metadata.name}}',
          },
          data: {
            meta: {
              titleSuffix: '| Product View',
            },
          },
        })

        .state('products.products.update', {
          url: '/products/:productId/edit',
          controller: 'controller.products.update',
          templateUrl: 'templates/products.update.template.html',
          resolve: {
            dataProduct: [
              '$stateParams',
              'ProductsService',
              function ($stateParams, ProductsService) {
                return ProductsService.retrieveProduct($stateParams.productId);
              },
            ],
            dataProductType: [
              'ProductsService',
              'dataProduct',
              function (ProductsService, dataProduct) {
                return ProductsService.getProductType(dataProduct);
              },
            ],
          },
          ncyBreadcrumb: {
            parent: 'products.products.retrieve({productId: product.id})',
            label: 'Update',
          },
          data: {
            meta: {
              titleSuffix: '| Update Product',
            },
          },
        });
    },
  ]);
})();
