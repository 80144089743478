(function () {
  'use strict';

  angular.module('module.visualiser').directive('ttPhotoBankUploadImage', [
    function () {
      return {
        restrict: 'E',
        templateUrl: 'templates/visualiser.photoBankUploadImage.directive.template.html',
        controllerAs: 'imageVm',
        scope: {
          image: '=',
          deleteFunc: '&',
          changeFunc: '&',
        },
        controller: imageController,
        bindToController: true,
      };
    },
  ]);

  function imageController() {
    /* jshint validthis: true */
    var vm = this,
      vfo = 'VFO',
      vfi = 'VFI';

    vm.togglePerspective = togglePerspective;
    vm.isVFO = isVFO;
    vm.isVFI = isVFI;
    vm.deleteImage = deleteImage;
    vm.locationChange = locationChange;

    init();

    /**
     * Init the directive
     */
    function init() {
      // default perspective to VFO if none set
      if (typeof vm.image.perspective === 'undefined') {
        vm.image.perspective = vfo;
      }

      // avoid missing locations (ng-model doesn't create till used)
      vm.image.location = '';
    }

    /**
     * Toggle view perspective
     * @deprecated feature disabled
     */
    function togglePerspective() {
      vm.image.perspective = isVFO() ? vfi : vfo;
    }

    /**
     * Is view from outside?
     * @returns {boolean}
     * @deprecated feature disabled
     */
    function isVFO() {
      return vm.image.perspective === vfo;
    }

    /**
     * Is view from inside?
     *
     * @returns {boolean}
     * @deprecated feature disabled
     */
    function isVFI() {
      return vm.image.perspective === vfi;
    }

    /**
     * Delete using passed function
     */
    function deleteImage() {
      vm.deleteFunc()(vm.image);
    }

    /**
     * Pass location change to parent
     */
    function locationChange() {
      vm.changeFunc()();
    }
  }
})();
