(function () {
  'use strict';

  angular.module('module.products').directive('ttProductPricingMatrices', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/products.retrieve.pricingMatrices.directive.template.html',
        controllerAs: 'matricesVm',
        scope: {
          product: '<',
        },
        controller: ['ProductsPricingMatrixService', controller],
        bindToController: true,
      };
    },
  ]);

  function controller(ProductsPricingMatrixService) {
    /* jshint validthis: true */
    var vm = this;

    ProductsPricingMatrixService.getMatrices(vm.product).then(function (matrices) {
      vm.matrices = [];

      angular.forEach(matrices, function (data, style) {
        vm.matrices.push({
          style: style,
          data: data.matrix,
        });
      });

      if (vm.matrices.length > 0) {
        vm.selectedMatrix = vm.matrices[0];
      }
    });
  }
})();
