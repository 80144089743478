(function () {
  'use strict';

  angular.module('module.products').filter('componentName', filter);

  function filter() {
    return function (component) {
      if (typeof component === 'undefined') {
        return '';
      }

      if (
        typeof component.metadata.manufacturerName !== 'undefined' &&
        component.metadata.manufacturerName !== null
      ) {
        return component.metadata.manufacturerName;
      }

      if (typeof component.metadata.supplierName !== 'undefined') {
        return component.metadata.supplierName;
      }

      return '';
    };
  }
})();
