(function () {
  'use strict';

  angular.module('module.products').directive('ttComponentDetails', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/components.retrieve.details.directive.template.html',
        controllerAs: 'detailsVm',
        scope: {
          component: '=',
        },
        controller: controller,
        bindToController: true,
      };
    },
  ]);

  function controller() {
    /* jshint validthis: true */
    var vm = this;

    vm.metadata = vm.component.metadata;
    vm.products = vm.component.products;
    vm.profileSuites = vm.component.profileSuites;
  }
})();
