(function () {
  'use strict';

  angular.module('module.products').directive('ttProductEntityWidget', directive);

  var types = {
    string: {
      controlClass: 'control__text',
    },
    integer: {
      controlClass: 'control__text',
    },
    boolean: {},
    float: {
      controlClass: 'control__text',
    },
    list: {
      controlClass: 'control__option',
    },
    image: {},
    price: {
      controlClass: 'control__text',
    },
  };

  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/products.widgets.directive.template.html',
      controllerAs: 'widgetVm',
      scope: {
        definition: '=',
        form: '=',
        widgetReadOnly: '<',
      },
      controller: controller,
      bindToController: true,
    };
  }

  function controller() {
    /* jshint validthis: true */
    var vm = this;

    if (null === vm.definition) {
      return;
    }

    var typeName = vm.definition.valueType;
    vm.type = getType(typeName);
    vm.template = getTemplateUrl(typeName);
    vm.name = 'widget_' + typeName + '_' + vm.definition.id + '_' + vm.definition.slug;
  }

  /**
   * Get type from type name. Validates.
   *
   * @param {string} typeName
   * @returns {object}
   */
  function getType(typeName) {
    if (!angular.isDefined(types[typeName])) {
      throw 'Unknown type "' + typeName + '"';
    }

    return types[typeName];
  }

  /**
   * Returns a template based on the widget type
   *
   * @param {string} typeName
   * @returns {string}
   */
  function getTemplateUrl(typeName) {
    return 'templates/products.widgets.' + typeName + '.directive.template.html';
  }
})();
