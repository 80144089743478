(function () {
  'use strict';

  angular.module('module.sales').component('ttCreateOrder', {
    templateUrl: 'templates/sales.quotes.createOrder.component.template.html',
    controller: ['$state', 'ModalService', 'CreateOrderApiService', controller],
    bindings: {
      quote: '<',
    },
    replace: true,
  });

  function controller($state, ModalService, CreateOrderApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.show = false;

    $ctrl.onClick = onClick;
    $ctrl.$doCheck = doCheck;

    function doCheck() {
      $ctrl.show = $ctrl.quote.metadata.allowCreateOrder;
    }

    function onClick() {
      if (!$ctrl.quote.metadata.hasGenericItem) {
        createOrder($ctrl.quote);

        return;
      }

      ModalService.showModal({
        controller: 'controller.sales.quotes.createOrder.modal',
        templateUrl: 'templates/sales.quotes.createOrder.modal.template.html',
        controllerAs: '$ctrl',
        inputs: {
          quote: $ctrl.quote,
        },
      }).then(function (modal) {
        var modalElement = modal.element.parent().find('#createOrderModal');
        modalElement.modal();

        modal.close.then(function (order) {
          modalElement.modal('hide');
          if (typeof order !== 'undefined' && typeof order.id !== 'undefined') {
            goToOrder(order.id);
          }
        });
      });
    }

    function createOrder(quote) {
      CreateOrderApiService.createOrder(quote, []).then(function (response) {
        goToOrder(response.order.id);
      });
    }

    function goToOrder(orderId) {
      $state.go('sales.orders.retrieve', {orderId: orderId});
    }
  }
})();
