(function () {
  'use strict';

  angular.module('module.settings').service('productRangesService', [
    '$q',
    'ApiService',
    'ProductsService',
    function ($q, ApiService, ProductsService) {
      var service = {};

      service.listProductRanges = function () {
        var url = '/productranges';

        var promises = [ApiService.get(url)];

        return $q(function (resolve, reject) {
          $q.all(promises).then(function (data) {
            var pagination = {
              pageItemsStart: data[0].data.productranges.length > 1 ? 1 : 0,
              pageItemsEnd: data[0].data.productranges.length,
              pageItemsTotal: data[0].data.productranges.length,
            };

            if (pagination.pageItemsEnd > pagination.pageItemsTotal) {
              pagination.pageItemsEnd = pagination.pageItemsTotal;
            }

            resolve({
              productranges: data[0].data.productranges,
              links: data[0].data.links,
              pagination: pagination,
              reference: {},
            });
          });
        });
      };

      service.createProductRange = function (productRangeData) {
        return ApiService.post('/productranges', productRangeData);
      };

      service.retrieveProductRange = function (productRangeId) {
        if (productRangeId !== undefined) {
          var promises = [
            ApiService.get('/productranges/' + productRangeId),
            ProductsService.listProducts(),
          ];

          return $q(function (resolve, reject) {
            $q.all(promises).then(function (data) {
              var products = [];

              for (var i = 0, l = data[1].length; i < l; i++) {
                var product = data[1][i];
                products.push({
                  id: product.id,
                  material: product.metadata.materialType.name,
                  manufacturer: product.metadata.company.name,
                  name: product.metadata.name,
                  selected:
                    data[0].data.productrange.products
                      .map(function (e) {
                        return e.id;
                      })
                      .indexOf(product.id) !== -1,
                  type: product.metadata.productType.name,
                });
              }

              resolve({
                productrange: data[0].data.productrange,
                reference: {
                  products: products,
                },
              });
            });
          });
        }
      };

      service.updateProductRange = function (
        productRangeId,
        productRangeData,
        productRangeProducts,
      ) {
        productRangeData.metadata.archived = true;

        if (productRangeProducts !== undefined) {
          var products = [];

          for (var i = 0, l = productRangeProducts.length; i < l; i++) {
            var product = productRangeProducts[i];
            if (product.selected === true) {
              products.push({id: product.id});
            }
          }

          productRangeData.products = products;
        }

        if (productRangeId !== undefined && productRangeData !== undefined) {
          return ApiService.put('/productranges/' + productRangeId, productRangeData);
        }
      };

      return service;
    },
  ]);
})();
