(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .controller('controller.settings.retailerProducts.update', [
      '$state',
      '$q',
      'RetailerProductsApiService',
      'RetailerProductsPricingMatricesApiService',
      'RepriceModalService',
      'dataRetailerProduct',
      'dataRetailerPricingAvailable',
      controller,
    ]);

  function controller(
    $state,
    $q,
    RetailerProductsApiService,
    PricingMatricesApiService,
    RepriceModalService,
    dataRetailerProduct,
    dataRetailerPricingAvailable,
  ) {
    /* jshint validthis: true */
    var vm = this;
    var pricingMatrixChanges = {
      queuedForUpload: [],
      queuedForDelete: [],
    };

    vm.loading = true;
    vm.editMode = true;
    vm.settings = dataRetailerProduct.settings;
    vm.name = dataRetailerProduct.name;
    vm.currentProductImage = dataRetailerProduct.productImage;
    vm.productImage = null;
    vm.activeTab = $state.params.tab || 'profiles';

    vm.retailerProduct = dataRetailerProduct;

    vm.uploadedMatrices = dataRetailerProduct.uploadedPricingMatrices;
    vm.pricing = Array.isArray(dataRetailerProduct.pricing) ? {} : dataRetailerProduct.pricing;
    vm.pricing.itemPricing = vm.pricing.itemPricing || 'manual';
    vm.pricing.componentPricing = vm.pricing.componentPricing || 'manual';
    vm.componentPricing = Array.isArray(dataRetailerProduct.componentPricing)
      ? {}
      : dataRetailerProduct.componentPricing;
    vm.showRepriceModal = false;
    vm.showPricing = false;
    vm.showComponentPricing = false;

    vm.getRenameKey = getRenameKey;
    vm.getComponentOptions = getComponentOptions;
    vm.onPricingMatrixChanges = onPricingMatrixChanges;
    vm.save = save;

    // The API returns saved empty objects as empty arrays
    var keys = ['renames', 'componentOptions', 'componentDefaults', 'ranges'];
    keys.forEach(function (keyString) {
      if (!vm.settings[keyString] || Array.isArray(vm.settings[keyString])) {
        vm.settings[keyString] = {};
      }
    });

    // Migrate joint type options to the new availabe/default format
    var jointProfiles = ['frame', 'sash', 'tm', 'doorSection'];
    jointProfiles.forEach(function (profile) {
      var previous = vm.settings[profile + 'JointType'];
      if (previous && !vm.settings[profile + 'JointOptions']) {
        vm.settings[profile + 'JointOptions'] = [previous];
        vm.settings[profile + 'JointDefault'] = previous;

        // For now, keep the old key because that makes rolling back harder
        // delete vm.settings[profile + 'JointType'];
      }
    });

    angular.forEach(vm.settings.renames, function (value, key) {
      if (!value || Array.isArray(value)) {
        vm.settings.renames[key] = {};
      }
    });
    if (typeof vm.settings.paintsAllowed === 'undefined') {
      vm.settings.paintsAllowed = true;
    }

    vm.currentManufacturerImage = dataRetailerProduct.manufacturerImage;
    vm.manufacturerImage = null;

    RetailerProductsApiService.getGenericProduct(dataRetailerProduct.product.id).then(
      function (genericProduct) {
        vm.selectedProduct = genericProduct;
        vm.showComponentPricing = dataRetailerPricingAvailable;
        vm.showPricing = dataRetailerPricingAvailable && vm.selectedProduct.pricingMethods.length;
        vm.loading = false;
      },
    );

    function save() {
      var showRepriceModal;
      if (vm.showRepriceModal) {
        showRepriceModal = RepriceModalService.showModalPromise(
          'Some of the changes made to this retailer product requires a reprice of draft quote items containing the ' +
            'product.',
        );
      }

      $q.resolve(showRepriceModal)
        .then(function () {
          vm.saving = true;
          return RetailerProductsApiService.update(
            dataRetailerProduct.id,
            vm.name,
            vm.settings,
            vm.productImage,
            vm.manufacturerImage,
            vm.pricing,
            vm.componentPricing,
          );
        })
        .then(function () {
          return PricingMatricesApiService.update(
            dataRetailerProduct,
            pricingMatrixChanges.queuedForDelete,
            pricingMatrixChanges.queuedForUpload,
          );
        })
        .then(function () {
          if (vm.closeAfterSave) {
            $state.go('settings.retailerProducts', null, {reload: true});
          } else {
            $state.go(
              'settings.retailerProducts.update',
              {
                id: dataRetailerProduct.id,
                tab: vm.activeTab,
              },
              {
                reload: true,
              },
            );
          }
        })
        .finally(function () {
          vm.saving = false;
        });
    }

    function getRenameKey(hardwareType) {
      if (['master handle', 'slave handle', 'folding handle'].includes(hardwareType)) {
        return 'door handle';
      }
      return hardwareType;
    }

    function onPricingMatrixChanges(changes) {
      pricingMatrixChanges = changes;

      if (vm.pricing.pricingMethod !== changes.pricingMethod) {
        vm.showRepriceModal = true;
      }

      vm.pricing.pricingMethod = changes.pricingMethod;
      if (changes.queuedForUpload.length) {
        vm.pricing.pricingMatrixType = changes.queuedForUpload[0].matrixType;
      }

      if (changes.queuedForUpload.length + changes.queuedForDelete.length) {
        vm.showRepriceModal = true;
      }
    }

    function getComponentOptions(componentKey) {
      return vm.selectedProduct.components[componentKey];
    }
  }
})();
