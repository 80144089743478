(function () {
  'use strict';

  angular.module('module.products').controller('controller.products.update', [
    '$scope',
    '$state',
    'ProductsService',
    'dataProduct',
    'dataProductType',
    function ($scope, $state, ProductsService, dataProduct, dataProductType) {
      $scope.product = dataProduct;
      $scope.productType = dataProductType;

      $scope.update = function () {
        ProductsService.updateProduct($scope.product).then(function (product) {
          $state.go('products.products.retrieve', {productId: product.id});
        });
      };
    },
  ]);
})();
