(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .factory('RetailerProductsPricingMatricesApiService', [
      '$q',
      'ApiService',
      'Notification',
      service,
    ]);

  function service($q, ApiService, Notification) {
    return {
      update: update,
      validate: validate,
      download: download,
      updateMatrices: updateMatrices,
    };

    function validate(matrixType, matrixData) {
      var body = {
        type: matrixType,
        matrix: matrixData,
      };

      return ApiService.put('/retailer-products/pricing-matrix/validate', body).then(
        function () {}, // discard response
        function (response) {
          var errors =
            response.status === 400
              ? response.data.errors
              : ['An unknown error occurred reading the CSV file. Please try again.'];

          return $q.reject(errors);
        },
      );
    }

    function update(retailerProduct, matricesToDelete, matricesToUpload) {
      matricesToDelete = matricesToDelete || [];
      matricesToUpload = matricesToUpload || [];

      var deletePromises = [];
      angular.forEach(matricesToDelete, function (matrix) {
        deletePromises.push(deleteMatrix(retailerProduct, matrix));
      });

      // run all deletions in parallel followed by all uploads in parallel
      return $q
        .all(deletePromises)
        .then(function () {
          var uploadPromises = [];
          angular.forEach(matricesToUpload, function (matrix) {
            uploadPromises.push(uploadMatrix(retailerProduct, matrix));
          });

          return $q.all(uploadPromises);
        })
        .catch(function () {
          Notification.error('Failed to update retailer product pricing matrices');

          return $q.reject();
        });
    }

    function uploadMatrix(retailerProduct, matrix) {
      var data = {
        type: matrix.matrixType,
        reference: matrix.reference,
        description: matrix.description,
        matrix: matrix.matrixData,
      };

      return ApiService.post('/retailer-products/' + retailerProduct.id + '/pricing-matrix', data);
    }

    function deleteMatrix(retailerProduct, matrix) {
      return ApiService.delete(
        '/retailer-products/' + retailerProduct.id + '/pricing-matrix/' + matrix.reference,
      );
    }

    function download(retailerProduct) {
      return ApiService.download(
        ApiService.get(
          '/retailer-products/' + retailerProduct.id + '/pricing-matrices/download',
          null,
          {responseType: 'blob'},
        ),
      ).catch(function () {
        Notification.error('Failed to download matrices');

        return $q.reject();
      });
    }

    function updateMatrices(retailerProduct, percentage) {
      var data = {
        percentage: percentage,
      };

      return ApiService.post(
        '/retailer-products/' + retailerProduct.id + '/pricing-matrices/update',
        data,
      ).then(
        function (response) {
          Notification.success('Updated matrices successfully');
          return response.data;
        },
        function () {
          Notification.error('Failed to update matrices');
        },
      );
    }
  }
})();
