(function () {
  'use strict';

  angular.module('module.sales').filter('activeCampaignAccount', filter);

  function filter() {
    return function (account) {
      if (!account) {
        return 'None';
      }

      return account.name + ' (' + account.id + ')';
    };
  }
})();
