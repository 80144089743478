(function () {
  'use strict';

  angular
    .module('module.authentication')
    .factory('AuthenticationService', [
      '$rootScope',
      '$state',
      '$q',
      '$window',
      'ApiService',
      'CompanySubdomainService',
      'ApiUrlService',
      service,
    ]);

  function service(
    $rootScope,
    $state,
    $q,
    $window,
    ApiService,
    CompanySubdomainService,
    ApiUrlService,
  ) {
    var userPromise,
      managerUsers = ['Admin', 'Manager'],
      adminUsers = ['Admin'];

    return {
      getUser: getUser,
      checkAuthentication: checkAuthentication,
      signIn: signIn,
      signOut: signOut,
      masqueradeAs: masqueradeAs,
      masqueradeEnd: masqueradeEnd,
      getPermissions: getPermissions,
      isManagerUser: isManagerUser,
      isAdminUser: isAdminUser,
      sendPasswordResetEmail: sendPasswordResetEmail,
      validateConfirmationToken: validateConfirmationToken,
      updatePasswordUsingToken: updatePasswordUsingToken,
      confirmRegistrationDetails: confirmRegistrationDetails,
      isManufacturer: isManufacturer,
      isRetailer: isRetailer,
      updatePassword: updatePassword,
    };

    /**
     * Get cached user or optionally flush and get fresh
     *
     * @param {boolean} [flush]
     * @returns {Promise}
     */
    function getUser(flush) {
      if (flush || !userPromise) {
        userPromise = ApiService.get('/authentication/me').then(processUserResponse, function () {
          clearCredentials();

          // a new rejected promise must be issued to prevent conversion to success
          return $q.reject(null);
        });
      }

      userPromise.then(function (user) {
        $rootScope.onlyOwnQuotesVisible = user.metadata.onlyOwnQuotesVisible;
      });

      return userPromise;
    }

    /**
     * Check if logged in. If token exists will flush and fetch the user object.
     *
     * @returns {Promise}
     */
    function checkAuthentication() {
      if (!ApiService.hasToken()) {
        return $q.reject();
      }

      return getUser(true).then(function (user) {
        if (
          !CompanySubdomainService.isDefaultSubdomain() &&
          user.subdomain !== CompanySubdomainService.getSubdomain()
        ) {
          $window.location.href = user.domain;

          return $q.reject(true);
        }

        return user;
      });
    }

    /**
     * Attempt to sign in to the API
     *
     * @param {string} email
     * @param {string} password
     * @returns {Promise}
     */
    function signIn(email, password) {
      var data = {
        email: email,
        password: password,
        subdomain: CompanySubdomainService.getSubdomain(),
      };

      return handleSignIn(ApiService.post('/authentication/signin', data), true);
    }

    /**
     * Sign out of the API and clear credentials
     * If masquerading log in as parent user
     *
     * @returns {Promise}
     */
    function signOut() {
      if (!userPromise) {
        return performSignOut();
      }

      // check if masquerading, end if so
      return userPromise.then(function (user) {
        if (user.metadata.isMasquerading) {
          return masqueradeEnd();
        }

        return performSignOut();
      }, performSignOut);
    }

    /**
     * Sign out the user
     *
     * @returns {Promise}
     */
    function performSignOut() {
      return ApiService.put('/authentication/signout')
        .then(clearCredentials, clearCredentials)
        .then(function () {
          $state.go('authentication.signin');
        });
    }

    /**
     * Masquerade as a different user
     *
     * @param userId
     * @returns {Promise}
     */
    function masqueradeAs(userId) {
      return handleSignIn(ApiService.post('/authentication/masquerade/' + userId, null));
    }

    /**
     * End masquerading and return to original user
     *
     * @returns {Promise}
     */
    function masqueradeEnd() {
      return handleSignIn(ApiService.delete('/authentication/masquerade')).then(function () {
        $state.go('dashboard');
      });
    }

    /**
     * Returns user permissions if available or an empty object
     * TODO: refactor away?
     *
     * @returns {object}
     */
    function getPermissions() {
      return $rootScope.userPermissions ? $rootScope.userPermissions : {};
    }

    /**
     * Determine if a manager user is logged in or default to false if not logged in
     *
     * @returns {Promise}
     */
    function isManagerUser() {
      return getUser().then(function (user) {
        return managerUsers.indexOf(user.metadata.userType.name) !== -1;
      });
    }

    /**
     * Determine if an admin user is logged in or default to false if not logged in
     *
     * @returns {Promise}
     */
    function isAdminUser() {
      return getUser().then(function (user) {
        return adminUsers.indexOf(user.metadata.userType.name) !== -1;
      });
    }

    /**
     * Trigger API to send password reset email
     *
     * @param {string} email
     * @returns {Promise}
     */
    function sendPasswordResetEmail(email) {
      return ApiService.post('/authentication/password', {email: email});
    }

    /**
     * Check validity of a confirmation token and return short user details
     *
     * @param {string} token
     * @returns {Promise}
     */
    function validateConfirmationToken(token) {
      return ApiService.get('/authentication/confirm/' + token).then(function (response) {
        return response.data.user;
      });
    }

    /**
     * Change password and sign in on success
     *
     * @param {string} token
     * @param {string} password
     * @returns {Promise}
     */
    function updatePasswordUsingToken(token, password) {
      var data = {
        token: token,
        password: password,
      };

      return handleSignIn(ApiService.post('/authentication/password/confirm', data));
    }

    /**
     * Update password
     *
     * @param {string} oldPassword
     * @param {string} newPassword
     */
    function updatePassword(oldPassword, newPassword) {
      return ApiService.put('/authentication/password/update', oldPassword, newPassword);
    }

    /**
     * Register using confirmation token
     *
     * @param {string} token
     * @param {string} password
     * @param {boolean} acceptTerms
     * @returns {Promise}
     */
    function confirmRegistrationDetails(token, password, acceptTerms) {
      var data = {
        password: password,
        acceptTermsAndConditions: acceptTerms,
      };

      return handleSignIn(ApiService.put('/authentication/confirm/' + token, data));
    }

    /**
     * Sign in handling promise chain
     *
     * @param {Promise} signInResponsePromise
     * @returns {Promise}
     */
    function handleSignIn(signInResponsePromise, allowRedirect) {
      userPromise = signInResponsePromise
        .then(function (response) {
          ApiService.setAuthToken(response.data.authToken);

          if (allowRedirect && response.data.redirectTo) {
            $window.location.href = response.data.redirectTo;

            return $q.reject({redirect: true});
          }

          return response;
        })
        .then(processUserResponse)
        .then(function (user) {
          $rootScope.$emit('authentication.login', user);

          return user;
        });

      return userPromise;
    }

    /**
     * Processes a user API response and updates the cache
     *
     * @param {object} response
     * @returns {object}
     */
    function processUserResponse(response) {
      var user = response.data.user;
      $rootScope.userPermissions = user.permissions;

      return user;
    }

    /**
     * Clear any existing user credentials and redirect to sign in page
     */
    function clearCredentials() {
      userPromise = null;
      ApiService.clearAuthToken();
      $rootScope.$emit('authentication.logout');
    }

    /**
     * Determine oif user is a manufacturer. Returns false if user permissions not set (not logged in).
     *
     * @return {boolean}
     */
    function isManufacturer() {
      var permissions = getPermissions();

      if (!angular.isDefined(permissions.companyType)) {
        return false;
      }

      return 'manufacturer' === getPermissions().companyType;
    }

    function isRetailer() {
      var permissions = getPermissions();

      if (!angular.isDefined(permissions.companyType)) {
        return false;
      }

      return 'retailer' === permissions.companyType;
    }
  }
})();
