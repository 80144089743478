(function () {
  'use strict';

  angular
    .module('module.api')
    .factory('ApiService', [
      '$http',
      '$cookies',
      '$document',
      '$q',
      '$timeout',
      'ApiUrlService',
      service,
    ]);

  function service($http, $cookies, $document, $q, $timeout, ApiUrlService) {
    $http.defaults.headers.common.ContentType = 'application/json; charset=utf-8';
    restoreToken();

    return {
      get: getRequest,
      post: postRequest,
      put: putRequest,
      delete: deleteRequest,
      setAuthToken: setAuthToken,
      clearAuthToken: clearAuthToken,
      hasToken: hasToken,
      download: download,
      poll: poll,
    };

    /**
     * Make a GET request
     *
     * @param {string} url
     * @param {object} params?
     * @param {object} config?
     * @returns {$q}
     */
    function getRequest(url, params, config) {
      var request = config || {};
      request.method = 'GET';
      request.url = url;
      request.params = params;

      return executeRequest(request);
    }

    /**
     * Make a POST request
     *
     * @param {string} url
     * @param {object} data
     * @param {object} config?
     * @returns {$q}
     */
    function postRequest(url, data, config) {
      var request = config || {};
      request.method = 'POST';
      request.url = url;
      request.data = data;

      return executeRequest(request);
    }

    /**
     * Make a PUT request
     *
     * @param {string} url
     * @param {object} data
     * @param {object} config?
     * @returns {$q}
     */
    function putRequest(url, data, config) {
      var request = config || {};
      request.method = 'PUT';
      request.url = url;
      request.data = data;

      return executeRequest(request);
    }

    /**
     * Make a DELETE request
     *
     * @param {string} url
     * @param {object} config?
     * @returns {$q}
     */
    function deleteRequest(url, config) {
      var request = config || {};
      request.method = 'DELETE';
      request.url = url;

      return executeRequest(request);
    }

    /**
     * @param {object} request
     * @returns {$q}
     */
    function executeRequest(request) {
      request.url = ApiUrlService.getApiUrl(request.url);

      return $http(request);
    }

    /**
     * Set API auth token
     *
     * @param {string} token
     */
    function setAuthToken(token) {
      $cookies.put('t', token, {domain: ApiUrlService.getBaseDomain()});
      $http.defaults.headers.common.Authorization = 'Basic ' + btoa(token + ':');
    }

    /**
     * Clear API auth token
     */
    function clearAuthToken() {
      $cookies.remove('t');
      $http.defaults.headers.common.Authorization = '';
    }

    /**
     * Check if token exists
     *
     * @returns {boolean}
     */
    function hasToken() {
      return typeof $cookies.get('t') !== 'undefined';
    }

    /**
     * Attempt to restore token from cookie
     */
    function restoreToken() {
      if (hasToken()) {
        setAuthToken($cookies.get('t'));
      }
    }

    /**
     * Note: ensure {responseType: 'blob'} is set in the request config to prevent data corruption
     */
    function download(responsePromise) {
      return responsePromise.then(function (response) {
        try {
          var headers = response.headers();
          var blob = new Blob([response.data], {
            type: headers['content-type'],
          });

          var url = URL.createObjectURL(blob);
          var link = document.createElement('a');

          link.setAttribute('href', url);
          link.setAttribute('download', getFilenameFromDisposition(headers['content-disposition']));
          link.click();

          URL.revokeObjectURL(url);
          link.remove();
        } catch (e) {
          return $q.reject();
        }
      });
    }

    function getFilenameFromDisposition(disposition) {
      var utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
      var asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
      var fileName;

      if (utf8FilenameRegex.test(disposition)) {
        fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
      } else {
        var matches = asciiFilenameRegex.exec(disposition);
        if (matches != null && matches[2]) {
          fileName = matches[2];
        }
      }

      return fileName;
    }

    /**
     * @param {Function} requestFn Function that returns a promise
     * @param {Function} endPollFn Return true to stop polling
     * @param {Number?} interval
     *
     * @returns {{cancel: Function, promise: Promise}}
     */
    function poll(requestFn, endPollFn, interval) {
      var lastData = null;
      var deferred = $q.defer();
      var pollTimeoutHandle = null;
      var cancelled = false;
      interval = interval || 1000;

      var pollFn = function () {
        requestFn().then(
          function (response) {
            // longer requests can mean cancellation does not occur during timeout, so terminate immediately
            if (cancelled) {
              return;
            }

            lastData = response.data;
            if (endPollFn(lastData)) {
              pollTimeoutHandle = null;
              deferred.resolve(lastData);

              return;
            }

            deferred.notify(lastData);
            pollTimeoutHandle = $timeout(pollFn, interval);
          },
          function (response) {
            pollTimeoutHandle = null;
            deferred.reject(response);
          },
        );
      };

      pollFn();

      return {
        promise: deferred.promise,
        cancel: function () {
          // either timeout running or waiting for response, cancel both to cover
          $timeout.cancel(pollTimeoutHandle);
          cancelled = true;
          pollTimeoutHandle = null;
          deferred.resolve(lastData);
        },
      };
    }
  }
})();
