(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.retailers.update.marketing', [
      '$state',
      'RetailersService',
      'dataRetailer',
      controller,
    ]);

  function controller($state, RetailersService, dataRetailer) {
    /* jshint validthis: true */
    var $ctrl = this;
    $ctrl.retailer = dataRetailer;

    $ctrl.update = function () {
      RetailersService.updateRetailer($ctrl.retailer);
    };
  }
})();
