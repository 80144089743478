(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuoteFittingDays', directive);

  /**
   * Init directive
   */
  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/sales.quotes.fittingDays.directive.template.html',
      controllerAs: 'fittingVm',
      scope: {
        quote: '=',
        costPrice: '=',
        sellPrice: '=',
        readOnly: '=',
      },
      controller: ['QuotesService', 'SettingsService', 'AuthenticationService', controller],
      bindToController: true,
    };
  }

  /**
   *
   * @param QuotesService
   * @param SettingsService
   */
  function controller(QuotesService, SettingsService, AuthenticationService) {
    /* jshint validthis: true */
    var vm = this;

    vm.updateFitting = updateFitting;

    vm.fittingDays = parseFloat(vm.quote.metadata.numFittingDays);

    SettingsService.getSettings().then(function (data) {
      vm.showFittingControls = data.settings.useFittingDays;
      vm.fittingRate = data.settings.fittingDayRate;
    });

    vm.showCostPrice = false;
    AuthenticationService.isManagerUser().then(function (isManager) {
      vm.showCostPrice = isManager;
    });

    /**
     * Checks if fitting fields contain valid data and update quote pricing and save if so
     */
    function updateFitting() {
      var days = parseFloat(vm.fittingDays);
      vm.quote.metadata.numFittingDays = isNaN(days) ? 0 : days;

      QuotesService.updateQuotePricing(vm.quote)
        .then(QuotesService.updateQuote)
        .then(function (quote) {
          // Note: it seems that PUT requests for quotes don't return custom
          // items. Deliberate or bug?
          vm.quote.pricing = quote.pricing;
        });
    }
  }
})();
