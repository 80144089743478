(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.orders.retrieve', [
      '$scope',
      '$rootScope',
      'OrderApiService',
      'CurrencyService',
      'dataOrder',
      controller,
    ]);

  function controller($scope, $rootScope, OrderApiService, CurrencyService, dataOrder) {
    /* jshint validthis: true */
    var vm = this;

    $rootScope.bodyClass = 'page__order';

    vm.onOrderPartUpdated = updateOrder;

    vm.order = dataOrder;
    vm.quote = dataOrder.quote;
    vm.salesTaxType = CurrencyService.getSettings().salesTaxType;
    vm.currency = CurrencyService.getSettings().currencySymbol;

    $rootScope.$on('settings.update', (event, settings) => {
      vm.currency =
        settings.currency.currencySymbol || CurrencyService.getSettings().currencySymbol;
      vm.salesTaxType =
        settings.currency.salesTaxType || CurrencyService.getSettings().salesTaxType;
    });

    $scope.orderReference = vm.order.reference;
    $scope.customerfullname = vm.order.customer.fullName;
    $scope.customersampleId = vm.order.customer.id;
    $scope.hashLocation = 'orders';

    $scope.$on('$destroy', function () {
      $rootScope.bodyClass = '';
    });

    function updateOrder() {
      OrderApiService.get(dataOrder.id).then(function (order) {
        vm.order = order;
      });
    }
  }
})();
