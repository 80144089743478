(function () {
  'use strict';

  angular.module('module.sales').filter('orderSliceStatusIcon', filter);

  var statusIcon = {
    // TODO: confirm status icons
    new: 'fa-unlock',
    ready: 'fa-unlock',
    queued: 'fa-cog rf-spin',
    sent: 'fa-check',
    accepted: 'fa-check',
    dispatched: 'fa-check',
    delivered: 'fa-check',
  };

  /**
   * Get status icon for a order
   *
   * @returns {function}
   */
  function filter() {
    return function (status) {
      return statusIcon[status];
    };
  }
})();
