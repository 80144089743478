(function () {
  'use strict';

  angular
    .module('module.retailers')
    .controller('controller.retailers.ownerView.retailerProducts', [
      'OwnerRetailerProductsApiService',
      'dataRetailer',
      controller,
    ]);

  function controller(OwnerRetailerProductsApiService, dataRetailer) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.onAddProducts = loadRetailerProducts;
    $ctrl.onDelete = loadRetailerProducts;
    $ctrl.retailer = dataRetailer;

    $ctrl.retailerProducts = null;
    loadRetailerProducts();

    function loadRetailerProducts() {
      OwnerRetailerProductsApiService.listForRetailer(dataRetailer.id).then(
        function (retailerProducts) {
          $ctrl.retailerProducts = retailerProducts;
        },
      );
    }
  }
})();
