(function () {
  'use strict';
  angular.module('module.authentication').component('accountBanner', {
    templateUrl: 'templates/authentication.accountBanner.template.html',
    controller: ['AuthenticationService', '$rootScope', controller],
  });
  function controller(AuthenticationService, $rootScope) {
    /* jshint validthis: true */
    var $ctrl = this;
    $ctrl.$onInit = function () {
      checkLogin();
    };
    $rootScope.$on('authentication.login', checkLogin);

    function checkLogin() {
      AuthenticationService.getUser().then(function (userData) {
        if (userData && userData.metadata && userData.metadata.isMasquerading !== undefined) {
          $ctrl.isMasquerading = userData.metadata.isMasquerading;
        }
      });
    }
  }
})();
