(function () {
  'use strict';

  angular.module('module.sales').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('sales.orders', {
      url: '/orders',
      views: {
        'content@': {
          template: '<div ui-view></div>',
        },
      },
      abstract: true,
      ncyBreadcrumb: {
        skip: true,
      },
    });
    $stateProvider.state('sales.orders.list', {
      url: '',
      controller: 'controller.sales.orders.list',
      controllerAs: '$ctrl',
      templateUrl: 'templates/sales.orders.list.template.html',
      ncyBreadcrumb: {
        label: 'Orders',
      },
      resolve: {
        dataOrders: [
          'OrderPartApiService',
          function (OrderPartApiService) {
            return OrderPartApiService.listOrders(1);
          },
        ],
        currencySettings: [
          'CurrencyService',
          function (CurrencyService) {
            return CurrencyService.getSettings();
          },
        ],
      },
      data: {
        meta: {
          titleSuffix: '| Orders',
        },
      },
    });

    $stateProvider.state('sales.orders.retrieve', {
      url: '/{orderId}',
      views: {
        'content@': {
          controllerProvider: [
            'AuthenticationService',
            function (AuthenticationService) {
              return AuthenticationService.isManufacturer()
                ? 'controller.sales.orders.orderPart'
                : 'controller.sales.orders.retrieve';
            },
          ],
          controllerAs: '$ctrl',
          templateProvider: [
            '$templateCache',
            'AuthenticationService',
            function ($templateCache, AuthenticationService) {
              var template = AuthenticationService.isManufacturer()
                ? 'templates/sales.orders.orderPart.controller.template.html'
                : 'templates/sales.orders.retrieve.template.html';

              return $templateCache.get(template);
            },
          ],
          resolve: {
            dataOrder: ['$stateParams', 'OrderApiService', 'AuthenticationService', resolveOrder],
            dataOrderPart: [
              '$stateParams',
              'OrderPartApiService',
              'AuthenticationService',
              resolveOrderPart,
            ],
            currencySettings: [
              'CurrencyService',
              function (CurrencyService) {
                return CurrencyService.getSettings();
              },
            ],
          },
        },
      },
      ncyBreadcrumb: {
        label: 'Order Ref {{ orderReference }}',
        parent:
          'sales.customers.retrieve({customerId: customersampleId, "#": "orders" , "hashLocation":"hashLocation"})',
      },
      data: {
        meta: {
          titleSuffix: '| Order Details',
        },
      },
    });
  }

  /**
   * @return {$q}
   */
  function resolveOrder($stateParams, OrderApiService, AuthenticationService) {
    if (AuthenticationService.isManufacturer()) {
      return null;
    }

    return OrderApiService.get($stateParams.orderId);
  }

  /**
   * @return {$q}
   */
  function resolveOrderPart($stateParams, OrderPartApiService, AuthenticationService) {
    if (!AuthenticationService.isManufacturer()) {
      return null;
    }

    return OrderPartApiService.get($stateParams.orderId);
  }
})();
