(function () {
  'use strict';

  angular.module('module.settings.retailerProducts').component('ttRetailerProductSingleComponent', {
    templateUrl: 'templates/settings.retailerProducts.singleComponent.component.template.html',
    bindings: {
      option: '<',
      currencySymbol: '<',
      containerClass: '<',
      componentType: '<',
      showComponentPricing: '<',
      pricingFields: '<',
      componentPricing: '=',
      showDefault: '<',
      hideAvailable: '<',
      titleOverride: '@?',
      optionBooleans: '=?',
      paintFinishBool: '=?',
      name: '<',
      renameObject: '=?',
      checkboxChange: '<?',
      defaultOption: '=?',
      radioChange: '<?',
      getComponentOptions: '<',
    },
    controller: controller,
  });

  function controller() {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.updatePiecePricing = updatePiecePricing;

    function updatePiecePricing(pricing) {
      $ctrl.componentPricing['door slab glazing piece'] =
        $ctrl.componentPricing['door slab glazing piece'] || {};
      angular.merge($ctrl.componentPricing['door slab glazing piece'], pricing);
    }
  }
})();
