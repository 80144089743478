(function () {
  'use strict';

  angular.module('module.components').component('ttDocumentPicker', {
    templateUrl: 'templates/components.documentPicker.component.template.html',
    controller: [controller],
    bindings: {
      documents: '<',
      manufacturerDocuments: '<',
      onChange: '<',
      coverPagePdf: '=',
      showPreSelected: '<',
    },
  });

  function controller() {
    /* jshint validthis: true */
    var vm = this;

    vm.onChangeSelected = function () {
      var selectedDocuments = vm.documents.concat(vm.manufacturerDocuments).filter(selected);
      vm.onChange(selectedDocuments);
    };

    function selected(document) {
      return document.selected;
    }
  }
})();
