(function () {
  'use strict';

  angular.module('module.visualiser').factory('PhotoBankService', ['ApiService', service]);

  /**
   * @param ApiService
   *
   * @returns {{getList: getList, deleteImage: deleteImage, create: create}}
   */
  function service(ApiService) {
    return {
      getList: getList,
      getImage: getImage,
      deleteImage: deleteImage,
      create: create,
    };

    /**
     * Get a list of all photo bank images in a quote
     *
     * @param {string} quoteId
     *
     * @returns {$q}
     */
    function getList(quoteId) {
      return ApiService.get('/visualiser/' + quoteId + '/photobankimage').then(function (response) {
        // return only photo bank image collection
        return response.data.photoBankImages;
      });
    }

    /**
     * Get a single photo bank image by id
     *
     * @param {string} quoteId
     * @param {string} imageId
     */
    function getImage(quoteId, imageId) {
      return getList(quoteId).then(function (photoBankImages) {
        var image = null;

        angular.forEach(photoBankImages, function (photoBankImage) {
          if (photoBankImage.id === imageId) {
            image = photoBankImage;
          }
        });

        return image;
      });
    }

    /**
     * Delete a photo bank image
     *
     * @param {string} quoteId
     * @param {string} imageId
     *
     * @returns {$q}
     */
    function deleteImage(quoteId, imageId) {
      return ApiService.delete('/visualiser/' + quoteId + '/photobankimage/' + imageId).then(
        function () {
          // no return other than success state
          return null;
        },
      );
    }

    /**
     * Create a Photo Bank image (save metadata)
     *
     * @param {string} quoteId
     * @param {Object} imageDetails
     * @param {string} imageDetails.location
     * @param {string} imageDetails.perspective
     * @param {string} imageDetails.resourceId
     *
     * @returns {$q}
     */
    function create(quoteId, imageDetails) {
      return ApiService.post('/visualiser/' + quoteId + '/photobankimage', {
        name: imageDetails.location,
        viewFrom: imageDetails.perspective,
        image: imageDetails.resourceId,
      }).then(function (response) {
        // return only photo bank image object
        return response.data.photoBankImage;
      });
    }
  }
})();
