(function () {
  'use strict';

  angular
    .module('module.profile.team')
    .factory('UsersService', [
      '$q',
      'Notification',
      'ApiService',
      'AuthenticationService',
      'TypesService',
      'SettingsService',
      service,
    ]);

  /**
   * @param $q
   * @param Notification
   * @param ApiService
   * @param AuthenticationService
   * @param TypesService
   * @param SettingsService
   * @returns {{initUser: initUser, listUsers: listUsers, createUser: createUser, retrieveUser: retrieveUser, updateUser: updateUser, deleteUser: deleteUser}}
   */
  function service(
    $q,
    Notification,
    ApiService,
    AuthenticationService,
    TypesService,
    SettingsService,
  ) {
    var USER_TYPE_CUSTOMER = 12,
      USER_TYPE_MANUFACTURER_ADMIN = 1,
      USER_TYPE_RETAILER_ADMIN = 5;

    return {
      initUser: initUser,
      listUsers: listUsers,
      createUser: createUser,
      retrieveUser: retrieveUser,
      updateUser: updateUser,
      deleteUser: deleteUser,
      resendInvite: resendInvite,
    };

    /**
     * @returns {$Q}
     */
    function initUser() {
      var user = {
        metadata: {
          company: {
            id: {},
          },
        },
        reference: {
          userTypes: [],
        },
      };

      return $q
        .all({
          userTypes: TypesService.List('users'),
          account: AuthenticationService.getUser(),
          settings: SettingsService.getSettings(),
        })
        .then(function (results) {
          /* Additional code that filters out irrelevant roles based on user's userType */
          /* API needs to be updated so this can be removed */
          var partialSlug = results.account.metadata.userType.slug;
          partialSlug = partialSlug.slice(0, partialSlug.indexOf('-'));

          user.reference.userTypes = [];
          angular.forEach(results.userTypes, function (userType) {
            if (userType.slug.indexOf(partialSlug) === 0) {
              user.reference.userTypes.push(userType);
            }
          });

          user.metadata.company.id = results.settings.id;

          return user;
        });
    }

    /**
     * @returns {$Q}
     */
    function listUsers() {
      return ApiService.get('/users').then(function (response) {
        return {
          users: response.data.users,
          canAddTeamMember: response.data.canAddTeamMember,
        };
      });
    }

    /**
     * TODO: separate into functions for each user class
     *
     * @param {string} urlPath
     * @param {object} userData
     * @returns {$Q}
     */
    function createUser(urlPath, userData) {
      userData.metadata.password = '';

      switch (urlPath) {
        // TODO: don't think this is used for creating customers
        case '/customers':
          userData.metadata.userType = {
            id: USER_TYPE_CUSTOMER,
          };
          break;
        case '/manufacturers':
          urlPath = '/users';
          userData.metadata.userType = {
            id: USER_TYPE_MANUFACTURER_ADMIN,
          };
          break;
        case '/retailers':
          urlPath = '/users';
          userData.metadata.userType = {
            id: USER_TYPE_RETAILER_ADMIN,
          };
          break;
        case '/team':
          urlPath = '/users';
          break;
      }

      return ApiService.post(urlPath, userData).then(
        function (response) {
          Notification.success('Created team member');

          return response.data;
        },
        function () {
          Notification.error('Failed to create team member');

          return $q.reject(null);
        },
      );
    }

    /**
     * TODO: needs some serious refactoring
     *
     * @param userId
     * @returns {$Q}
     */
    function retrieveUser(userId) {
      return $q
        .all({
          user: ApiService.get('/users/' + userId),
          userTypes: TypesService.List('users'),
          currentUser: AuthenticationService.getUser(),
        })
        .then(function (results) {
          var responseData = {
            user: results.user.data.user,
            reference: {
              userTypes: [],
            },
          };

          /* Additional code that filters out irrelevant roles based on user's userType */
          /* API needs to be updated so this can be removed */
          var partialSlug = responseData.user.metadata.userType.slug;
          partialSlug = partialSlug.slice(0, partialSlug.indexOf('-'));

          angular.forEach(results.userTypes, function (userType) {
            if (userType.slug.indexOf(partialSlug) === 0) {
              responseData.reference.userTypes.push(userType);
            }
          });

          responseData.user.currentUser = results.currentUser.id === responseData.user.id;

          return responseData;
        });
    }

    /**
     * @param {object} user
     * @returns {$q}
     */
    function updateUser(user) {
      // prevent corruption of passed user object
      var userCopy = angular.copy(user);
      userCopy.metadata.userType = {id: userCopy.metadata.userType.id};

      return ApiService.put('/users/' + userCopy.id, userCopy).then(
        function (response) {
          Notification.success('Profile updated');

          return response.data.user;
        },
        function () {
          Notification.error('Failed to update profile');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {object} user
     */
    function resendInvite(user) {
      return ApiService.post('/users/' + user.id + '/resendinvite').then(
        function (response) {
          Notification.success('Invite sent');
        },
        function () {
          Notification.error('Failed to send invite');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {string} userId
     */
    function deleteUser(userId) {
      return ApiService.delete('/users' + userId);
    }
  }
})();
