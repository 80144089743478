(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .directive('ttPricingMatrixUpdateModal', ['ModalService', directive]);

  function directive(ModalService) {
    return {
      restrict: 'A',
      scope: {
        retailerProduct: '<',
        onUpdate: '<',
      },
      link: link,
    };

    function link(scope, element) {
      element.on('click', function (event) {
        showModal(scope.retailerProduct, scope.onUpdate);
      });
    }

    function showModal(retailerProduct, onUpdate) {
      ModalService.showModal({
        controller: 'controller.retailerProducts.pricingMatrices.update.modal',
        templateUrl:
          'templates/settings.retailerProducts.pricingMatrices.update.modal.template.html',
        controllerAs: '$ctrl',
        inputs: {
          retailerProduct: retailerProduct,
        },
      }).then(function (modal) {
        modal.element.modal();

        modal.close.then(function (updateData) {
          modal.element.modal('hide');

          if (updateData && onUpdate) {
            onUpdate(updateData);
          }
        });
      });
    }
  }
})();
