(function () {
  'use strict';

  angular.module('module.sales').filter('customerPhoneNumber', filter);

  /**
   * Concatenate phone number and alt phone number for a customer
   *
   * @returns {function}
   */
  function filter() {
    return function (customer) {
      var phoneNumber = customer.metadata.contactNumber;

      if (!phoneNumber) {
        return customer.metadata.altContactNumber || '-';
      }

      return (
        phoneNumber +
        (customer.metadata.altContactNumber ? ' / ' + customer.metadata.altContactNumber : '')
      );
    };
  }
})();
