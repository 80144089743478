(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.manufacturers', [
      'SettingsService',
      'dataSettings',
      controller,
    ]);

  function controller(SettingsService, dataSettings) {
    /* jshint validthis: true */
    var vm = this;

    vm.confirm = confirm;

    vm.manufacturers = dataSettings.settings.companyManufacturers;

    /**
     * @param {Object} manufacturer
     */
    function confirm(manufacturer) {
      manufacturer.acceptedTermsAndConditions = true;
      SettingsService.updateSettings(dataSettings);
    }
  }
})();
