(function () {
  'use strict';

  angular.module('module.settings.billingAndPayments').directive('ttStripeCard', function () {
    return {
      restrict: 'E',
      templateUrl:
        'templates/settings.billingAndPayments.billing.stripeCard.directive.template.html',
      scope: {
        onCardUpdated: '<',
      },
      bindToController: true,
      controllerAs: '$ctrl',
      controller: ['StripeApiService', 'Stripe', controller],
      link: link,
    };
  });

  function controller(StripeApiService, Stripe) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.saveCard = saveCard;

    $ctrl.error = null;
    $ctrl.saving = false;
    $ctrl.showCardForm = false;

    var clientSecretPromise = StripeApiService.createSetupIntent().then(
      function (clientSecret) {
        $ctrl.showCardForm = true;

        return clientSecret;
      },
      function () {
        $ctrl.error =
          'There is a problem with the card handling service. Please try again later or contact support.';
      },
    );

    var elements = Stripe.elements();
    $ctrl.cardElement = elements.create('card');

    function saveCard() {
      $ctrl.error = null;
      $ctrl.saving = true;
      clientSecretPromise
        .then(function (clientSecret) {
          return Stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: $ctrl.cardElement,
            },
          });
        })
        .then(function (response) {
          if (response.error) {
            $ctrl.error = response.error.message;
          } else {
            $ctrl.onCardUpdated(response.setupIntent);
          }
        })
        .finally(function () {
          $ctrl.saving = false;
        });
    }
  }

  function link(scope, element, attrs, controller) {
    controller.cardElement.mount(element.find('#stripe__card--element')[0]);
  }
})();
