(function () {
  'use strict';

  angular
    .module('module.settings.billingAndPayments')
    .controller('controller.settings.billingAndPayments.billing', [
      'AuthenticationService',
      'dataPaymentMethod',
      'dataCommissionRate',
      controller,
    ]);

  function controller(AuthenticationService, dataPaymentMethod, dataCommissionRate) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.isRetailer = AuthenticationService.isRetailer();
    $ctrl.paymentMethod = dataPaymentMethod;
    $ctrl.commissionRate = dataCommissionRate;
  }
})();
