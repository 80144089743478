(function () {
  'use strict';

  angular
    .module('module.authentication')
    .controller('controller.authentication.passwordReset', [
      '$rootScope',
      '$state',
      '$stateParams',
      'AuthenticationService',
      'CompanySubdomainService',
      'BreadcrumbsService',
      'Notification',
      'user',
      controller,
    ]);

  /**
   * @param $rootScope
   * @param $state
   * @param $stateParams
   * @param AuthenticationService
   * @param CompanySubdomainService
   * @param BreadcrumbsService
   * @param user
   */
  function controller(
    $rootScope,
    $state,
    $stateParams,
    AuthenticationService,
    CompanySubdomainService,
    BreadcrumbsService,
    Notification,
    user,
  ) {
    /* jshint validthis: true */
    var vm = this;
    var zxcvbn = window.zxcvbn;
    vm.updatePasswordStrengthBar = updatePasswordStrengthBar;
    var strengthBarElement = angular.element('.password-strength-bar');
    var strengthTextElement = angular.element('.password-strength-text');

    BreadcrumbsService.hideBreadcrumbs();
    $rootScope.bodyClass = 'login';

    vm.sendResetEmail = sendResetEmail;
    vm.resetPassword = resetPassword;

    if (user) {
      vm.email = user.metadata.email;
    }

    CompanySubdomainService.getSubdomainDetails().then(function (details) {
      vm.title = details.company;
      vm.logo = details.logo;
    });

    function sendResetEmail() {
      AuthenticationService.sendPasswordResetEmail(vm.email).finally(function () {
        vm.sent = true;
        vm.message =
          'Instructions for resetting your password have been sent if the email is registered with an account. Please check your inbox. If you are still having trouble logging in please contact support.';
      });
    }

    function resetPassword() {
      if (!vm.password || !$stateParams.token) {
        Notification.error('Password or token is missing.');
        return;
      }

      AuthenticationService.updatePasswordUsingToken($stateParams.token, vm.password).then(
        function () {
          $state.go('dashboard');
        },
        function (response) {
          if (response.status === 400) {
            $state.go('authentication.signin', {
              message: 'Password reset token is invalid or expired. Please try again.',
            });
          } else {
            Notification.error('An error has occurred. Please contact support.');
          }
        },
      );
    }

    function updatePasswordStrengthBar() {
      if (vm.password) {
        vm.passwordStrength = zxcvbn(vm.password);
        switch (vm.passwordStrength.score) {
          case 0:
            // Very weak
            strengthBarElement
              .removeClass('password-strength-bar-medium password-strength-bar-strong')
              .addClass('password-strength-bar-weak');
            strengthTextElement.text('Very Weak').css('color', 'red');
            break;
          case 1:
            // Weak
            strengthBarElement
              .removeClass('password-strength-bar-medium password-strength-bar-strong')
              .addClass('password-strength-bar-weak');
            strengthTextElement.text('Weak').css('color', 'red');
            break;
          case 2:
            // Medium
            strengthBarElement
              .removeClass('password-strength-bar-weak password-strength-bar-strong')
              .addClass('password-strength-bar-medium');
            strengthTextElement.text('Medium').css('color', '#af6700');
            break;
          case 3:
            // Strong
            strengthBarElement
              .removeClass('password-strength-bar-weak password-strength-bar-medium')
              .addClass('password-strength-bar-strong');
            strengthTextElement.text('Strong').css('color', '#27ae60');
            break;
          case 4:
            // Very strong
            strengthBarElement
              .removeClass('password-strength-bar-weak password-strength-bar-medium')
              .addClass('password-strength-bar-strong');
            strengthTextElement.text('Very Strong').css('color', '#27ae60');
            break;
          default:
            // Handle unexpected score
            strengthBarElement.removeClass(
              'password-strength-bar-medium password-strength-bar-strong',
            );
            strengthTextElement.text('').css('color', '');
            break;
        }
      }
    }
  }
})();
