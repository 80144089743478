(function () {
  'use strict';

  angular
    .module('module.marketing')
    .controller('controller.marketing.video.complete', ['$scope', '$stateParams', controller]);

  // TODO: update with final delivery days
  var deliveryDays = {
    bronze: 2,
    silver: 3,
    gold: 4,
  };

  function controller($scope, $stateParams) {
    /* jshint validthis: true */
    var vm = this;

    // this needs to be on scope to support the breadcrumb of the parent state
    $scope.packageName = $stateParams.packageName;
    vm.deliveryDays = deliveryDays[$stateParams.packageName];
  }
})();
