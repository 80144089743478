(function () {
  'use strict';

  angular.module('tommyApp').directive('emptyDefault', directive);

  function directive() {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: link,
    };

    function link(scope, elem, attrs, ngModel) {
      var defaultValue = attrs.emptyDefault;

      ngModel.$parsers.push(valueTransformer);

      function valueTransformer(viewValue) {
        return viewValue === null ? defaultValue : viewValue;
      }
    }
  }
})();
