(function () {
  'use strict';

  angular.module('module.sales').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('sales', {
        abstract: true,
        views: {
          'navigation@': {
            controller: 'controller.navigation',
            templateUrl: 'templates/navigation.template.html',
            resolve: {
              dataUser: [
                'AuthenticationService',
                function (AuthenticationService) {
                  return AuthenticationService.getUser();
                },
              ],
            },
          },
        },
        ncyBreadcrumb: {
          skip: true,
        },
      });
    },
  ]);
})();
