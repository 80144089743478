(function () {
  'use strict';

  angular.module('module.visualiser').controller('controller.visualiser.dash', [
    '$rootScope',
    '$scope',
    'quote',
    function ($rootScope, $scope, quote) {
      /* jshint validthis: true */
      var vm = this;

      $rootScope.bodyClass = '';

      // for breadcrumbs TODO: improve
      $scope.quote = quote;
      $scope.customer = quote.metadata.customer;
      $scope.customer.metadata = {
        name: $scope.customer.name,
      };

      $scope.customersampleId = quote.metadata.customer.id;
      $scope.hashLocation = 'quotes';
      $scope.itemActiveName = 'Visualiser';
      $scope.customerfullname = quote.metadata.customer.metadata.name.fullName;
      vm.quote = quote;
    },
  ]);
})();
