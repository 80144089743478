(function () {
  'use strict';

  angular
    .module('module.components')
    .controller('controller.components.upsellModal', ['contentTemplate', 'close', controller]);

  function controller(contentTemplate, close) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.closeModal = close;
    $ctrl.contentTemplate =
      'templates/components.upsellModal.' + contentTemplate + '.template.html';
  }
})();
