(function () {
  'use strict';

  angular
    .module('module.navigation')
    .factory('NavigationService', ['$rootScope', 'AuthenticationService', service]);

  /**
   * @param $rootScope
   * @param AuthenticationService
   */
  function service($rootScope, AuthenticationService) {
    var mainNavItems,
      profileNavItems,
      productNavItems,
      settingsDropdownItems,
      profileDropdownItems,
      marketingDropdownItems,
      retailerSettingsNavItems,
      billingSettingsNavItems,
      companySettingsNavItems,
      advancedSettingsNavItems,
      ownerUsersNavItems;

    init();

    return {
      getMainNavItems: getMainNavItems,
      getProfileNavItems: getProfileNavItems,
      getProductNavItems: getProductNavItems,
      getRetailerSettingsNavItems: getRetailerSettingsNavItems,
      getBillingSettingsNavItems: getBillingSettingsNavItems,
      getCompanySettingsNavItems: getCompanySettingsNavItems,
      getAdvancedSettingsNavItems: getAdvancedSettingsNavItems,
      getSettingsDropdownItems: getSettingsDropdownItems,
      getProfileDropdownItems: getProfileDropdownItems,
      getMarketingDropdownItems: getMarketingDropdownItems,
      getOwnerUsersNavItems: getOwnerUsersNavItems,
    };

    /**
     * Get nav items
     *
     * @returns {Array}
     */
    function getMainNavItems() {
      return filterNavItems(mainNavItems);
    }

    /**
     * Get profile nav items
     *
     * @returns {Array}
     */
    function getProfileNavItems() {
      return filterNavItems(profileNavItems);
    }

    /**
     * Get product nav items
     *
     * @returns {Array}
     */
    function getProductNavItems() {
      return filterNavItems(productNavItems);
    }

    /**
     * Get retailer settings nav items
     *
     * @returns {Array}
     */
    function getRetailerSettingsNavItems() {
      return filterNavItems(retailerSettingsNavItems);
    }

    /**
     * Get billing settings nav items
     *
     * @returns {Array}
     */
    function getBillingSettingsNavItems() {
      return filterNavItems(billingSettingsNavItems);
    }

    /**
     * Get company settings nav items
     *
     * @returns {Array}
     */
    function getCompanySettingsNavItems() {
      return filterNavItems(companySettingsNavItems);
    }

    /**
     * Get advanced settings nav items
     *
     * @returns {Array}
     */
    function getAdvancedSettingsNavItems() {
      return filterNavItems(advancedSettingsNavItems);
    }

    /**
     * Get settings dropdown items
     *
     * @returns {Array}
     */
    function getSettingsDropdownItems() {
      return filterNavItems(settingsDropdownItems);
    }

    /**
     * Get profile dropdown items
     *
     * @returns {Array}
     */
    function getProfileDropdownItems() {
      return filterNavItems(profileDropdownItems);
    }

    /**
     * Get marketing dropdown items
     *
     * @returns {Array}
     */
    function getMarketingDropdownItems() {
      return filterNavItems(marketingDropdownItems);
    }

    function getOwnerUsersNavItems() {
      return filterNavItems(ownerUsersNavItems);
    }

    /**
     * Init module
     */
    function init() {
      initMainNavItems();
      initProfileNavItems();
      initProductNavItems();
      initRetailerSettingsNavItems();
      initBillingSettingsNavItems();
      initCompanySettingsNavItems();
      initAdvancedSettingsNavItems();
      initSettingsDropdownItems();
      initProfileDropdownItems();
      initMarketingDropdownItems();
      initOwnerUsersNavItems();

      // rebuild nav on login (permissions probably have changed)
      $rootScope.$on('authentication.login', function () {
        init();
      });
    }

    /**
     * Filter a list of nav items by permissions
     *
     * @param {Array} navItems
     * @returns {Array}
     */
    function filterNavItems(navItems) {
      var perms = getPermissions();

      return navItems.reduce(function (filteredItems, item) {
        if (perms.indexOf(item.id) !== -1) {
          filteredItems.push(item);
        }

        return filteredItems;
      }, []);
    }

    /**
     * Flatten permissions array
     *
     * @param {Array} perms
     * @returns {Array}
     */
    function flattenPerms(perms) {
      if (typeof perms.nav === 'undefined') {
        return [];
      }

      var flattenedPerms = [];

      angular.forEach(perms.nav, function (perm) {
        flattenedPerms.push(perm.id);
        angular.forEach(perm.children, function (childPerm) {
          flattenedPerms.push(perm.id + '.' + childPerm.id);
        });
      });

      return flattenedPerms;
    }

    /**
     * Get permissions
     *
     * @returns {Array}
     */
    function getPermissions() {
      return flattenPerms(AuthenticationService.getPermissions());
    }

    /**
     * Init all main nav items
     */
    function initMainNavItems() {
      mainNavItems = [];

      // these entries are to be shown to manufacturers only but aren't currently restricted using permissions
      // other than components
      if (getCompanyType() === 'manufacturer') {
        // TODO: re-enable when manufacturers given access to products and components
        // mainNavItems.push({id: 'products', name: 'Products', state: 'products.products.windows'});
        // mainNavItems.push({id: 'products.components', name: 'Components', state: 'products.components.list'});
        mainNavItems.push({
          id: 'sales.retailers',
          name: 'Retailers',
          state: 'sales.retailers.list',
        });
      }

      mainNavItems.push({id: 'sales.customers', name: 'Customers', state: 'sales.customers.list'});
      mainNavItems.push({id: 'sales.orders', name: 'Orders', state: 'sales.orders.list'});

      mainNavItems.push({
        id: 'sales.manufacturers',
        name: 'Manufacturers',
        state: 'sales.manufacturers.list',
      });

      if (getCompanyType() === 'owner') {
        mainNavItems.push({
          id: 'sales.pendingRetailers',
          name: 'Retailers',
          state: 'retailers.owner.list',
        });
      }

      mainNavItems.push({
        id: 'sales.pendingRetailers',
        name: 'Pending Retailers',
        state: 'sales.retailers.pending.ownerList',
      });
      mainNavItems.push({id: 'users.pending', name: 'Users', state: 'users.all'});
    }

    function initProfileNavItems() {
      profileNavItems = [];

      profileNavItems.push({
        id: 'profile.myprofile',
        name: 'My Profile',
        state: 'profile.myprofile',
      });
      profileNavItems.push({id: 'profile.security', name: 'Security', state: 'profile.security'});
      profileNavItems.push({id: 'team', name: 'My Team', state: 'profile.team'});
      profileNavItems.push({
        id: 'profile.internalRetailer',
        name: 'Test Retailer',
        state: 'profile.internalRetailer',
      });

      if (getApiAccess() === 'FULL') {
        profileNavItems.push({id: 'profile.fullAPI', name: 'API Access', state: 'profile.fullAPI'});
      } else if (getApiAccess() === 'BUSINESS_PILOT') {
        profileNavItems.push({
          id: 'profile.apiAccess',
          name: 'Business Pilot',
          state: 'profile.apiAccess',
        });
      } else if (getApiAccess() === 'NONE') {
      }
    }
    /**
     * Init all retailer settings nav items
     */
    function initRetailerSettingsNavItems() {
      retailerSettingsNavItems = [];
      retailerSettingsNavItems.push(
        {id: 'settings.retailerDiscount', name: 'Discount', state: 'settings.retailer.discount'},
        {id: 'settings.retailerSurcharge', name: 'Surcharge', state: 'settings.retailer.surcharge'},
        {
          id: 'settings.termsandconditions',
          name: 'Terms & Conditions',
          state: 'settings.retailer.termsAndConditions',
        },
        {
          id: 'settings.paymentterms',
          name: 'Payment Terms',
          state: 'settings.retailer.paymentTerms',
        },
      );
    }

    /**
     * Init all product nav items
     */
    function initProductNavItems() {
      productNavItems = [];
      productNavItems.push({
        id: 'products.windows',
        name: 'Windows',
        state: 'products.products.windows',
      });
      productNavItems.push({id: 'products.doors', name: 'Doors', state: 'products.products.doors'});
      productNavItems.push({
        id: 'products.roofLanterns',
        name: 'Roof Lanterns',
        state: 'products.products.roofLanterns',
      });
      productNavItems.push({
        id: 'products.combiProducts',
        name: 'Combi Products',
        state: 'products.products.combiProducts',
      });
    }

    /**
     * Init all billing settings nav items
     */
    function initBillingSettingsNavItems() {
      billingSettingsNavItems = [
        {id: 'profile.billing', name: 'Billing', state: 'settings.billingAndPayments.billing'},
      ];
    }

    /**
     * Init all company settings nav items
     */
    function initCompanySettingsNavItems() {
      companySettingsNavItems = [
        {id: 'profile.companydetails', name: 'Contact Details', state: 'settings.company.details'},
        {id: 'profile.companydetails', name: 'Logo', state: 'settings.company.logo'},
      ];
    }

    /**
     * Init all advanced settings nav items
     */

    function initAdvancedSettingsNavItems() {
      advancedSettingsNavItems = [];

      advancedSettingsNavItems.push({
        id: 'settings.pricing',
        name: 'Pricing',
        state: 'settings.advanced.pricing',
      });
      advancedSettingsNavItems.push({
        id: 'settings.customerQuotes',
        name: 'Quote',
        state: 'settings.advanced.quote',
      });
      advancedSettingsNavItems.push({
        id: 'settings.documentStore',
        name: 'Documents',
        state: 'settings.advanced.documentStore',
      });
      advancedSettingsNavItems.push({
        id: 'settings.customerpaymentterms',
        name: 'Payment',
        state: 'settings.advanced.customerPaymentTerms',
      });
      advancedSettingsNavItems.push({
        id: 'settings.contractSettings',
        name: 'Contract',
        state: 'settings.advanced.contractSettings',
      });
      advancedSettingsNavItems.push({
        id: 'settings.salesperson',
        name: 'Salesperson',
        state: 'settings.advanced.salesperson',
      });
      advancedSettingsNavItems.push({
        id: 'settings.country',
        name: 'Country',
        state: 'settings.advanced.country',
      });
      advancedSettingsNavItems.push({
        id: 'settings.manufacturers',
        name: 'Manufacturers',
        state: 'settings.advanced.manufacturers',
      });
      advancedSettingsNavItems.push({
        id: 'settings.suppliers',
        name: 'Suppliers',
        state: 'settings.advanced.suppliers',
      });
      advancedSettingsNavItems.push({
        id: 'settings.profilesuites',
        name: 'Profile Suites',
        state: 'settings.advanced.profileSuites',
      });
      // TODO: re-enable when manufacturers given access to products and components
      // {id: 'settings.productStyles', name: 'Product Styles', state: 'settings.advanced.productStyles'},
      advancedSettingsNavItems.push({
        id: 'settings.materialtypes',
        name: 'Material Types',
        state: 'settings.advanced.materialTypes',
      });
      advancedSettingsNavItems.push({
        id: 'settings.producttypes',
        name: 'Product Types',
        state: 'settings.advanced.productTypes',
      });
      advancedSettingsNavItems.push({
        id: 'settings.orderCharges',
        name: 'Order Charges',
        state: 'settings.advanced.orderCharges',
      });
      if (getApiAccess() === 'FULL') {
        advancedSettingsNavItems.push({
          id: 'settings.externalApi',
          name: 'API',
          state: 'settings.advanced.externalApi',
        });
      } else if (getApiAccess() === 'BUSINESS_PILOT') {
        advancedSettingsNavItems.push({
          id: 'settings.businessPilot',
          name: 'Business Pilot',
          state: 'settings.advanced.businessPilot',
        });
      } else if (getApiAccess() === 'NONE') {
      }
      advancedSettingsNavItems.push({
        id: 'settings.vipSupport',
        name: 'VIP Support',
        state: 'settings.advanced.vipSupport',
      });
    }

    /**
     * Init all settings dropdown items
     */
    function initSettingsDropdownItems() {
      settingsDropdownItems = [
        {id: 'settings.retailerProducts', name: 'My Products', state: 'settings.retailerProducts'},
        {id: 'settings.glass', name: 'My Glass', state: 'settings.retailerGlass'},
        {id: 'settings.customItems', name: 'Parts Shop', state: 'settings.partsShop'},
        {id: 'settings.glass', spacer: true},
        {id: 'profile.companydetails', name: 'Company Details', state: 'settings.company.details'},
        {
          id: 'profile.billing',
          name: 'Billing & Payments',
          state: 'settings.billingAndPayments.billing',
        },
        {id: 'settings.retailerDiscount', name: 'Retailer', state: 'settings.retailer.discount'},
        {id: 'settings', spacer: true},
      ];

      var companyType = getCompanyType();
      if (companyType === 'retailer') {
        settingsDropdownItems.push({
          id: 'settings.pricing',
          name: 'Advanced',
          state: 'settings.advanced.pricing',
        });
      } else if (companyType === 'manufacturer') {
        settingsDropdownItems.push({
          id: 'settings.documentStore',
          name: 'Advanced',
          state: 'settings.advanced.documentStore',
        });
      }
    }

    /**
     * Init all profile dropdown items
     */
    function initProfileDropdownItems() {
      profileDropdownItems = [
        {id: 'profile.myprofile', name: 'My Profile', state: 'profile.myprofile'},
        {id: 'team', name: 'My Team', state: 'profile.team'},
        {id: 'profile.internalRetailer', name: 'Test Retailer', state: 'profile.internalRetailer'},
      ];
    }

    /**
     * Init all marketing dropdown items
     */
    function initMarketingDropdownItems() {
      marketingDropdownItems = [];
      marketingDropdownItems.push({id: 'marketing.video', name: 'Video', state: 'marketing.video'});
    }

    function initOwnerUsersNavItems() {
      ownerUsersNavItems = [
        {id: 'users.pending', name: 'Users', state: 'users.all'},
        {id: 'users.pending', name: 'Pending Users', state: 'users.pending'},
      ];
    }

    /**
     * @returns {string}
     */
    function getCompanyType() {
      return AuthenticationService.getPermissions().companyType;
    }

    /**
     * @returns {string}
     */
    function getApiAccess() {
      return AuthenticationService.getPermissions().apiAccess;
    }
  }
})();
