(function () {
  'use strict';

  angular.module('module.settings.advanced').controller('controller.settings.advanced.suppliers', [
    '$scope',
    '$state',
    'CompaniesService',
    'SettingsSuppliersService',
    'dataSettingsSuppliers',
    function ($scope, $state, CompaniesService, SettingsSuppliersService, dataSettingsSuppliers) {
      $scope.suppliers = dataSettingsSuppliers;

      $scope.update = function () {
        SettingsSuppliersService.SuppliersUpdate($scope.suppliers).then(function () {
          $state.reload();
        });
      };

      $scope.init = function () {
        $scope.newFormShow = false;
        $scope.supplier = {};
      };

      $scope.create = function () {
        CompaniesService.createCompany('/suppliers', $scope.supplier).success(function () {
          $scope.init();
          $state.reload();
        });
      };
    },
  ]);
})();
