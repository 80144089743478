(function () {
  'use strict';

  angular.module('module.sales').directive('ttMakeoversDetails', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/sales.quotes.makeovers.directive.template.html',
        controllerAs: 'quoteVm',
        scope: {
          customer: '=',
          quote: '=',
        },
        controller: [
          '$scope',
          '$state',
          'SettingsService',
          'CompletedImageService',
          'UpsellModalService',
          'QuoteFinalisationService',
          'PhotoBankService',
          controller,
        ],
        bindToController: true,
      };
    },
  ]);

  function controller(
    $scope,
    $state,
    SettingsService,
    CompletedImageService,
    UpsellModalService,
    QuoteFinalisationService,
    PhotoBankService,
  ) {
    /* jshint validthis: true */
    var vm = this;
    vm.onClick = onClick;
    vm.refreshCompletedImages = refreshCompletedImages;
    vm.makeoversEnabled = false;
    vm.showButton = false;
    vm.photoBank = [];
    vm.loading = true;
    vm.completedImagesLoading = true;
    vm.photoBankLoading = true;

    SettingsService.getSettings().then(function (settings) {
      vm.makeoversEnabled = settings.settings.makeoversEnabled;
    });

    vm.onCustomerUpdate = onCustomerUpdate;

    init();

    /**
     * Init the directive
     */
    function init() {
      refreshCompletedImages();
      refreshPhotoBank();
      checkStatus();
    }

    /**
     * Refresh the data in the completed images
     */
    function refreshCompletedImages() {
      vm.completedImagesLoading = true;
      vm.completedImages = [];
      vm.deleteCompletedImage = deleteCompletedImage;

      $scope.$on('$destroy', function () {
        CompletedImageService.cancelPollList();
      });

      CompletedImageService.pollList(vm.quote.id, function (images) {
        vm.loadingIcon = true;
        vm.completedImages = images;
        vm.completedImagesLoading = false;
        checkStatus();
      });
    }

    /**
     * Refresh the data in the photo bank
     */
    function refreshPhotoBank() {
      if (vm.quote.metadata.status === 'draft') {
        vm.photoBankLoading = true;
        PhotoBankService.getList(vm.quote.id).then(function (images) {
          vm.photoBank = images;
          vm.photoBankLoading = false;
          checkStatus();
        });
      } else {
        vm.photoBankLoading = false;
        checkStatus();
      }
    }

    function checkStatus() {
      vm.hasCompletedImages = vm.completedImages.length > 0;
      vm.showButton = !isFinalisedWithNoVisualisations();
      vm.loading = vm.completedImagesLoading || vm.photoBankLoading;
      if (vm.loading) {
        vm.loadingIcon = false;
      } else {
        vm.loadingIcon = true;
      }
    }

    function isFinalisedWithNoVisualisations() {
      return vm.quote.metadata.status !== 'draft' && !vm.hasCompletedImages;
    }

    function onClick() {
      if (vm.makeoversEnabled) {
        $state.go('visualiser.dash', {quoteId: vm.quote.id});
      } else {
        UpsellModalService.showModal('makeover');
      }
    }

    $scope.$watch('detailsVm.quote.metadata.status', function () {
      vm.finalised = QuoteFinalisationService.isFinalised(vm.quote);
    });

    function onCustomerUpdate() {
      $state.reload();
    }

    /**
     * Delete completed image
     *
     * @param {Object} image
     */
    function deleteCompletedImage(image) {
      CompletedImageService.deleteImage(vm.quote.id, image.id).then(function () {
        vm.completedImages.splice(vm.completedImages.indexOf(image), 1);
        checkStatus();
      });
    }
  }
})();
