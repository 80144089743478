(function () {
  'use strict';

  angular.module('module.authentication').directive('showPassword', directive);

  /**
   * Directive to toggle password form element type
   */
  function directive() {
    return {
      restrict: 'A',
      scope: {
        showPassword: '=',
      },
      link: link,
    };

    /**
     * @param scope
     * @param element
     *
     */
    function link(scope, element) {
      scope.$watch('showPassword', function (showPassword) {
        element.prop('type', showPassword ? 'text' : 'password');
      });
    }
  }
})();
