(function () {
  'use strict';

  angular.module('module.sales').component('ttFinaliseModalCompletedImages', {
    templateUrl: 'templates/sales.quotes.finalise.modal.completedImages.component.template.html',
    controller: ['CompletedImageService', controller],
    bindings: {
      quote: '<',
      onChange: '<',
    },
  });

  function controller(CompletedImageService) {
    /* jshint validthis: true */
    var vm = this;

    vm.$onInit = onInit;
    vm.onChangeSelected = onChange;
    vm.setAllCheckboxes = setAllCheckboxes;

    function onInit() {
      CompletedImageService.getList(vm.quote.id).then(function (completedImages) {
        vm.completedImages = completedImages;
        setAllCheckboxes(true);
      });
    }

    function onChange() {
      var selectedImages = vm.completedImages.reduce(function (selected, image) {
        if (image.selected) {
          selected.push(image.id);
        }

        return selected;
      }, []);

      vm.onChange(selectedImages);
    }

    function setAllCheckboxes(setTo) {
      for (var i = 0; i < vm.completedImages.length; i++) {
        vm.completedImages[i].selected = setTo;
      }
      onChange();
    }
  }
})();
