(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.customers.retrieve', [
      '$rootScope',
      '$scope',
      '$location',
      'CustomerQuotesApiService',
      'CustomerQuotesArchiveApiService',
      'dataCustomer',
      controller,
    ]);

  function controller(
    $rootScope,
    $scope,
    $location,
    CustomerQuotesApiService,
    CustomerQuotesArchiveApiService,
    dataCustomer,
  ) {
    /* jshint validthis: true */
    var vm = this;

    $rootScope.bodyClass = 'customer__retrieve';
    vm.customer = dataCustomer;
    vm.creating = false;
    $scope.customer = vm.customer;
    $scope.customerfullname = vm.customer.metadata.name.fullName;
    vm.customerDetailsCollapsed = true;
    vm.quotesLoaded = false;
    vm.loaderIcon = true;
    vm.quotes = [];
    vm.archivedQuotes = [];
    vm.hasArchivedQuotes = function () {
      return vm.customer && vm.customer.archiveQuotesCount > 0;
    };

    function updateQuotes(quotes) {
      vm.loaderIcon = true;
      vm.quotes = quotes;
      vm.quotesLoaded = true;
      vm.loaderIcon = false;
    }

    function updateArchiveQuotes(quotes) {
      vm.archivedQuotes = quotes;
    }

    var quotePoller;
    activate();

    function activate() {
      initializeQuotePoller();
      setHashLocation();
      setTabBasedOnHash();
    }

    function initializeQuotePoller() {
      quotePoller = CustomerQuotesApiService.pollList(vm.customer);
      quotePoller.promise.then(updateQuotes, null, updateQuotes);
      $scope.$on('$destroy', function () {
        quotePoller.cancel();
      });
    }

    function setHashLocation() {
      $location.hash($location.hash() || 'quotes');
      $scope.hashLocation = $location.hash();
    }

    function setTabBasedOnHash() {
      vm.activeTab =
        $location.hash() === 'archive' && vm.hasArchivedQuotes() ? 'archive' : $location.hash();
      $location.hash(vm.activeTab);
      if ($location.hash() === 'archive') {
        CustomerQuotesArchiveApiService.getArchiveList(vm.customer).then(updateArchiveQuotes);
      }
    }

    vm.setTab = function (tab) {
      if (tab === 'archive') {
        CustomerQuotesArchiveApiService.getArchiveList(vm.customer).then(updateArchiveQuotes);
      }
      $scope.hashLocation = tab;
      vm.activeTab = tab;
      $location.hash(tab);
    };
  }
})();
