(function () {
  'use strict';

  angular.module('module.retailers').component('ttRetailerOwnerSlice', {
    templateUrl: 'templates/retailers.ownerList.slice.component.template.html',
    bindings: {
      retailer: '<',
      logIn: '<?',
      masqueradeAsUsers: '=',
      selectedUser: '=',
      index: '<',
    },
  });
})();
