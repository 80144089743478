(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.quotes.finalise.modal', [
      '$scope',
      '$element',
      'QuoteFinalisationService',
      'quote',
      'action',
      'close',
      controller,
    ]);

  function controller($scope, $element, QuoteFinalisationService, quote, action, close) {
    /* jshint validthis: true */
    var vm = this,
      selectedCompletedImages = [],
      selectedDocuments = [],
      quoteSettings;

    vm.nextStep = nextStep;
    vm.closeModal = closeModal;
    vm.setSelectedCompletedImages = setSelectedCompletedImages;
    vm.setSelectedDocuments = setSelectedDocuments;
    vm.setQuoteSettingsAndContinue = setQuoteSettingsAndContinue;

    vm.quote = quote;
    vm.action = action;
    vm.modes = {
      customerInactive: 'customerInactive',
      quoteSettings: 'quoteSettings',
      completedImages: 'completedImages',
      selectDocuments: 'selectDocuments',
      processing: 'processing',
      emailSent: 'emailSent',
      pdfFailed: 'pdfFailed',
    };

    setInitialMode(vm.quote);

    $scope.$watch('modalVm.quote', onUpdate, true);

    /**
     * Determine initial model state
     */
    function setInitialMode(quote) {
      if (QuoteFinalisationService.isPdfFailed(quote)) {
        setMode(vm.modes.pdfFailed);

        return;
      }

      if (QuoteFinalisationService.isProcessing(quote)) {
        setMode(vm.modes.processing);

        return;
      }

      if (QuoteFinalisationService.isFinalised(quote)) {
        setMode(vm.modes.processing);

        if (action === 'email') {
          QuoteFinalisationService.doSend(quote).then(function () {
            onUpdate(quote);
          });
        } else {
          onUpdate(quote);
        }

        return;
      }

      if (isCustomerInactive(quote)) {
        setMode(vm.modes.customerInactive);

        return;
      }

      setMode(vm.modes.quoteSettings);
    }

    /**
     * @param {Object} quote
     *
     * @return {boolean}
     */
    function isCustomerInactive(quote) {
      return !quote.metadata.customer.active;
    }

    /**
     * @param {Object} quote
     *
     * @return {boolean}
     */
    function hasCompletedImages(quote) {
      return 0 !== quote.metadata.completedImagesCount;
    }

    /**
     * Next step in the modal
     */
    function nextStep() {
      setNextMode(vm.mode, vm.quote);
    }

    /**
     * Determine next mode
     *
     * @param {string} currentMode
     * @param {Object} quote
     */
    function setNextMode(currentMode, quote) {
      if (currentMode === vm.modes.quoteSettings) {
        setMode(vm.modes.selectDocuments);
        return;
      }
      if (currentMode === vm.modes.selectDocuments && hasCompletedImages(quote)) {
        setMode(vm.modes.completedImages);
        return;
      }
      setMode(vm.modes.processing);
      finalise();
    }

    function finalise() {
      QuoteFinalisationService.finaliseQuote(
        vm.quote,
        quoteSettings,
        selectedCompletedImages,
        selectedDocuments,
        vm.action === 'email',
      );
    }

    /**
     * Perform the action following finalise
     */
    function onUpdate(quote) {
      if (QuoteFinalisationService.isDraft(quote)) {
        return;
      }

      if (QuoteFinalisationService.isProcessing(quote)) {
        return;
      }

      if (QuoteFinalisationService.isPdfFailed(quote)) {
        setMode(vm.modes.pdfFailed);

        return;
      }

      if (vm.action === 'email') {
        setMode(vm.modes.emailSent);

        return;
      }

      closeModal(true);
    }

    /**
     * Trigger modal cleanup to ensure removed from DOM and scope destroyed
     *
     * @param {Boolean} doAction
     */
    function closeModal(doAction) {
      // this has to happen here as following close the modal is removed from the DOM and doesn't clean up
      $element.parent().find('#finalise-modal').modal('hide');
      close({doAction: doAction || false});
    }

    /**
     * Set the selected completed images
     *
     * @param {Array} completedImages
     */
    function setSelectedCompletedImages(completedImages) {
      selectedCompletedImages = completedImages;
    }

    /**
     * @param {Array} documents
     */
    function setSelectedDocuments(documents) {
      selectedDocuments = documents;
    }

    /**
     * @param {Array} settings
     */
    function setQuoteSettingsAndContinue(settings) {
      quoteSettings = settings;

      nextStep();
    }

    /**
     * @param {String} mode
     */
    function setMode(mode) {
      vm.mode = mode;
      vm.template = 'templates/sales.quotes.finalise.modal.' + mode + '.template.html';
    }
  }
})();
