(function () {
  'use strict';

  angular.module('tommyApp').component('ttPaginator', {
    templateUrl: 'templates/app.paginator.component.template.html',
    controller: controller,
    bindings: {
      onPageChange: '<?',
      totalPages: '<',
      currentPage: '<',
    },
  });

  function controller() {
    /* jshint validthis: true */
    var vm = this;

    vm.$onInit = function () {
      vm.visiblePages = [];
      vm.calculatePageNumbers();
    };

    vm.$onChanges = function (changes) {
      if (changes.currentPage || changes.totalPages) {
        vm.calculatePageNumbers();
      }
    };

    vm.changePage = function (newPage) {
      if (newPage === vm.currentPage || newPage < 1 || newPage > vm.totalPages) {
        return;
      }
      vm.currentPage = newPage;
      vm.onPageChange(newPage);
      vm.calculatePageNumbers();
    };

    vm.calculatePageNumbers = function () {
      const totalPages = vm.totalPages;
      let pages = [];

      if (totalPages <= 20) {
        pages = Array.from({length: totalPages}, (_, i) => i + 1);
      } else {
        pages.push(1);

        let rangeStart = Math.max(2, vm.currentPage - 2);
        let rangeEnd = Math.min(totalPages - 1, vm.currentPage + 2);

        if (vm.currentPage - rangeStart < 2) {
          rangeEnd = Math.min(totalPages - 1, rangeEnd + (2 - (vm.currentPage - rangeStart)));
        }
        if (rangeEnd - vm.currentPage < 2) {
          rangeStart = Math.max(2, rangeStart - (2 - (rangeEnd - vm.currentPage)));
        }
        if (rangeStart > 2) {
          pages.push({type: 'ellipsis', id: 'start'});
          let intermediateJump = Math.max(1, Math.floor(rangeStart / 2));
          let intermediatePage = rangeStart - intermediateJump;
          pages.push(intermediatePage);
          pages.push({type: 'ellipsis', id: 'end'});
        }
        for (let i = rangeStart; i <= rangeEnd; i++) {
          if (i !== 1 && i !== totalPages) {
            pages.push(i);
          }
        }
        if (rangeEnd < totalPages - 1) {
          pages.push({type: 'ellipsis', id: 'start'});
          let intermediateJump = Math.max(1, Math.floor((totalPages - rangeEnd) / 2));
          let intermediatePage = rangeEnd + intermediateJump;
          pages.push(intermediatePage);
          pages.push({type: 'ellipsis', id: 'end'});
        }
        pages.push(totalPages);
      }
      vm.visiblePages = pages;
    };
  }
})();
