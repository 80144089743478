(function () {
  'use strict';

  angular.module('module.visualiser').directive('ttCompletedImages', [
    function () {
      return {
        restrict: 'E',
        templateUrl: 'templates/visualiser.completedImages.directive.template.html',
        controllerAs: 'bankVm',
        scope: {
          quote: '=',
        },
        controller: ['$scope', 'CompletedImageService', controller],
        bindToController: true,
      };
    },
  ]);

  /**
   * @param $scope
   * @param CompletedImageService
   */
  function controller($scope, CompletedImageService) {
    /* jshint validthis: true */
    var vm = this;

    vm.deleteCompletedImage = deleteCompletedImage;
    vm.completedImages = [];
    vm.loading = true;

    init();

    /**
     * Init the directive
     */
    function init() {
      refreshCompletedImages();
    }

    /**
     * Refresh the data in the completed images
     */
    function refreshCompletedImages() {
      vm.loading = true;
      vm.completedImages = [];

      $scope.$on('$destroy', function () {
        CompletedImageService.cancelPollList();
      });

      CompletedImageService.pollList(vm.quote.id, function (images) {
        vm.completedImages = images;
        vm.loading = false;
      });
    }

    /**
     * Delete completed image
     *
     * @param {Object} image
     */
    function deleteCompletedImage(image) {
      CompletedImageService.deleteImage(vm.quote.id, image.id).then(function () {
        vm.completedImages.splice(vm.completedImages.indexOf(image), 1);
      });
    }
  }
})();
