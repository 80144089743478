(function () {
  'use strict';

  angular.module('module.settings.advanced.documentStore').component('ttDocument', {
    templateUrl: 'templates/settings.advanced.documentStore.document.component.template.html',
    controller: ['$sce', '$window', '$scope', 'DocumentStoreApiService', controller],
    bindings: {
      document: '<',
      onDelete: '&',
      showCoverPage: '<',
      coverPagePdf: '=',
      allDocuments: '<',
    },
  });

  function controller($sce, $window, $scope, DocumentStoreApiService) {
    /* jshint validthis: true */
    var vm = this;

    vm.delete = deleteDocument;
    vm.update = update;
    vm.updateDefaultCoverPage = updateDefaultCoverPage;
    vm.isNew = false;
    vm.downloadUrl = $sce.trustAsResourceUrl(vm.document.downloadUrl);
    vm.thumbnailUrl = $sce.trustAsResourceUrl(vm.document.thumbnailUrl);

    var previousCoverPagePdf = vm.coverPagePdf;

    $scope.$watch(
      function () {
        return vm.coverPagePdf;
      },
      function (newVal, oldVal) {
        if (newVal !== oldVal) {
          previousCoverPagePdf = oldVal;
        }
      },
    );

    /**
     * Delete document then trigger callback
     */
    function deleteDocument() {
      if ($window.confirm('Are you sure you want to delete this document?')) {
        DocumentStoreApiService.deleteDocument(vm.document).then(vm.onDelete);
      }
    }

    function update() {
      DocumentStoreApiService.update(vm.document);
    }

    function updateDefaultCoverPage() {
      if (previousCoverPagePdf && previousCoverPagePdf !== vm.coverPagePdf) {
        var prevCoverPageDoc = vm.allDocuments.find((doc) => doc.id === previousCoverPagePdf);
        if (prevCoverPageDoc) {
          prevCoverPageDoc.requireAppend = false;
          DocumentStoreApiService.update(prevCoverPageDoc);
        }
      }

      vm.document.requireAppend = true;
      DocumentStoreApiService.updateQuotePdfCoverPage(vm.document.id);
      DocumentStoreApiService.update(vm.document);
    }
  }
})();
