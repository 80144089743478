(function () {
  'use strict';

  angular.module('tommyApp').filter('skipKeys', filterSkipKeys);

  function filterSkipKeys() {
    return function (object, skipKeys) {
      if (!angular.isObject(object)) {
        return;
      }

      return Object.keys(object).reduce(function (accumulator, key) {
        if (!skipKeys.includes(key)) {
          accumulator[key] = object[key];
        }

        return accumulator;
      }, {});
    };
  }
})();
