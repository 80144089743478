(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('CustomersService', ['$q', 'Notification', 'ApiService', service]);

  function service($q, Notification, ApiService) {
    return {
      createCustomer: createCustomer,
      retrieveCustomer: retrieveCustomer,
      updateCustomer: updateCustomer,
      updateCustomerPaymentStages: updateCustomerPaymentStages,
      listCustomers: listCustomers,
    };

    /**
     * Create a new customer
     *
     * @param {Object} customer
     *
     * @return {$q}
     */
    function createCustomer(customer) {
      return ApiService.post('/customers', customer).then(
        function (response) {
          var customer = response.data.customer;
          Notification.success(
            'A new customer profile for ' + customer.metadata.name.fullName + ' has been created',
          );

          return customer;
        },
        function () {
          Notification.error('Failed to create customer');

          return $q.reject(null);
        },
      );
    }

    /**
     * Retrieve an existing customer
     *
     * @param {String} customerId
     *
     * @return {$q}
     */
    function retrieveCustomer(customerId) {
      return ApiService.get('/customers/' + customerId).then(function (response) {
        return response.data.customer;
      });
    }

    /**
     * Update an existing customer
     *
     * @param {Object} customer
     * @param {Boolean} notifyOnSuccess
     *
     * @return {$q}
     */
    function updateCustomer(customer, notifyOnSuccess) {
      return ApiService.put('/customers/' + customer.id, customer).then(
        function (response) {
          var customer = response.data.customer;

          // Notify unless explicitly told otherwise
          if (notifyOnSuccess !== false) {
            Notification.success('Updated customer profile for ' + customer.metadata.name.fullName);
          }

          return customer;
        },
        function () {
          Notification.error('Failed to update customer');

          return $q.reject(null);
        },
      );
    }

    /**
     * Update an existing customer's stage payments
     *
     * @param {Object} customer
     * @param {Object} paymentStages
     * @param {Boolean} notifyOnSuccess
     *
     * @return {$q}
     */
    function updateCustomerPaymentStages(customer, paymentStages, notifyOnSuccess) {
      return ApiService.put('/customers/' + customer.id + '/payment-stages', paymentStages).then(
        function (response) {
          var customer = response.data.customer;

          // Notify unless explicitly told otherwise
          if (notifyOnSuccess !== false) {
            Notification.success('Updated customer stage payments');
          }

          return customer;
        },
        function () {
          Notification.error('Failed to update customer stage payments');

          return $q.reject(null);
        },
      );
    }

    /**
     * List customers, optionally constrained by a user and/or search string
     *
     * @param {String=} userId
     * @param {String=} search
     * @param {String=} page
     *
     * @return {$q}
     */
    function listCustomers(userId, search, page) {
      var params = {
        u: userId, // id is a uuid so will always be truthy if set
        q: search,
        page: page || 1,
      };

      return ApiService.get('/customers', params).then(function (response) {
        return response.data;
      });
    }
  }
})();
