(function () {
  'use strict';

  angular
    .module('module.products')
    .controller('controller.components', [
      '$scope',
      'NavigationService',
      function ($scope, NavigationService) {},
    ]);
})();
