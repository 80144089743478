(function () {
  'use strict';

  angular.module('module.products').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider

        .state('products.components', {
          abstract: true,
          views: {
            'content@': {
              controller: 'controller.components',
              templateUrl: 'templates/components.template.html',
            },
          },
          ncyBreadcrumb: {
            skip: true,
          },
        })

        .state('products.components.list', {
          url: '/components',
          views: {
            component: {
              controller: 'controller.components.list',
              templateUrl: 'templates/components.list.template.html',
              // resolve: {
              //   dataComponents: function($stateParams, ComponentsService) {

              //     var searchString = 'all',
              //         filterComponentType = '0',
              //         filterSupplierId = '0',
              //         pageId = '1';

              //     // if($stateParams['page'] === undefined) { $stateParams['page'] = 1; }
              //     // if($stateParams['componentType'] != undefined) { filterComponentType = $stateParams['componentType']; }

              //     return ComponentsService.ListComponents(searchString, filterComponentType, supplierId, pageId);

              //   }
              // }
            },
          },
          ncyBreadcrumb: {
            label: 'Components',
          },
          data: {
            meta: {
              titleSuffix: '| Components',
            },
          },
        })

        .state('products.components.search', {
          url: '/components/:searchString/:filterComponentType/:filterSupplierId/:pageId',
          views: {
            component: {
              controller: 'controller.components.list',
              templateUrl: 'templates/components.list.template.html',
            },
          },
          ncyBreadcrumb: {
            label: 'Components',
          },
          data: {
            meta: {
              titleSuffix: '| Search Components',
            },
          },
        })

        // TODO: endpoint for this doesn't seem to be in the API
        .state('products.components.pricing', {
          url: '/components/pricing',
          views: {
            component: {
              controller: 'controller.components.pricing',
              templateUrl: 'templates/components.pricing.template.html',
              resolve: {
                dataComponents: [
                  'ComponentsService',
                  function (ComponentsService) {
                    return ComponentsService.bulkListComponentsPricing();
                  },
                ],
              },
            },
          },
        })

        .state('products.components.create', {
          url: '/components/new',
          views: {
            component: {
              controller: 'controller.components.create',
              templateUrl: 'templates/components.create.template.html',
            },
          },
          resolve: {
            dataComponentTypes: [
              'TypesService',
              function (TypesService) {
                return TypesService.List('components').then(function (components) {
                  return components.flatLastOnly;
                });
              },
            ],
            dataSuppliers: [
              'SettingsService',
              function (SettingsService) {
                return SettingsService.getSettings().then(function (settings) {
                  return settings.settings.companySuppliersComponents;
                });
              },
            ],
          },
          ncyBreadcrumb: {
            parent: 'products.components.list',
            label: 'New Component',
          },
          data: {
            meta: {
              titleSuffix: '| New Component',
            },
          },
        })

        .state('products.components.retrieve', {
          url: '/components/:componentId',
          views: {
            component: {
              controller: 'controller.components.retrieve',
              templateUrl: 'templates/components.retrieve.template.html',
              resolve: {
                dataComponent: [
                  '$stateParams',
                  'ComponentsService',
                  function ($stateParams, ComponentsService) {
                    return ComponentsService.retrieveComponent($stateParams.componentId);
                  },
                ],
              },
            },
          },
          ncyBreadcrumb: {
            parent: 'products.components.list',
            label: '{{component|componentName}}',
          },
          data: {
            meta: {
              titleSuffix: '| Component Details',
            },
          },
        })

        .state('products.components.update', {
          url: '/components/:componentId/edit',
          views: {
            component: {
              controller: 'controller.components.update',
              templateUrl: 'templates/components.update.template.html',
              resolve: {
                dataComponent: [
                  '$stateParams',
                  'ComponentsService',
                  function ($stateParams, ComponentsService) {
                    return ComponentsService.retrieveComponent($stateParams.componentId);
                  },
                ],
                dataProducts: [
                  'ProductsService',
                  function (ProductsService) {
                    return ProductsService.listProducts();
                  },
                ],
                dataProfileSuites: [
                  'ProfileSuitesService',
                  function (ProfileSuitesService) {
                    return ProfileSuitesService.ListProfileSuites();
                  },
                ],
              },
            },
          },
          ncyBreadcrumb: {
            parent: 'products.components.retrieve({componentId: component.id})',
            label: 'Update Component',
          },
          data: {
            meta: {
              titleSuffix: '| Updating Component',
            },
          },
        });
    },
  ]);
})();
