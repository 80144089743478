(function () {
  'use strict';

  angular.module('module.retailers').filter('ownerRetailerStatus', filter);

  function filter() {
    return function (retailer) {
      if (!retailer) {
        return '';
      }

      if (retailer.metadata.suspended) {
        return 'Suspended';
      } else if (retailer.metadata.active) {
        return 'Active';
      } else {
        return 'Onboarding';
      }
    };
  }
})();
