(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .controller('controller.retailerProducts.pricingMatrices.update.modal', [
      '$q',
      '$window',
      'RetailerProductsPricingMatricesApiService',
      'retailerProduct',
      'close',
      controller,
    ]);

  function controller(
    $q,
    $window,
    RetailerProductsPricingMatricesApiService,
    retailerProduct,
    close,
  ) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.retailerProduct = retailerProduct;
    $ctrl.update = update;
    $ctrl.close = close;
    $ctrl.updating = false;

    function update() {
      if (
        !$window.confirm(
          'Update pricing matrices requires a reprice of draft quote items using the product.\n\n' +
            'Are you sure you want to continue?',
        )
      ) {
        return;
      }

      $ctrl.updating = true;

      RetailerProductsPricingMatricesApiService.updateMatrices(
        $ctrl.retailerProduct,
        $ctrl.percentage,
      ).then(
        function (updateDetails) {
          close(updateDetails);
        },
        function () {
          $ctrl.updating = false;
        },
      );
    }
  }
})();
