(function () {
  'use strict';

  angular
    .module('module.settings.retailer')
    .controller('controller.settings.retailer.termsAndConditions', [
      '$scope',
      '$state',
      'SettingsService',
      'dataSettings',
      function ($scope, $state, SettingsService, dataSettings) {
        $scope.company = dataSettings;
        $scope.isSubmitting = false;
        $scope.isUploaded = false;

        $scope.uploadPdf = function () {
          if ($scope.isSubmitting) {
            return;
          }
          $scope.isSubmitting = true;

          SettingsService.uploadTermsAndConditions(document.getElementById('file').files[0])
            .then(function () {
              $scope.isUploaded = true;
              $state.reload();
            })
            .finally(function () {
              if (!$scope.isUploaded) {
                $scope.isSubmitting = false;
              }
            });
        };
      },
    ]);
})();
