(function () {
  'use strict';

  angular.module('angular-pca').factory('pca', ['$window', service]);

  function service($window) {
    if (typeof $window.pca === 'undefined') {
      throw Error('PCA library not found');
    }

    return $window.pca;
  }
})();
