(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('PendingRetailerService', ['$q', 'ApiService', 'Notification', service]);

  function service($q, ApiService, Notification) {
    return {
      create: create,
      list: list,
      get: get,
      linkRetailer: linkRetailer,
      newRetailer: newRetailer,
      reject: reject,
    };

    /**
     * Create a pending retailer.
     *
     * @param {object} newPendingRetailer
     *
     * @returns {$q}
     */
    function create(newPendingRetailer) {
      return ApiService.post('/companies/pending-retailers', newPendingRetailer).then(
        function (response) {
          Notification.success('Retailer invitation submitted');

          return response.data;
        },
        function () {
          Notification.error('Retailer submission failed');

          return $q.reject();
        },
      );
    }

    /**
     * Get list of all pending retailers.
     *
     * @returns {$q}
     */
    function list() {
      return ApiService.get('/companies/pending-retailers').then(function (response) {
        return response.data;
      });
    }

    /**
     * Get details of a single pending retailer.
     *
     * @param {string} pendingRetailerId
     *
     * @returns {$q}
     */
    function get(pendingRetailerId) {
      return ApiService.get('/companies/pending-retailers/' + pendingRetailerId).then(
        function (response) {
          return response.data;
        },
      );
    }

    /**
     * Link pending retailer to an existing retailer.
     *
     * @param {object} pendingRetailer
     * @param {object} linkToRetailer
     *
     * @returns {$q}
     */
    function linkRetailer(pendingRetailer, linkToRetailer) {
      var url = '/companies/pending-retailers/' + pendingRetailer.id + '/' + linkToRetailer.id;

      return ApiService.post(url).then(
        function () {
          Notification.success('Linked to existing retailer');
        },
        function () {
          Notification.error('Failed to link to existing retailer');

          return $q.reject();
        },
      );
    }

    /**
     * Create a new retailer from a pending retailer.
     *
     * @param {object} pendingRetailer
     *
     * @returns {$q}
     */
    function newRetailer(pendingRetailer) {
      return ApiService.post('/companies/pending-retailers/' + pendingRetailer.id).then(
        function () {
          Notification.success('New retailer created');
        },
        function () {
          Notification.error('Failed to invite new retailer');

          return $q.reject();
        },
      );
    }

    /**
     * @param {Object} pendingRetailer
     *
     * @returns {$q}
     */
    function reject(pendingRetailer) {
      return ApiService.delete('/companies/pending-retailers/' + pendingRetailer.id).then(
        function () {
          Notification.success('Retailer rejected');
        },
        function () {
          Notification.error('Failed to reject retailer');

          return $q.reject();
        },
      );
    }
  }
})();
