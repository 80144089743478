(function () {
  'use strict';

  angular.module('tommyApp').filter('capitalise', filterCapitalise);

  function filterCapitalise() {
    return function (text) {
      return !!text ? text.charAt(0).toUpperCase() + text.substr(1).toLowerCase() : '';
    };
  }
})();
