(function () {
  'use strict';

  angular
    .module('module.retailers')
    .factory('RetailerSuspendApiService', ['$q', 'ApiService', 'Notification', service]);

  function service($q, ApiService, Notification) {
    return {
      suspend: suspend,
      activate: activate,
    };

    function suspend(retailer) {
      return update(retailer, true);
    }

    function activate(retailer) {
      return update(retailer, false);
    }

    function update(retailer, suspend) {
      var body = {
        suspended: suspend,
      };

      return ApiService.put('/companies/company/' + retailer.id + '/suspend', body).then(
        function () {
          Notification.success('Retailer account ' + (suspend ? 'suspended' : 'activated'));
        },
        function () {
          Notification.error('Failed to update account');

          return $q.reject();
        },
      );
    }
  }
})();
