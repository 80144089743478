(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.productStyles', ['dataProductTypes', controller]);

  function controller(dataProductTypes) {
    /* jshint validthis: true */
    var vm = this;

    vm.productTypes = dataProductTypes;
    vm.selectedProductType = vm.productTypes[0];
  }
})();
