(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.retailers.pending.ownerList', [
      'dataPendingRetailers',
      controller,
    ]);

  function controller(dataPendingRetailers) {
    /* jshint validthis: true */
    var vm = this;

    vm.retailers = dataPendingRetailers;
  }
})();
