(function () {
  'use strict';

  angular
    .module('module.users')
    .controller('controller.users.all', ['$q', 'UsersApiService', 'StatsApiService', controller]);

  function controller($q, UsersApiService, StatsApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    var promises = {
      users: UsersApiService.getAllUsers().then(function (users) {
        $ctrl.users = users;
      }),
      stats: StatsApiService.getUserQuotes(),
    };

    $q.all(promises).then(function (responses) {
      angular.forEach($ctrl.users, function (user) {
        if (angular.isDefined(responses.stats[user.id])) {
          var userStats = responses.stats[user.id];

          user.countThisWeek = userStats.countThisWeek;
          user.count1WeekAgo = userStats.count1WeekAgo;
          user.count2WeeksAgo = userStats.count2WeeksAgo;
          user.costPriceThisWeek = userStats.costPriceThisWeek;
          user.costPrice1WeekAgo = userStats.costPrice1WeekAgo;
          user.costPrice2WeeksAgo = userStats.costPrice2WeeksAgo;
          user.sellPriceThisWeek = userStats.sellPriceThisWeek;
          user.sellPrice1WeekAgo = userStats.sellPrice1WeekAgo;
          user.sellPrice2WeeksAgo = userStats.sellPrice2WeeksAgo;
        }
      });
    });
  }
})();
