(function () {
  'use strict';

  angular.module('module.profile').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('profile', {
      url: '/profile',
      abstract: true,
      views: {
        'navigation@': {
          controller: 'controller.navigation',
          templateUrl: 'templates/navigation.template.html',
          resolve: {
            dataUser: [
              'AuthenticationService',
              function (AuthenticationService) {
                return AuthenticationService.getUser();
              },
            ],
          },
        },
        'content@': {
          controller: [
            '$scope',
            'NavigationService',
            function ($scope, NavigationService) {
              $scope.navigation = NavigationService.getProfileNavItems();
            },
          ],
          templateUrl: 'templates/profile.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Profile',
      },
    });

    $stateProvider.state('profile.myprofile', {
      url: '/my-profile',
      controller: 'controller.profile.myprofile',
      templateUrl: 'templates/profile.myprofile.template.html',
      resolve: {
        dataProfile: [
          'AuthenticationService',
          function (AuthenticationService) {
            return AuthenticationService.getUser();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'My Profile',
      },
      data: {
        meta: {
          titleSuffix: '| My Profile',
        },
      },
    });

    $stateProvider.state('profile.internalRetailer', {
      url: '/test-retailer',
      controller: 'controller.profile.internalRetailer',
      controllerAs: 'internalRetailerVm',
      templateUrl: 'templates/profile.internalRetailer.template.html',
      resolve: {
        dataUser: [
          'AuthenticationService',
          function (AuthenticationService) {
            return AuthenticationService.getUser();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Test Retailer',
      },
      data: {
        meta: {
          titleSuffix: '| Test Retailer',
        },
      },
    });

    $stateProvider.state('profile.apiAccess', {
      url: '/api-access',
      controller: 'controller.profile.apiAccess',
      controllerAs: '$ctrl',
      templateUrl: 'templates/profile.apiAccess.template.html',
      resolve: {
        dataHasApiKey: [
          'ExternalApiApiService',
          function (ExternalApiApiService) {
            return ExternalApiApiService.hasApiKey();
          },
        ],
        dataUserId: [
          'AuthenticationService',
          function (AuthenticationService) {
            return AuthenticationService.getUser().then(function (user) {
              return user.id;
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Business Pilot',
      },
      data: {
        meta: {
          titleSuffix: '| Business Pilot',
        },
      },
    });

    $stateProvider.state('profile.fullAPI', {
      url: '/full-api',
      controller: 'controller.profile.fullApi',
      controllerAs: '$ctrl',
      templateUrl: 'templates/profile.fullApi.template.html',
      resolve: {
        dataHasApiKey: [
          'ExternalApiApiService',
          function (ExternalApiApiService) {
            return ExternalApiApiService.hasApiKey();
          },
        ],
        dataUserId: [
          'AuthenticationService',
          function (AuthenticationService) {
            return AuthenticationService.getUser().then(function (user) {
              return user.id;
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'API Access',
      },
      data: {
        meta: {
          titleSuffix: '| API Access',
        },
      },
    });

    $stateProvider.state('profile.security', {
      url: '/security',
      controller: 'controller.profile.security',
      templateUrl: 'templates/profile.security.template.html',
      controllerAs: 'securityVm',
      resolve: {
        dataProfile: [
          'AuthenticationService',
          function (AuthenticationService) {
            return AuthenticationService.getUser();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Security',
      },
      data: {
        meta: {
          titleSuffix: '| Security',
        },
      },
    });
  }
})();
