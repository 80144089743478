(function () {
  'use strict';

  angular.module('module.marketing').config(['$stateProvider', createStates]);

  function createStates($stateProvider) {
    $stateProvider.state('marketing', {
      url: '/marketing',
      abstract: true,
      views: {
        'navigation@': {
          controller: 'controller.navigation',
          templateUrl: 'templates/navigation.template.html',
          resolve: {
            dataUser: [
              'AuthenticationService',
              function (AuthenticationService) {
                return AuthenticationService.getUser();
              },
            ],
          },
        },
      },
      ncyBreadcrumb: {
        label: 'Marketing',
      },
    });

    $stateProvider.state('marketing.video', {
      url: '/video',
      views: {
        'content@': {
          templateUrl: 'templates/marketing.video.index.template.html',
          controller: 'controller.marketing.video.index',
        },
      },
      ncyBreadcrumb: {
        label: 'Video',
      },
      data: {
        meta: {
          titleSuffix: '| Marketing | Video',
        },
      },
    });

    $stateProvider.state('marketing.video.package', {
      url: '/{packageName:bronze|silver|gold}-package',
      views: {
        'content@': {
          controller: 'controller.marketing.video.package',
          controllerAs: 'packageVm',
          templateUrl: 'templates/marketing.video.package.template.html',
        },
      },
      ncyBreadcrumb: {
        label: '{{packageName}} Package',
      },
      data: {
        meta: {
          titleSuffix: '| Marketing | Video | Package',
        },
      },
    });

    $stateProvider.state('marketing.video.package.complete', {
      url: '/completed',
      views: {
        'content@': {
          controller: 'controller.marketing.video.complete',
          controllerAs: 'completeVm',
          templateUrl: 'templates/marketing.video.complete.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Completed',
      },
      data: {
        meta: {
          titleSuffix: '| Marketing | Video | Complete',
        },
      },
    });

    /**
     * @param SettingsService
     * @returns {$q}
     */
    getSettingsPromise.$inject = ['SettingsService'];
    function getSettingsPromise(SettingsService) {
      return SettingsService.getSettings();
    }
  }
})();
