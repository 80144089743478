(function () {
  'use strict';

  angular.module('tommyApp').directive('entityfields', ['$compile', entityfields]);

  function entityfields($compile) {
    return {
      template: '<div class="grid__col--8"></div>',
      replace: true,

      link: function (scope, element) {
        var el;

        if (scope.entity.valueType === 'undefined') {
          el = angular.element(
            '<div class="control__text control__text--readonly"><label>{{entity.name}}</label></div>',
          );

          if (scope.entity.valueType === 'boolean') {
            el.append(
              '<p class="form-control-static" ng-show="!toggleCheck(entity.id)">{{entity.value | truefalse}}</p>',
            );
          } else {
            el.append('<input type="text" disabled value="{{entity.value}}">');
          }
        } else {
          el = angular.element('<div class="control__text"><label>{{entity.name}}</label></div>');

          switch (scope.entity.valueType) {
            case 'list':
              el.append(
                '<select ng-model="entity.value" ng-options="o as o for o in entity.valueOptions"></select>',
              );
              break;
            case 'string':
            case 'price':
            case 'integer':
            case 'float':
              el.append('<input type="text" ng-model="entity.value"></form>');
              break;
            case 'boolean':
              scope.entity.valueOptions = [];
              scope.entity.valueOptions.push({k: true, v: 'Yes'});
              scope.entity.valueOptions.push({k: false, v: 'No'});
              el.append(
                '<select ng-model="entity.value" ng-options="o.k as o.v for o in entity.valueOptions"></select>',
              );
              break;
          }
        }

        $compile(el)(scope);
        element.append(el);
      },
    };
  }
})();
