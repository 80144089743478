(function () {
  'use strict';

  angular.module('module.products').controller('controller.components.retrieve', [
    '$scope',
    '$state',
    'AuthenticationService',
    'ComponentsService',
    'dataComponent',
    function ($scope, $state, AuthenticationService, ComponentsService, dataComponent) {
      AuthenticationService.getUser().then(function (user) {
        if (user.permissions.companyType === 'manufacturer') {
          $scope.canEditComponent = true;
        }
      });

      $scope.component = dataComponent;
      $scope.activeTab = 'details';
    },
  ]);
})();
