(function () {
  'use strict';

  angular.module('module.dashboard').component('ttStatsActiveRetailers', {
    templateUrl: 'templates/dashboard.stats.activeRetailers.component.template.html',
    controller: ['StatsApiService', controller],
  });

  function controller(StatsApiService) {
    /* jshint validthis: true */
    var vm = this;

    vm.failed = false;
    vm.retailers = false;
    vm.limit = 5;

    vm.$onInit = function () {
      StatsApiService.getActiveRetailers().then(
        function (retailers) {
          vm.retailers = retailers;
        },
        function () {
          vm.failed = true;
        },
      );
    };

    vm.seeAll = function () {
      vm.limit = vm.retailers.length;
    };
  }
})();
