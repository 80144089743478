(function () {
  'use strict';

  angular.module('module.dashboard').component('ttStats', {
    templateUrl: 'templates/dashboard.stats.component.template.html',
    bindings: {
      failed: '<',
      loading: '<',
      vertical: '<',
      title: '@',
      subtitle: '@',
    },
    transclude: true,
  });
})();
