(function () {
  'use strict';

  angular
    .module('module.visualiser')
    .factory('PhotoBankUploadService', ['PhotoBankService', 'UploadService', service]);

  function service(PhotoBankService, UploadService) {
    return {
      upload: upload,
    };

    /**
     * Upload an image, then upload metadata
     *
     * @param {string} quoteId
     * @param {object} image
     * @returns {$q}
     */
    function upload(quoteId, image) {
      image.state = 'uploading';

      return UploadService.uploadImage(image).then(
        function (uploadedImage) {
          // save image metadata
          return PhotoBankService.create(quoteId, uploadedImage).then(function (photoBankImage) {
            image.state = 'uploaded';

            return photoBankImage;
          });
        },
        function () {
          image.state = 'failed';
        },
        function (progress) {
          image.uploadProgress = parseInt(progress);
        },
      );
    }
  }
})();
