(function () {
  'use strict';

  angular.module('module.retailers').component('ttLibraryProductsPicker', {
    templateUrl: 'templates/retailers.ownerView.libraryProductsPicker.component.template.html',
    controller: ['RetailerProductsApiService', controller],
    bindings: {
      onChange: '<',
      search: '<',
    },
  });

  function controller(RetailerProductsApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.onChangeSelected = onChangeSelected;
    $ctrl.products = null;

    RetailerProductsApiService.listProductLibrary().then(function (products) {
      $ctrl.products = products;
    });

    $ctrl.selectionRequired = true;

    function onChangeSelected() {
      var selectedProducts = $ctrl.products.reduce(function (selected, product) {
        if (product.selected) {
          selected.push(product.id);
        }

        return selected;
      }, []);

      $ctrl.selectionRequired = selectedProducts.length === 0;
      $ctrl.onChange(selectedProducts);
    }
  }
})();
