(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.retailers.update.products', [
      '$state',
      'RetailersService',
      'dataRetailer',
      controller,
    ]);

  function controller($state, RetailersService, dataRetailer) {
    /* jshint validthis: true */
    var $ctrl = this;
    $ctrl.retailer = dataRetailer;

    $ctrl.update = function () {
      var data = {
        pricingMode: $ctrl.retailer.metadata.pricingMode,
        discount: $ctrl.retailer.metadata.discount,
        surchargePercentage: $ctrl.retailer.metadata.surchargePercentage,
        surchargeFixedAmount: $ctrl.retailer.metadata.surchargeFixedAmount,
        products: [],
      };

      $ctrl.retailer.metadata.products.forEach(function (product) {
        data.products.push({
          id: product.id,
          discount: product.discount,
          finishSetDiscounts: {
            A: product.finishSetDiscounts.A,
            B: product.finishSetDiscounts.B,
            C: product.finishSetDiscounts.C,
          },
          surchargePercentage: product.surchargePercentage,
          surchargeFixedAmount: product.surchargeFixedAmount,
          enabled: product.enabled,
        });
      });

      RetailersService.updateRetailerPricing($ctrl.retailer, data);
    };
  }
})();
