(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .controller('controller.settings.retailerProducts.list', [
      '$scope',
      'RetailerProductsApiService',
      controller,
    ]);

  function controller($scope, RetailerProductsApiService) {
    /* jshint validthis: true */
    const $ctrl = this;

    const materialOrder = ['PVC', 'Aluminium', 'Timber'];

    $ctrl.onDelete = onDelete;
    $ctrl.onCopy = onCopy;
    $ctrl.materials = [];
    $ctrl.activeTab = null;

    updateProducts();

    function onDelete(product) {
      $ctrl.products.splice($ctrl.products.indexOf(product), 1);
      getMaterialsList();
    }

    function onCopy(newProductId) {
      updateProducts();
    }

    function getMaterialsList() {
      $ctrl.materials = [];

      $ctrl.products.forEach(function (p) {
        if (!$ctrl.materials.includes(p.product.materialType)) {
          $ctrl.materials.push(p.product.materialType);
        }
      });

      $ctrl.materials.sort((a, b) => materialOrder.indexOf(a) - materialOrder.indexOf(b));

      if (!$ctrl.materials.includes($ctrl.activeTab)) {
        $ctrl.activeTab = $ctrl.materials[0];
      }
    }

    function updateProducts() {
      function update(retailerProducts) {
        $ctrl.products = retailerProducts;
        getMaterialsList();
      }

      RetailerProductsApiService.pollList($scope).then(update, null, update);
    }
  }
})();
