(function () {
  'use strict';

  angular
    .module('module.profile')
    .controller('controller.profile.internalRetailer', [
      '$state',
      'AuthenticationService',
      'dataUser',
      controller,
    ]);

  function controller($state, AuthenticationService, dataUser) {
    /* jshint validthis: true */
    var vm = this;
    vm.signIn = signIn;

    vm.masqueradeAsUsers = dataUser.metadata.canMasqueradeAs;
    if (vm.masqueradeAsUsers.length > 0) {
      vm.user = vm.masqueradeAsUsers[0];
    }

    function signIn() {
      AuthenticationService.masqueradeAs(vm.user.id).then(function () {
        $state.go('dashboard');
      });
    }
  }
})();
