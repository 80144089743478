(function () {
  'use strict';

  angular.module('tommyApp').directive('percentage', directive);

  /**
   * Directive to format percentage values from and to the decimal format used by the API
   */
  function directive() {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: link,
      priority: 100, // run at top of stack
    };

    /**
     * @param scope
     * @param element
     * @param attr
     * @param ngModel
     */
    function link(scope, element, attr, ngModel) {
      ngModel.$formatters.push(toDisplayTransformer);
      ngModel.$parsers.push(toApiTransformer);
    }

    /**
     * Divide by 100 for API (stored as 0.15 for 15%)
     *
     * @param {string|Number} displayValue
     * @returns {string|void}
     */
    function toApiTransformer(displayValue) {
      if (!angular.isNumber(displayValue)) {
        displayValue = Number(displayValue);
      }

      if (isNaN(displayValue)) {
        return;
      }

      return (displayValue / 100).toFixed(4);
    }

    /**
     * Multiply by 100 for display (displayed as a percentage)
     *
     * @param {float|null} apiValue
     * @returns {Number|void}
     */
    function toDisplayTransformer(apiValue) {
      if (!angular.isNumber(apiValue)) {
        return;
      }

      return apiValue * 100;
    }
  }
})();
