(function () {
  'use strict';

  angular
    .module('module.bugsnag')
    .factory('bugsnagClient', [
      '$window',
      'APP_ENV',
      'BUGSNAG_API_KEY',
      'BUGSNAG_RELEASE_STAGES',
      'PORTAL_VERSION',
      service,
    ]);

  /**
   * @param $window
   * @param APP_ENV
   * @param BUGSNAG_API_KEY
   * @param BUGSNAG_RELEASE_STAGES
   * @param PORTAL_VERSION
   * @returns {bugsnagClient}
   */
  function service($window, APP_ENV, BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGES, PORTAL_VERSION) {
    if (typeof $window.bugsnag === 'undefined') {
      throw Error('Bugsnag library not found');
    }

    return $window.bugsnag({
      apiKey: BUGSNAG_API_KEY,
      notifyReleaseStages: BUGSNAG_RELEASE_STAGES,
      releaseStage: APP_ENV,
      appVersion: PORTAL_VERSION.tag,
      metaData: {
        portalVersionSha: PORTAL_VERSION.sha,
      },
    });
  }
})();
