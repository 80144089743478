(function () {
  'use strict';

  angular.module('module.settings.retailerProducts').component('ttRetailerProductOwnerSlice', {
    templateUrl: 'templates/settings.retailerProducts.slice.component.template.html',
    controller: ['$window', 'RetailerProductsApiService', controller],
    bindings: {
      retailerProduct: '<',
      onDelete: '<',
      onCopy: '<',
    },
  });

  function controller($window, ApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.delete = deleteProduct;
    $ctrl.copy = copyProduct;
    $ctrl.onClick = null;
    $ctrl.canDelete = !$ctrl.retailerProduct.isModified;
    $ctrl.canCopy = false;

    function deleteProduct() {
      if ($window.confirm('Are you sure you want to delete this product?')) {
        ApiService.delete($ctrl.retailerProduct).then(function () {
          $ctrl.onDelete($ctrl.retailerProduct);
        });
      }
    }

    function copyProduct() {
      ApiService.copy($ctrl.retailerProduct).then($ctrl.onCopy);
    }
  }
})();
