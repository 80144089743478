(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.quotes.createOrder.modal', [
      '$element',
      'CreateOrderApiService',
      'quote',
      'close',
      controller,
    ]);

  function controller($element, CreateOrderApiService, quote, close) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.closeModal = closeModal;
    $ctrl.createOrder = create;
    $ctrl.allocate = allocate;
    $ctrl.setSelectedItems = setSelectedItems;

    $ctrl.loading = true;

    $ctrl.itemsToAllocate = [];
    $ctrl.selectedItems = [];
    $ctrl.allocatedItems = [];
    $ctrl.manufacturerItems = [];

    $ctrl.template = 'templates/sales.quotes.createOrder.modal.allocate.template.html';

    CreateOrderApiService.getDetails(quote).then(function (details) {
      if (details.genmanItems.length === 0) {
        create();

        return;
      }

      $ctrl.itemsToAllocate = details.genmanItems;
      $ctrl.manufacturerItems = details.manufacturers;
      $ctrl.loading = false;
    });

    function allocate() {
      $ctrl.allocatedItems.push({
        manufacturerName: $ctrl.manufacturerName,
        items: $ctrl.selectedItems,
      });

      var selectedItemIds = $ctrl.selectedItems.map(function (item) {
        return item.id;
      });

      $ctrl.itemsToAllocate = $ctrl.itemsToAllocate.filter(function (item) {
        return !selectedItemIds.includes(item.id);
      });

      $ctrl.selectedItems = [];
      $ctrl.manufacturerName = '';

      if ($ctrl.itemsToAllocate.length === 0) {
        $ctrl.template = 'templates/sales.quotes.createOrder.modal.confirm.template.html';
      }
    }

    function create() {
      CreateOrderApiService.createOrder(quote, $ctrl.allocatedItems).then(function (response) {
        closeModal(response.order);
      });
    }

    function setSelectedItems(selectedItems) {
      $ctrl.selectedItems = selectedItems;
    }

    function closeModal(order) {
      // this has to happen here as following close the modal is removed from the DOM and doesn't clean up
      $element.parent().find('#createOrderModal').modal('hide');
      close(order);
    }
  }
})();
