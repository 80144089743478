(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('QuoteFinalisationService', [
      '$rootScope',
      '$timeout',
      '$window',
      '$document',
      'QuotesService',
      'PdfPrintService',
      'ApiService',
      service,
    ]);

  function service(
    $rootScope,
    $timeout,
    $window,
    $document,
    QuotesService,
    PdfPrintService,
    ApiService,
  ) {
    var validActions = ['email', 'print', 'download'],
      processingStatus = 'finalising',
      draftStatus = 'draft';

    return {
      isFinalised: isFinalised,
      isProcessing: isProcessing,
      isPdfFailed: isPdfFailed,
      isDraft: isDraft,
      checkAction: checkAction,
      doSend: doSend,
      doDownload: doDownload,
      doPrint: doPrint,
      finaliseQuote: finaliseQuote,
      getFinalisationSettings: getFinalisationSettings,
    };

    /**
     * Determine if quote finalised
     *
     * @param {object} quote
     * @returns {boolean}
     */
    function isFinalised(quote) {
      return quote.metadata.finalised;
    }

    /**
     * Determine if quote is processing from status
     *
     * @param {object} quote
     * @returns {boolean}
     */
    function isProcessing(quote) {
      return !isPdfFailed(quote) && quote.metadata.status === processingStatus;
    }

    /**
     * Determine if quote PDF generation has failed
     *
     * @param {object} quote
     * @return {boolean}
     */
    function isPdfFailed(quote) {
      return quote.metadata.documents.quote.pdfGenerationFailed;
    }

    /**
     * Determine if quote is a draft from status
     *
     * @param {object} quote
     * @returns {boolean}
     */
    function isDraft(quote) {
      return quote.metadata.status === draftStatus;
    }

    /**
     * Check if action valid, throw exception if not
     *
     * @param {string} action
     * @returns {boolean}
     */
    function checkAction(action) {
      if (validActions.indexOf(action) === -1) {
        throw Error('Invalid finalisation action [' + action + ']');
      }
    }

    /**
     * Send the quote
     *
     * @param {object} quote
     * @returns {$q}
     */
    function doSend(quote) {
      return QuotesService.sendQuote(quote);
    }

    /**
     * Download the quote
     *
     * @param {object} quote
     */
    function doDownload(quote) {
      var aElement = $document[0].createElement('a');

      aElement.href = QuotesService.getQuotePdfUrl(quote);
      aElement.download = aElement.href;
      aElement.target = '_blank';
      aElement.click();

      aElement.remove();
    }

    /**
     * Print the quote
     *
     * @param {object} quote
     * @returns $q
     */
    function doPrint(quote) {
      return PdfPrintService.print(quote);
    }

    /**
     * Finalise a quote.
     *
     * @param {Object} quote
     * @param {Object} quoteSettings
     * @param {Array} completedImages
     * @param {Object} includeDocuments
     * @param {Boolean} sendEmail
     *
     * @return {$q}
     */
    function finaliseQuote(quote, quoteSettings, completedImages, includeDocuments, sendEmail) {
      var includeDocumentIds = includeDocuments.map(function (document) {
        return document.id;
      });

      var data = {
        completedImages: completedImages,
        includeDocuments: includeDocumentIds,
        guarantee: quoteSettings.guarantee,
        leadTime: quoteSettings.leadTime,
        vatExclusive: quoteSettings.vatExclusive,
        hideDimensions: quoteSettings.hideDimensions,
        customerNotes: quoteSettings.customerNotes,
        sendEmail: sendEmail,
      };

      return ApiService.put('/quotes/' + quote.id + '/finalise', data).then(function () {
        $rootScope.$emit('quote.finalised', {id: quote.id});
      });
    }

    /**
     * Get default finalisation settings.
     *
     * @param {Object} quote
     *
     * @return {$q}
     */
    function getFinalisationSettings(quote) {
      return ApiService.get('/quotes/' + quote.id + '/finalise').then(function (response) {
        return response.data;
      });
    }
  }
})();
