(function () {
  'use strict';

  angular.module('module.profile.team').controller('controller.profile.team.member', [
    '$state',
    'UsersService',
    'dataProfile',
    function ($state, UsersService, dataProfile) {
      var vm = this;

      vm.user = dataProfile.user;
      vm.reference = dataProfile.reference;
      vm.currentUser = dataProfile.currentUser;

      vm.save = function () {
        UsersService.updateUser(vm.user).then(function () {
          $state.go('profile.team', null, {reload: true});
        });
      };
    },
  ]);
})();
