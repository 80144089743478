(function () {
  'use strict';

  angular.module('module.products', [
    'module.api',
    'module.types',
    'module.productStyles',
    'ui.router',
    'angular.filter',
    'ui.grid',
    'ui.grid.pinning',
  ]);
})();
