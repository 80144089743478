(function () {
  'use strict';

  angular
    .module('module.settings.retailer')
    .controller('controller.settings.retailer.paymentTerms.update', [
      '$scope',
      '$state',
      'PaymentTermsService',
      'dataPaymentTerm',
      function ($scope, $state, PaymentTermsService, dataPaymentTerm) {
        $scope.paymentTerm = dataPaymentTerm;
        $scope.buttonText = 'Update Payment Term Details';

        $scope.submit = function () {
          PaymentTermsService.paymentTermsUpdate($scope.paymentTerm).then(function () {
            $state.go('settings.retailer.paymentTerms', {}, {reload: true});
          });
        };
      },
    ]);
})();
