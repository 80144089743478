(function () {
  'use strict';

  angular.module('module.products').directive('ttProductPricingMatrix', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/products.pricingMatrix.directive.template.html',
        controllerAs: 'matrixVm',
        scope: {
          matrix: '<',
        },
        controller: ['$scope', controller],
        bindToController: true,
      };
    },
  ]);

  var defaultGridOptions = {
    enableSorting: false,
    flatEntityAccess: true,
    enableColumnMenus: false,
    minimumColumnSize: 80,
    excessColumns: 50,
    excessRows: 100,
    fastWatch: true,
    onRegisterApi: createRowHeaderColumn,
  };

  /**
   * @param $scope
   */
  function controller($scope) {
    /* jshint validthis: true */
    var vm = this;

    $scope.$watch('matrixVm.matrix', function (newMatrix) {
      vm.gridOptions = processMatrixToGridOptions(newMatrix);
    });
  }

  /**
   * Register a row header column
   *
   * @param {Grid} gridApi
   */
  function createRowHeaderColumn(gridApi) {
    gridApi.core.addRowHeaderColumn({name: 'rowHeader', field: 'rowHeader', displayName: ''});
  }

  /**
   * API matrix format is {rowLabel: {columnLabel: value, columnLabel: value, ...}, ...}
   * Grid expected format is [{colLabel1: value1, colLabel2: value2, ...}] where each element is a row
   *
   * @param {Object} matrix
   * @returns {Object}
   */
  function processMatrixToGridOptions(matrix) {
    // numerically sorted row keys
    var rowKeys = Object.keys(matrix).sort(stringToIntNumericSort),
      // numerically sorted column keys, taken from first row
      columnKeys = Object.keys(matrix[rowKeys[0]]).sort(stringToIntNumericSort),
      gridOptions = angular.copy(defaultGridOptions);

    // create columns
    gridOptions.columnDefs = [];
    angular.forEach(columnKeys, function (columnKey) {
      gridOptions.columnDefs.push({name: columnKey, field: columnKey});
    });

    // create rows
    gridOptions.data = [];
    angular.forEach(rowKeys, function (rowKey) {
      var row = angular.copy(matrix[rowKey]);
      row.rowHeader = rowKey;
      gridOptions.data.push(row);
    });

    return gridOptions;
  }

  /**
   * Numeric sort with type conversion from strings to ints
   *
   * @param a
   * @param b
   * @returns {number}
   */
  function stringToIntNumericSort(a, b) {
    return parseInt(a) - parseInt(b);
  }
})();
