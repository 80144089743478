(function () {
  'use strict';

  angular
    .module('module.settings.retailer')
    .factory('PaymentTermsService', ['$q', 'Notification', 'ApiService', service]);

  /**
   * @param $q
   * @param Notification
   * @param ApiService
   * @returns {{paymentTermsList: paymentTermsList, paymentTermsCreate: paymentTermsCreate, paymentTermsRetrieve: paymentTermsRetrieve, paymentTermsUpdate: paymentTermsUpdate}}
   */
  function service($q, Notification, ApiService) {
    return {
      paymentTermsList: paymentTermsList,
      paymentTermsCreate: paymentTermsCreate,
      paymentTermsRetrieve: paymentTermsRetrieve,
      paymentTermsUpdate: paymentTermsUpdate,
    };

    /**
     * @returns {$Q}
     */
    function paymentTermsList() {
      return ApiService.get('/paymentterms').then(function (response) {
        return response.data.paymentTerms;
      });
    }

    /**
     * @param {object} paymentTerm
     * @returns {$Q}
     */
    function paymentTermsCreate(paymentTerm) {
      return ApiService.post('/paymentterms', paymentTerm).then(
        function () {
          Notification.success('Created payment term');
        },
        function () {
          Notification.error('Failed to create payment term');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {string} paymentTermId
     * @returns ${Q}
     */
    function paymentTermsRetrieve(paymentTermId) {
      return ApiService.get('/paymentterms/' + paymentTermId).then(function (response) {
        return response.data.paymentTerm;
      });
    }

    /**
     * @param {object} paymentTerm
     * @returns {$Q}
     */
    function paymentTermsUpdate(paymentTerm) {
      return ApiService.put('/paymentterms/' + paymentTerm.id, paymentTerm).then(
        function () {
          Notification.success('Updated payment term');
        },
        function () {
          Notification.error('Failed to update payment term');

          return $q.reject(null);
        },
      );
    }
  }
})();
