(function () {
  'use strict';

  angular.module('module.settings.retailerProducts').component('ttRetailerProductSlice', {
    templateUrl: 'templates/settings.retailerProducts.slice.component.template.html',
    controller: ['$window', '$state', 'RetailerProductsApiService', controller],
    bindings: {
      retailerProduct: '<',
      onDelete: '<',
      onCopy: '<',
    },
  });

  function controller($window, $state, ApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.delete = deleteProduct;
    $ctrl.copy = copyProduct;
    $ctrl.onClick = onClick;
    $ctrl.canDelete = true;
    $ctrl.canCopy = true;

    function deleteProduct() {
      if ($window.confirm('Are you sure you want to delete this product?')) {
        ApiService.delete($ctrl.retailerProduct).then(function () {
          $ctrl.onDelete($ctrl.retailerProduct);
        });
      }
    }

    function copyProduct() {
      ApiService.copy($ctrl.retailerProduct).then($ctrl.onCopy);
    }

    function onClick() {
      $state.go('settings.retailerProducts.update', {id: $ctrl.retailerProduct.id});
    }
  }
})();
