(function () {
  'use strict';

  angular.module('module.productStyles').directive('ttProductStyleMoreInfo', function () {
    return {
      restrict: 'A',
      scope: {
        productStyle: '<',
      },
      controller: ['ModalService', controller],
      bindToController: true,
      link: link,
    };
  });

  /**
   * @param ModalService
   */
  function controller(ModalService) {
    /* jshint validthis: true */
    var vm = this;

    vm.onClick = onClick;

    /**
     * Show modal
     */
    function onClick() {
      ModalService.showModal({
        controller: 'controller.productStyles.moreInfo.modal',
        controllerAs: 'modalVm',
        templateUrl: 'templates/productStyles.moreInfo.modal.template.html',
        inputs: {
          dataProductStyle: vm.productStyle,
        },
      }).then(function (modal) {
        // open modal
        var modalElement = modal.element.parent().find('#product-style__modal');
        modalElement.modal();
      });
    }
  }

  /**
   * @param scope
   * @param element
   * @param attrs
   * @param controller
   */
  function link(scope, element, attrs, controller) {
    element.on('click', function (event) {
      event.preventDefault();
      controller.onClick();
    });
  }
})();
