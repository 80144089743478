(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.contractSettings', [
      'SettingsService',
      'dataContractSettings',
      controller,
    ]);

  /**
   * @param SettingsService SettingsService
   * @param {Object} dataContractSettings
   */
  function controller(SettingsService, dataContractSettings) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.save = save;
    $ctrl.saving = false;

    $ctrl.contractSettings = dataContractSettings;

    function save() {
      $ctrl.saving = true;
      SettingsService.updateContractSettings(
        $ctrl.contractSettings.contractPdfShowDimensions,
        $ctrl.contractSettings.cancellationConditions,
      ).finally(function () {
        $ctrl.saving = false;
      });
    }
  }
})();
