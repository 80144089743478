(function () {
  'use strict';

  angular
    .module('module.settings.retailer')
    .factory('RetailerSurchargeApiService', ['$q', 'ApiService', 'Notification', service]);

  function service($q, ApiService, Notification) {
    return {
      get: get,
      update: update,
    };

    function get() {
      return ApiService.get('/companies/retailers/surcharge').then(
        function (response) {
          return response.data;
        },
        function () {
          Notification.error('Failed to get retailer surcharge settings');

          return $q.reject();
        },
      );
    }

    function update(settings, applyToAllRetailers) {
      var data = {
        products: [],
        applyToAllRetailers: applyToAllRetailers,
      };

      settings.products.forEach(function (product) {
        data.products.push({
          id: product.id,
          surchargePercentage: product.surchargePercentage,
        });
      });

      return ApiService.put('/companies/retailers/surcharge', data).then(
        function () {
          Notification.success('Updated retailer surcharge settings');
        },
        function () {
          Notification.error('Failed to update retailer surcharge settings');

          return $q.reject();
        },
      );
    }
  }
})();
