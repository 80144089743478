(function () {
  'use strict';

  angular
    .module('module.settings.advanced.documentStore')
    .factory('DocumentStoreUploadService', ['DocumentStoreApiService', 'UploadService', service]);

  function service(DocumentStoreApiService, UploadService) {
    return {
      upload: upload,
    };

    /**
     * Upload a document to the document store
     *
     * @param {object} document
     * @returns {$q}
     */
    function upload(document) {
      return UploadService.uploadDocument(document).then(function (uploadedDocument) {
        return DocumentStoreApiService.create({
          uploadUuid: uploadedDocument.resourceId,
          name: uploadedDocument.name,
          requireAppend: uploadedDocument.requireAppend,
          tempFile: uploadedDocument.tempFile,
        });
      });
    }
  }
})();
