(function () {
  'use strict';

  angular.module('module.products').filter('componentCode', filter);

  function filter() {
    return function (component) {
      if (
        angular.isDefined(component.metadata.manufacturerCode) &&
        component.metadata.manufacturerCode !== null
      ) {
        return component.metadata.manufacturerCode;
      }

      if (angular.isDefined(component.metadata.supplierCode)) {
        return component.metadata.supplierCode;
      }

      return '';
    };
  }
})();
