(function () {
  'use strict';

  angular.module('module.freshchat').factory('FreshchatApiService', ['ApiService', service]);

  function service(ApiService) {
    return {
      setRestoreId: setRestoreId,
    };

    /**
     * @param {string} restoreId
     */
    function setRestoreId(restoreId) {
      ApiService.put('/freshchat/restore-id', {freshchatRestoreId: restoreId}).then(function () {
        return null;
      });
    }
  }
})();
