(function () {
  'use strict';

  angular
    .module('module.products')
    .factory('ProductsPricingMatrixService', ['ApiService', service]);

  function service(ApiService) {
    return {
      getMatrices: getMatrices,
    };

    /**
     * Get all pricing matrices for a given product
     *
     * @param {object} product
     * @returns {$q}
     */
    function getMatrices(product) {
      return ApiService.get('/products/' + product.id + '/pricing-matrix').then(
        function (response) {
          return response.data;
        },
      );
    }
  }
})();
