(function () {
  'use strict';

  angular
    .module('module.settings.advanced.documentStore')
    .directive('ttDocumentUpload', ['ModalService', directive]);

  function directive(ModalService) {
    return {
      scope: {
        maxUploads: '<',
        onUpdate: '&',
      },
      restrict: 'A',
      link: link,
    };

    function link(scope, element) {
      element.click(function () {
        showModal(scope.maxUploads, scope.onUpdate);
      });
    }

    /**
     * Show the modal and trigger callback on close
     *
     * @param {integer} maxUploads
     * @param {Function} onUpdate
     */
    function showModal(maxUploads, onUpdate) {
      ModalService.showModal({
        controller: 'controller.settings.advanced.documentStore.upload.modal',
        templateUrl: 'templates/settings.advanced.documentStore.upload.modal.template.html',
        controllerAs: '$ctrl',
        inputs: {
          maxUploads: maxUploads,
        },
      }).then(function (modal) {
        modal.element.modal();
        modal.close.then(function (uploadedDocuments) {
          onUpdate()(uploadedDocuments);
        });
      });
    }
  }
})();
