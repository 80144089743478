(function () {
  'use strict';

  angular.module('module.components').service('UpsellModalService', ['ModalService', service]);

  function service(ModalService) {
    return {
      showModal: showModal,
    };

    function showModal(contentTemplate) {
      ModalService.showModal({
        controller: 'controller.components.upsellModal',
        controllerAs: '$ctrl',
        templateUrl: 'templates/components.upsellModal.template.html',
        inputs: {
          contentTemplate: contentTemplate,
        },
      }).then(function (modal) {
        var modalElement = modal.element.parent().find('#upsellModal');
        modalElement.modal();

        modal.close.then(function () {
          modalElement.modal('hide');
        });
      });
    }
  }
})();
