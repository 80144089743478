(function () {
  'use strict';

  var materialTypes = {
    Timber: 'timber',
    PVC: 'pvc',
    Aluminium: 'aluminium',
  };

  var jointOptions = {
    timber: ['Welded', 'Mechanical'],
    pvc: ['Welded', 'Mechanical', 'Mechanical/Welded', 'Welded/Mechanical'],
    aluminium: ['Welded', 'Mechanical', 'Mechanical/Welded', 'Welded/Mechanical'],
  };

  var jointRenames = {
    timber: {
      Welded: 'Mitred',
      Mechanical: 'Traditional',
    },
    pvc: {
      'Welded/Mechanical': 'Welded Out, Mechanical In',
      'Mechanical/Welded': 'Mechanical Out, Welded In',
    },
    aluminium: {
      Welded: 'Mitred',
      Mechanical: 'Square',
      'Welded/Mechanical': 'Mitred Out, Square In',
      'Mechanical/Welded': 'Square Out, Mitred In',
    },
  };

  var weldedLabel = {
    timber: 'Mitred',
    pvc: 'Welded',
    aluminium: 'Mitred',
  };

  var mechanicalLabel = {
    timber: 'Traditional',
    pvc: 'Mechanical',
    aluminium: 'Square',
  };

  angular.module('module.settings.retailerProducts').component('ttRetailerProductTabs', {
    templateUrl: 'templates/settings.retailerProducts.tabs.component.template.html',
    bindings: {
      settings: '=',
      selectedProduct: '=',
      activeTab: '=',
      editMode: '=',

      pricing: '=',
      showPricing: '=',
      componentPricing: '=',
      showComponentPricing: '=',
      uploadedMatrices: '=',
      onPricingMatrixChanges: '=',
      productImage: '=',
      currentProductImage: '=',
      manufacturerImage: '=',
      currentManufacturerImage: '=',
      retailerProduct: '<?',
    },
    controller: ['$scope', controller],
  });

  function controller($scope) {
    /* jshint validthis: true */
    var vm = this;

    vm.getRenameKey = getRenameKey;
    vm.getComponentOptions = getComponentOptions;

    vm.componentLabels = {
      'vertical slider catch': 'Catch',
      'travel restrictor': 'Restrictor',
      'finish pairs': 'Foils',
      stain: 'Stains',
      'door slab': 'Door Leaf',
      'side screen slab': 'Side Screen',
      'profile trickle vent': 'Trickle Vents',
      'door hinge': 'Hinge',
      'door knocker': 'Knocker',
      'door slab glazing': 'Feature glazing',
    };

    $scope.$watch('$ctrl.selectedProduct', function () {
      if (vm.selectedProduct) {
        var materialType = materialTypes[vm.selectedProduct.materialType];
        vm.jointOptions = jointOptions[materialType];
        vm.jointRenames = jointRenames[materialType];
        vm.weldedLabel = weldedLabel[materialType];
        vm.mechanicalLabel = mechanicalLabel[materialType];
        vm.tabs = generateComponentTabs(vm.selectedProduct.components);
      }
    });

    function generateComponentTabs(components) {
      var tabGroups = {
        'flat panel': 'panels',
        'feature panel': 'panels',

        // 'cill': 'profiles',
        // 'addon': 'profiles',
        // 'low threshold': 'profiles',

        cill: 'ancillaries',
        addon: 'ancillaries',
        'low threshold': 'ancillaries',

        'door slab glazing': 'glazing',
        'astragal bar': 'glazing',

        'master handle': 'handles',
        'slave handle': 'handles',
        'folding handle': 'handles',
        'door handle': 'handles',

        'profile trickle vent': 'ventilation',

        letterplate: 'hardware',

        'door knocker': 'hardware',
        'door hinge': 'hardware',
        'door slab': 'slabs',
        'side screen slab': 'slabs',

        paint: 'finish',
        stain: 'finish',
        'friction hinge': 'hinges',
        'window handle': 'handle',
        'sash horn': 'horns',
        'sash lift': 'lifts',
        'sash ring': 'rings',
      };
      var hiddenComponentTypes = ['door slab glazing piece'];
      var tabs = {
        // 'profiles': [],
      };
      var componentKeys = Object.keys(components);
      for (var i = 0; i < componentKeys.length; i++) {
        var componentType = componentKeys[i];
        if (!hiddenComponentTypes.includes(componentType)) {
          var tabName = tabGroups[componentType] || componentType;
          if (tabs[tabName]) {
            tabs[tabName].push(componentType);
          } else {
            tabs[tabName] = [componentType];
          }
        }
      }
      vm.componentTabs = tabs;
    }

    function getRenameKey(hardwareType) {
      if (['master handle', 'slave handle', 'folding handle'].includes(hardwareType)) {
        return 'door handle';
      }
      return hardwareType;
    }

    function getComponentOptions(componentKey) {
      return vm.selectedProduct.components[componentKey];
    }
  }
})();
