(function () {
  'use strict';

  angular.module('module.products').filter('ttComponentAttribute', filter);

  /**
   * Return manufacturer attribute falling back to supplier then to a default
   *
   * @returns {function}
   */
  function filter() {
    return function (component, attributeSlug, defaultValue) {
      // start with default
      var value = defaultValue;

      // override with supplier attributes
      angular.forEach(component.attributes, function (attribute) {
        if (attributeSlug === attribute.slug && attribute.value) {
          value = attribute.value;
        }
      });

      // override with manufacturer attributes
      angular.forEach(component.manufacturerAttributes, function (attribute) {
        if (attributeSlug === attribute.slug && attribute.value) {
          value = attribute.value;
        }
      });

      return value;
    };
  }
})();
