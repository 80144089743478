(function () {
  'use strict';

  angular.module('module.sales').directive('nonZeroNumber', directive);

  function directive() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, element, attrs, ngModel) {
      ngModel.$validators.nonZeroNumber = function (modelValue, viewValue) {
        if (ngModel.$isEmpty(modelValue)) {
          return true;
        }

        var number = parseFloat(viewValue);
        if (isNaN(number)) {
          return false;
        }

        if (number > 0 || number < 0) {
          return true;
        }

        return false;
      };
    }
  }
})();
