(function () {
  'use strict';

  angular.module('module.settings.advanced.documentStore').component('ttDocumentUpload', {
    templateUrl: 'templates/settings.advanced.documentStore.document.component.template.html',
    controller: controller,
    bindings: {
      document: '<',
      onDelete: '&',
    },
  });

  function controller() {
    /* jshint validthis: true */
    var vm = this;

    vm.delete = vm.onDelete;
    vm.update = function () {};

    vm.isNew = true;
    vm.document.requireAppend = false;
    vm.document.tempFile = false;
  }
})();
