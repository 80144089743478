(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.quotes.createContractReport.modal', [
      '$element',
      '$rootScope',
      'DocumentStoreApiService',
      'DocumentStoreUploadService',
      'ContractReportApiService',
      'SettingsService',
      'quote',
      'close',
      controller,
    ]);

  function controller(
    $element,
    $rootScope,
    DocumentStoreApiService,
    DocumentStoreUploadService,
    ContractReportApiService,
    SettingsService,
    quote,
    close,
  ) {
    /* jshint validthis: true */
    var vm = this;
    vm.maxDocuments = 30;
    vm.closeModal = closeModal;
    vm.create = create;
    vm.proceed = proceed;
    vm.onUpload = onUpload;
    vm.onChange = onChange;

    vm.quote = quote;
    vm.notes = quote.metadata.specialNotes;
    vm.defaultCancellationConditions = '';
    vm.retailerDocuments = [];
    vm.manufacturerDocuments = [];
    vm.uploadedDocuments = []; // Ensure this is defined

    onInit();

    function onInit() {
      vm.step = 'customise';

      SettingsService.getSettings().then(function (settings) {
        vm.defaultCancellationConditions = settings.settings.contract.cancellationConditions;
        vm.cancellationConditions = vm.defaultCancellationConditions;
        vm.contractPdfShowDimensions = settings.settings.contract.contractPdfShowDimensions;
      });

      vm.documentsLoaded = false;
      DocumentStoreApiService.list(vm.quote).then(function (response) {
        vm.retailerDocuments = response.documents;
        vm.manufacturerDocuments = response.manufacturerDocuments;
        vm.documentsLoaded = true;
      });
    }

    function proceed(formValid) {
      if (!formValid) {
        return;
      }

      if (vm.step === 'customise') {
        vm.step = 'Upload';
        return;
      } else if (
        vm.step === 'Upload' ||
        vm.retailerDocuments.length ||
        vm.manufacturerDocuments.length
      ) {
        create();
      } else {
        return;
      }
    }

    function create() {
      ContractReportApiService.create(
        vm.quote,
        vm.notes,
        vm.cancellationConditions,
        vm.selectedDocuments,
        vm.contractPdfShowDimensions,
      ).then(function () {
        $rootScope.activeTab = 'Documents';
        closeModal(true);
      });
    }

    function onUpload() {
      var promises = [];

      angular.forEach(vm.uploadedDocuments, function (document) {
        document.selected = true;
        document.tempFile = true;
        promises.push(
          DocumentStoreUploadService.upload(document).then(function (response) {
            response.selected = true;
            vm.retailerDocuments = vm.retailerDocuments.concat(response);
            vm.onChangeSelected();
            vm.uploadedDocuments = [];
          }),
        );
      });
    }
    function selected(document) {
      return document.selected;
    }

    vm.onChangeSelected = function () {
      var selectedDocuments = vm.selectedDocuments;
      if (selectedDocuments) {
        selectedDocuments = selectedDocuments.concat(vm.retailerDocuments).filter(selected);
      } else {
        selectedDocuments = vm.retailerDocuments.filter(selected);
      }
      vm.onChange(selectedDocuments);
    };

    function onChange(selectedDocuments) {
      vm.selectedDocuments = selectedDocuments.map(function (document) {
        return document.id;
      });
    }

    function closeModal(created) {
      $element.parent().find('#createContractReportModal').modal('hide');
      close({created: !!created});
    }
  }
})();
