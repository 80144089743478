(function () {
  'use strict';

  angular
    .module('module.stripe')
    .factory('StripeApiService', [
      '$q',
      '$timeout',
      'Notification',
      'ApiService',
      'Stripe',
      service,
    ]);

  const pollRate = 1000;

  function service($q, $timeout, Notification, ApiService, Stripe) {
    return {
      createSetupIntent: createSetupIntent,
      removePaymentMethod: removePaymentMethod,
      getPaymentMethod: getPaymentMethod,
      authCard: authCard,
      pollPaymentMethodForUpdate: pollPaymentMethodForUpdate,
    };

    function createSetupIntent() {
      return ApiService.post('/stripe/create-setup-intent').then(function (response) {
        return response.data.clientSecret;
      });
    }

    function removePaymentMethod(paymentMethodId) {
      var url = '/stripe/payment-method';
      if (paymentMethodId !== null) {
        url += '/' + paymentMethodId;
      }

      return ApiService.delete(url).catch(function (response) {
        if (response.status === 409) {
          // ignore mismatch
          return;
        }

        Notification.error('Failed to update card');

        return $q.reject();
      });
    }

    function getPaymentMethod() {
      return ApiService.get('/stripe/payment-method').then(function (response) {
        return response.data;
      });
    }

    function authCard(failedIntentSecret, paymentMethodId) {
      return Stripe.confirmCardPayment(failedIntentSecret, {
        payment_method: paymentMethodId,
      })
        .then(function (result) {
          if (typeof result.error !== 'undefined') {
            return $q.reject();
          }
        })
        .then(ApiService.post('/stripe/payment-method/' + paymentMethodId + '/auth'))
        .catch(function () {
          Notification.error('Failed to authenticate card');

          return $q.reject();
        });
    }

    function pollPaymentMethodForUpdate(scope) {
      var deferred = $q.defer();
      var pollPromise;

      var pollFunction = function () {
        getPaymentMethod().then(
          function (paymentMethod) {
            if (paymentMethod.status !== 'pending') {
              deferred.resolve(paymentMethod);
            } else {
              pollPromise = $timeout(pollFunction, pollRate);
            }
          },
          function () {
            pollPromise = $timeout(pollFunction, pollRate);
          },
        );
      };

      pollFunction();

      scope.$on('$destroy', function () {
        if (typeof pollPromise !== 'undefined') {
          $timeout.cancel(pollPromise);
        }
      });

      return deferred.promise;
    }
  }
})();
