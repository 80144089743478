(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('CustomerQuotesArchiveApiService', ['ApiService', 'QuotesService', service]);

  function service(ApiService, QuotesService) {
    return {
      getArchiveList: getArchiveList,
    };

    function getArchiveList(customer) {
      return ApiService.get('/customers/' + customer.id + '/quotes/archive').then(
        function (response) {
          return response.data;
        },
      );
    }
  }
})();
