(function () {
  'use strict';

  angular.module('module.retailers').component('ttOwnerRetailerSuspend', {
    templateUrl: 'templates/retailers.ownerView.suspend.component.template.html',
    bindings: {
      retailer: '<',
      onChange: '<',
    },
    controller: ['$window', 'RetailerSuspendApiService', controller],
  });

  function controller($window, RetailerSuspendApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.suspend = suspend;
    $ctrl.suspended = $ctrl.retailer.metadata.suspended;

    function suspend() {
      if (
        !$window.confirm(
          'Are you sure you want to ' +
            ($ctrl.suspended ? 'activate' : 'suspend') +
            ' this retailer?',
        )
      ) {
        return;
      }

      var suspendPromise = $ctrl.suspended
        ? RetailerSuspendApiService.activate($ctrl.retailer)
        : RetailerSuspendApiService.suspend($ctrl.retailer);

      suspendPromise.then(function () {
        $ctrl.suspended = !$ctrl.suspended;
        $ctrl.onChange($ctrl.suspended);
      });
    }
  }
})();
