(function () {
  'use strict';

  angular.module('module.sales').component('ttPendingCompanyDetails', {
    templateUrl: 'templates/sales.retailers.pending.companyDetails.component.template.html',
    bindings: {
      company: '<',
      errorMessage: '<',
      localeInvitingManufacturer: '<',
      showLink: '<?',
      onLink: '&?',
    },
    controller: [controller],
  });
  function controller() {
    /* jshint validthis: true */
    var $ctrl = this;
    CheckLocale();

    function CheckLocale() {
      if (
        $ctrl.localeInvitingManufacturer !== $ctrl.company.locale &&
        $ctrl.errorMessage === undefined
      ) {
        $ctrl.errorMessage = true;
      }
    }
  }
})();
