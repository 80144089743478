(function () {
  'use strict';

  angular.module('module.visualiser').controller('controller.visualiser.photoBankUpload', [
    '$stateParams',
    'PhotoBankUploadService',
    '$q',
    '$element',
    'close',
    function ($stateParams, PhotoBankUploadService, $q, $element, close) {
      /* jshint validthis: true */
      var vm = this,
        refreshPhotoBank = false;

      vm.deleteImage = deleteImage;
      vm.upload = upload;
      vm.closeModal = closeModal;
      vm.imageChanged = imageChanged;
      vm.uploadsChanged = uploadsChanged;

      init();

      /**
       * Init the controller
       */
      function init() {
        vm.images = [];
        vm.uploading = false;
        vm.error = false;
        vm.locationsValid = false;
      }

      /**
       * Handle update of image
       */
      function imageChanged() {
        checkLocations();
      }

      /**
       * Check if all locations are valid
       * @returns {boolean}
       */
      function checkLocations() {
        vm.locationsValid = vm.images.reduce(function (carry, image) {
          // undefined check due to not existing when initially created
          if (typeof image.location !== 'undefined' && image.location.length < 3) {
            return false;
          }

          return carry;
        }, true);
      }

      /**
       * Handle selected images
       *
       * @param {[]} files
       * @param {[]} invalidFiles
       */
      function uploadsChanged(files, invalidFiles) {
        vm.error = false;
        angular.forEach(invalidFiles, function (file) {
          if (file.$error === 'maxSize') {
            vm.error = 'Some of the selected files were too large (10MB max)';
          }
        });

        // reset invalid files - persisted between file selections
        invalidFiles.length = 0;
      }

      /**
       * Remove a given image from the collection
       *
       * @param {object} image
       */
      function deleteImage(image) {
        vm.images.splice(vm.images.indexOf(image), 1);
      }

      /**
       * Upload all images in the collection
       */
      function upload() {
        // set uploaded flag if any images in queue
        var promises = [];
        vm.uploading = true;

        angular.forEach(vm.images, function (image) {
          promises.push(
            PhotoBankUploadService.upload($stateParams.quoteId, image).then(
              function () {
                refreshPhotoBank = true;
                $element.modal('hide');
                closeModal();
              },
              function () {
                // TODO: show failed in view
              },
            ),
          );
        });

        $q.all(promises).then(function () {
          vm.uploading = false;
        });
      }

      /**
       * Call the close function passing uploaded flag
       */
      function closeModal() {
        close(refreshPhotoBank, 200); // close, but give 200ms for bootstrap to animate
      }
    },
  ]);
})();
