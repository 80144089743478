(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('PricingRequestApiService', [
      '$q',
      '$timeout',
      '$rootScope',
      'Notification',
      'ApiService',
      service,
    ]);

  var generatedStatus = 'Generated';
  var pollRate = 2000;

  function service($q, $timeout, $rootScope, Notification, ApiService) {
    return {
      list: list,
      create: create,
      pollList: pollList,
    };

    /**
     * @param {object} quote
     *
     * @returns {$q}
     */
    function list(quote) {
      return ApiService.get('/quotes/' + quote.id + '/pricingRequests').then(function (response) {
        return response.data;
      });
    }

    function pollList(quote, scope) {
      var deferred = $q.defer();
      var pollPromise;

      var pollFunction = function () {
        list(quote).then(
          function (pricingRequests) {
            for (var i = 0; i < pricingRequests.length; i++) {
              if (pricingRequests[i].status !== generatedStatus) {
                deferred.notify(pricingRequests);
                pollPromise = $timeout(pollFunction, pollRate);

                return;
              }
            }

            deferred.resolve(pricingRequests);
          },
          function () {
            pollPromise = $timeout(pollFunction, pollRate);
          },
        );
      };

      pollFunction();

      scope.$on('$destroy', function () {
        if (typeof pollPromise !== 'undefined') {
          $timeout.cancel(pollPromise);
        }
      });

      return deferred.promise;
    }

    /**
     * @param {object} quote
     * @param {Array} selectedItemIds
     * @param {string} reference
     * @param {string?} notes
     *
     * @returns {$q}
     */
    function create(quote, selectedItemIds, reference, notes) {
      var data = {
        items: selectedItemIds,
        reference: reference,
        notes: typeof notes === 'undefined' ? null : notes,
      };

      return ApiService.post('/quotes/' + quote.id + '/pricingRequests', data).then(
        function (response) {
          Notification.success('Created pricing request');
          $rootScope.activeTab = 'Documents';

          return response.data;
        },
        function () {
          Notification.error('Failed to create pricing request');

          return $q.reject(null);
        },
      );
    }
  }
})();
