(function () {
  'use strict';

  angular
    .module('module.authentication')
    .controller('controller.authentication.signin', [
      '$rootScope',
      '$scope',
      '$state',
      '$stateParams',
      'AuthenticationService',
      'CompanySubdomainService',
      'BreadcrumbsService',
      controller,
    ]);

  function controller(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    AuthenticationService,
    CompanySubdomainService,
    BreadcrumbsService,
  ) {
    /* jshint validthis: true */
    var vm = this;

    BreadcrumbsService.hideBreadcrumbs();
    $rootScope.bodyClass = 'login';

    vm.signIn = signIn;

    vm.message = $stateParams.message;
    vm.showPassword = false;

    CompanySubdomainService.getSubdomainDetails().then(function (details) {
      vm.title = details.company;
      vm.logo = details.logo;
    });

    /**
     * Attempt to sign in
     */
    function signIn() {
      $scope.$broadcast('fireChange');
      vm.message = '';

      AuthenticationService.signIn(vm.email, vm.password).then(
        function () {
          $state.go('dashboard');
        },
        function (response) {
          if (response.redirect) {
            return;
          }

          if (response.status === 403) {
            vm.message = 'Your account has been suspended. Please contact support.';
          } else {
            vm.message = 'Username and password combination is not valid';
          }

          vm.password = '';
        },
      );
    }
  }
})();
