(function () {
  'use strict';

  angular.module('ngAnimate').animation('.slide', slideAnimation);

  /**
   * @returns {{beforeAddClass: beforeAddClass, removeClass: removeClass}}
   */
  function slideAnimation() {
    return {
      beforeAddClass: function (element, className, done) {
        if (className === 'ng-hide') {
          element.slideUp(done);
        }
      },
      removeClass: function (element, className, done) {
        if (className === 'ng-hide') {
          element.hide().slideDown(done);
        }
      },
    };
  }
})();
