(function () {
  'use strict';

  angular.module('module.settings.partsShop').component('ttPartsShopSlice', {
    templateUrl: 'templates/settings.partsShop.slice.component.template.html',
    controller: [
      '$window',
      '$state',
      'PartsShopApiService',
      'PRICING_UNIT',
      'PRICING_UNIT_SUFFIX',
      'CurrencyService',
      '$rootScope',
      controller,
    ],
    bindings: {
      item: '<',
      onDelete: '<',
      onCopy: '<',
    },
  });

  function controller(
    $window,
    $state,
    ApiService,
    PRICING_UNIT,
    PRICING_UNIT_SUFFIX,
    CurrencyService,
    $rootScope,
  ) {
    /* jshint validthis: true */
    const $ctrl = this;

    $ctrl.delete = deleteItem;
    $ctrl.copy = copyItem;
    $ctrl.onClick = onClick;
    $ctrl.pricingUnitSuffix =
      $ctrl.item.pricingUnit === PRICING_UNIT.EACH
        ? ''
        : 'per ' + PRICING_UNIT_SUFFIX[$ctrl.item.pricingUnit];
    $ctrl.currency = CurrencyService.getSettings();

    $rootScope.$on('settings.update', (event, settings) => {
      $ctrl.currency = settings.currency || CurrencyService.getSettings();
    });

    function deleteItem() {
      if ($window.confirm('Are you sure you want to delete this?')) {
        ApiService.remove($ctrl.item.id).then(() => $ctrl.onDelete($ctrl.item));
      }
    }

    function copyItem() {
      ApiService.copy($ctrl.item.id).then($ctrl.onCopy);
    }

    function onClick() {
      $state.go('settings.partsShop.update', {id: $ctrl.item.id});
    }
  }
})();
