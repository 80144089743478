(function () {
  'use strict';

  angular.module('module.products').directive('ttComponentConstraints', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/components.retrieve.constraints.directive.template.html',
        controllerAs: 'constraintsVm',
        scope: {
          component: '=',
        },
        controller: ['ComponentsService', controller],
        bindToController: true,
      };
    },
  ]);

  /**
   * @param ComponentsService
   */
  function controller(ComponentsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.save = save;

    vm.constraints = buildConstraints(vm.component);

    function save() {
      ComponentsService.updateComponent(vm.component);
    }

    /**
     * Create array of mapped manufacturer and supplier constraints
     *
     * @param {object} component
     * @returns {Array}
     */
    function buildConstraints(component) {
      var constraints = [];

      // supplier constraints
      angular.forEach(component.constraints, function (constraint) {
        constraints.push({
          name: constraint.name,
          supplier: constraint,
          manufacturer: null,
        });
      });

      // manufacturer constraints
      angular.forEach(component.manufacturerConstraints, function (constraint) {
        // check if constraint exists already
        for (var i = 0; i < constraints.length; i++) {
          if (constraints[i].name === constraint.name) {
            constraints[i].manufacturer = constraint;

            return;
          }
        }

        // supplier constraint did not exist, create new
        constraints.push({
          name: constraint.name,
          supplier: null,
          manufacturer: constraint,
        });
      });

      return constraints;
    }
  }
})();
