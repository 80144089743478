(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .controller('controller.retailerProducts.pricingMatrices.add.modal', [
      '$q',
      'RetailerProductsPricingMatricesApiService',
      'matrixType',
      'availableMatrices',
      'close',
      'styleLabels',
      controller,
    ]);

  function controller(
    $q,
    RetailerProductsPricingMatricesApiService,
    matrixType,
    availableMatrices,
    close,
    styleLabels,
  ) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.add = add;
    $ctrl.close = close;

    $ctrl.availableMatrices = angular.copy(availableMatrices);
    $ctrl.matrixType = matrixType;
    $ctrl.singleMatrix = availableMatrices.length === 1;
    $ctrl.csvFile = null;
    $ctrl.validationErrors = [];
    $ctrl.groupedMatrices = groupMatrices($ctrl.availableMatrices);
    $ctrl.groups = Object.keys($ctrl.groupedMatrices);
    $ctrl.selectedGroup = $ctrl.groups[0];
    $ctrl.styleLabels = styleLabels;

    if ($ctrl.singleMatrix) {
      $ctrl.availableMatrices[0].selected = true;
    }

    function add() {
      if (!$ctrl.csvFile) {
        $ctrl.validationErrors = ['CSV file is required'];

        return;
      }

      readFile($ctrl.csvFile)
        .then(function (matrixData) {
          return RetailerProductsPricingMatricesApiService.validate(matrixType, matrixData).then(
            function () {
              var selectedMatrices = $ctrl.availableMatrices.filter(function (matrix) {
                return matrix.selected;
              });

              selectedMatrices.forEach(function (matrix) {
                matrix.matrixData = matrixData;
                matrix.queuedForDelete = false;
                matrix.queuedForUpload = true;
                matrix.matrixType = matrixType;
                matrix.description = $ctrl.csvFile.name;
                delete matrix.selected;
              });

              close(selectedMatrices);
            },
          );
        })
        .catch(function (errors) {
          if (!angular.isArray(errors)) {
            errors = [errors];
          }

          $ctrl.validationErrors = errors;
        });
    }

    function readFile(file) {
      return $q(function (resolve, reject) {
        var fileReader = new FileReader();

        fileReader.addEventListener('load', function () {
          resolve(fileReader.result);
        });

        fileReader.addEventListener('error', function () {
          reject(fileReader.error.message);
        });

        fileReader.readAsText(file);
      });
    }

    function groupMatrices(availableMatrices) {
      var groups = {};
      availableMatrices.forEach(function (matrix) {
        if (matrix.reference.match(/^DS[0-9]{3}$/)) {
          return pushOrNewArray(groups, 'Door Sash', matrix);
        }
        if (matrix.reference.match(/^(CS[0-9]{3})|(TS[0-9]{3})$/)) {
          return pushOrNewArray(groups, 'Openers', matrix);
        }
        if (matrix.reference.match(/^TS[0-9]{3}$/)) {
          return pushOrNewArray(groups, 'Tilt Turn Sash', matrix);
        }
        if (matrix.reference.match(/^(EF|DDF|CD)[0-9]{3}$/)) {
          return pushOrNewArray(groups, 'Frame', matrix);
        }
        if (matrix.reference.match(/^BF[0-9]{3}$/)) {
          return pushOrNewArray(groups, 'Bifold', matrix);
        }
        if (matrix.reference.match(/^PD[0-9]{3}$/)) {
          return pushOrNewArray(groups, 'Patio', matrix);
        }
        if (matrix.reference.match(/^[0-9] Aperture$/)) {
          return pushOrNewArray(groups, 'Frame Aperture Count', matrix);
        }
        for (var mullions = 0; mullions <= 8; mullions++) {
          var regEx = new RegExp('^(C|T)' + (mullions + 1) + '[0-9]{2,4}$');
          if (matrix.reference.match(regEx)) {
            var label = mullions + ' mullion' + (mullions === 1 ? '' : 's');
            return pushOrNewArray(groups, label, matrix);
          }
        }
        if (matrix.reference.match(/^EF[0-9]{3}-DS[0-9]{3}$/)) {
          return pushOrNewArray(groups, 'Complete Item', matrix);
        }
        if (matrix.reference.match(/^DDF[0-9]{3}-DS[0-9]{3}-DS[0-9]{3}$/)) {
          return pushOrNewArray(groups, 'Complete Item', matrix);
        }
        pushOrNewArray(groups, 'Other', matrix);
      });
      var ordered = {};
      Object.keys(groups)
        .sort()
        .forEach(function (key) {
          ordered[key] = groups[key];
        });
      return ordered;
    }

    function pushOrNewArray(object, key, value) {
      if (!object[key]) {
        object[key] = [];
      }
      object[key].push(value);
    }
  }
})();
