(function () {
  'use strict';

  angular.module('tommyApp').directive('msDate', directive);

  /**
   * Directive to format Date values from and to millisecond timestamp values used in API
   */
  function directive() {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: link,
    };

    /**
     * @param scope
     * @param element
     * @param attr
     * @param ngModel
     */
    function link(scope, element, attr, ngModel) {
      ngModel.$formatters.push(toDateTransformer);
      ngModel.$parsers.push(toMsTransformer);
    }

    /**
     * Transform Date to time in milliseconds
     *
     * @param {Date|null} date
     * @return {int|void}
     */
    function toMsTransformer(date) {
      if (!angular.isDate(date)) {
        return;
      }

      return date.getTime();
    }

    /**
     * Transform time in milliseconds to Date
     *
     * @param {int|null} timeInMs
     * @return {Date|void}
     */
    function toDateTransformer(timeInMs) {
      if (!angular.isNumber(timeInMs)) {
        return;
      }

      return new Date(timeInMs);
    }
  }
})();
