(function () {
  'use strict';

  angular.module('tommyApp').component('ttVersion', {
    templateUrl: 'templates/app.version.component.template.html',
    controller: ['VersionService', controller],
  });

  function controller(VersionService) {
    /* jshint validthis: true */
    var $ctrl = this;

    var version = VersionService.getPortalVersion();
    $ctrl.tag = version.tag;
    $ctrl.sha = version.sha;
  }
})();
