(function () {
  'use strict';
  angular.module('tommyApp').directive('input', preventScrollOnNumberInputs);
  function preventScrollOnNumberInputs() {
    return {
      restrict: 'E',
      link: function (scope, element, attrs) {
        if (attrs.type === 'number') {
          element.on('wheel', function (e) {
            e.preventDefault();
            this.blur();
          });
          scope.$on('$destroy', function () {
            element.off('wheel');
          });
        }
      },
    };
  }
})();
