(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.quote', [
      'QuoteSettingsApiService',
      'dataSettings',
      '$q',
      'Notification',
      controller,
    ]);

  function controller(QuoteSettingsApiService, dataSettings, $q, Notification) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.save = save;

    $ctrl.settings = dataSettings;
    $ctrl.saving = false;

    function save() {
      if (!$ctrl.settings.showCheckaTradeBranding) {
        $ctrl.settings.checkaTradeId = null;
      }
      $ctrl.saving = true;
      QuoteSettingsApiService.updateSettings($ctrl.settings).finally(function () {
        $ctrl.saving = false;
      });
    }
  }
})();
