(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .directive('ttGlazingPricingModal', ['ModalService', directive]);

  function directive(ModalService) {
    return {
      restrict: 'A',
      scope: {
        getComponentOptions: '<',
        componentPricing: '<',
        onUpdate: '<',
        range: '<',
        name: '<',
      },
      bindToController: true,
      link: link,
      controller: controller,
    };

    function link(scope, element, attr, controller) {
      element.on('click', function (event) {
        var allGlazing = controller.getComponentOptions('door slab glazing piece');
        var glazingForRange = allGlazing.filter(function (g) {
          return g.range === controller.range;
        });
        event.preventDefault();
        controller.showModal(
          glazingForRange,
          allGlazing,
          controller.name,
          controller.componentPricing,
        );
      });
    }

    function controller() {
      /* jshint validthis: true */
      var $ctrl = this;

      $ctrl.showModal = function (glazingForRange, allGlazing, rangeName, componentPricing) {
        ModalService.showModal({
          controller: 'controller.retailerProducts.glazingPricing.modal',
          templateUrl: 'templates/settings.retailerProducts.glazingPricing.modal.template.html',
          controllerAs: '$ctrl',
          inputs: {
            glazingForRange: glazingForRange,
            rangeName: rangeName,
            componentPricing: componentPricing,
            allGlazing: allGlazing,
          },
        }).then(function (modal) {
          modal.element.modal();

          modal.close.then(function (pricing) {
            modal.element.modal('hide');
            if (pricing) {
              $ctrl.onUpdate(pricing);
            }
          });
        });
      };
    }
  }
})();
