(function () {
  'use strict';

  angular.module('angularBowser', []).factory('Bowser', ['$window', service]);

  function service($window) {
    if (typeof $window.bowser === 'undefined') {
      throw Error('Bowser not found');
    }

    return $window.bowser;
  }
})();
