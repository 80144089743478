(function () {
  'use strict';

  angular.module('module.visualiser').directive('ttPhotoBank', [
    function () {
      return {
        restrict: 'E',
        templateUrl: 'templates/visualiser.photoBank.directive.template.html',
        controllerAs: 'bankVm',
        scope: {
          quote: '=',
        },
        controller: ['ModalService', 'PhotoBankService', controller],
        bindToController: true,
      };
    },
  ]);

  /**
   * @param ModalService
   * @param PhotoBankService
   */
  function controller(ModalService, PhotoBankService) {
    /* jshint validthis: true */
    var vm = this;

    vm.deletePhotoBankImage = deletePhotoBankImage;
    vm.uploadModal = uploadModal;
    vm.photoBank = [];
    vm.loading = true;

    init();

    /**
     * Init the directive
     */
    function init() {
      refreshPhotoBank();
    }

    /**
     * Delete a photo bank image
     *
     * @param {Object} image
     */
    function deletePhotoBankImage(image) {
      PhotoBankService.deleteImage(vm.quote.id, image.id).then(function () {
        vm.photoBank.splice(vm.photoBank.indexOf(image), 1);
      });
    }

    /**
     * Create the photo bank upload modal
     */
    function uploadModal() {
      ModalService.showModal({
        controller: 'controller.visualiser.photoBankUpload',
        templateUrl: 'templates/visualiser.photoBankUpload.template.html',
        controllerAs: 'uploadVm',
      }).then(function (modal) {
        // open modal
        modal.element.modal();

        modal.close.then(function (refresh) {
          if (refresh) {
            refreshPhotoBank();
          }
        });
      });
    }

    /**
     * Refresh the data in the photo bank
     */
    function refreshPhotoBank() {
      vm.loading = true;
      vm.photoBank = [];

      PhotoBankService.getList(vm.quote.id).then(function (images) {
        vm.photoBank = images;
        vm.loading = false;
      });
    }
  }
})();
