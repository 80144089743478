(function () {
  'use strict';

  angular.module('tommyApp').factory('VersionService', ['$rootScope', 'PORTAL_VERSION', service]);

  function service($rootScope, PORTAL_VERSION) {
    var apiVersion = {
      tag: 'Fetching...',
      sha: '',
    };

    $rootScope.$on('api-version-changed', function (event, newApiVersion) {
      angular.merge(apiVersion, newApiVersion);
    });

    return {
      getPortalVersion: getPortalVersion,
      getApiVersion: getApiVersion,
    };

    function getPortalVersion() {
      return PORTAL_VERSION;
    }

    function getApiVersion() {
      return apiVersion;
    }
  }
})();
