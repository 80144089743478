(function () {
  'use strict';

  angular.module('module.sales').factory('OrderApiService', ['ApiService', service]);

  function service(ApiService) {
    return {
      list: list,
      get: get,
    };

    /**
     * @returns {$q}
     */
    function list() {
      return ApiService.get('/orders').then(function (response) {
        return response.data;
      });
    }

    /**
     * @param {string} orderId
     * @returns {$q}
     */
    function get(orderId) {
      return ApiService.get('/orders/' + orderId).then(function (response) {
        return response.data;
      });
    }
  }
})();
