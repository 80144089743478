(function () {
  'use strict';

  angular.module('module.settings.partsShop').component('ttPartsShopProductOwnerSlice', {
    templateUrl: 'templates/settings.partsShop.slice.component.template.html',
    controller: ['$window', 'PartsShopApiService', controller],
    bindings: {
      item: '<',
    },
  });

  function controller($window, ApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.onClick = null;
    $ctrl.canDelete = false;
    $ctrl.canCopy = false;
  }
})();
