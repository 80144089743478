(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.profileSuites.retrieve', [
      '$scope',
      '$state',
      'ProfileSuitesService',
      'dataProfileSuite',
      function ($scope, $state, ProfileSuitesService, dataProfileSuite) {
        $scope.profilesuite = dataProfileSuite;

        $scope.update = function () {
          ProfileSuitesService.UpdateProfileSuite(
            $scope.profilesuite.id,
            $scope.profilesuite,
          ).success(function () {
            $state.reload();
          });
        };

        $scope.activate = function () {
          ProfileSuitesService.ActivateProfileSuite(
            $scope.profilesuite.id,
            $scope.profilesuite,
          ).success(function () {
            $state.reload();
          });
        };

        $scope.removeComponent = function (componentId) {
          ProfileSuitesService.RemoveComponent($scope.profilesuite.id, componentId).success(
            function () {
              $state.reload();
            },
          );
        };
      },
    ]);
})();
