(function () {
  'use strict';

  angular
    .module('module.authentication')
    .controller('controller.authentication.browserCheck', [
      '$rootScope',
      'BreadcrumbsService',
      'BrowserCheckService',
      'CompanySubdomainService',
      controller,
    ]);

  function controller(
    $rootScope,
    BreadcrumbsService,
    BrowserCheckService,
    CompanySubdomainService,
  ) {
    /* jshint validthis: true */
    var vm = this;

    BreadcrumbsService.hideBreadcrumbs();
    $rootScope.bodyClass = 'login';

    vm.supportedResolution = BrowserCheckService.isResolutionSupported;
    vm.supportedBrowser = BrowserCheckService.isBrowserSupported;

    CompanySubdomainService.getSubdomainDetails().then(function (details) {
      vm.title = details.company;
      vm.logo = details.logo;
    });
  }
})();
