(function () {
  'use strict';

  angular.module('module.sales').factory('CompaniesService', ['ApiService', service]);

  function service(ApiService) {
    return {
      createCompany: createCompany,
      retrieveCompany: retrieveCompany,
      updateCompany: updateCompany,
      listManufacturers: listManufacturers,
      listSuppliers: listSuppliers,
    };

    /**
     * TODO: refactor
     *
     * @param {string} urlPath
     * @param {Object} companyData
     *
     * @return {$q}
     */
    function createCompany(urlPath, companyData) {
      return ApiService.post('/companies' + urlPath, companyData);
    }

    /**
     * TODO: refactor
     *
     * @param {string} urlPath
     *
     * @return {$q}
     */
    function retrieveCompany(urlPath) {
      return ApiService.get('/companies' + urlPath);
    }

    /**
     * TODO: refactor
     *
     * @param {string} urlPath
     * @param {Object} companyData
     *
     * @return {$q}
     */
    function updateCompany(urlPath, companyData) {
      return ApiService.put('/companies' + urlPath, companyData);
    }

    /**
     * @return {$q}
     */
    function listManufacturers() {
      return listCompanies('manufacturers');
    }

    /**
     * @return {$q}
     */
    function listSuppliers() {
      return listCompanies('suppliers');
    }

    /**
     * @param {string} type
     *
     * @return {$q}
     */
    function listCompanies(type) {
      return ApiService.get('/companies/' + type).then(function (response) {
        return response.data.companies;
      });
    }
  }
})();
