(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.manufacturers.list', ['CompaniesService', controller]);

  function controller(CompaniesService) {
    /* jshint validthis: true */
    var vm = this;

    CompaniesService.listManufacturers().then(function (manufacturers) {
      vm.manufacturers = manufacturers;
    });
  }
})();
