(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuoteName', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/sales.quotes.quoteName.directive.template.html',
        controllerAs: 'nameVm',
        scope: {
          quote: '=',
        },
        controller: ['QuotesService', controller],
        bindToController: true,
      };

      function controller(QuotesService) {
        /* jshint validthis: true */
        var vm = this;

        vm.editDescription = false;
        vm.toggleEditDescription = toggleEditDescription;
        vm.description = vm.quote.metadata.description;

        function toggleEditDescription() {
          var editDescription = document.getElementById('editDescription');
          setTimeout(function () {
            editDescription.focus();
            editDescription.style.border = '1px solid #0067b5';
          }, 10);
          if (vm.editDescription && editDescription.value) {
            vm.quote.metadata.description = editDescription.value;
            QuotesService.updateQuoteDescription(vm.quote, vm.quote.metadata.description);
          }
          vm.editDescription = !vm.editDescription;
        }
      }
    },
  ]);
})();
