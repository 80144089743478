(function () {
  'use strict';

  angular.module('module.sales').filter('quoteSliceStatusIcon', filter);

  var statusIcon = {
    draft: 'fa-unlock',
    /** @deprecated */
    reprice: 'fa-cog rf-spin',
    repricing: 'fa-cog rf-spin',
    needsReprice: 'fa-cog rf-spin',
    finalising: 'fa-lock',
    finalised: 'fa-lock',
    sent: 'fa-lock',
    viewed: 'fa-lock',
    confirmed: 'fa-check',
    // TODO: confirm status icon
    expired: 'fa-hourglass-end',
    pdfFailed: 'fa-exclamation-triangle',
  };

  /**
   * Get status icon for a quote
   *
   * @returns {function}
   */
  function filter() {
    return function (quote) {
      if (quote.metadata.documents.quote.pdfGenerationFailed) {
        return statusIcon.pdfFailed;
      }

      return statusIcon[quote.metadata.status];
    };
  }
})();
