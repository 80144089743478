(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('CustomerQuotesApiService', ['ApiService', 'QuotesService', service]);

  function service(ApiService, QuotesService) {
    return {
      pollList: pollList,
    };

    function pollList(customer) {
      var requestFn = function () {
        return ApiService.get('/customers/' + customer.id + '/quotes');
      };

      var endPollFn = function (quotes) {
        for (var index = 0; index < quotes.length; index++) {
          if (QuotesService.quoteShouldBePolled(quotes[index])) {
            return false;
          }
        }

        return true;
      };

      return ApiService.poll(requestFn, endPollFn);
    }
  }
})();
