(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .filter('matrixLastUpdated', ['$filter', filter]);

  function filter($filter) {
    return function (retailerProduct) {
      if (retailerProduct) {
        if (retailerProduct.matrixLastUpdateAt) {
          var percentage = parseFloat(
              (retailerProduct.matrixLastUpdatePercentage * 100).toFixed(2),
            ),
            percentageSign = percentage > 0 ? '+' : '',
            date = $filter('date')(new Date(retailerProduct.matrixLastUpdateAt), 'd/M/yyyy HH:mm');

          return percentageSign + percentage + '% on ' + date;
        }

        return 'Never';
      }

      return '';
    };
  }
})();
