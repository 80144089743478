(function () {
  'use strict';

  angular.module('angularNumeric', []).factory('numeric', ['$window', service]);

  function service($window) {
    if (typeof $window.numeric === 'undefined') {
      throw Error('numericjs not found');
    }

    return $window.numeric;
  }
})();
