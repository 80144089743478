(function () {
  'use strict';

  angular.module('module.components').component('ttRenamableLabel', {
    templateUrl: 'templates/components.renamableLabel.template.html',
    bindings: {
      default: '=',
      value: '=',
      allowRenaming: '=',
      disabled: '<?',
    },
    replace: true,
    controller: controller,
  });

  function controller() {
    /* jshint validthis: true */
    var vm = this;

    vm.inputId = 'tt-renamable-label__input--' + Math.random().toString().slice(2, 9);
    vm.enableEditing = enableEditing;
    vm.onKeypress = onKeypress;
    vm.onSubmit = onSubmit;

    init();

    function init() {
      if (vm.allowRenaming) {
        if (!vm.value) {
          vm.value = vm.default;
          vm.internalValue = vm.default;
        } else {
          vm.internalValue = vm.value;
        }
      }
    }

    function enableEditing($event) {
      if (vm.allowRenaming && !vm.active && !vm.disabled) {
        if (!vm.active) {
          vm.internalValue = vm.value;
        }

        vm.active = true;

        // Note that just calling .focus() without the setTimeout doesn't work
        window.setTimeout(function () {
          document.getElementById(vm.inputId).focus();
        });

        $event.preventDefault();
      }
    }

    function onKeypress(event, option) {
      if (event.keyCode === 13) {
        event.preventDefault();
        vm.onSubmit();
      }
    }

    function onSubmit($event) {
      vm.active = false;
      vm.value = vm.internalValue;

      if (!vm.internalValue) {
        vm.value = vm.default;
      }

      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
    }
  }
})();
