(function () {
  'use strict';

  angular.module('module.products').directive('ttProductAttributes', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/products.retrieve.attributes.directive.template.html',
        controllerAs: 'attributesVm',
        scope: {
          product: '=',
        },
        controller: ['$scope', 'ProductsService', controller],
        bindToController: true,
      };
    },
  ]);

  /**
   * @param $scope
   * @param ProductsService
   */
  function controller($scope, ProductsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.save = save;
    vm.attributes = [];

    $scope.$watch('attributesVm.product.metadata.active', function (newVal) {
      vm.productActive = newVal;
      angular.copy(vm.product.metadata.fullAttributes, vm.attributes);
    });

    function save() {
      vm.product.metadata.fullAttributes = vm.attributes;
      ProductsService.updateProduct(vm.product);
    }
  }
})();
