(function () {
  'use strict';

  angular.module('module.settings.retailerProducts').component('ttPricingMatrixSlice', {
    templateUrl:
      'templates/settings.retailerProducts.pricingMatrices.slice.component.template.html',
    bindings: {
      matrix: '<',
      matrixType: '<',
      onDelete: '<',
      onAdd: '<',
      styleLabels: '<',
      required: '<',
    },
    controller: ['$window', controller],
  });

  function controller($window) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.fallbackImageUrl = '/images/component_thumb_placeholder.png';
    $ctrl.deleteMatrix = deleteMatrix;

    function deleteMatrix() {
      if (!$window.confirm('Are you sure you want to delete this matrix?')) {
        return;
      }

      if ($ctrl.matrix.uploaded) {
        $ctrl.matrix.queuedForDelete = true;
      }

      if ($ctrl.matrix.queuedForUpload) {
        delete $ctrl.matrix.matrixData;
        $ctrl.matrix.queuedForUpload = false;
      }

      $ctrl.onDelete($ctrl.matrix);
    }
  }
})();
