(function () {
  'use strict';

  angular
    .module('module.profile')
    .controller('controller.profile.apiAccess', [
      'ExternalApiApiService',
      'Notification',
      'dataHasApiKey',
      'dataUserId',
      controller,
    ]);

  function controller(ExternalApiApiService, Notification, dataHasApiKey, dataUserId) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.generateApiKey = generateApiKey;
    $ctrl.notifyCopy = notifyCopy;

    $ctrl.apiKey = null;
    $ctrl.hasApiKey = dataHasApiKey;
    $ctrl.userId = dataUserId;

    function generateApiKey() {
      ExternalApiApiService.generateApiKey().then(function (apiKey) {
        $ctrl.apiKey = apiKey;
      });
    }

    function notifyCopy(name) {
      Notification.success(name + ' copied to clipboard');
    }
  }
})();
