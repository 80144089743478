(function () {
  'use strict';

  angular.module('module.components').component('ttItemPicker', {
    templateUrl: 'templates/components.itemPicker.component.template.html',
    controller: controller,
    bindings: {
      inputItems: '<items',
      onChange: '<',
    },
  });

  function controller() {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.$onInit = onInit;
    $ctrl.$onChanges = onChanges;
    $ctrl.onChangeSelected = onChangeSelected;
    $ctrl.setAllCheckboxes = setAllCheckboxes;
    $ctrl.items = [];

    $ctrl.selectionRequired = true;

    function onInit() {
      updateItems($ctrl.inputItems);
    }

    function onChanges(changes) {
      if (typeof changes.inputItems !== 'undefined' && !changes.inputItems.isFirstChange()) {
        updateItems(changes.inputItems.currentValue);
      }
    }

    function onChangeSelected() {
      var selectedItems = $ctrl.items.reduce(function (selected, item) {
        if (item.selected) {
          selected.push({
            id: item.id,
            name: item.name,
            location: item.location,
            thumbImageUrl: item.thumbImageUrl,
          });
        }

        return selected;
      }, []);

      $ctrl.selectionRequired = selectedItems.length === 0;

      $ctrl.onChange(selectedItems);
    }

    function updateItems(inputItems) {
      var newItems = [];

      inputItems.forEach(function (newItem) {
        var selectedItem = $ctrl.items.some(function (existingItem) {
          return existingItem.id === newItem.id && existingItem.selected;
        });

        newItems.push({
          id: newItem.id,
          name: newItem.name,
          location: newItem.location,
          thumbImageUrl: newItem.thumbImageUrl,
          selected: selectedItem,
        });
      });

      $ctrl.items = newItems;
      onChangeSelected();
    }

    function setAllCheckboxes(bool) {
      $ctrl.items.forEach(function (item) {
        item.selected = bool;
      });

      onChangeSelected();
    }
  }
})();
