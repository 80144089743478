(function () {
  'use strict';

  angular
    .module('module.profile')
    .controller('controller.profile.security', [
      'AuthenticationService',
      'Notification',
      'dataProfile',
      controller,
    ]);

  function controller(AuthenticationService, Notification, dataProfile) {
    /* jshint validthis: true */
    var vm = this;
    var strengthBarElement = angular.element('.password-strength-bar');
    var strengthTextElement = angular.element('.password-strength-text');
    var checkSamePassword = angular.element('.samePassword');
    var checkInCurrectPassword = angular.element('.inCurrectPassword');

    var zxcvbn = window.zxcvbn;

    vm.user = dataProfile;
    vm.savePassword = savePassword;
    vm.checkPassword = checkPassword;
    vm.updatePasswordStrengthBar = updatePasswordStrengthBar;
    vm.passwordStrength = 0;

    function savePassword() {
      if (vm.isSubmitting) {
        return;
      }
      vm.isSubmitting = true;
      var data = {
        newPassword: vm.newPassword,
        oldPassword: vm.currentPassword,
      };

      AuthenticationService.updatePassword(data)
        .then(function (response) {
          checkInCurrectPassword.addClass('ng-hide');
          Notification.success('Password updated successfully');
          vm.currentPassword = vm.newPassword = vm.repeatPassword = '';
        })
        .catch(function (error) {
          if (error.status === 400) {
            checkInCurrectPassword.removeClass('ng-hide');
          } else {
            Notification.error(
              'Error updating password: ' +
                (error.data && error.data.message ? error.data.message : 'Unknown error'),
            );
          }
        })
        .finally(function () {
          vm.isSubmitting = false;
        });
    }

    function checkPassword() {
      if (vm.newPassword && vm.currentPassword && vm.newPassword === vm.currentPassword) {
        checkSamePassword.removeClass('ng-hide');
      } else {
        checkSamePassword.addClass('ng-hide');
      }
    }

    function updatePasswordStrengthBar() {
      if (vm.newPassword) {
        vm.passwordStrength = zxcvbn(vm.newPassword);
        switch (vm.passwordStrength.score) {
          case 0:
            // Very weak
            strengthBarElement
              .removeClass('password-strength-bar-medium password-strength-bar-strong')
              .addClass('password-strength-bar-weak');
            strengthTextElement.text('Very Weak').css('color', 'red');
            break;
          case 1:
            // Weak
            strengthBarElement
              .removeClass('password-strength-bar-medium password-strength-bar-strong')
              .addClass('password-strength-bar-weak');
            strengthTextElement.text('Weak').css('color', 'red');
            break;
          case 2:
            // Medium
            strengthBarElement
              .removeClass('password-strength-bar-weak password-strength-bar-strong')
              .addClass('password-strength-bar-medium');
            strengthTextElement.text('Medium').css('color', '#af6700');
            break;
          case 3:
            // Strong
            strengthBarElement
              .removeClass('password-strength-bar-weak password-strength-bar-medium')
              .addClass('password-strength-bar-strong');
            strengthTextElement.text('Strong').css('color', '#27ae60');
            break;
          case 4:
            // Very strong
            strengthBarElement
              .removeClass('password-strength-bar-weak password-strength-bar-medium')
              .addClass('password-strength-bar-strong');
            strengthTextElement.text('Very Strong').css('color', '#27ae60');
            break;
          default:
            // Handle unexpected score
            strengthBarElement.removeClass(
              'password-strength-bar-medium password-strength-bar-strong',
            );
            strengthTextElement.text('').css('color', '');
            break;
        }
      }
    }
  }
})();
