(function () {
  'use strict';

  angular.module('module.visualiser').factory('CompositorScalingService', [service]);

  function service() {
    var containerSize,
      imageSize,
      scalingFactor = 1,
      scaleChangeEventListeners = [];

    return {
      setImageSize: setImageSize,
      setContainerSize: setContainerSize,
      scale: scale,
      reverseScale: reverseScale,
      addScaleChangeEventListener: addScaleChangeEventListener,
      removeScaleChangeEventListener: removeScaleChangeEventListener,
    };

    /**
     * Set the size of the image to scale
     *
     * @param {int} width
     * @param {int} height
     */
    function setImageSize(width, height) {
      imageSize = {
        width: width,
        height: height,
      };

      updateScalingFactor();
    }

    /**
     * Sets the size of the target container
     *
     * @param {int} width
     * @param {int} height
     */
    function setContainerSize(width, height) {
      containerSize = {
        width: width,
        height: height,
      };

      updateScalingFactor();
    }

    /**
     * Scale a number
     *
     * @param {number} num
     * @param {float?} factor
     * @returns {number}
     */
    function scale(num, factor) {
      factor = factor || scalingFactor;

      return num * factor;
    }

    /**
     * Reverse scale a number
     *
     * @param {number} num
     * @param {float?} factor
     * @returns {number}
     */
    function reverseScale(num, factor) {
      factor = factor || scalingFactor;

      return num / factor;
    }

    /**
     * Updates the scaling factor based on given dimensions
     */
    function updateScalingFactor() {
      // skip processing if still setting up
      if (typeof imageSize === 'undefined' || typeof containerSize === 'undefined') {
        return;
      }

      var oldScalingFactor = scalingFactor,
        photoBankRatio = getRatio(imageSize),
        viewportRatio = getRatio(containerSize);

      scalingFactor =
        viewportRatio > photoBankRatio
          ? containerSize.height / imageSize.height
          : containerSize.width / imageSize.width;

      // scaling factor must not be above 1
      if (scalingFactor > 1) {
        scalingFactor = 1;
      }

      if (scalingFactor !== oldScalingFactor) {
        notifyScaleChangeEventListeners(scalingFactor, oldScalingFactor);
      }
    }

    /**
     * Get ratio given dimensions
     *
     * @param dimensions
     *
     * @returns {number}
     */
    function getRatio(dimensions) {
      return dimensions.width / dimensions.height;
    }

    /**
     * Call scale change event listeners
     */
    function notifyScaleChangeEventListeners(scalingFactor, oldScalingFactor) {
      angular.forEach(scaleChangeEventListeners, function (cb) {
        cb(scalingFactor, oldScalingFactor);
      });
    }

    /**
     * Add a scale change event listener
     *
     * @param {function} cb
     */
    function addScaleChangeEventListener(cb) {
      scaleChangeEventListeners.push(cb);
    }

    /**
     * Remove a scale change event listener
     *
     * @param {function} cb
     */
    function removeScaleChangeEventListener(cb) {
      var index = scaleChangeEventListeners.indexOf(cb);

      if (index !== -1) {
        scaleChangeEventListeners.splice(index, 1);
      }
    }
  }
})();
