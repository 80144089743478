(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.orders.list', [
      '$rootScope',
      'BreadcrumbsService',
      'dataOrders',
      'OrderPartApiService',
      controller,
    ]);

  function controller($rootScope, BreadcrumbsService, dataOrders, OrderPartApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.changePage = changePage;
    $ctrl.noOrders = !dataOrders.orders.length;
    $ctrl.orderParts = dataOrders.orders;

    $ctrl.pagination = {
      currentPage: 1,
      totalPages: dataOrders.meta.totalPages,
    };

    BreadcrumbsService.hideBreadcrumbs();
    $rootScope.bodyClass = 'customer__list';

    function getOrders(page) {
      OrderPartApiService.listOrders(page).then(function (orderParts) {
        $ctrl.pagination.currentPage = page;
        $ctrl.pagination.totalPages = orderParts.meta.totalPages;
        $ctrl.orderParts = orderParts.orders;
      });
    }

    function changePage(page) {
      getOrders(page);
    }
  }
})();
