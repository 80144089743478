(function () {
  'use strict';

  angular
    .module('module.retailers')
    .factory('RetailerPricingApiService', ['$q', 'ApiService', 'Notification', service]);

  function service($q, ApiService, Notification) {
    return {
      enable: enable,
      disable: disable,
    };

    function enable(retailer) {
      return update(retailer, true);
    }

    function disable(retailer) {
      return update(retailer, false);
    }

    function update(retailer, enable) {
      var uri =
        '/companies/retailers/' +
        retailer.id +
        '/retailerPricing/' +
        (enable ? 'enable' : 'disable');

      return ApiService.put(uri).then(
        function () {
          Notification.success('Retailer pricing ' + (enable ? 'enabled' : 'disabled'));
        },
        function () {
          Notification.error('Failed to update account');

          return $q.reject();
        },
      );
    }
  }
})();
