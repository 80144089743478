(function () {
  'use strict';

  angular.module('module.messages', ['ui-notification']).config(['NotificationProvider', config]);

  function config(NotificationProvider) {
    NotificationProvider.setOptions({
      delay: 5000,
    });
  }
})();
