(function () {
  'use strict';

  var hasCardStatus = ['success', 'pending', 'auth_required', 'replace_card'];

  angular.module('module.settings.billingAndPayments').component('ttPaymentMethod', {
    templateUrl:
      'templates/settings.billingAndPayments.billing.paymentMethod.component.template.html',
    bindings: {
      paymentMethod: '<',
    },
    controller: ['$scope', 'AuthenticationService', 'StripeApiService', controller],
  });

  function controller($scope, AuthenticationService, StripeApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    init();
    $ctrl.isRetailer = AuthenticationService.isRetailer();

    $ctrl.cardUpdated = cardUpdated;
    $ctrl.replaceCard = replaceCard;
    $ctrl.authCard = authCard;

    function cardUpdated() {
      $ctrl.showCardForm = false;
      $ctrl.card = null;
      $ctrl.cardStatus = 'pending';

      StripeApiService.removePaymentMethod($ctrl.paymentMethod.paymentMethodId).then(
        pollForStatusUpdate,
      );
    }

    function replaceCard() {
      $ctrl.showCardForm = true;
    }

    function authCard() {
      $ctrl.showCardForm = false;

      StripeApiService.authCard(
        $ctrl.paymentMethod.failedIntentSecret,
        $ctrl.paymentMethod.paymentMethodId,
      )
        .then(function () {
          $ctrl.cardStatus = 'success';
          $scope.$digest();
        })
        .catch(function () {
          $ctrl.cardStatus = 'pending';
          $scope.$digest();
          pollForStatusUpdate();
        });
    }

    function init() {
      $ctrl.card = $ctrl.paymentMethod.card;
      $ctrl.hasCard = hasCardStatus.includes($ctrl.paymentMethod.status);
      $ctrl.showCardForm = !$ctrl.hasCard || $ctrl.paymentMethod.status === 'replace_card';
      $ctrl.cardStatus = $ctrl.paymentMethod.status;

      if ($ctrl.cardStatus === 'pending') {
        pollForStatusUpdate();
      }
    }

    function pollForStatusUpdate() {
      StripeApiService.pollPaymentMethodForUpdate($scope).then(function (paymentMethod) {
        $ctrl.paymentMethod = paymentMethod;
        init();
      });
    }
  }
})();
