(function () {
  'use strict';

  angular
    .module('module.products')
    .factory('ComponentsService', [
      '$filter',
      '$q',
      'Notification',
      'ApiService',
      'CompaniesService',
      'TypesService',
      service,
    ]);

  /**
   * @param $filter
   * @param $q
   * @param Notification
   * @param ApiService
   * @param CompaniesService
   * @param TypesService
   * @returns {{initComponent: initComponent, getFilterData: getFilterData, listComponents: listComponents, createComponent: createComponent, retrieveComponent: retrieveComponent, updateComponent: updateComponent, activateComponent: activateComponent, bulkUpdateComponentsProducts: bulkUpdateComponentsProducts, bulkListComponentsPricing: bulkListComponentsPricing, bulkUpdateComponentsPricing: bulkUpdateComponentsPricing}}
   */
  function service($filter, $q, Notification, ApiService, CompaniesService, TypesService) {
    return {
      initComponent: initComponent,
      getFilterData: getFilterData,
      listComponents: listComponents,
      createComponent: createComponent,
      retrieveComponent: retrieveComponent,
      updateComponent: updateComponent,
      activateComponent: activateComponent,
      bulkUpdateComponentsProducts: bulkUpdateComponentsProducts,
      bulkListComponentsPricing: bulkListComponentsPricing,
      bulkUpdateComponentsPricing: bulkUpdateComponentsPricing,
    };

    /**
     * @returns {object}
     */
    function initComponent() {
      return {
        metadata: {
          componentType: {},
        },
      };
    }

    /**
     * TODO: remove when refactoring pagination
     *
     * @returns {$Q}
     */
    function getFilterData() {
      var promises = [TypesService.List('components'), CompaniesService.listSuppliers()];

      return $q(function (resolve, reject) {
        $q.all(promises).then(function (data) {
          var suppliers = [];

          for (var i = data[1].length - 1; i >= 0; i--) {
            if (data[1][i].productCount.components !== 0) {
              suppliers.push({
                id: data[1][i].id,
                name: data[1][i].metadata.name,
              });
            }
          }

          var filterData = {
            componentTypes: data[0].flat,
            suppliers: $filter('orderBy')(suppliers, 'name'),
          };

          resolve(filterData);
        });
      });
    }

    /**
     * TODO: refactor when fixing pagination
     *
     * @param {object} listParameters
     * @returns {$Q}
     */
    function listComponents(listParameters) {
      var parametersReference = [
        {
          name: 'limit',
          value: 25,
        },
      ];

      var parametersString;

      if (listParameters !== undefined) {
        if (listParameters.pageId !== undefined) {
          parametersReference.push({name: 'page', value: listParameters.pageId});
        }
        if (listParameters.filterSupplierId !== undefined) {
          parametersReference.push({
            name: 'companySupplier',
            value: listParameters.filterSupplierId,
          });
        }
        if (listParameters.filterComponentType !== undefined) {
          parametersReference.push({
            name: 'componentType',
            value: listParameters.filterComponentType,
          });
        }
        if (listParameters.searchString !== undefined && listParameters.searchString !== 'all') {
          parametersReference.push({name: 'q', value: listParameters.searchString});
        }
      } else {
        listParameters = {};
      }

      var parameters = [];

      for (var i = parametersReference.length - 1; i >= 0; i--) {
        var elem = parametersReference[i];
        if (elem.value !== '0' && elem.value !== 'all') {
          parameters.push(elem.name + '=' + elem.value);
        }
      }

      parametersString = parameters.join('&');

      var url = '/components?' + parametersString;

      var promises = [ApiService.get(url)];

      return $q(function (resolve, reject) {
        $q.all(promises).then(function (data) {
          var components = data[0].data.components,
            links = data[0].data.links,
            meta = data[0].data.meta,
            pagination = {
              pageItemsStart: meta.totalItems > 0 ? (meta.page - 1) * meta.perPage + 1 : 0,
              pageItemsEnd: (meta.page - 1) * meta.perPage + meta.perPage,
              pageItemsTotal: meta.totalItems,
              next: null,
              prev: null,
            },
            i,
            prevStr,
            nextStr,
            nextArray,
            parametersReference,
            parameter;

          if (links.prev !== null) {
            prevStr = links.prev.substr(links.prev.indexOf('?'));
            nextArray = prevStr.substr(1).split('&');

            parametersReference = [];

            for (i = nextArray.length - 1; i >= 0; i--) {
              parameter = nextArray[i].split('=');
              parametersReference[parameter[0]] = parameter[1];
            }

            prevStr = '/components/';
            prevStr += parametersReference.q !== undefined ? parametersReference.q : 'all';
            prevStr += '/';
            prevStr +=
              parametersReference.componentType !== undefined
                ? parametersReference.componentType
                : '0';
            prevStr += '/';
            prevStr +=
              parametersReference.companySupplier !== undefined
                ? parametersReference.companySupplier
                : '0';
            prevStr += '/';
            prevStr +=
              parametersReference.page !== undefined ? parametersReference.page.toString() : '0';

            pagination.prev = prevStr;
          }

          if (links.next !== null) {
            nextStr = links.next.substr(links.next.indexOf('?'));
            nextArray = nextStr.substr(1).split('&');

            parametersReference = [];

            for (i = nextArray.length - 1; i >= 0; i--) {
              parameter = nextArray[i].split('=');
              parametersReference[parameter[0]] = parameter[1];
            }

            nextStr = '/components/';
            nextStr += parametersReference.q !== undefined ? parametersReference.q : 'all';
            nextStr += '/';
            nextStr +=
              parametersReference.componentType !== undefined
                ? parametersReference.componentType
                : '0';
            nextStr += '/';
            nextStr +=
              parametersReference.companySupplier !== undefined
                ? parametersReference.companySupplier
                : '0';
            nextStr += '/';
            nextStr +=
              parametersReference.page !== undefined ? parametersReference.page.toString() : '0';

            pagination.next = nextStr;
          }

          if (pagination.pageItemsEnd > pagination.pageItemsTotal) {
            pagination.pageItemsEnd = pagination.pageItemsTotal;
          }

          resolve({
            components: components,
            pagination: pagination,
            search: {
              string:
                listParameters.searchString !== undefined && listParameters.searchString !== 'all'
                  ? listParameters.searchString
                  : '',
              filter: {
                componentType:
                  listParameters.filterComponentType !== undefined
                    ? parseInt(listParameters.filterComponentType)
                    : 0,
                supplier:
                  listParameters.filterSupplierId !== undefined
                    ? listParameters.filterSupplierId
                    : '0',
              },
            },
          });
        });
      });
    }

    /**
     * @param {object} component
     * @returns {$Q}
     */
    function createComponent(component) {
      return ApiService.post('/components', component).then(
        function (response) {
          Notification.success('Created component');

          return response.data.component;
        },
        function () {
          Notification.error('Failed to create component');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {string} componentId
     * @returns {$Q}
     */
    function retrieveComponent(componentId) {
      return $q
        .all({
          componentResponse: ApiService.get('/components/' + componentId),
          componentTypes: TypesService.List('components'),
        })
        .then(function (data) {
          return processComponent(data.componentResponse.data.component, data.componentTypes);
        });
    }

    /**
     * @param {object} component
     * @param {boolean=} blockNotification
     * @returns {$Q}
     */
    function updateComponent(component, blockNotification) {
      blockNotification = blockNotification || false;

      return ApiService.put('/components/' + component.id, component).then(
        function () {
          if (!blockNotification) {
            Notification.success('Updated component');
          }
        },
        function () {
          if (!blockNotification) {
            Notification.error('Failed to update component');
          }

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {object} component
     * @returns {$Q}
     */
    function activateComponent(component) {
      component.metadata.active = true;

      return updateComponent(component, true).then(
        function () {
          Notification.success('Activated component');
        },
        function () {
          Notification.error('Failed to activate component');

          return $q.reject(null);
        },
      );
    }

    /**
     * TODO: check that this is in use
     *
     * @param {object} componentProducts
     * @returns {$Q}
     */
    function bulkUpdateComponentsProducts(componentProducts) {
      return ApiService.put('/components/products', componentProducts);
    }

    /**
     * TODO: check that this is in use, endpoint doesn't exist
     *
     * @returns {$Q}
     */
    function bulkListComponentsPricing() {
      return ApiService.get('/components/pricing');
    }

    /**
     * TODO: check that this is in use, endpoint doesn't exist
     *
     * @param {object} componentPricing
     * @returns {$Q}
     */
    function bulkUpdateComponentsPricing(componentPricing) {
      return ApiService.put('/components/pricing', componentPricing);
    }

    /**
     * TODO: most of this should be a directive
     *
     * @param {object} component
     * @param {object} componentTypes
     * @returns {object}
     */
    function processComponent(component, componentTypes) {
      for (var i = componentTypes.flat.length - 1; i >= 0; i--) {
        if (component.metadata.componentType.id === componentTypes.flat[i].id) {
          component.metadata.componentType.pathDescription = componentTypes.flat[i].pathDescription;
        }
      }

      return component;
    }
  }
})();
