(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.profileSuites.list', [
      '$scope',
      '$state',
      'ProfileSuitesService',
      'dataProfileSuites',
      function ($scope, $state, ProfileSuitesService, dataProfileSuites) {
        $scope.profilesuites = dataProfileSuites.profilesuites;
        $scope.pagination = dataProfileSuites.pagination;

        $scope.update = function () {
          ProfileSuitesService.UpdateProfileSuite(
            $scope.profilesuite.id,
            $scope.profilesuite,
          ).success(function () {
            $state.reload();
          });
        };

        $scope.activate = function () {
          ProfileSuitesService.ActivateProfileSuite(
            $scope.profilesuite.id,
            $scope.profilesuite,
          ).success(function () {
            $state.reload();
          });
        };
      },
    ]);
})();
