(function () {
  'use strict';

  angular.module('module.users').component('ttUserSlice', {
    templateUrl: 'templates/users.userSlice.component.template.html',
    controller: ['UsersService', controller],
    bindings: {
      user: '<',
    },
  });

  function controller(UsersService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.resendInvite = resendInvite;
    function resendInvite() {
      UsersService.resendInvite($ctrl.user);
    }
  }
})();
