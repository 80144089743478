(function () {
  'use strict';

  angular.module('module.sales').directive('ttRetailerDiscountGroups', directive);

  /**
   * Init directive
   */
  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/sales.retailer.discountGroups.directive.template.html',
      controllerAs: 'discountGroupsVm',
      scope: {
        retailerSettings: '=',
      },
      bindToController: true,
      controller: controller,
    };
  }

  function controller() {
    /* jshint validthis: true */
    var vm = this;

    vm.discountGroupsEnabled = checkForDiscounts(vm.retailerSettings.componentDiscountGroups);
    vm.resetDiscounts = resetDiscounts;
  }

  /**
   * Set all discount groups to 0, e.g. when unticking the checkbox
   *
   * @param {object} products
   */
  function resetDiscounts(discountGroups) {
    angular.forEach(discountGroups, function (discountGroup) {
      discountGroup.discount = 0;
    });
  }

  /**
   * Do any of the discoutn groups have a discount set?
   *
   * @param {object} discountGroups
   * @returns {boolean}
   */
  function checkForDiscounts(discountGroups) {
    var discountFound = false;

    angular.forEach(discountGroups, function (discountGroup) {
      if (discountGroup.discount > 0) {
        discountFound = true;
      }
    });

    return discountFound;
  }
})();
