(function () {
  'use strict';

  angular.module('angular-pca').directive('capturePlus', [
    'pca',
    '$log',
    'PCA_API_KEY',
    function (pca, $log, PCA_API_KEY) {
      return {
        restrict: 'A',
        scope: {
          capturePlusPopulate: '=?',
          capturePlusOptions: '<?',
          capturePlusManualFunc: '&?',
          capturePlusPopulateFunc: '&?',
        },
        link: link,
      };

      function link(scope, element, attrs) {
        var fields = [{element: attrs.name, field: '', mode: pca.fieldMode.SEARCH}],
          options = scope.capturePlusOptions || {};

        if (typeof PCA_API_KEY === 'undefined') {
          throw new Error('PCA API key not set');
        }
        options.key = PCA_API_KEY;

        var control = new pca.Address(fields, options);

        control.listen('populate', function (data) {
          scope.$apply(function () {
            scope.capturePlusPopulate = data;
            if (typeof scope.capturePlusPopulateFunc === 'function') {
              scope.capturePlusPopulateFunc()(data);
            }
            element.val('');
          });
        });

        if (typeof scope.capturePlusManualFunc === 'function') {
          control.listen('manual', function () {
            scope.$apply(function () {
              scope.capturePlusManualFunc()();
            });
          });
        }
      }
    },
  ]);
})();
