(function () {
  'use strict';

  angular.module('module.api').factory('ApiVersionInterceptor', ['$rootScope', service]);

  var apiVersion = {
    tag: null,
    sha: null,
  };

  function service($rootScope) {
    return {
      response: onResponse,
    };

    function onResponse(response) {
      var tag = response.headers('tommy-trinder-api-version-tag') || apiVersion.tag,
        sha = response.headers('tommy-trinder-api-version-sha') || apiVersion.sha;

      if (tag !== apiVersion.tag || sha !== apiVersion.sha) {
        apiVersion.sha = sha;
        apiVersion.tag = tag;

        $rootScope.$emit('api-version-changed', apiVersion);
      }

      return response;
    }
  }
})();
