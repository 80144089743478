(function () {
  'use strict';

  angular.module('module.sales').filter('userSelectName', filter);

  /**
   * Pluralise and add 'Customers' to a users name
   *
   * @returns {function}
   */
  function filter() {
    return function (user) {
      // do nothing if not passed a user
      if (typeof user === 'undefined') {
        return;
      }

      // all user doesn't need pluralised
      if (user.id === 'all') {
        return user.name;
      }

      return pluraliseName(user.name) + ' Customers';
    };
  }

  /**
   * Pluralise a name
   *
   * @param {string} name
   * @returns {string}
   */
  function pluraliseName(name) {
    return name + (name.substr(-1, 1) === 's' ? "'" : "'s");
  }
})();
