(function () {
  'use strict';

  angular.module('module.api').factory('ApiUrlService', ['$location', service]);

  function service($location) {
    var apiUrl,
      apiVersion = 'v1';

    return {
      getApiUrl: getApiUrl,
      getBaseDomain: getBaseDomain,
    };

    /**
     * Get API url, optionally with an appended path
     *
     * @returns {string}
     */
    function getApiUrl(path) {
      if (!apiUrl) {
        apiUrl = buildApiUrl();
      }

      return apiUrl + path;
    }

    /**
     * Build API url string
     * @returns {string}
     */
    function buildApiUrl() {
      var url;

      switch (getBaseDomain()) {
        case 'tommytrinder.com':
          url = 'https://api.tommytrinder.com';
          break;
        case 'tommytrinder.net':
          url = 'https://api.tommytrinder.net';
          break;
        case 'tommytrindertest.com':
          url = 'https://api.tommytrindertest.com';
          break;
        case 'tommytrinderdev.com':
          url = 'https://api.tommytrinderdev.com';
          break;
        case 'tommytrinderdata.com':
          url = 'https://api.tommytrinderdata.com';
          break;
        case 'tommytrinder.local':
          url = 'http://api.tommytrinder.local';
      }

      return url + '/' + apiVersion;
    }

    function getBaseDomain() {
      return $location.host().split('.').slice(-2).join('.');
    }
  }
})();
