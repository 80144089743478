(function () {
  'use strict';

  angular.module('module.sales').directive('ttContractReportSlice', function () {
    return {
      templateUrl: 'templates/sales.quotes.contractReport.slice.directive.template.html',
      restrict: 'A',
      controller: ['Notification', controller],
      controllerAs: '$ctrl',
      bindToController: true,
      scope: {
        contractReport: '<',
        status: '<',
      },
    };
  });

  function controller(Notification) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.copyLinkAction = copyLinkAction;

    function copyLinkAction() {
      Notification.success('Link copied to clipboard');
    }
  }
})();
