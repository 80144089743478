(function () {
  'use strict';

  angular.module('module.sales').filter('commissionStatus', ['$filter', filter]);

  var statusText = {
    new: 'Processing',
    successful: 'Accepted',
    failed: 'Issue processing payment',
    notProcessed: 'Not yet due',
  };

  /**
   * Return text version of commission status
   *
   * @returns {function}
   */
  function filter($filter) {
    return function (commission) {
      if (!angular.isDefined(statusText[commission.status])) {
        return 'Unknown';
      }

      if ('successful' === commission.status) {
        return statusText.successful + ' ' + $filter('date')(commission.processedTime, 'medium');
      }

      return statusText[commission.status];
    };
  }
})();
