(function () {
  'use strict';
  angular
    .module('module.sales')
    .controller('controller.sales.customers.modal', [
      '$element',
      '$state',
      '$rootScope',
      'CustomersService',
      'SettingsService',
      'Notification',
      'customer',
      'close',
      controller,
    ]);
  function controller(
    $element,
    $state,
    $rootScope,
    CustomersService,
    SettingsService,
    Notification,
    customer,
    close,
  ) {
    /* jshint validthis: true */
    var vm = this;
    vm.save = save;
    vm.saving = false;
    vm.closeModal = closeModal;
    vm.isUpdate = typeof customer !== 'undefined';
    vm.customer = angular.copy(customer);
    SettingsService.getSettings().then(function (settings) {
      vm.locale = settings.metadata.locale;
      vm.defaultPaymentTerms = settings.settings.paymentTerms;
      vm.defaultPaymentStages = settings.settings.paymentStages;
      if (!vm.isUpdate) {
        vm.customer = {
          metadata: {
            paymentTerms: vm.defaultPaymentTerms,
            paymentStages: vm.defaultPaymentStages,
          },
        };
      }
    });
    /**
     * Update or create customer
     */
    function save() {
      vm.saving = true;
      if (vm.customer.metadata.paymentStages.length) {
        var total = 0;
        vm.customer.metadata.paymentStages.forEach(function (stage) {
          total += stage.percentage;
        });
        if (total !== 100) {
          Notification.error('Stage payments must add up to 100%');
          return;
        }
      }
      var stages = {paymentStages: vm.customer.metadata.paymentStages};
      if (vm.isUpdate) {
        CustomersService.updateCustomerPaymentStages(vm.customer, stages, false).then(function () {
          CustomersService.updateCustomer(vm.customer).then(function (savedCustomer) {
            angular.extend(customer, savedCustomer);
            $rootScope.activeTab = 'Contact';
            closeModal(true);
          });
        });
        return;
      }
      // Close the modal and go to the new customer even if the stage payments
      // fail to save. Notify on success or failure of customer creation and on
      // stage payment failure
      CustomersService.createCustomer(vm.customer)
        .then(function (savedCustomer) {
          CustomersService.updateCustomerPaymentStages(savedCustomer, stages, false).finally(
            function () {
              closeModal(true);
              $state.go('sales.customers.retrieve', {customerId: savedCustomer.id});
            },
          );
        })
        .catch(function (error) {
          vm.saving = false;
        });
    }
    /**
     * Close and properly unload the modal
     */
    function closeModal(update) {
      $element.parent().find('#customerModal').modal('hide');
      close(update);
    }
  }
})();
