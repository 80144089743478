(function () {
  'use strict';

  angular.module('module.help').controller('controller.help.howTo', ['$rootScope', controller]);

  function controller($rootScope) {
    /* jshint validthis: true */
    var vm = this;
    $rootScope.bodyClass = 'page__video';
    vm.activeTab = 'designing';

    vm.setTab = function (tab) {
      var videoContent = document.querySelector('.video__content');
      if (videoContent) {
        videoContent.scrollTop = 0;
      }
      vm.activeTab = tab;
    };
  }
})();
