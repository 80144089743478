(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuoteFinalise', [
    function () {
      return {
        restrict: 'A',
        scope: {
          quote: '<',
          action: '@',
        },
        controller: [
          '$q',
          '$window',
          '$rootScope',
          'ModalService',
          'QuoteFinalisationService',
          controller,
        ],
        bindToController: true,
        link: link,
      };
    },
  ]);

  /**
   * @param $q
   * @param ModalService
   * @param QuoteFinalisationService
   */
  function controller($q, $window, $rootScope, ModalService, QuoteFinalisationService) {
    /* jshint validthis: true */
    var vm = this;

    vm.onClick = onClick;

    /**
     * Show modal or trigger action
     */
    function onClick() {
      if (vm.action === 'email') {
        const message =
          vm.quote.metadata.status === 'draft'
            ? 'This will finalise the Quote and then send an email to your customer containing a link to the Quote PDF'
            : 'This will send an email to your customer containing a link to the Quote PDF';

        if ($window.confirm(message)) {
          QuoteFinalisationService.checkAction(vm.action);
          $rootScope.activeTab = 'Documents';
        } else {
          return false;
        }
      }

      if (skipModal(vm.quote, vm.action)) {
        doAction(vm.action, vm.quote);

        return;
      }

      showModal(vm.action, vm.quote);
    }

    /**
     * Show the finalise modal
     *
     * @param action
     * @param quote
     */
    function showModal(action, quote) {
      ModalService.showModal({
        controller: 'controller.sales.quotes.finalise.modal',
        templateUrl: 'templates/sales.quotes.finalise.modal.template.html',
        controllerAs: 'modalVm',
        inputs: {
          quote: quote,
          action: action,
        },
      }).then(function (modal) {
        // open modal
        var modalElement = modal.element.parent().find('#finalise-modal');
        modalElement.modal();

        // on close clean up modal and trigger action if required
        modal.close.then(function (options) {
          // modalElement is already removed from the DOM here so can't be used
          if (options.doAction) {
            doAction(action, quote);
          }
        });
      });
    }

    /**
     * Do a post-finalisation action
     *
     * @param action
     * @param quote
     */
    function doAction(action, quote) {
      switch (action) {
        case 'download':
          QuoteFinalisationService.doDownload(quote);
          break;
        case 'print':
          QuoteFinalisationService.doPrint(quote);
      }
    }

    /**
     * Determine if modal should be skipped
     *
     * @param {object} quote
     * @param {string} action
     * @returns {boolean}
     */
    function skipModal(quote, action) {
      // always show modal if not yet finalised
      if (!QuoteFinalisationService.isFinalised(quote)) {
        return false;
      }

      // always show modal if email
      if (action === 'email') {
        return false;
      }

      return true;
    }
  }

  /**
   * @param scope
   * @param element
   * @param attrs
   * @param controller
   */
  function link(scope, element, attrs, controller) {
    element.on('click', function (event) {
      event.preventDefault();
      controller.onClick();
    });
  }
})();
