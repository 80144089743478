(function () {
  'use strict';

  angular
    .module('module.sales')
    .filter('quoteSliceStatusDescription', ['moment', filter])
    .filter('archiveSliceStatus', ['moment', archiveFilter]);

  /**
   * Generate a quote status description for a given quote
   *
   * @param moment
   * @returns {function}
   */
  function filter(moment) {
    return function (quote) {
      if (quote.metadata.documents.quote.pdfGenerationFailed) {
        return 'Quote PDF generation failed';
      }

      switch (quote.metadata.status) {
        case 'draft':
          return 'Edited ' + moment(quote.metadata.lastUpdated - 1000).fromNow();
        case 'needsReprice':
        case 'repricing':
        /** @deprecated */
        case 'reprice':
          return 'Edited ' + moment(quote.metadata.lastUpdated - 1000).fromNow();
        case 'finalising':
          return 'Quote PDF is being created';
        case 'finalised':
          // TODO: Add date PDF created to API output
          return 'Quote PDF created ' + moment(quote.metadata.quoteFinalised - 1000).fromNow();
        case 'sent':
          return 'Quote sent to customer ' + moment(quote.metadata.quoteSent - 1000).fromNow();
        case 'viewed':
          // TODO: Add date PDF opened to API output
          return 'Quote opened';
        case 'confirmed':
          return 'Quote confirmed ' + moment(quote.metadata.quoteConfirmed - 1000).fromNow();
        case 'expired':
          // TODO: confirm copy
          return 'Quote has expired';
      }

      return 'Unknown quote status';
    };
  }
  function archiveFilter(moment) {
    return function (quote) {
      if (quote.metadata.archived) {
        return 'Archived ' + moment(quote.metadata.lastArchivedAt - 1000).fromNow();
      }
    };
  }
})();
