(function () {
  'use strict';

  angular.module('module.dashboard').component('ttStatsOrders', {
    templateUrl: 'templates/dashboard.stats.orders.component.template.html',
    controller: ['StatsApiService', controller],
    bindings: {
      load: '<',
    },
  });

  function controller(StatsApiService) {
    /* jshint validthis: true */
    const $ctrl = this;

    $ctrl.failed = false;
    $ctrl.orders = false;

    $ctrl.$onChanges = (changes) => {
      if (changes.load.currentValue === true && $ctrl.orders === false) {
        StatsApiService.getRealTimeOrders().then(
          (orders) => ($ctrl.orders = orders),
          () => ($ctrl.failed = true),
        );
      }
    };
  }
})();
