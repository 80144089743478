(function () {
  'use strict';

  angular.module('module.freshchat').directive('ttFreshchatToggle', ['Freshchat', directive]);

  function directive(Freshchat) {
    var widget = Freshchat.getWidget();

    return {
      restrict: 'A',
      scope: {
        toggle: '<?',
      },
      link: link,
    };

    /**
     * @param {object} scope
     * @param {object} element
     * @param {Attributes} attrs
     */
    function link(scope, element, attrs) {
      var toggle = 'false' !== attrs.toggle;

      element.on('click', function () {
        if (widget.isOpen()) {
          if (toggle) {
            widget.close();
          }
        } else {
          widget.open();
        }
      });
    }
  }
})();
