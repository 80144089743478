(function () {
  'use strict';

  angular.module('module.profile.team').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider

        .state('profile.team', {
          url: '/team',
          controller: 'controller.profile.team.list',
          controllerAs: 'teamVm',
          templateUrl: 'templates/profile.team.list.template.html',
          resolve: {
            dataTeam: [
              'UsersService',
              function (UsersService) {
                return UsersService.listUsers();
              },
            ],
          },
          ncyBreadcrumb: {
            label: 'My Team',
          },
          data: {
            meta: {
              titleSuffix: '| Profile | My Team',
            },
          },
        })

        .state('profile.team.member', {
          url: '/member/:userId',
          controller: 'controller.profile.team.member',
          controllerAs: 'teamMemberVm',
          templateUrl: 'templates/profile.team.member.template.html',
          resolve: {
            dataProfile: [
              '$stateParams',
              'UsersService',
              function ($stateParams, UsersService) {
                return UsersService.retrieveUser($stateParams.userId);
              },
            ],
          },
          ncyBreadcrumb: {
            label: '{{user.metadata.name}}',
          },
          data: {
            meta: {
              titleSuffix: '| Profile | Team | Member',
            },
          },
        });
    },
  ]);
})();
