(function () {
  'use strict';

  angular.module('module.dashboard').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider

        .state('dashboard', {
          url: '/',
          views: {
            'navigation@': {
              controller: 'controller.navigation',
              templateUrl: 'templates/navigation.template.html',
              resolve: {
                dataUser: [
                  'AuthenticationService',
                  function (AuthenticationService) {
                    return AuthenticationService.getUser();
                  },
                ],
              },
            },
          },
          onEnter: companyTypeRedirect,
          ncyBreadcrumb: {
            skip: true,
          },
        })

        .state('dashboard.manufacturer', {
          views: {
            'content@': {
              controller: 'controller.dashboard.manufacturer',
              controllerAs: '$ctrl',
              templateUrl: 'templates/dashboard.manufacturer.template.html',
            },
          },
          ncyBreadcrumb: {
            label: 'Dashboard',
          },
          data: {
            meta: {
              titleSuffix: '| Dashboard',
            },
          },
        })

        .state('dashboard.owner', {
          views: {
            'content@': {
              controller: 'controller.dashboard.owner',
              controllerAs: 'dashboardVm',
              templateUrl: 'templates/dashboard.owner.template.html',
            },
          },
          data: {
            meta: {
              titleSuffix: '| Dashboard',
            },
          },
        });
    },
  ]);

  companyTypeRedirect.$inject = ['$state', 'AuthenticationService'];

  function companyTypeRedirect($state, AuthenticationService) {
    AuthenticationService.getUser().then(function (user) {
      if (user.permissions.companyType === 'retailer') {
        return $state.go('sales.customers.list');
      }

      $state.go('dashboard.' + user.permissions.companyType);
    });
  }
})();
