(function () {
  'use strict';

  angular.module('module.settings.retailerGlass').directive('ttSettingsGlassMarkup', directive);

  var glassMarkupModes = {
    none: 'none',
    fixed: 'fixed',
    perProduct: 'perProduct',
  };

  /**
   * Init and configure directive
   */
  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/settings.retailerGlass.markup.directive.template.html',
      controllerAs: 'markupVm',
      scope: {
        markupMode: '=',
      },
      controller: ['$scope', controller],
      bindToController: true,
    };

    /**
     * @param $scope
     */
    function controller($scope) {
      /* jshint validthis: true */
      var vm = this;

      $scope.markup = {
        enabled:
          vm.markupMode === glassMarkupModes.fixed || vm.markupMode === glassMarkupModes.perProduct,
        perProduct: vm.markupMode === glassMarkupModes.perProduct,
      };

      // update passed markup mode when settings change
      $scope.$watch(
        'markup',
        function () {
          vm.markupMode = getMarkupMode($scope.markup);
        },
        true,
      );
    }

    /**
     * Get markup mode from markup object
     *
     * @param markup
     * @returns {boolean|string}
     */
    function getMarkupMode(markup) {
      if (!markup.enabled) {
        return glassMarkupModes.none;
      }

      return markup.perProduct ? glassMarkupModes.perProduct : glassMarkupModes.fixed;
    }
  }
})();
