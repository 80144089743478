(function () {
  'use strict';

  angular
    .module('tommyApp')
    .config([
      '$locationProvider',
      '$stateProvider',
      '$httpProvider',
      '$breadcrumbProvider',
      'ngMetaProvider',
      'uiSelectConfig',
      config,
    ]);

  function config(
    $locationProvider,
    $stateProvider,
    $httpProvider,
    $breadcrumbProvider,
    ngMetaProvider,
    uiSelectConfig,
  ) {
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: true,
    });

    $stateProvider.state('app', {
      views: {
        'navigation@': {
          controller: 'controller.navigation',
          controllerAs: 'navVm',
          templateUrl: 'templates/navigation.template.html',
        },
      },
    });

    $breadcrumbProvider.setOptions({
      templateUrl: 'templates/ncyBreadcrumbs.template.html',
      includeAbstract: true,
    });

    ngMetaProvider.setDefaultTag('viewport', 'width=device-width,initial-scale=1');
    ngMetaProvider.setDefaultTitle('Tommy Trinder ');
    ngMetaProvider.setDefaultTitleSuffix('');
    ngMetaProvider.useTitleSuffix(true);

    $httpProvider.interceptors.push('ApiVersionInterceptor');

    uiSelectConfig.theme = 'select2';
  }
})();
