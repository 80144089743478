(function () {
  'use strict';

  angular.module('module.sales').directive('ttProductActions', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/products.actions.directive.template.html',
        controllerAs: 'actionsVm',
        scope: {
          product: '=',
        },
        controller: ['$state', '$window', 'ProductsService', 'AuthenticationService', controller],
        bindToController: true,
      };
    },
  ]);

  /**
   * @param $state
   * @param $window
   * @param ProductsService
   * @param AuthenticationService
   */
  function controller($state, $window, ProductsService, AuthenticationService) {
    /* jshint validthis: true */
    var vm = this;

    vm.actionAvailable = actionAvailable;
    vm.activateAction = activateAction;
    vm.copyAction = copyAction;
    vm.archiveAction = archiveAction;

    /**
     * Check if action available for current product status
     *
     * @param {string} action
     * @returns {boolean}
     */
    function actionAvailable(action) {
      return getActions(vm.product).indexOf(action) !== -1;
    }

    /**
     * Get list of available actions for a product
     *
     * @param {object} product
     * @returns {Array}
     */
    function getActions(product) {
      var actions = [],
        permissions = AuthenticationService.getPermissions();

      // TODO: uncomment when product copying issues fixed: https://trello.com/c/Cy7RB5tp
      // if (permissions.actions.copyProduct) {
      //   actions.push('copy');
      // }

      if (permissions.actions.updateProduct) {
        actions.push('update');
      }

      if (!product.metadata.archived) {
        // TODO: uncomment when archiving supported
        // actions.push('archive');

        if (!product.metadata.active && permissions.actions.updateProduct) {
          actions.push('activate');
        }
      }

      return actions;
    }

    /**
     * Get user confirmation then copy the product
     */
    function copyAction() {
      var confirm = $window.confirm('Are you sure you want to copy this product?');

      if (confirm) {
        ProductsService.copyProduct(vm.product).then(function (product) {
          $state.go('products.products.retrieve', {productId: product.id});
        });
      }
    }

    /**
     * Prompt user to confirm activation then activate the product
     */
    function activateAction() {
      var response = '';

      while ('activate' !== response) {
        response = $window.prompt('Type "activate" in the box below to activate the product.');

        if (null === response) {
          return;
        }
      }

      ProductsService.activateProduct(vm.product).then(function (product) {
        angular.merge(product, vm.product);
      });
    }

    /**
     * Archive the product
     * TODO: implement when archiving supported
     */
    function archiveAction() {}
  }
})();
