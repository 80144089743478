(function () {
  'use strict';

  angular
    .module('module.settings.retailerGlass')
    .controller('controller.settings.retailerGlass', [
      '$scope',
      'SettingsService',
      'ModalService',
      'dataSettings',
      controller,
    ]);

  /**
   * @param $scope
   * @param SettingsService
   * @param ModalService
   * @param dataSettings
   */
  function controller($scope, SettingsService, ModalService, dataSettings) {
    /* jshint validthis: true */
    var vm = this;

    vm.confirmSave = confirmSave;

    init();

    /**
     * Init controller
     */
    function init() {
      vm.tabSelected = 'glassSupply';
      vm.settings = dataSettings;

      $scope.$watch(
        function () {
          return vm.settings.settings.retailerGlassEnabled;
        },
        function (newValue) {
          vm.glassEnabled = newValue;
        },
      );
    }

    /**
     * Show modal to get confirmation of triggering a reprice
     * TODO: move to component
     */
    function confirmSave() {
      ModalService.showModal({
        controller: 'controller.settings.advanced.pricing.reprice.modal',
        templateUrl: 'templates/settings.advanced.pricing.reprice.modal.template.html',
        controllerAs: 'modalVm',
        inputs: {
          message: 'Changing glass settings results in the recalculation of draft quotes.',
        },
      }).then(function (modal) {
        // open modal
        modal.element.parent().find('#reprice-modal').modal();
        modal.close.then(function (result) {
          if (result) {
            save();
          }
        });
      });
    }

    /**
     * Save pricing settings to API
     */
    function save() {
      SettingsService.updateSettings(vm.settings);
    }
  }
})();
