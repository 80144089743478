(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('OrderPartApiService', ['$q', 'Notification', 'ApiService', service]);

  /**
   * @param $q
   * @param Notification
   * @param ApiService
   */
  function service($q, Notification, ApiService) {
    return {
      list: list,
      get: get,
      update: update,
      send: send,
      accept: accept,
      dispatch: dispatch,
      listOrders: listOrders,
      delivered: delivered,
    };

    /**
     * @returns {$q}
     */
    function list() {
      return ApiService.get('/order-parts').then(function (response) {
        return response.data;
      });
    }

    /**
     * @param {string} orderPartId
     *
     * @returns {$q}
     */
    function get(orderPartId) {
      return ApiService.get('/order-parts/' + orderPartId).then(function (response) {
        return response.data;
      });
    }

    /**
     * @param {object} orderPart
     *
     * @returns {$q}
     */
    function update(orderPart) {
      var data = {
        requiredByTime: orderPart.requiredByTime,
        deliveryAddressType: orderPart.deliveryAddress.type,
        customDeliveryAddress: orderPart.deliveryAddress.address,
        customDeliveryAddressPostcode: orderPart.deliveryAddress.postcode,
        deliveryInstructions: orderPart.deliveryInstructions,
        notes: orderPart.notes,
      };

      return ApiService.put('/order-parts/' + orderPart.id, data).then(
        function (response) {
          Notification.success('Updated order');

          return response.data;
        },
        function () {
          Notification.error('Failed to update order');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {object} orderPart
     *
     * @returns {$q}
     */
    function send(orderPart) {
      return orderPartAction(
        orderPart,
        'send',
        'Sent order to manufacturer',
        'Failed to send order to manufacturer',
      );
    }

    /**
     * @param {object} orderPart
     *
     * @returns {$q}
     */
    function accept(orderPart) {
      return orderPartAction(orderPart, 'accept', 'Accepted order', 'Failed to accept order');
    }

    /**
     * @param {object} orderPart
     *
     * @returns {$q}
     */
    function dispatch(orderPart) {
      return orderPartAction(
        orderPart,
        'dispatch',
        'Marked order as dispatched',
        'Failed to mark order as dispatched',
      );
    }

    /**
     * @param {object} orderPart
     *
     * @returns {$q}
     */
    function delivered(orderPart) {
      return orderPartAction(
        orderPart,
        'delivered',
        'Marked order as delivered',
        'Failed to mark order as delivered',
      );
    }

    /**
     * @param {object} orderPart
     * @param {string} action
     * @param {string} successMessage
     * @param {string} failureMessage
     *
     * @returns {$q}
     */
    function orderPartAction(orderPart, action, successMessage, failureMessage) {
      return ApiService.put('/order-parts/' + orderPart.id + '/' + action).then(
        function (response) {
          Notification.success(successMessage);

          return response.data;
        },
        function () {
          Notification.error(failureMessage);

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {String} page
     *
     * @return {$q}
     */
    function listOrders(page) {
      var params = {
        page: page == null ? 1 : page,
      };

      return ApiService.get('/order-parts', params).then(function (response) {
        return response.data;
      });
    }
  }
})();
