(function () {
  'use strict';

  angular.module('module.authentication').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider

        .state('authentication', {
          abstract: true,
        })

        .state('authentication.signin', {
          url: '/signin',
          views: {
            'content@': {
              controller: 'controller.authentication.signin',
              controllerAs: 'signInVm',
              templateUrl: 'templates/authentication.signin.template.html',
              resolve: {
                redirect: [
                  '$state',
                  'AuthenticationService',
                  function ($state, AuthenticationService) {
                    return AuthenticationService.checkAuthentication().then(
                      function () {
                        $state.go('dashboard');
                      },
                      function () {},
                    );
                  },
                ],
              },
            },
          },
          params: {
            message: {
              value: null,
            },
          },
          data: {
            meta: {
              titleSuffix: '| Sign In',
            },
          },
        })

        .state('authentication.signout', {
          url: '/signout',
          onEnter: [
            'AuthenticationService',
            function (AuthenticationService) {
              AuthenticationService.signOut();
            },
          ],
        })

        .state('authentication.register', {
          url: '/register/:token',
          views: {
            'content@': {
              controller: 'controller.authentication.register',
              controllerAs: 'registerVm',
              templateUrl: 'templates/authentication.register.template.html',
              resolve: {
                registrationData: [
                  '$state',
                  '$stateParams',
                  'AuthenticationService',
                  function ($state, $stateParams, AuthenticationService) {
                    return AuthenticationService.validateConfirmationToken(
                      $stateParams.token,
                    ).catch(function (error) {
                      $state.go('authentication.signin', {
                        message:
                          error.status === 404
                            ? 'Registration token is invalid or expired. Please contact support.'
                            : 'There has been an issue processing your registration. Please contact support.',
                      });
                    });
                  },
                ],
              },
            },
          },
          data: {
            meta: {
              titleSuffix: '| Confirm Registration',
            },
          },
        })

        .state('authentication.passwordReset', {
          url: '/password-reset/:token',
          views: {
            'content@': {
              controller: 'controller.authentication.passwordReset',
              controllerAs: 'passwordVm',
              templateUrl: function ($stateParams) {
                return null === $stateParams.token
                  ? 'templates/authentication.passwordReset.request.template.html'
                  : 'templates/authentication.passwordReset.update.template.html';
              },
              resolve: {
                user: [
                  '$state',
                  '$stateParams',
                  'AuthenticationService',
                  function ($state, $stateParams, AuthenticationService) {
                    if (null === $stateParams.token) {
                      return;
                    }

                    return AuthenticationService.validateConfirmationToken(
                      $stateParams.token,
                    ).catch(function () {
                      $state.go('authentication.signin', {
                        message: 'Password reset token is invalid or expired. Please try again.',
                      });
                    });
                  },
                ],
              },
            },
          },
          params: {
            token: {
              value: null,
              squash: true,
            },
          },
          data: {
            meta: {
              titleSuffix: '| Password Reset',
            },
          },
        })

        .state('authentication.browserCheck', {
          url: '/browser-check',
          views: {
            'content@': {
              controller: 'controller.authentication.browserCheck',
              controllerAs: '$ctrl',
              templateUrl: 'templates/authentication.browserCheck.template.html',
            },
          },
          data: {
            meta: {
              titleSuffix: '| Browser Warning',
            },
          },
        });
    },
  ]);
})();
