(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.productTypes', [
      '$scope',
      '$state',
      'SettingsProductTypesService',
      'dataSettingsMaterials',
      function ($scope, $state, SettingsProductTypesService, dataSettingsMaterials) {
        $scope.productTypes = dataSettingsMaterials;

        $scope.update = function () {
          SettingsProductTypesService.ProductTypesUpdate($scope.productTypes).then(function () {
            $state.reload();
          });
        };
      },
    ]);
})();
