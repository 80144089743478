(function () {
  'use strict';

  angular.module('module.dashboard').factory('StatsApiService', ['$q', 'ApiService', service]);

  function service($q, ApiService) {
    return {
      getRealTimeOrders: getRealTimeOrders,
      getRealTimeQuotes: getRealTimeQuotes,
      getActiveRetailers: getActiveRetailers,
      getRetailerOrders: getRetailerOrders,
      getRetailerQuotes: getRetailerQuotes,
      getUserQuotes: getUserQuotes,
    };

    /**
     * @return {$q}
     */
    function getRealTimeOrders() {
      return getStats('/orders/real-time');
    }

    /**
     * @return {$q}
     */
    function getRealTimeQuotes() {
      return getStats('/quotes/real-time');
    }

    /**
     * @return {$q}
     */
    function getActiveRetailers() {
      return getStats('/retailers/active');
    }

    /**
     * @return {$q}
     */
    function getRetailerOrders() {
      return getStats('/retailers/orders');
    }

    /**
     * @return {$q}
     */
    function getRetailerQuotes() {
      return getStats('/retailers/quotes');
    }

    /**
     * @return {$q}
     */
    function getUserQuotes() {
      return getStats('/users/quotes');
    }

    /**
     * @param {String} url
     *
     * @return {$q}
     */
    function getStats(url) {
      return ApiService.get('/reports' + url).then(function (response) {
        return response.data;
      });
    }
  }
})();
