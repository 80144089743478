(function () {
  'use strict';

  angular
    .module('module.retailers')
    .factory('ActiveCampaignApiService', ['$q', 'ApiService', 'Notification', service]);

  function service($q, ApiService, Notification) {
    return {
      searchAccounts: searchAccounts,
      updateAccount: updateAccount,
    };

    function searchAccounts(search) {
      search = encodeURIComponent(search);

      return ApiService.get('/active-campaign/accounts?search=' + search).then(function (response) {
        return response.data;
      });
    }

    function updateAccount(retailer, account) {
      var body = {
        id: account.id,
        name: account.name,
      };

      return ApiService.put(
        '/companies/retailers/' + retailer.id + '/activeCampaignAccount',
        body,
      ).then(
        function () {
          Notification.success('Updated ActiveCampaign account');
        },
        function () {
          Notification.error('Failed to update ActiveCampaign account');

          return $q.reject();
        },
      );
    }
  }
})();
