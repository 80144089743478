(function () {
  'use strict';

  angular.module('module.sales').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('sales.manufacturers', {
      abstract: true,
      url: '/manufacturers',
      ncyBreadcrumb: {
        skip: true,
      },
    });

    $stateProvider.state('sales.manufacturers.list', {
      url: '',
      views: {
        'content@': {
          controller: 'controller.sales.manufacturers.list',
          controllerAs: '$ctrl',
          templateUrl: 'templates/sales.manufacturers.list.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Manufacturers',
      },
      data: {
        meta: {
          titleSuffix: '| Manufacturers',
        },
      },
    });

    $stateProvider.state('sales.manufacturers.create', {
      url: '/new',
      views: {
        'content@': {
          controller: 'controller.sales.manufacturers.create',
          templateUrl: 'templates/sales.manufacturers.create.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'sales.manufacturers.list',
      },
      data: {
        meta: {
          titleSuffix: '| New Manufacturer',
        },
      },
    });

    $stateProvider.state('sales.manufacturers.update', {
      url: '/:manufacturerId',
      views: {
        'content@': {
          controller: 'controller.sales.manufacturers.update',
          controllerAs: '$ctrl',
          templateUrl: 'templates/sales.manufacturers.update.template.html',
          resolve: {
            dataManufacturer: [
              '$stateParams',
              'CompaniesService',
              function ($stateParams, CompaniesService) {
                return CompaniesService.retrieveCompany(
                  '/manufacturers/' + $stateParams.manufacturerId,
                ).then(function (response) {
                  return response.data.company;
                });
              },
            ],
          },
        },
      },
      ncyBreadcrumb: {
        label: 'Update {{ $ctrl.manufacturer.metadata.name }}',
        parent: 'sales.manufacturers.list',
      },
      data: {
        meta: {
          titleSuffix: '| Updating Manufacturer',
        },
      },
    });
  }
})();
