(function () {
  'use strict';

  angular.module('module.sales').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider

        .state('sales.quotes', {
          views: {
            'content@': {
              templateUrl: 'templates/sales.quotes.template.html',
            },
          },
        })

        .state('sales.quotes.retrieve', {
          url: '/quotes/:quoteId',
          controller: 'controller.sales.quotes.retrieve',
          controllerAs: 'quoteVm',
          templateUrl: 'templates/sales.quotes.retrieve.template.html',
          resolve: {
            dataQuote: [
              '$stateParams',
              'QuotesService',
              function ($stateParams, QuotesService) {
                return QuotesService.retrieveQuote($stateParams.quoteId);
              },
            ],
            dataQuoteItems: [
              '$stateParams',
              'QuoteItemsService',
              function ($stateParams, QuoteItemsService) {
                return QuoteItemsService.retrieveItems($stateParams.quoteId);
              },
            ],
            dataCustomer: [
              'CustomersService',
              'dataQuote',
              function (CustomersService, dataQuote) {
                return CustomersService.retrieveCustomer(dataQuote.metadata.customer.id);
              },
            ],
            dataSettings: [
              'SettingsService',
              function (SettingsService) {
                return SettingsService.getSettings();
              },
            ],
            currencySettings: [
              'CurrencyService',
              function (CurrencyService) {
                return CurrencyService.getSettings();
              },
            ],
          },
          ncyBreadcrumb: {
            label:
              '{{itemActiveName }} for Quote Ref {{ quoteVm.reference || quote.metadata.reference }}',
            parent:
              'sales.customers.retrieve({customerId: customersampleId, "#": "quotes", "hashLocation":"hashLocation"})',
          },
          data: {
            meta: {
              titleSuffix: '| Quote Details',
            },
          },
        })

        .state('sales.quotes.update', {
          url: '/quotes/:quoteId/edit',
          controller: 'controller.sales.quotes.update',
          controllerAs: 'quoteVm',
          templateUrl: 'templates/sales.quotes.update.template.html',
          resolve: {
            dataQuote: [
              '$stateParams',
              'QuotesService',
              function ($stateParams, QuotesService) {
                return QuotesService.retrieveQuote($stateParams.quoteId);
              },
            ],
          },
          ncyBreadcrumb: {
            parent: 'sales.quotes.retrieve({quoteId: quote.id})',
            label: 'Edit Quote',
          },
          data: {
            meta: {
              titleSuffix: '| Updating Quote',
            },
          },
        })

        .state('sales.quotes.newCustomItem', {
          url: '/quotes/:quoteId/custom-items/new',
          controller: 'controller.sales.quotes.customItem',
          controllerAs: '$ctrl',
          templateUrl: 'templates/sales.quotes.customItem.template.html',
          resolve: {
            dataCustomItem: () => null,
            dataCustomItemTemplate: () => null,
            dataQuote: [
              '$stateParams',
              'QuotesService',
              function ($stateParams, QuotesService) {
                return QuotesService.retrieveQuote($stateParams.quoteId);
              },
            ],
            currencySettings: [
              'CurrencyService',
              function (CurrencyService) {
                return CurrencyService.getSettings();
              },
            ],
          },
          ncyBreadcrumb: {
            parent: 'sales.quotes.retrieve({quoteId: $ctrl.quote.id})',
            label: 'New Custom Item',
          },
          data: {
            meta: {
              titleSuffix: '| Custom Item',
            },
          },
        })

        .state('sales.quotes.newItemFromPartsShop', {
          url: '/quotes/:quoteId/parts-shop/:partsShopId',
          controller: 'controller.sales.quotes.itemFromPartsShop',
          controllerAs: '$ctrl',
          templateUrl: 'templates/sales.quotes.itemFromPartsShop.template.html',
          resolve: {
            dataItem: () => null,
            dataItemTemplate: [
              '$stateParams',
              'PartsShopApiService',
              ($stateParams, ApiService) => ApiService.retrieve($stateParams.partsShopId),
            ],
            dataQuote: [
              '$stateParams',
              'QuotesService',
              ($stateParams, QuotesService) => QuotesService.retrieveQuote($stateParams.quoteId),
            ],
            currencySettings: [
              'CurrencyService',
              function (CurrencyService) {
                return CurrencyService.getSettings();
              },
            ],
          },
          ncyBreadcrumb: {
            parent: 'sales.quotes.retrieve({quoteId: $ctrl.quote.id})',
            label: 'Create from Parts Shop',
          },
          data: {
            meta: {
              titleSuffix: '| Create from Parts Shop',
            },
          },
        })

        .state('sales.quotes.editCustomItem', {
          url: '/quotes/:quoteId/custom-items/:customItemId',
          controller: 'controller.sales.quotes.customItem',
          controllerAs: '$ctrl',
          templateUrl: 'templates/sales.quotes.customItem.template.html',
          resolve: {
            dataCustomItem: [
              '$stateParams',
              'CustomItemService',
              function ($stateParams, CustomItemService) {
                return CustomItemService.retrieveCustomItem(
                  $stateParams.quoteId,
                  $stateParams.customItemId,
                );
              },
            ],
            dataQuote: [
              '$stateParams',
              'QuotesService',
              function ($stateParams, QuotesService) {
                return QuotesService.retrieveQuote($stateParams.quoteId);
              },
            ],
          },
          ncyBreadcrumb: {
            parent: 'sales.quotes.retrieve({quoteId: $ctrl.quote.id})',
            label: 'Edit Custom Item',
          },
          data: {
            meta: {
              titleSuffix: '| Custom Item',
            },
          },
        })

        .state('sales.quotes.editItemFromPartsShop', {
          url: '/quotes/:quoteId/from-parts-shop/:customItemId',
          controller: 'controller.sales.quotes.itemFromPartsShop',
          controllerAs: '$ctrl',
          templateUrl: 'templates/sales.quotes.itemFromPartsShop.template.html',
          resolve: {
            dataItem: [
              '$stateParams',
              'CustomItemService',
              function ($stateParams, CustomItemService) {
                return CustomItemService.retrieveCustomItem(
                  $stateParams.quoteId,
                  $stateParams.customItemId,
                );
              },
            ],
            dataQuote: [
              '$stateParams',
              'QuotesService',
              function ($stateParams, QuotesService) {
                return QuotesService.retrieveQuote($stateParams.quoteId);
              },
            ],
            dataItemTemplate: () => null,
          },
          ncyBreadcrumb: {
            parent: 'sales.quotes.retrieve({quoteId: $ctrl.quote.id})',
            label: 'Edit Parts Shop',
          },
          data: {
            meta: {
              titleSuffix: '| Edit',
            },
          },
        });
    },
  ]);
})();
