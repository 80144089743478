(function () {
  'use strict';

  var module = angular.module('module.settings.retailerProducts');

  module.constant('matrixTypes', {
    widthByHeight: 'Width * Height',
    // TODO: enable at milestone #4 https://trello.com/c/5SXOXIrU
    // widthPlusHeight: 'Width + Height',
    // fourCorners: 'Four Corners',
  });

  module.config(['$stateProvider', createStates]);

  function createStates($stateProvider) {
    $stateProvider.state('settings.retailerProducts', {
      url: '/my-products',
      views: {
        'content@': {
          templateUrl: 'templates/settings.retailerProducts.list.template.html',
          controller: 'controller.settings.retailerProducts.list',
          controllerAs: '$ctrl',
        },
      },
      resolve: {
        dataProducts: [
          'RetailerProductsApiService',
          function (ApiService) {
            return ApiService.list();
          },
        ],
        currencySettings: [
          'CurrencyService',
          function (CurrencyService) {
            return CurrencyService.getSettings();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'My Products',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | My Products',
        },
      },
    });

    $stateProvider.state('settings.retailerProducts.create', {
      url: '/new',
      templateUrl: 'templates/settings.retailerProducts.form.template.html',
      controller: 'controller.settings.retailerProducts.create',
      controllerAs: '$ctrl',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings();
          },
        ],
        dataRetailerPricingAvailable: [
          'dataSettings',
          function (settings) {
            return settings.metadata.retailerPricingAvailable;
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'New',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | My Products | New',
        },
      },
    });

    $stateProvider.state('settings.retailerProducts.update', {
      url: '/{id}',
      templateUrl: 'templates/settings.retailerProducts.form.template.html',
      controller: 'controller.settings.retailerProducts.update',
      controllerAs: '$ctrl',
      params: {
        tab: null,
      },
      resolve: {
        dataRetailerProduct: [
          'RetailerProductsApiService',
          '$stateParams',
          function (ApiService, $stateParams) {
            return ApiService.get($stateParams.id);
          },
        ],
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings();
          },
        ],
        dataRetailerPricingAvailable: [
          'dataSettings',
          function (settings) {
            return settings.metadata.retailerPricingAvailable;
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Update',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | My Products | Update',
        },
      },
    });
  }
})();
