(function () {
  'use strict';

  angular.module('module.sales').directive('ttOrderDelivery', [
    function () {
      return {
        restrict: 'A',
        scope: {
          order: '<',
          orderPart: '<',
          onOrderUpdate: '&?',
        },
        controller: ['ModalService', controller],
        bindToController: true,
        link: link,
      };
    },
  ]);

  /**
   * @param ModalService
   */
  function controller(ModalService) {
    /* jshint validthis: true */
    var vm = this;

    vm.onClick = onClick;

    /**
     * Show modal
     */
    function onClick() {
      ModalService.showModal({
        controller: 'controller.sales.orders.delivery.modal',
        templateUrl: 'templates/sales.orders.delivery.modal.template.html',
        controllerAs: 'modalVm',
        inputs: {
          order: vm.order,
          orderPart: vm.orderPart,
        },
      }).then(function (modal) {
        // open modal
        var modalElement = modal.element.parent().find('#deliveryModal');
        modalElement.modal();

        // on close clean up modal
        modal.close.then(function (options) {
          if (options.updated) {
            if (typeof vm.onOrderUpdate === 'function') {
              vm.onOrderUpdate()();
            }
          }
        });
      });
    }
  }

  /**
   * @param scope
   * @param element
   * @param attrs
   * @param controller
   */
  function link(scope, element, attrs, controller) {
    element.on('click', function (event) {
      event.preventDefault();
      controller.onClick();
    });
  }
})();
