(function () {
  'use strict';

  angular.module('module.settings.retailer').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('settings.retailer', {
      url: '/retailer',
      abstract: true,
      views: {
        'content@': {
          controller: [
            '$scope',
            'NavigationService',
            function ($scope, NavigationService) {
              $scope.navigation = NavigationService.getRetailerSettingsNavItems();
            },
          ],
          templateUrl: 'templates/settings.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Retailer Settings',
      },
    });

    $stateProvider.state('settings.retailer.paymentTerms', {
      url: '/payment-terms',
      templateUrl: 'templates/settings.retailer.paymentTerms.list.template.html',
      controller: 'controller.settings.retailer.paymentTerms.list',
      resolve: {
        dataPaymentTerms: [
          'PaymentTermsService',
          function (PaymentTermsService) {
            return PaymentTermsService.paymentTermsList();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Payment Terms',
      },
      data: {
        meta: {
          titleSuffix: '| Retailer Settings | Payment Terms',
        },
      },
    });

    $stateProvider.state('settings.retailer.paymentTerms.create', {
      url: '/create',
      views: {
        paymentTerms: {
          controller: 'controller.settings.retailer.paymentTerms.create',
          templateUrl: 'templates/settings.retailer.paymentTerms.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Create Payment Term',
      },
      data: {
        meta: {
          titleSuffix: '| Retailer Settings | Create Payment Term',
        },
      },
    });

    $stateProvider.state('settings.retailer.paymentTerms.update', {
      url: '/{paymentTermId}',
      views: {
        paymentTerms: {
          controller: 'controller.settings.retailer.paymentTerms.update',
          templateUrl: 'templates/settings.retailer.paymentTerms.template.html',
        },
      },
      resolve: {
        dataPaymentTerm: [
          '$stateParams',
          'PaymentTermsService',
          function ($stateParams, PaymentTermsService) {
            return PaymentTermsService.paymentTermsRetrieve($stateParams.paymentTermId);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Update Payment Term',
      },
      data: {
        meta: {
          titleSuffix: '| Retailer Settings | Update Payment Term',
        },
      },
    });

    $stateProvider.state('settings.retailer.termsAndConditions', {
      url: '/terms-and-conditions',
      controller: 'controller.settings.retailer.termsAndConditions',
      templateUrl: 'templates/settings.retailer.termsAndConditions.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Terms & Conditions',
      },
      data: {
        meta: {
          titleSuffix: '| Retailer Settings | Terms & Conditions',
        },
      },
    });

    $stateProvider.state('settings.retailer.discount', {
      url: '/discount',
      controller: 'controller.settings.retailer.discount',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.retailer.discount.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Discount',
      },
      data: {
        meta: {
          titleSuffix: '| Retailer Settings | Discount',
        },
      },
    });

    $stateProvider.state('settings.retailer.surcharge', {
      url: '/surcharge',
      controller: 'controller.settings.retailer.surcharge',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.retailer.surcharge.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Surcharge',
      },
      data: {
        meta: {
          titleSuffix: '| Retailer Settings | Surcharge',
        },
      },
    });
  }
})();
