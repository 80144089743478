(function () {
  'use strict';

  angular.module('module.sales').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('sales.retailers.pending', {
      abstract: true,
      url: '/pending-retailers',
      ncyBreadcrumb: {
        skip: true,
      },
    });

    $stateProvider.state('sales.retailers.pending.ownerList', {
      url: '',
      views: {
        'content@': {
          controller: 'controller.sales.retailers.pending.ownerList',
          controllerAs: 'pendingVm',
          templateUrl: 'templates/sales.retailers.pending.ownerList.template.html',
          resolve: {
            dataPendingRetailers: [
              'PendingRetailerService',
              function (PendingRetailerService) {
                return PendingRetailerService.list();
              },
            ],
          },
        },
      },
      ncyBreadcrumb: {
        label: 'Pending Retailers',
      },
      data: {
        meta: {
          titleSuffix: '| Pending Retailers',
        },
      },
    });

    $stateProvider.state('sales.retailers.pending.ownerUpdate', {
      url: '/{pendingRetailerId}',
      views: {
        'content@': {
          controller: 'controller.sales.retailers.pending.ownerUpdate',
          controllerAs: 'pendingVm',
          templateUrl: 'templates/sales.retailers.pending.ownerUpdate.template.html',
          resolve: {
            dataPendingRetailer: [
              '$stateParams',
              'PendingRetailerService',
              function ($stateParams, PendingRetailerService) {
                return PendingRetailerService.get($stateParams.pendingRetailerId);
              },
            ],
          },
        },
      },
      ncyBreadcrumb: {
        label: '{{ pendingVm.retailer.companyName }}',
        parent: 'sales.retailers.pending.ownerList',
      },
      data: {
        meta: {
          titleSuffix: '| Pending Retailers | Update',
        },
      },
    });
  }
})();
