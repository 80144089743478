(function () {
  'use strict';

  angular.module('module.visualiser').directive('ttPhotoBankImage', [
    function () {
      return {
        restrict: 'E',
        templateUrl: 'templates/visualiser.photoBankImage.directive.template.html',
        controllerAs: 'imageVm',
        scope: {
          image: '=',
          deleteFunc: '&',
        },
        controller: ['$stateParams', '$window', imageController],
        bindToController: true,
      };
    },
  ]);

  function imageController($stateParams, $window) {
    /* jshint validthis: true */
    var vm = this;

    vm.deleteDisabled = false;
    vm.deleteImage = deleteImage;
    vm.quoteId = $stateParams.quoteId;

    /**
     * Delete using passed function, debouncing
     */
    function deleteImage() {
      if (vm.deleteDisabled) {
        return;
      }
      if ($window.confirm('Are you sure you want to delete this image?')) {
        vm.deleteDisabled = true;
        vm.deleteFunc()(vm.image);
      }
    }
  }
})();
