(function () {
  'use strict';

  angular.module('module.sales').factory('QuotePricingService', ['$q', 'SettingsService', service]);

  function service($q, SettingsService) {
    return {
      updateQuotePricing: updateQuotePricing,
      recalculateItemSellingPrice: recalculateItemSellingPrice,
    };

    /**
     * Updates the pricing on a given quote. Returns a promise of the result.
     *
     * @param quote
     * @returns {$q}
     */
    function updateQuotePricing(quote) {
      return getRetailerSettings().then(function (retailerSettings) {
        var qp = quote.pricing;
        // item level pricing
        qp.costPrice = 0;
        qp.sellPrice = 0;
        qp.nonDiscountableSellPrice = 0;
        qp.glassCostPrice = 0;

        if (typeof quote.items !== 'undefined') {
          angular.forEach(quote.items, function (item) {
            if (item.metadata.itemValid) {
              qp.costPrice += item.pricing.costPrice * item.quantity;
              qp.glassCostPrice += item.pricing.glassCostPrice * item.quantity;
              qp.sellPrice += item.pricing.sellPrice * item.quantity;
            }
          });
        }

        if (typeof quote.customItems !== 'undefined') {
          angular.forEach(quote.customItems, function (item) {
            qp.costPrice += Math.round(
              item.metadata.costPrice * item.metadata.quantity * item.metadata.multiple,
            );
            qp.sellPrice += Math.round(
              item.metadata.sellPrice * item.metadata.quantity * item.metadata.multiple,
            );
            if (!item.metadata.discountable) {
              qp.nonDiscountableSellPrice += Math.round(
                item.metadata.sellPrice * item.metadata.quantity * item.metadata.multiple,
              );
            }
          });
        }

        var deliveryCostTotal = 0;
        qp.deliveryCostBreakdown.forEach(function (dc) {
          deliveryCostTotal += dc.amount;
        });

        var customChargeTotal = 0;
        qp.customChargeBreakdown.forEach(function (cc) {
          customChargeTotal += cc.amount;
        });

        qp.totalNetCostPrice =
          qp.costPrice + qp.glassCostPrice + deliveryCostTotal + customChargeTotal;

        qp.costVat = calculateVatOnNetAmount(qp.totalNetCostPrice, qp.vatRate);

        qp.costGrossPrice = qp.totalNetCostPrice + qp.costVat;

        qp.fittingPrice = getFittingPrice(
          quote.metadata.numFittingDays,
          retailerSettings.settings.fittingDayRate,
        );

        qp.fixedAmount = getFixedAmountPerQuote(
          retailerSettings.settings.fixedAmountPerQuote,
          retailerSettings.settings.useFixedAmountPerQuote,
        );

        qp.preAdjustmentNetPrice = qp.sellPrice + qp.fittingPrice + qp.fixedAmount;

        qp.preAdjustmentVat = calculateVatOnNetAmount(qp.preAdjustmentNetPrice, qp.vatRate);

        qp.preAdjustmentGrossPrice = qp.preAdjustmentNetPrice + qp.preAdjustmentVat;

        qp.grossTotalAfterPenceAdjustment = qp.preAdjustmentGrossPrice + qp.finalAdjustmentPence;

        const nonDiscountableGrossPrice =
          qp.nonDiscountableSellPrice +
          calculateVatOnNetAmount(qp.nonDiscountableSellPrice, qp.vatRate);

        const discountableGrossTotalAfterPenceAdjustment =
          qp.grossTotalAfterPenceAdjustment - nonDiscountableGrossPrice;

        const percentageAdjustmentAmount = Math.round(
          (discountableGrossTotalAfterPenceAdjustment * qp.finalAdjustmentPercentage) / 100,
        );

        qp.finalGrossPrice = qp.grossTotalAfterPenceAdjustment - percentageAdjustmentAmount;

        qp.finalVat = calculateVatOnGrossAmount(qp.finalGrossPrice, qp.vatRate);

        qp.finalNetPrice = qp.finalGrossPrice - qp.finalVat;

        quote.pricing = qp;
        return quote;
      });
    }

    function recalculateItemSellingPrice(item) {
      var childrenCostPrice = 0;
      var childrenSellPrice = 0;

      if (item.metadata.children && item.metadata.children.length) {
        item.metadata.children.forEach(function (child) {
          childrenCostPrice += child.costPrice;
          childrenSellPrice += child.sellPrice;
        });
      }

      var costPrice = item.pricing.costPrice - childrenCostPrice;
      var multiplier = 1 + (item.genman.markup || 0) / 100;
      var fixedAmount = item.genman.fixedAmount || 0;
      var adjustment = item.genman.adjustment || 0;
      var customExtras = item.genman.customExtras || 0;
      var glassMultiplier = 1 + (item.genman.glassMarkup || 0) / 100;
      var glass = item.pricing.glassCostPrice * glassMultiplier;
      var addition = fixedAmount + adjustment + customExtras + glass;

      item.pricing.sellPrice = Math.round(costPrice * multiplier + addition + childrenSellPrice);

      return $q.when(item);
    }

    /**
     * Returns a promise of retailer settings
     *
     * @returns {$q}
     */
    function getRetailerSettings() {
      return SettingsService.getSettings();
    }

    /**
     * Get fitting price
     *
     * @param {float} fittingDays
     * @param {float} fittingRate
     * @returns {int}
     */
    function getFittingPrice(fittingDays, fittingRate) {
      return Math.round(fittingDays * fittingRate);
    }

    /**
     * Get fixed amount per quote
     *
     * @param {int} fixedAmount
     * @param {boolean} enabled
     * @returns {int}
     */
    function getFixedAmountPerQuote(fixedAmount, enabled) {
      return enabled ? fixedAmount : 0;
    }

    /**
     * Calculate VAT on a net price
     *
     * @param {int} netPriceInPence
     * @param {float} vatRate
     * @returns {int}
     */
    function calculateVatOnNetAmount(netPriceInPence, vatRate) {
      return Math.round(netPriceInPence * vatRate);
    }

    /**
     * Calculate VAT on a gross price
     *
     * @param {int} grossPriceInPence
     * @param {float} vatRate
     * @returns {int}
     */
    function calculateVatOnGrossAmount(grossPriceInPence, vatRate) {
      return Math.round(grossPriceInPence / (1 + 1 / vatRate));
    }
  }
})();
