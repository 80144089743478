(function () {
  'use strict';

  angular
    .module('module.productStyles')
    .controller('controller.productStyles.moreInfo.modal', [
      '$element',
      'dataProductStyle',
      'close',
      controller,
    ]);

  function controller($element, dataProductStyle, close) {
    /* jshint validthis: true */
    var vm = this;

    vm.closeModal = closeModal;

    vm.productStyle = dataProductStyle;
    vm.productStyleName = vm.productStyle.manufacturerName || vm.productStyle.name;

    /**
     * Trigger modal cleanup to ensure removed from DOM and scope destroyed
     */
    function closeModal() {
      // this has to happen here as following close the modal is removed from the DOM and doesn't clean up
      $element.parent().find('#product-style__modal').modal('hide');
      close();
    }
  }
})();
