(function () {
  'use strict';

  angular.module('module.products').controller('controller.products.list', [
    '$scope',
    'dataProducts',
    'dataProductType',
    'NavigationService',
    function ($scope, dataProducts, dataProductType, NavigationService) {
      $scope.navigation = NavigationService.getProductNavItems();
      $scope.products = dataProducts;
      $scope.productType = dataProductType;
    },
  ]);
})();
