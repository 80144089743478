(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuotePricingDiscountRow', directive);

  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/sales.quotes.pricingDiscountRow.directive.template.html',
      controllerAs: '$ctrl',
      scope: {
        readonly: '<',
        showOnQuote: '=',
        label: '=',
        value: '=',
        onUpdate: '<',
        discountValue: '=',
      },
      bindToController: true,
      controller: ['$scope', 'AuthenticationService', controller],
    };
  }

  function controller($scope, AuthenticationService) {
    /* jshint validthis: true */
    var vm = this;
    vm.focusDiscount = focusDiscount;

    vm.showCostPrice = false;
    AuthenticationService.isManagerUser().then(function (isManager) {
      vm.showCostPrice = isManager;
    });

    $scope.$watchGroup(['$ctrl.showOnQuote', '$ctrl.value'], function (newValues, oldValues) {
      if (!angular.equals(newValues, oldValues)) {
        vm.onUpdate();
      }
    });
  }

  function focusDiscount() {
    var discount = document.getElementById('discount');
    setTimeout(function () {
      discount.focus();
      discount.style.border = '1px solid #0067b5';
    }, 10);
  }
})();
