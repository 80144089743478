(function () {
  'use strict';

  angular.module('module.settings.advanced').controller('controller.settings.advanced.materials', [
    '$scope',
    'SettingsMaterialsService',
    'dataSettingsMaterials',
    function ($scope, SettingsMaterialsService, dataSettingsMaterials) {
      $scope.updated = false;

      $scope.materialTypes = dataSettingsMaterials;

      $scope.update = function () {
        SettingsMaterialsService.MaterialsUpdate($scope.materialTypes).then(function () {
          $scope.updated = true;
        });
      };
    },
  ]);
})();
