(function () {
  'use strict';

  angular.module('module.settings.advanced').service('SettingsSuppliersService', [
    'SettingsService',
    'CompaniesService',
    function (SettingsService, CompaniesService) {
      var service = {};

      service.SuppliersRetrieve = function () {
        return CompaniesService.listSuppliers().then(function (companies) {
          var suppliers = companies;

          for (var i = 0, l = suppliers.length; i < l; i++) {
            var supplier = {
              id: suppliers[i].id,
              name: suppliers[i].metadata.name,
              typeComponentSelected: false,
            };

            if (suppliers[i].metadata.isProfileSupplier === true) {
              supplier.typeProfileSelected = false;
            }

            suppliers[i] = supplier;
          }

          SettingsService.getSettings().then(function (settings) {
            if (
              settings.settings.companySuppliersComponents !== undefined &&
              settings.settings.companySuppliersProfiles !== undefined
            ) {
              var settingsSuppliersComponents = settings.settings.companySuppliersComponents;
              var settingsSuppliersProfiles = settings.settings.companySuppliersProfiles;

              for (var i = 0, l = suppliers.length; i < l; i++) {
                for (var j = 0, m = settingsSuppliersComponents.length; j < m; j++) {
                  if (settingsSuppliersComponents[j].id === suppliers[i].id) {
                    suppliers[i].typeComponentSelected = true;
                  }
                }

                if (suppliers[i].typeProfileSelected !== undefined) {
                  for (var k = 0, n = settingsSuppliersProfiles.length; k < n; k++) {
                    if (settingsSuppliersProfiles[k].id === suppliers[i].id) {
                      suppliers[i].typeProfileSelected = true;
                    }
                  }
                }
              }
            }
          });

          return suppliers;
        });
      };

      service.SuppliersUpdate = function (supplierData) {
        var processedSuppliersComponents = [],
          processedSuppliersProfiles = [];

        for (var i = 0, l = supplierData.length; i < l; i++) {
          /* If component supplier is true add to array */
          if (
            supplierData[i].typeComponentSelected !== undefined &&
            supplierData[i].typeComponentSelected === true
          ) {
            processedSuppliersComponents.push({
              id: supplierData[i].id,
            });
          }

          /* If profile supplier is true add to array */
          if (
            supplierData[i].typeProfileSelected !== undefined &&
            supplierData[i].typeProfileSelected === true
          ) {
            processedSuppliersProfiles.push({
              id: supplierData[i].id,
            });
          }
        }

        return SettingsService.getSettings(true).then(function (settings) {
          settings.settings.companySuppliersComponents = processedSuppliersComponents;
          settings.settings.companySuppliersProfiles = processedSuppliersProfiles;
          SettingsService.updateSettings(settings);
        });
      };

      return service;
    },
  ]);
})();
