(function () {
  'use strict';

  angular.module('module.marketing').directive('ttSampleVideo', directive);

  var packageVideo = {
      default: 'https://player.vimeo.com/video/281562791',
      bronze: 'https://player.vimeo.com/video/281520063',
      silver: 'https://player.vimeo.com/video/281519696',
      gold: 'https://player.vimeo.com/video/281519308',
    },
    videoSettings = '?color=f7005a&title=0&byline=0&portrait=0';

  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/marketing.video.sampleVideo.directive.template.html',
      controllerAs: 'videoVm',
      scope: {
        packageName: '<?',
      },
      controller: ['$sce', controller],
      bindToController: true,
    };
  }

  function controller($sce) {
    /* jshint validthis: true */
    var vm = this;

    vm.videoUrl = $sce.trustAsResourceUrl(getVideoUrl(vm.packageName));
  }

  /**
   * @param {string} packageName
   * @returns {string}
   */
  function getVideoUrl(packageName) {
    if ('undefined' === typeof packageName) {
      packageName = 'default';
    }

    if ('undefined' === typeof packageVideo[packageName]) {
      throw 'Invalid video package name';
    }

    return packageVideo[packageName] + videoSettings;
  }
})();
