(function () {
  'use strict';

  angular
    .module('module.users')
    .controller('controller.users.pending', ['pendingUsers', controller]);

  function controller(pendingUsers) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.pendingUsers = pendingUsers;
  }
})();
