(function () {
  'use strict';

  angular.module('module.sales').factory('JobTypeApiService', ['$q', 'ApiService', service]);

  function service($q, ApiService) {
    return {
      list,
    };
    /**
     * @returns {Promise}
     */
    function list() {
      return ApiService.get('/jobTypes/list').then((response) => response.data);
    }
  }
})();
