(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('ContractReportApiService', ['$q', 'Notification', 'ApiService', '$timeout', service]);

  function service($q, Notification, ApiService, $timeout) {
    var pollRate = 2000;

    return {
      create: create,
      pollUntilGenerated: pollUntilGenerated,
    };

    /**
     * @param {object} quote
     * @param {string?} notes
     * @param {string?} cancellation
     * @param {Array} documents
     * @param {bool} contractPdfShowDimensions
     *
     * @returns {$q}
     */
    function create(quote, notes, cancellation, documents, contractPdfShowDimensions) {
      return ApiService.post('/quotes/' + quote.id + '/contractReport', {
        notes: notes,
        cancellation: cancellation,
        includeDocuments: documents,
        contractPdfShowDimensions: contractPdfShowDimensions,
      }).then(
        function (response) {
          Notification.success('Created contract report');

          return response.data;
        },
        function () {
          Notification.error('Failed to create contract report');

          return $q.reject(null);
        },
      );
    }

    function pollUntilGenerated(quote, scope) {
      var deferred = $q.defer();
      var pollPromise;

      var pollFunction = function () {
        return ApiService.get('/quotes/' + quote.id).then(function (response) {
          var quote = response.data.quote;
          var contractReport = quote.metadata.documents.contractReport;

          if (!contractReport || contractReport.status === 'None') {
            deferred.resolve();
            return;
          }

          if (quote.metadata.documents.contractReport.status === 'Generating') {
            pollPromise = $timeout(pollFunction, pollRate);
            return;
          }

          deferred.resolve(quote.metadata.documents.contractReport);
        });
      };

      pollFunction();

      scope.$on('$destroy', function () {
        if (typeof pollPromise !== 'undefined') {
          $timeout.cancel(pollPromise);
        }
      });

      return deferred.promise;
    }
  }
})();
