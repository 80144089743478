(function () {
  'use strict';

  angular.module('module.retailers').component('ttOwnerRetailerPricing', {
    templateUrl: 'templates/retailers.ownerView.retailerPricing.component.template.html',
    bindings: {
      retailer: '<',
      onChange: '<',
    },
    controller: ['$window', 'RetailerPricingApiService', controller],
  });

  function controller($window, RetailerPricingApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.toggle = toggle;
    $ctrl.enabled = $ctrl.retailer.retailerPricingAvailable;

    function toggle() {
      if (
        !$window.confirm(
          'Are you sure you want to ' +
            ($ctrl.enabled ? 'disable' : 'enable') +
            ' retailer pricing?',
        )
      ) {
        return;
      }

      var togglePromise = $ctrl.enabled
        ? RetailerPricingApiService.disable($ctrl.retailer)
        : RetailerPricingApiService.enable($ctrl.retailer);

      togglePromise.then(function () {
        $ctrl.enabled = !$ctrl.enabled;
        $ctrl.onChange($ctrl.enabled);
      });
    }
  }
})();
