(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.quotes.retrieve', [
      '$rootScope',
      '$scope',
      '$location',
      'QuotesService',
      'dataQuote',
      'dataSettings',
      'dataQuoteItems',
      'dataCustomer',
      controller,
    ]);

  function controller(
    $rootScope,
    $scope,
    $location,
    QuotesService,
    dataQuote,
    dataSettings,
    dataQuoteItems,
    dataCustomer,
  ) {
    /* jshint validthis: true */
    var vm = this;
    var quotePoller = null;
    var cancelFinalisationListener = null;
    vm.reference = '';
    vm.isValid = true;
    vm.activeTab = 'Items';

    $scope.$on('$destroy', function () {
      cancelFinalisationListener();
      cancelPoller();
    });

    $rootScope.bodyClass = 'page__quote-items';
    vm.quote = $scope.quote = dataQuote;
    vm.quote.items = dataQuoteItems.items;
    vm.quote.customItems = dataQuoteItems.customItems;
    vm.customer = $scope.customer = dataCustomer;
    $scope.customersampleId = vm.customer.id;
    $scope.hashLocation = 'quotes';
    vm.companyActiveStatus = dataSettings.metadata.active;
    $scope.customerfullname = vm.customer.metadata.name.fullName;
    vm.toggleEditReference = toggleEditReference;
    vm.updateQuoteReference = updateQuoteReference;
    vm.showFinancials = false;

    checkQuoteForPolling();
    setHashLocation();

    function setHashLocation() {
      $location.hash($location.hash() || 'Items');
      $scope.itemActiveName = $location.hash();
      vm.activeTab = $location.hash();
    }

    cancelFinalisationListener = $rootScope.$on('quote.finalised', function (event, quote) {
      if (quote.id === vm.quote.id) {
        checkQuoteForPolling(true);
      }
    });

    function checkQuoteForPolling(force) {
      cancelPoller();
      force = force || false;

      if (force || QuotesService.quoteShouldBePolled(vm.quote)) {
        quotePoller = QuotesService.pollQuote(vm.quote.id);
        quotePoller.promise.then(updateQuote, null, updateQuote);
      }
    }

    function cancelPoller() {
      if (quotePoller !== null) {
        quotePoller.cancel();
      }
    }

    function updateQuote(data) {
      angular.merge(vm.quote, data.quote);
    }

    vm.handleKeyPress = function (event) {
      const pattern = /^[a-zA-Z0-9_-]+$/;
      vm.isValid = pattern.test(vm.reference);
      if (event && event.which === 13) {
        if (vm.isValid) {
          vm.updateQuoteReference();
        }
        event.preventDefault();
      }
    };
    if ($rootScope.activeTab) {
      vm.activeTab = $rootScope.activeTab;
    }
    vm.toggleVisibility = function (tabName) {
      vm.activeTab = tabName;
      $location.hash(vm.activeTab);
      $scope.itemActiveName = vm.activeTab;
    };

    vm.clearInput = function () {
      vm.reference = '';
      vm.isValid = true;
    };
    vm.update = true;

    function toggleEditReference() {
      var inputReference = document.getElementById('reference');
      setTimeout(function () {
        inputReference.focus();
        inputReference.style.border = '1px solid #0067b5';
      }, 10);
      if (!vm.reference) {
        let lastString = vm.quote.metadata.reference.lastIndexOf('-');
        let metaReference = vm.quote.metadata.reference.slice(0, lastString);
        vm.reference = metaReference;
      }
      vm.quote.metadata.reference = vm.reference;
      vm.editReference = !vm.editReference;
      vm.update = !vm.update;
    }

    function updateQuoteReference() {
      vm.editReference = !vm.editReference;
      vm.update = !vm.update;
      if (vm.reference) {
        QuotesService.updateReference(vm.quote, vm.reference);
      }
    }

    vm.showFinancials = false;
    vm.toggleFinancials = function () {
      vm.showFinancials = !vm.showFinancials;
    };
  }
})();
