(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .component('ttRetailerProductsPricingMatrices', {
      templateUrl: 'templates/settings.retailerProducts.pricingMatrices.component.template.html',
      bindings: {
        selectedProduct: '<',
        uploadedMatrices: '<',
        onChange: '<',
        parentPricingMethod: '<?pricingMethod',
        matrixType: '<?',
        retailerProduct: '<?',
      },
      controller: ['matrixTypes', 'RetailerProductsPricingMatricesApiService', controller],
    });

  function controller(matrixTypes, PricingMatrixApiService) {
    /* jshint validthis: true */
    var $ctrl = this;
    var changesToBeApplied;

    $ctrl.matrixTypes = matrixTypes;
    $ctrl.requiredMatrices = [];
    $ctrl.optionalMatrices = [];
    $ctrl.hasMatrices = false;
    $ctrl.downloading = false;

    $ctrl.styleLabels = {
      CS101: '(Top Hung)',
      CS102: '(Left Hung)',
      CS103: '(Right Hung)',
      CS105: '(Dummy)',
      TS100: '(Left Hung)',
      TS101: '(Right Hung)',
      TS102: '(Dummy)',
    };

    $ctrl.$onInit = function () {
      $ctrl.matrixType = $ctrl.matrixType || 'widthByHeight';

      if (angular.isDefined($ctrl.parentPricingMethod)) {
        $ctrl.pricingMethod = $ctrl.parentPricingMethod;
        changePricingMethod();
      }
    };

    $ctrl.$onChanges = function (changes) {
      if (
        angular.isDefined(changes.selectedProduct) &&
        angular.isDefined(changes.selectedProduct.currentValue)
      ) {
        changeSelectedProduct();
      }
    };

    $ctrl.updatePricingMethod = changePricingMethod;
    $ctrl.matricesAdded = matricesAdded;
    $ctrl.matrixDeleted = matrixDeleted;
    $ctrl.showOptionalMatrix = showOptionalMatrix;
    $ctrl.downloadMatrices = downloadMatrices;
    $ctrl.onUpdateMatrices = onUpdateMatrices;

    function changeSelectedProduct() {
      $ctrl.hasMatrices = false;
      if (angular.isUndefined($ctrl.selectedProduct.pricingMethods)) {
        return;
      }

      $ctrl.pricingMethods = $ctrl.selectedProduct.pricingMethods.map(
        function (pricingMethodMatrices) {
          return pricingMethodMatrices.pricingMethod;
        },
      );

      $ctrl.pricingMethod =
        $ctrl.pricingMethod || $ctrl.parentPricingMethod || $ctrl.pricingMethods[0];

      changePricingMethod();
    }

    function changePricingMethod() {
      // The retailer can change the pricing method freely even if they have matrices uploaded and only
      // the matrices incompatible with the new method will be removed

      // IMPORTANT: When we add new matrix types, e.g. w+h, make sure that we remove *all* the matrices

      changesToBeApplied = changesToBeApplied || {
        queuedForDelete: [],
        queuedForUpload: [],
      };
      changesToBeApplied.pricingMethod = $ctrl.pricingMethod;

      var methodMatrices = $ctrl.selectedProduct.pricingMethods.filter(function (pricingMethod) {
        return pricingMethod.pricingMethod === $ctrl.pricingMethod;
      })[0];

      if (!methodMatrices) {
        return;
      }

      // Get an array of all the matrix references (optional and required) for the current pricing method
      var methodMatrixRefs = methodMatrices.optionalMatrices
        .map(function (matrix) {
          return matrix.reference;
        })
        .concat(
          methodMatrices.requiredMatrices.map(function (matrix) {
            return matrix.reference;
          }),
        );

      // Remove incompatible matrices from queuedForUpload
      changesToBeApplied.queuedForUpload = changesToBeApplied.queuedForUpload.filter(
        function (matrix) {
          return methodMatrixRefs.includes(matrix.reference);
        },
      );

      // Add incompatible already-uploaded matrices to queuedForDelete
      Object.keys($ctrl.uploadedMatrices).forEach(function (reference) {
        $ctrl.hasMatrices = true;
        if (!methodMatrixRefs.includes(reference)) {
          changesToBeApplied.queuedForDelete.push({reference: reference});
        }
      });

      // Make an array of all the matrices that are still on the product
      var matricesRemaining = angular.copy($ctrl.uploadedMatrices);
      changesToBeApplied.queuedForUpload.forEach(function (m) {
        matricesRemaining[m.reference] = m.description;
      });
      changesToBeApplied.queuedForDelete.map(function (m) {
        delete matricesRemaining[m.reference];
      });

      // Mark which matrices are uploaded for the template
      $ctrl.requiredMatrices = markUploadedMatrices(
        methodMatrices.requiredMatrices,
        matricesRemaining,
      );
      $ctrl.optionalMatrices = markUploadedMatrices(
        methodMatrices.optionalMatrices,
        matricesRemaining,
      );

      $ctrl.onChange(changesToBeApplied);
    }

    function markUploadedMatrices(availableMatrices, uploadedMatrices) {
      return availableMatrices.map(function (matrix) {
        if (Object.keys(uploadedMatrices).includes(matrix.reference)) {
          matrix.uploaded = true;
          matrix.description = $ctrl.uploadedMatrices[matrix.reference];
        } else {
          matrix.uploaded = false;
          matrix.queuedForUpload = false;
          matrix.description = null;
        }

        return matrix;
      });
    }

    function matricesAdded(addedMatrices) {
      addedMatrices.forEach(function (addedMatrix) {
        var reference = addedMatrix.reference;
        var matrix = getMatrix(reference);
        angular.merge(matrix, addedMatrix);

        removeMatrixFromChangesToBeApplied(matrix);
        changesToBeApplied.queuedForUpload.push(matrix);
      });

      $ctrl.onChange(changesToBeApplied);
    }

    function matrixDeleted(matrix) {
      removeMatrixFromChangesToBeApplied(matrix);
      if (matrix.uploaded) {
        changesToBeApplied.queuedForDelete.push(matrix);
      }

      $ctrl.onChange(changesToBeApplied);
    }

    function getMatrix(reference) {
      var matrices = $ctrl.requiredMatrices.concat($ctrl.optionalMatrices);

      for (var i = 0; i < matrices.length; i++) {
        if (matrices[i].reference === reference) {
          return matrices[i];
        }
      }

      throw Error('Matrix with reference ' + reference + ' not found');
    }

    function showOptionalMatrix(matrix) {
      return (matrix.uploaded && !matrix.queuedForDelete) || matrix.queuedForUpload;
    }

    function removeMatrixFromChangesToBeApplied(matrix) {
      changesToBeApplied.queuedForUpload = changesToBeApplied.queuedForUpload.filter(
        function (queuedMatrix) {
          return matrix.reference !== queuedMatrix.reference;
        },
      );

      changesToBeApplied.queuedForDelete = changesToBeApplied.queuedForDelete.filter(
        function (queuedMatrix) {
          return matrix.reference !== queuedMatrix.reference;
        },
      );
    }

    function downloadMatrices() {
      $ctrl.downloading = true;

      PricingMatrixApiService.download($ctrl.retailerProduct).finally(function () {
        $ctrl.downloading = false;
      });
    }

    function onUpdateMatrices(updateData) {
      if (updateData) {
        $ctrl.retailerProduct.matrixLastUpdateAt = updateData.updateDate;
        $ctrl.retailerProduct.matrixLastUpdatePercentage = updateData.updatePercentage;
      }
    }
  }
})();
