(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuotePricingRow', directive);

  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/sales.quotes.pricingRow.directive.template.html',
      controllerAs: 'pricingVm',
      scope: {
        costPrice: '=?',
        sellPrice: '=?',
        rowLabel: '<?',
      },
      controller: ['$scope', 'AuthenticationService', controller],
      bindToController: true,
    };
  }

  function controller($scope, AuthenticationService) {
    /* jshint validthis: true */
    var vm = this;

    vm.showCostPrice = false;
    vm.showSellPrice = typeof vm.sellPrice !== 'undefined';

    vm.costPricePresent = typeof vm.costPrice === 'number';
    vm.sellPricePresent = typeof vm.sellPrice === 'number';

    AuthenticationService.isManagerUser().then(function (isManager) {
      vm.showCostPrice = isManager;
    });
  }
})();
