(function () {
  'use strict';

  angular.module('module.sales').filter('address', ['AddressService', filter]);

  /**
   * Format an address
   *
   * @returns {function}
   */
  function filter(AddressService) {
    return function (address, type) {
      if (typeof address === 'undefined') {
        return '';
      }

      var hasAddress = false;
      Object.keys(address).forEach(function (key) {
        if (address[key]) {
          hasAddress = true;
        }
      });

      if (!hasAddress) {
        return '';
      }

      if (type === 'tiny') {
        return AddressService.getTinyAddress(address);
      }

      if (type === 'short') {
        return AddressService.getShortAddress(address);
      }

      if (type === 'long') {
        return AddressService.getLongAddress(address);
      }

      return '';
    };
  }
})();
