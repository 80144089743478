(function () {
  'use strict';

  angular.module('module.settings.retailerProducts').component('ttRetailerProductOptions', {
    templateUrl: 'templates/settings.retailerProducts.options.component.template.html',
    bindings: {
      defaultOption: '=?',
      availableOptions: '=',
      optionsList: '=',
      newProduct: '=',
      name: '@',
      label: '@',
      noDefault: '<?',
      noAvailable: '<?',
      small: '<?',
      allowRenaming: '=?',
      renameObject: '=?',
      minToShow: '=?',
    },
    controller: ['$scope', 'Notification', controller],
  });

  function controller($scope, Notification) {
    /* jshint validthis: true */
    var vm = this;

    vm.checkboxChange = checkboxChange;
    vm.radioChange = radioChange;
    vm.minToShow = vm.minToShow === undefined ? 0 : vm.minToShow;

    $scope.$watch('$ctrl.optionsList', changeOptions);

    function checkboxChange(option) {
      if (vm.noAvailable) {
        return;
      }

      if (vm.defaultOption === option && !vm.optionBooleans[option]) {
        var done = false;
        angular.forEach(vm.optionBooleans, function (selected, op) {
          if (selected && !done) {
            vm.defaultOption = op;
            done = true;
          }
        });
        if (!done) {
          Notification.error('At least one option must be available');
          vm.optionBooleans[option] = true;
        }
      }

      vm.availableOptions = [];
      angular.forEach(vm.optionBooleans, function (selected, option) {
        if (selected) {
          vm.availableOptions.push(option);
        }
      });
    }

    function radioChange(option) {
      if (vm.noDefault) {
        return;
      }

      if (!vm.optionBooleans[option]) {
        vm.optionBooleans[option] = true;
        vm.availableOptions.push(option);
      }
    }

    function changeOptions() {
      if (vm.noAvailable && vm.noDefault) {
        return;
      }

      vm.optionBooleans = {};

      // Note: If the saved retailer product doesn't have availableOptions set
      // at all, all options are available
      if (vm.newProduct || !vm.availableOptions) {
        if (!vm.optionsList || !vm.optionsList.length) {
          vm.defaultOption = null;
          vm.availableOptions = null;
        } else {
          vm.availableOptions = [];
          vm.defaultOption = null;
          angular.forEach(vm.optionsList, function (opt, index) {
            if (opt === 'Mechanical/Welded' || opt === 'Welded/Mechanical') {
              vm.optionBooleans[opt] = false;
            } else {
              vm.optionBooleans[opt] = true;
            }
            vm.availableOptions.push(opt);
            if (index === 0) {
              vm.defaultOption = opt;
            }
          });
        }
      } else {
        angular.forEach(vm.optionsList, function (opt, index) {
          vm.optionBooleans[opt] = vm.availableOptions.includes(opt);
        });
      }

      if (vm.noDefault && vm.defaultOption) {
        vm.defaultOption = null;
      } else if (vm.optionsList && !vm.optionsList.includes(vm.defaultOption)) {
        vm.defaultOption = vm.optionsList[0];
      }
    }
  }
})();
