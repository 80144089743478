(function () {
  'use strict';

  angular.module('module.products').directive('ttProductComponents', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/products.retrieve.components.directive.template.html',
        controllerAs: 'componentsVm',
        scope: {
          product: '=',
        },
        controller: ['ProductsService', 'AuthenticationService', controller],
        bindToController: true,
      };
    },
  ]);

  /**
   * @param ProductsService
   * @param AuthenticationService
   */
  function controller(ProductsService, AuthenticationService) {
    /* jshint validthis: true */
    var vm = this;

    vm.loadingComponents = true;
    vm.setDefaultComponent = setDefaultComponent;
    vm.removeComponent = removeComponent;
    init();

    function init() {
      loadComponents();

      AuthenticationService.getUser().then(function (user) {
        vm.permComponents = user.permissions.companyType === 'manufacturer';
      });
    }

    /**
     * Load the product components.
     */
    function loadComponents() {
      vm.componentTypes = [];
      ProductsService.retrieveProductComponents(vm.product.id).then(function (componentTypes) {
        vm.componentTypes = componentTypes;
        if (componentTypes.length !== 0) {
          vm.activeTab = componentTypes[0].name;
        }

        vm.loadingComponents = false;
      });
    }

    /**
     * @param {object} component
     * @param {object} componentType
     */
    function setDefaultComponent(component, componentType) {
      ProductsService.setDefaultComponent(vm.product.id, component.id).then(function () {
        angular.forEach(componentType.components, function (loopComponent) {
          if (loopComponent.metadata.componentType.id === component.metadata.componentType.id) {
            loopComponent.default = false;
          }
        });

        component.default = true;
      });
    }

    /**
     * @param {object} component
     * @param {object} componentType
     */
    function removeComponent(component, componentType) {
      ProductsService.removeComponent(vm.product.id, component.id).then(function () {
        componentType.components.splice(componentType.components.indexOf(component), 1);
      });
    }
  }
})();
