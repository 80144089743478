(function () {
  'use strict';

  angular.module('module.settings').controller('controller.settings.productranges.list', [
    '$scope',
    'dataProductRanges',
    function ($scope, dataProductRanges) {
      $scope.productranges = dataProductRanges.productranges;
      $scope.pagination = dataProductRanges.pagination;
    },
  ]);
})();
