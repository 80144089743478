(function () {
  'use strict';

  angular
    .module('module.settings')
    .service('CurrencyService', ['$rootScope', 'SettingsService', service]);

  var currencySymbols = {
    pound: '£',
    euro: '€',
    dollar: '$',
  };

  var defaultSettings = {
    currency: 'pound',
    currencySymbol: '£',
    salesTaxType: 'VAT',
    salesTaxRate: '0.20',
  };

  function service($rootScope, SettingsService) {
    var settings;

    resetSettings();

    $rootScope.$on('settings.update', function (event, settings) {
      setFromCompanySettings(settings);
    });

    SettingsService.getSettings().then(setFromCompanySettings);

    return {
      setSettings: setSettings,
      getSettings: getSettings,
    };

    function setSettings(currency, salesTaxType, salesTaxRate) {
      settings = {
        currency: currency,
        currencySymbol: currencySymbols[currency],
        salesTaxType: salesTaxType,
        salesTaxRate: salesTaxRate,
      };
    }

    function getSettings() {
      return Object.assign({}, settings);
    }

    function setFromCompanySettings(companySettings) {
      if (companySettings === null) {
        resetSettings();

        return;
      }

      var currencySettings = companySettings.settings.currency;
      setSettings(currencySettings.currency, currencySettings.salesTaxType, currencySettings.rate);
    }

    function resetSettings() {
      settings = angular.copy(defaultSettings);
    }
  }
})();
