(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.orders.delivery.modal', [
      '$element',
      'SettingsService',
      'AddressService',
      'OrderPartApiService',
      'order',
      'orderPart',
      'close',
      controller,
    ]);

  var deliveryAddressTypes = {
    retailer: 'retailer',
    customer: 'customer',
    site: 'site',
    other: 'other',
  };

  function controller(
    $element,
    SettingsService,
    AddressService,
    OrderPartApiService,
    order,
    orderPart,
    close,
  ) {
    /* jshint validthis: true */
    var vm = this;

    vm.closeModal = closeModal;
    vm.save = save;
    vm.deliveryAddressTypes = deliveryAddressTypes;

    init();

    /**
     * Set starting conditions
     */
    function init() {
      vm.orderPart = orderPart;
      vm.customerAddress = order.customer.address;
      vm.siteAddress = AddressService.getSiteAddressFromQuote(order.quote);
      SettingsService.getSettings().then(function (settings) {
        vm.retailerAddress = settings.metadata.address;
        vm.postcodeFieldName = settings.metadata.locale === 'UK' ? 'Postcode' : 'ZIP Code';
      });
    }

    /**
     * Save order and close modal
     */
    function save() {
      OrderPartApiService.update(vm.orderPart).then(function () {
        closeModal(true);
      });
    }

    /**
     * Trigger modal cleanup to ensure removed from DOM and scope destroyed
     *
     * @param {boolean?} updated
     */
    function closeModal(updated) {
      updated = updated || false;

      // this has to happen here as following close the modal is removed from the DOM and doesn't clean up
      $element.parent().find('#deliveryModal').modal('hide');
      close({updated: updated});
    }
  }
})();
