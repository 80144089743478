(function () {
  'use strict';

  angular.module('module.sales').component('ttDocumentsList', {
    templateUrl: 'templates/sales.quotes.documents.list.component.template.html',
    controller: [
      '$scope',
      'PricingRequestApiService',
      'ContractReportApiService',
      'SettingsService',
      'QuoteFinalisationService',
      'Notification',
      'CurrencyService',
      'bugsnagClient',
      controller,
    ],
    bindings: {
      quote: '<',
    },
  });

  function controller(
    $scope,
    PricingRequestApiService,
    ContractReportApiService,
    SettingsService,
    QuoteFinalisationService,
    Notification,
    CurrencyService,
    bugsnagClient,
  ) {
    /* jshint validthis: true */
    var vm = this;

    vm.pricingRequests = [];
    vm.$onInit = onInit;

    vm.currency = CurrencyService.getSettings();
    vm.currencyIcon = getCurrencyIcon(vm.currency.currency);

    function getCurrencyIcon(currency) {
      switch (currency) {
        case 'pound':
          return 'fa-gbp';
        case 'dollar':
          return 'fa-usd';
        case 'euro':
          return 'fa-eur';
        default:
          bugsnagClient.notify(new Error(`Invalid currency type: ${currency}`));
          return 'fa-gbp';
      }
    }

    function onInit() {
      PricingRequestApiService.pollList(vm.quote, $scope).then(
        function (pricingRequests) {
          vm.pricingRequests = pricingRequests;
        },
        null,
        function (pricingRequests) {
          vm.pricingRequests = pricingRequests;
        },
      );

      ContractReportApiService.pollUntilGenerated(vm.quote, $scope).then(function (contractReport) {
        if (contractReport) {
          vm.contractReport = contractReport;
        }
      });

      SettingsService.getSettings().then(function (settings) {
        vm.retailerActive = settings.metadata.active;
      });

      vm.contractReport = vm.quote.metadata.documents.contractReport;
      vm.customerDetailsComplete = vm.quote.metadata.customer.active;
    }

    $scope.$watch(
      '$ctrl.quote.metadata',
      function () {
        vm.cannotFinalise = cannotFinalise();
      },
      true,
    );

    function cannotFinalise() {
      if (vm.quote.metadata.status === 'expired') {
        return true;
      }

      return (
        !QuoteFinalisationService.isFinalised(vm.quote) &&
        !QuoteFinalisationService.isProcessing(vm.quote) &&
        !vm.quote.metadata.allowFinalise
      );
    }

    vm.copyLinkAction = copyLinkAction;
    function copyLinkAction() {
      Notification.success('Link copied to clipboard');
    }
  }
})();
