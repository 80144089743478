(function () {
  'use strict';

  angular.module('module.sales').factory('AddressService', service);

  function service() {
    return {
      capturePlusToAddress: capturePlusToAddress,
      getTinyAddress: getTinyAddress,
      getShortAddress: getShortAddress,
      getLongAddress: getLongAddress,
      getSiteAddress: getSiteAddress,
      getSiteAddressFromQuote: getSiteAddressFromQuote,
      addressEmpty: addressEmpty,
    };

    /**
     * Convert capture plus address to model address
     *
     * @param {object} capturePlusAddress
     * @returns {object}
     */
    function capturePlusToAddress(capturePlusAddress) {
      return {
        line1: capturePlusAddress.Line1,
        line2: capturePlusAddress.Line2,
        line3: capturePlusAddress.Line3,
        city: capturePlusAddress.City,
        county: capturePlusAddress.Province,
        country: capturePlusAddress.CountryName,
        postcode: capturePlusAddress.PostalCode,
      };
    }

    /**
     * Get a short formatted address
     *
     * @param {object} address
     * @returns {string}
     */
    function getShortAddress(address) {
      var addressArray = [];

      addToArrayIfSet(addressArray, address.line1 || null);
      addToArrayIfSet(addressArray, address.line2 || null);
      addToArrayIfSet(addressArray, address.line3 || null);
      addToArrayIfSet(addressArray, address.city || null);
      addToArrayIfSet(addressArray, address.postcode || null);

      return addressArray.join(', ');
    }

    /**
     * Get a long formatted address with line breaks
     *
     * @param {object} address
     * @returns {string}
     */
    function getLongAddress(address) {
      var addressArray = [];

      addToArrayIfSet(addressArray, address.line1 || null);
      addToArrayIfSet(addressArray, address.line2 || null);
      addToArrayIfSet(addressArray, address.line3 || null);
      addToArrayIfSet(addressArray, address.city || null);
      addToArrayIfSet(addressArray, address.county || null);
      addToArrayIfSet(addressArray, address.postcode || null);
      addToArrayIfSet(addressArray, address.country || null);

      return addressArray.join('<br>');
    }

    /**
     * Get a tiny formatted address
     *
     * @param {object} address
     * @returns {string}
     */
    function getTinyAddress(address) {
      var addressArray = [];

      addToArrayIfSet(addressArray, address.line1 || null);
      addToArrayIfSet(addressArray, address.line2 || null);
      addToArrayIfSet(addressArray, address.city || null);
      addToArrayIfSet(addressArray, address.county || null);
      addToArrayIfSet(addressArray, address.postcode || null);

      return addressArray.join(', ');
    }

    /**
     * Get an address suitable for quote site address
     *
     * @param {object} address
     * @returns {string}
     */
    function getSiteAddress(address) {
      var addressArray = [];

      addToArrayIfSet(addressArray, address.line1 || null);
      addToArrayIfSet(addressArray, address.line2 || null);
      addToArrayIfSet(addressArray, address.line3 || null);
      addToArrayIfSet(addressArray, address.city || null);
      addToArrayIfSet(addressArray, address.county || null);
      addToArrayIfSet(addressArray, address.country || null);

      return addressArray.join('\n');
    }

    /**
     * Get a string site address given a quote
     *
     * @param {object} quote
     * @returns {string|null}
     */
    function getSiteAddressFromQuote(quote) {
      if (quote.metadata.siteAddress === null) {
        return null;
      }

      var siteAddress = quote.metadata.siteAddress.replace(/(?:\r\n|\r|\n)/g, ', ');

      if (quote.metadata.siteAddressPostcode) {
        siteAddress = siteAddress + ', ' + quote.metadata.siteAddressPostcode;
      }

      return siteAddress;
    }

    function addressEmpty(address) {
      var addressString =
        address.line1 +
        address.line2 +
        address.line3 +
        address.city +
        address.county +
        address.country;

      return addressString.length === 0;
    }

    /**
     * @param {Array} array
     * @param {?String} string
     * @returns {Array}
     */
    function addToArrayIfSet(array, string) {
      if (string === null) {
        return array;
      }

      if (string.length) {
        array.push(string);
      }

      return array;
    }
  }
})();
