(function () {
  'use strict';

  angular.module('module.settings.advanced').constant('activeStatus', true);
  angular
    .module('module.settings.advanced')
    .directive('ttAccountMonitoring', ['ModalService', directive]);

  function directive(ModalService) {
    return {
      restrict: 'A',
      link: link,
    };

    function link(scope, element, attrs) {
      element.on('click', function (event) {
        event.preventDefault();
        showModal(scope, attrs);
      });
    }

    function showModal(scope, attrs) {
      ModalService.showModal({
        controller: 'controller.settings.advanced.accountMonitoring',
        controllerAs: 'modalVm',
        templateUrl: 'templates/settings.advanced.accountMonitoring.modal.template.html',
        inputs: {
          dataProfile: scope.$eval(attrs.dataProfile),
        },
      }).then(function (modal) {
        // Open modal
        var modalElement = modal.element.parent().find('#account_monitoring_modal');
        modalElement.modal();
      });
    }
  }
})();
