(function () {
  'use strict';

  angular.module('module.products').directive('ttComponentPricing', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/components.retrieve.pricing.directive.template.html',
        controllerAs: 'pricingVm',
        scope: {
          component: '=',
        },
        controller: ['ComponentsService', controller],
        bindToController: true,
      };
    },
  ]);

  /**
   * @param ComponentsService
   */
  function controller(ComponentsService) {
    /* jshint validthis: true */
    var vm = this;

    vm.save = save;

    vm.pricing = vm.component.prices;

    function save() {
      ComponentsService.updateComponent(vm.component);
    }
  }
})();
