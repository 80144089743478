(function () {
  'use strict';

  angular.module('module.settings.billingAndPayments').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('settings.billingAndPayments', {
      abstract: true,
      url: '/billing-and-payments',
      views: {
        'content@': {
          controller: [
            '$scope',
            'NavigationService',
            function ($scope, NavigationService) {
              $scope.navigation = NavigationService.getBillingSettingsNavItems();
            },
          ],
          templateUrl: 'templates/settings.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Billing & Payments',
      },
    });

    $stateProvider.state('settings.billingAndPayments.billing', {
      url: '/billing',
      controller: 'controller.settings.billingAndPayments.billing',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.billingAndPayments.billing.template.html',
      resolve: {
        dataPaymentMethod: [
          'StripeApiService',
          function (StripeApiService) {
            return StripeApiService.getPaymentMethod();
          },
        ],
        dataCommissionRate: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings().then(function (settings) {
              return settings.metadata.commissionRate;
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Billing',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Billing & Payments | Billing',
        },
      },
    });
  }
})();
