(function () {
  'use strict';

  angular.module('module.authentication').directive('fireChange', directive);

  /**
   * Directive to trigger a change event on fireChange events
   */
  function directive() {
    return {
      restrict: 'A',
      link: link,
    };

    /**
     * @param scope
     * @param element
     *
     */
    function link(scope, element) {
      scope.$on('fireChange', function () {
        element.trigger('change');
      });
    }
  }
})();
