(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('PdfPrintService', ['$q', '$http', '$window', '$rootScope', 'QuotesService', service]);

  function service($q, $http, $window, $rootScope, QuotesService) {
    var iframe = null;

    return {
      print: print,
    };

    /**
     * Print a quote
     *
     * @param {object} quote
     * @returns $q
     */
    function print(quote) {
      if (iframe && iframe.contentWindow && iframe.dataset.quoteId === quote.id) {
        iframe.contentWindow.print();

        return $q.resolve();
      }

      return getPdfDataUrl(quote).then(function (objectUrl) {
        if (iframe === null) {
          createIframe();
        }

        iframe.src = objectUrl;
        iframe.dataset.quoteId = quote.id;
      });
    }

    function createIframe() {
      iframe = $window.document.createElement('iframe');
      iframe.id = 'print__pdf';
      iframe.style.display = 'none';
      iframe.addEventListener('load', function () {
        // If you call .print without the timeout you get a blank page first
        window.setTimeout(function () {
          iframe.contentWindow.print();
        });
      });

      // Note: afterprint event doesn't fire on IFrame.contentWindow if it contains a PDF
      var deregisterListener = $rootScope.$on('$stateChangeStart', function () {
        $window.document.body.removeChild(iframe);
        iframe = null;
        deregisterListener();
      });

      $window.document.body.appendChild(iframe);
    }

    /**
     * Get promise of a PDF data url
     *
     * @param {object} quote
     * @returns {$q}
     */
    function getPdfDataUrl(quote) {
      return $http
        .get(QuotesService.getQuotePdfFetchUrl(quote), {responseType: 'blob'})
        .then(function (response) {
          return $window.URL.createObjectURL(response.data);
        });
    }
  }
})();
