(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.quotes.update', [
      '$scope',
      '$state',
      'QuotesService',
      'AddressService',
      'QuoteFinalisationService',
      'dataQuote',
      controller,
    ]);

  function controller(
    $scope,
    $state,
    QuotesService,
    AddressService,
    QuoteFinalisationService,
    dataQuote,
  ) {
    /* jshint validthis: true */
    var vm = this;

    vm.update = update;
    vm.copyAddress = copyAddress;
    vm.quote = dataQuote;

    vm.readOnly = QuoteFinalisationService.isFinalised(dataQuote);

    // TODO: this is for the breadcrumbs, embed customer properly
    $scope.quote = dataQuote;
    $scope.customer = dataQuote.metadata.customer;
    $scope.customerfullname = dataQuote.metadata.customer.name.fullName;
    $scope.customersampleId = dataQuote.metadata.customer.id;
    $scope.hashLocation = 'quotes';
    $scope.customer.metadata = {
      name: $scope.customer.name,
    };

    function update() {
      QuotesService.updateQuote(vm.quote).then(function (quote) {
        $state.go('sales.quotes.retrieve', {quoteId: quote.id});
      });
    }

    /**
     * Copies the customers address to the site address of the quote
     */
    function copyAddress() {
      vm.quote.metadata.siteAddress = AddressService.getSiteAddress(
        vm.quote.metadata.customer.address,
      );
      vm.quote.metadata.siteAddressPostcode = vm.quote.metadata.customer.address.postcode;
    }
  }
})();
