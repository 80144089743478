(function () {
  'use strict';

  angular.module('module.visualiser').directive('ttItemImage', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/visualiser.itemImage.directive.template.html',
        scope: {
          image: '=',
        },
        controllerAs: 'imageVm',
        bindToController: true,
        controller: ['$element', controller],
        link: link,
      };
    },
  ]);

  /**
   * @param $element
   */
  function controller($element) {
    /* jshint validthis: true */
    var vm = this;

    vm.getDragData = getDragData;

    /**
     * Get additional data for drag
     *
     * @returns {object}
     */
    function getDragData() {
      var img = $element.find('img')[0];

      return {
        id: vm.image.id,
        itemId: vm.image.itemId,
        url: vm.image.images.outsidePngUrl,
        width: img.offsetWidth,
        height: img.offsetHeight,
      };
    }
  }

  /**
   * @param scope
   * @param element
   */
  function link(scope, element) {
    // prevent scree dragging with item image on mobile devices
    element.find('.item__image')[0].addEventListener(
      'touchmove',
      function (event) {
        event.preventDefault();
      },
      false,
    );
  }
})();
