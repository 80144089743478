(function () {
  'use strict';
  angular
    .module('module.retailers')
    .controller('controller.retailers.ownerList', [
      'RetailersService',
      'dataRetailers',
      'AuthenticationService',
      '$state',
      controller,
    ]);
  function controller(RetailersService, dataRetailers, AuthenticationService, $state) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.changePage = changePage;
    $ctrl.changeSearchTerm = changeSearchTerm;
    $ctrl.searchTerm = '';
    $ctrl.noRetailers = !dataRetailers.retailers.length;
    $ctrl.retailers = dataRetailers.retailers;
    $ctrl.logIn = logIn;
    $ctrl.pagination = {
      currentPage: 1,
      totalPages: dataRetailers.totalPages,
    };

    $ctrl.selectedUser = [];

    function populateSelectedUser() {
      $ctrl.selectedUser = [];
      $ctrl.retailers.forEach(function (retailer) {
        if (
          retailer &&
          retailer.metadata &&
          retailer.metadata.activeUsers &&
          retailer.metadata.activeUsers.length > 0
        ) {
          $ctrl.selectedUser.push(retailer.metadata.activeUsers[0].uuid);
        } else {
          $ctrl.selectedUser.push(null);
        }
      });
    }

    populateSelectedUser();

    function changeSearchTerm() {
      getRetailers(1);
    }

    function changePage(page) {
      getRetailers(page);
    }

    function logIn(selectedUser) {
      if (selectedUser) {
        AuthenticationService.masqueradeAs(selectedUser).then(function () {
          $state.go('dashboard');
        });
      }
    }

    function getRetailers(page) {
      RetailersService.listRetailers(page, null, $ctrl.searchTerm).then(function (list) {
        $ctrl.noRetailers = !list.retailers.length;
        $ctrl.retailers = list.retailers;
        $ctrl.pagination.totalPages = list.totalPages;
        $ctrl.pagination.currentPage = page;
        populateSelectedUser();
      });
    }
  }
})();
