(function () {
  'use strict';

  angular
    .module('module.retailers')
    .factory('OwnerRetailerProductsApiService', ['$q', 'ApiService', 'Notification', service]);

  function service($q, ApiService, Notification) {
    return {
      listForRetailer: listForRetailer,
    };

    function listForRetailer(retailerId) {
      return ApiService.get('/companies/retailers/' + retailerId + '/retailer-products').then(
        function (response) {
          return response.data.retailerProducts;
        },
      );
    }
  }
})();
