(function () {
  'use strict';

  angular.module('module.sales').directive('ttCustomerModal', ['ModalService', directive]);

  function directive(ModalService) {
    return {
      restrict: 'A',
      scope: {
        customer: '=?',
        onUpdate: '<?',
      },
      link: link,
    };

    function link(scope, element) {
      element.on('click', function (event) {
        event.preventDefault();
        showModal(scope.customer, scope.onUpdate);
      });
    }

    function showModal(customer, onUpdate) {
      ModalService.showModal({
        controller: 'controller.sales.customers.modal',
        templateUrl: 'templates/sales.customers.modal.template.html',
        controllerAs: 'modalVm',
        inputs: {
          customer: customer,
        },
      }).then(function (modal) {
        // open modal
        modal.element.parent().find('#customerModal').modal();

        modal.close.then(function (updated) {
          if (updated && onUpdate) {
            onUpdate();
          }
        });
      });
    }
  }
})();
