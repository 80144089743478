(function () {
  'use strict';

  angular.module('module.settings.advanced').service('ProfileSuitesService', [
    '$q',
    'ApiService',
    'SettingsService',
    function ($q, ApiService, SettingsService) {
      var service = {};

      service.InitProfileSuite = function () {
        var profilesuite = {
          reference: {},
        };

        return $q(function (resolve, reject) {
          $q.all({
            data: SettingsService.getSettings(),
          }).then(function (results) {
            profilesuite.reference.suppliers = results.data.settings.companySuppliersProfiles;
            profilesuite.reference.materialTypes = results.data.settings.materialTypes;
          });

          resolve(profilesuite);
        });
      };

      service.ListProfileSuites = function () {
        var url = '/profilesuites';

        var promises = [ApiService.get(url)];

        return $q(function (resolve, reject) {
          $q.all(promises).then(function (data) {
            var pagination = {
              pageItemsStart: data[0].data.profileSuites.length > 1 ? 1 : 0,
              pageItemsEnd: data[0].data.profileSuites.length,
              pageItemsTotal: data[0].data.profileSuites.length,
            };

            if (pagination.pageItemsEnd > pagination.pageItemsTotal) {
              pagination.pageItemsEnd = pagination.pageItemsTotal;
            }

            resolve({
              profilesuites: data[0].data.profileSuites,
              links: data[0].data.links,
              pagination: pagination,
              reference: {},
            });
          });
        });
      };

      service.CreateProfileSuite = function (ProfileSuiteData) {
        return ApiService.post('/profilesuites', ProfileSuiteData);
      };

      service.RetrieveProfileSuite = function (ProfileSuiteId) {
        return ApiService.get('/profilesuites/' + ProfileSuiteId).then(function (response) {
          if (
            response.data.profileSuite.metadata.alias === '' ||
            response.data.profileSuite.metadata.alias === null
          ) {
            response.data.profileSuite.metadata.alias = response.data.profileSuite.metadata.name;
          }
          return response.data.profileSuite;
        });
      };

      service.UpdateProfileSuite = function (ProfileSuiteId, ProfileSuiteData) {
        return ApiService.put('/profilesuites/' + ProfileSuiteId, ProfileSuiteData);
      };

      service.ActivateProfileSuite = function (ProfileSuiteId, ProfileSuiteData) {
        ProfileSuiteData.metadata.active = true;
        return ApiService.put('/profilesuites/' + ProfileSuiteId, ProfileSuiteData);
      };

      service.RetrieveComponents = function (ProfileSuiteId) {
        return ApiService.get('/profilesuites/' + ProfileSuiteId + '/components').then(
          function (response) {
            return response.data.components;
          },
        );
      };

      service.RemoveComponent = function (ProfileSuiteId, ComponentId) {
        if (ProfileSuiteId !== undefined && ComponentId !== undefined) {
          return ApiService.delete(
            '/profilesuites/' + ProfileSuiteId + '/components/' + ComponentId,
          );
        }
      };

      return service;
    },
  ]);
})();
