(function () {
  'use strict';

  angular
    .module('module.sales')
    .directive('ttRetailerFinishSetDiscounts', ['SettingsService', directive]);

  /**
   * Init directive
   */
  function directive(SettingsService) {
    return {
      restrict: 'A',
      templateUrl: 'templates/sales.retailer.finishSetDiscounts.directive.template.html',
      controllerAs: 'discountsVm',
      scope: {
        retailerSettings: '=',
      },
      bindToController: true,
      controller: controller(SettingsService),
    };
  }

  function controller(SettingsService) {
    return function () {
      /* jshint validthis: true */

      var vm = this;

      vm.finishSetDiscountsEnabled = checkForFinishSetDiscounts(vm.retailerSettings.products);
      vm.resetFinishSetDiscounts = resetFinishSetDiscounts;

      SettingsService.getSettings().then(function (data) {
        var companyCode = data.metadata.companyCode;
        var companiesWithSetDiscounts = ['ABT'];
        vm.finishSetDiscountsVisible = companiesWithSetDiscounts.includes(companyCode);
      });
    };
  }

  /**
   * Set all finish set discounts to 0, e.g. when unticking the checkbox
   *
   * @param {object} products
   */
  function resetFinishSetDiscounts(products) {
    angular.forEach(products, function (product) {
      angular.forEach(product.finishSetDiscounts, function (discount, set) {
        product.finishSetDiscounts[set] = 0;
      });
    });
  }

  /**
   * Do any of the given products have an finish set discount set?
   *
   * @param {object} products
   * @returns {boolean}
   */
  function checkForFinishSetDiscounts(products) {
    var finishSetDiscountFound = false;

    angular.forEach(products, function (product) {
      angular.forEach(product.finishSetDiscounts, function (discount, set) {
        if (discount > 0) {
          finishSetDiscountFound = true;
        }
      });
    });

    return finishSetDiscountFound;
  }
})();
