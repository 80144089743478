(function () {
  'use strict';

  angular.module('module.sales').controller('controller.sales.orders.update', [
    '$scope',
    '$state',
    'moment',
    'OrdersService',
    'dataOrder',
    function ($scope, $state, moment, OrdersService, dataOrder) {
      $scope.order = dataOrder;

      $scope.minimumDate = moment().add(1, 'days').format('YYYY-MM-DD');

      $scope.update = function () {
        OrdersService.updateOrder($scope.order).then(function (order) {
          $state.go('sales.orders.retrieve', {orderId: order.id});
        });
      };
    },
  ]);
})();
