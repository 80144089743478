(function () {
  'use strict';

  angular.module('module.sales').component('ttOrderSlice', {
    templateUrl: 'templates/sales.orders.slice.component.template.html',
    bindings: {
      order: '<',
      showDate: '<?',
    },
    controller: ['Notification', controller],
  });

  function controller(Notification) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.showActions = false;
    $ctrl.showDate = typeof $ctrl.showDate === 'undefined' ? true : $ctrl.showDate;

    $ctrl.copyLinkAction = copyLinkAction;

    $ctrl.$onChanges = function (changes) {
      if (changes.order && changes.order.currentValue) {
        updateData(changes.order.currentValue);
      }
    };

    function updateData(order) {
      $ctrl.id = order.id;
      $ctrl.reference = order.reference;
      $ctrl.status = order.status;
      $ctrl.items = order.totalItems;
      $ctrl.lastUpdatedTime = order.lastUpdatedTime;
      $ctrl.downloadUrl = null;
      $ctrl.externalLinkUrl = null;
    }

    function copyLinkAction() {
      Notification.success('Link copied to clipboard');
    }
  }
})();
